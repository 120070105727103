import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Card, FloatingLabel, Form, Row, Col, ListGroup, OverlayTrigger
  , Tooltip
} from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { connect } from "react-redux";
import { deleteCustomField, setNewContractTypeData } from "@redux/contract/index.actions";

import { useTranslation } from "react-i18next";

import DefaultFieldsList from "@pages/contractTypes/components/CustomFieldOffcanvas/DefaultFieldsList";


const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts
});
const mapActionsToProps = {
  setNewContractTypeData,
};

const DropList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const handleInputChange = (event: any) => {
    console.log(event?.target?.value)
    console.log(event?.target?.name)
    props.setNewContractTypeData({
      key: "changeInput",
      data: {
        [event?.target?.name]: event?.target?.value,
      }
    })
  }

  return (
    <Card className="h-100">
      <Card.Body>
        <Row>
          <Col xs={12} className="mb-3">
            <FloatingLabel label={t("CONTRACTS_TYPES.TITLE_LABEL")}>
              <Form.Control
                name="title"
                onInput={event => handleInputChange(event)}
                type="text" placeholder={t("CONTRACTS_TYPES.TITLE_PLACEHOLDER")} />
            </FloatingLabel>
          </Col>
          <Col xs={12}>
            <DefaultFieldsList />
          </Col>
          <Col xs={12}>
            <h6 className="m-0 p-0 mb-2">{t("CONTRACTS_TYPES.CUSTOM_FIELDS")}</h6>
            <Droppable key={2} droppableId={"contractCustomFields"}>
              {(provided, snapshot) => (
                <div className="drop-list" ref={provided.innerRef}>
                  <ListGroup className="dropInner" as="ul">
                    {(props?.contracts?.newContractType?.customFields || []).map((item: any, index: number) => (
                      <Draggable
                        key={item?._id}
                        draggableId={item?._id + ""}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ListGroup.Item
                              key={index}
                              as="li"
                              className="rounded mb-1 border-1"
                            >
                              <div className="d-flex gap-1 align-items-center justify-content-between">
                                <span>{item?.title}</span>
                                <OverlayTrigger
                                  key={"kanban-tt-dp" + props?.pipeline?._id}
                                  placement="right"
                                  overlay={<Tooltip>
                                    <strong>{t("CONTRACTS_CUSTOM_FIELDS.INTERNAL_NAME")}:</strong> {item?.key}
                                    <br /><strong>{t("CONTRACTS_CUSTOM_FIELDS.FIELD_TYPE")}:</strong> {item?.CustomComponent}
                                    <br /><strong>{t("CONTRACTS_CUSTOM_FIELDS.REQIRED")}:</strong> {item?.isOptional === true ? 'Required' : 'Not Required'}
                                  </Tooltip>}
                                >
                                  <i className="icon">
                                    <span>
                                      <FeatherIcons icon={'info'} />
                                    </span>
                                  </i>
                                </OverlayTrigger>

                              </div>
                            </ListGroup.Item>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </ListGroup>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(DropList);
