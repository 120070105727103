import React, { useEffect, useMemo, useRef, useState } from "react";

import QuillEditor from "@components/QuillEditor";
import { Accordion, Button, Card, Col, Dropdown, DropdownButton, Form, ListGroup, Modal, Row, Table } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { setFileData, editTemplate, createDocumentFromTemplate, addFilesToContract } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal, toggleGenericAddContractCompaniesModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";

import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import ContractOverviewRightPanel from "@popups/ContractOverview/components/ContractOverviewRightPanel";
import { BsLock, BsUnlock, BsShieldLock } from "react-icons/bs";
import { MdLock, MdLockOpen, MdLockOutline } from "react-icons/md";
import ContractsSelect from "@components/SelectsPaginated/Files";
import {
  getContractById, getContractTypes,
  getCompanyStatuses,
  getCompanyPipelines,
  editContractDetails,
  resetContractOverview
} from "@redux/contract/index.actions";
import { evaluate } from "mathjs";

import './index.scss';
import { DefaultLoader } from "@components/Loader";
import InfoCards from "./components/InfoCards";
import StatusBar from "./components/StatusBar";
import Avatar from "react-avatar";
import AssignedUsers from "@popups/ContractOverview/components/AssignedUsers";
import AssignedUsersFlatList from "./components/AssignedUsersFlatList";
import Companies from "./components/Companies";
import CompaniesFlatList from "./components/CompaniesFlatList";
import Tags from "./components/Tags";
import Reminder from "./components/Reminder";
import CDNUploadPopup from "@popups/CDNUploadPopup";
import Files from "./components/Files";
import EditModal from "./components/EditModal";
import DeleteModal from "./components/DeleteModal";
import PageTitle from "@components/PageTitle";
import moment from "moment";
import _ from "lodash";
const mapStateToProps = (state: any) => ({
  components: state?.components,
  contracts: state?.contracts,
  files: state?.files
});
const mapActionsToProps = {
  toggleGenericContractOverviewModal,
  getContractById,
  getContractTypes,
  getCompanyStatuses,
  getCompanyPipelines,
  editContractDetails,
  resetContractOverview,
  addFilesToContract,
  toggleGenericAddContractCompaniesModal
};






const ContractOverviewModal = (props: any) => {
  const { t } = useTranslation();
  const componentRef = useRef();

  const [loader, setLoader] = useState(false);

  const fetcher: any = async () => {
    setLoader(true);

    await props.getContractById({
      contractId: props?.contracts?.contractOverviewModalContractId,
      company: props?.companies?.currentCompany,
    })
    await props.getContractTypes()
    await props.getCompanyStatuses()
    await props.getCompanyPipelines()
    setLoader(false);
  }

  useEffect(() => {
    if (props?.contracts?.contractOverviewModalContractId != null || props?.contracts?.contractOverviewModalContractId != undefined) {
      fetcher();
    }
  }, [props?.contracts?.contractOverviewModalContractId])

  const [data, setData] = useState<{
    _id: string
    title?: string
  }>({
    _id: props?.contracts?.contractOverview?._id,
    title: props?.contracts?.contractOverview?.title,
  });
  const [editAbout, setEditAbout] = useState(false)

  const handleInput = (target: any) => {
    setData({ ...data, ...{ [target?.name]: target?.value } })
  }
  const handleEditAbout = (event: any) => {
    setEditAbout(true)
  }

  const handleSaveAbout = (event: any) => {
    event.preventDefault();


    const requestData: {
      _id: string
      title?: string
    } = {
      _id: props?.contracts?.contractOverview?._id,
      title: data?.title,
    }

    props.editContractDetails(requestData).then(() => setEditAbout(false))
  }

  const overview = props?.contracts?.contractOverview

  const handleTogglePrivacy = async () => {

    await props?.editContractDetails({
      _id: props?.contracts?.contractOverview?._id,
      private: !props?.contracts?.contractOverview?.private
    })
  }

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showReminderModal, setShowReminderModal] = useState<boolean>(false);

  const toggleNewReminderModal = () => {
    setShowReminderModal((prevstate) => !prevstate);
  };

  const [editModal, setEditModal] = useState(false);

  const toggleEditModal = () => {
    setEditModal((prevstate) => !prevstate);
  };
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModal((prevstate) => !prevstate);
  };
  const handleRemove = () => {

    toggleDeleteModal()

  }
  const handleEdit = () => {

    toggleEditModal()

  }


  const [externalCompanyList, setExternalCompanyList] = useState<any>(false);
  const [internalCompanyList, setInternalCompanyList] = useState<any>(false);

  const [selectedFiles, setSelectedFiles] = useState<any>([]);


  const [selectFileModal, setSelectFileModal] = useState(false);

  const toggleSelectFileModal = () => {
    setSelectFileModal((prevstate) => !prevstate);
  };

  if (props?.contracts?.contractOverviewModalContractId == null || props?.contracts?.contractOverviewModalContractId == undefined) {
    return (
      <>Loading</>
    )
  }

  return (
    <>
      <div className={props?.padding ? "overview-header px-3 py-2" : "overview-header"}>
        <div className="breadcrumb-overview m-0">
          <div className="breadcrumb-item">
            <a href="/">
              <FeatherIcons icon="home" size={16} />
            </a>
          </div>
          <div className="breadcrumb-item">
            <a href="/contract/table">
              {t('CONTRACTS.TABLE.TOP_TITLE')}
            </a>
          </div>
          <div className="breadcrumb-item">
            <a href={"/contract/overview/" + props?.contracts?.contractOverview?._id}>
              {t("CONTRACTS.PAGE_TITLE")}
            </a>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <Button className="d-flex" variant="link" onClick={handleRemove}>
            <FeatherIcons icon="trash-2" size={20} />
            <span>{t("CONTRACTS.OVERVIEW.ACTIONS.REMOVE_CONTRACT")}</span>
          </Button>
          <Button className="d-flex" variant="link" onClick={handleEdit}>
            <FeatherIcons icon="edit" size={20} />
            <span>{t("CONTRACTS.OVERVIEW.ACTIONS.EDIT_CONTRACT")}</span>
          </Button>
          <Button
            variant="primary"
            className="d-flex"
            onClick={() => {
              props?.toggleGenericContractOverviewModal({
                contractId: null
              });
            }}
          >

            {props?.contracts?.savingContractOverview ? (
              <>Saving</>
            ) : (
              <>
                Saved
              </>
            )}
          </Button>
          {props?.modalView === true ? (
            <Button
              variant="link"
              className="d-flex"
              onClick={() => {
                props?.toggleGenericContractOverviewModal({
                  contractId: null
                });
              }}
            >
              <FeatherIcons icon="x" size={20} />
            </Button>
          ) : null}
        </div>
      </div>

      <div className="contract-overview-modal-body">



        {editModal && (
          <EditModal
            show={editModal}
            toggle={toggleEditModal}
            data={props?.contracts?.contractOverview}
          />
        )}
        {deleteModal && (
          <DeleteModal
            show={deleteModal}
            toggle={toggleDeleteModal}
            data={props?.contracts?.contractOverview}
          />
        )}
        <div className="contract-overview-topbar">
          <Row>
            <Col md={7}>
              <InfoCards overview={overview} />

            </Col>
            <Col md={5}>
              <div className="contract-overview-topbar-right">
                <div className="status-select">
                  <StatusBar overview={overview}></StatusBar>
                </div>

                <div className="assigned-users">
                  <div className="user-list">
                    <AssignedUsers />

                  </div>
                  <div className="locked">
                    <Button
                      onClick={handleTogglePrivacy}
                      variant="link"
                      className="btn-sm"
                    >
                      {
                        props?.contracts?.contractOverview?.private ? <BsLock size={27} /> : <BsUnlock size={27} />
                      }
                    </Button>

                  </div>

                </div>
              </div>

            </Col>
          </Row>
        </div>
        <Row className="m-0">
          <Col className="overview-modal-left" md={7}>

            <div className="tags-icon mb-2" style={{
              padding: "10px"
            }}>
              <Tags />


            </div>

            <div className="section p-0">
              <FormInput
                key={props?.contracts?.contractOverview?._id + "title"}
                onBlur={(event) => {
                  if (event.target.value === "" || event.target.value === null || event.target.value === undefined) {
                    toast.error(t("CONTRACTS.OVERVIEW.TITLE_ERROR"));
                    return;
                  }
                  props.editContractDetails({
                    _id: props?.contracts?.contractOverview?._id,
                    title: event.target.value,
                  });
                }}
                className="overview-title-input"
                name="title"
                type="textarea"
                rows={Math.ceil(props?.contracts?.contractOverview?.title?.length / 35) <= 4 ? Math.ceil(props?.contracts?.contractOverview?.title?.length / 35) : 3}
                defaultValue={props?.contracts?.contractOverview?.title}
              />

            </div>
            <div className="section p-0">
              <FormInput
                key={props?.contracts?.contractOverview?._id + "description"}
                className="overview-about-input"
                name="description"
                placeholder={t("CONTRACTS.OVERVIEW.DESCRIPTION.DESCRIPTION_PLACEHOLDER")}
                type="textarea"
                rows={5}
                onBlur={(event) => {
                  props.editContractDetails({
                    _id: props?.contracts?.contractOverview?._id,
                    description: event.target.value,
                  });
                }}
                defaultValue={props?.contracts?.contractOverview?.description}
              />
            </div>

            <div className="section">
              <h4 className="fw-bold">{t("CONTRACTS.OVERVIEW.CONTRACT_TYPE")}</h4>
              <div
                style={{
                  backgroundColor: '#f3f4f7',
                  border: '1px solid rgba(0, 0, 0, 0.025)',
                  padding: '16px 20px',
                  width: '100%',
                  borderRadius: '4px'

                }}
              >
                {props?.contracts?.contractOverview?.type?.title}
              </div>

            </div>
            <div className="section">
              <h4 className="fw-bold">{t("CONTRACTS.OVERVIEW.CUSTOM_FIELDS.CUSTOM_FIELDS")}</h4>
              <Table style={{ width: 'auto' }} className="mb-0" bordered>
                <tbody>

                  {(props?.contracts?.contractOverview?.customFields?.map((e: any, i: number) => {
                    return (
                      <tr key={'cf1' + i} className="custom-field">
                        <th className="custom-field-title">
                          {e?.field?.title} :
                        </th>
                        <td className="custom-field-value">
                          {(() => {
                            if (e?.field?.CustomComponent === 'Number') {
                              if (e?.value) {
                                let x = e?.value;
                                var parts = x.toString().split(".");
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                return parts.join(",");
                              } else {
                                return ""
                              }
                            } else if (e?.field?.CustomComponent === 'Currency') {
                              if (e?.value) {
                                console.log('e?.field?', e?.field?.extraProps?.currency?.symbol)
                                let x = e?.value;
                                var parts = x.toString().split(".");
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                return (e?.field?.extraProps?.currency?.symbol || '') + ' ' + parts.join(",");
                              } else {
                                return ""
                              }
                            }

                            else if (e?.field?.CustomComponent === 'Formula') {

                              let val = null;

                              // Find all variables in formula between { and }
                              const regex = /{([^}]+)}/g;
                              let m;
                              let variables: any = [];
                              while ((m = regex.exec(e?.field?.extraProps?.formula)) !== null) {
                                // This is necessary to avoid infinite loops with zero-width matches
                                if (m.index === regex.lastIndex) {
                                  regex.lastIndex++;
                                }

                                // The result can be accessed through the `m`-variable.
                                m.forEach((match, groupIndex) => {
                                  if (groupIndex === 1) {
                                    variables.push(match);
                                  }
                                });
                              }

                              // Replace variables with values
                              let formula = e?.field?.extraProps?.formula;
                              for (let i = 0; i < variables.length; i++) {
                                let variable = variables[i];

                                let variableId = props?.contracts?.fields?.filter((field: any) => field?.key === variable)[0]?._id;

                                let variableValue = _.find(props?.contracts?.contractOverview?.customFields, { field: { _id: variableId } }) || {};

                                formula = formula?.replace(`{${variable}}`, variableValue?.value);
                              }

                              if (formula) {
                                try {
                                  val = evaluate(formula);
                                } catch (e) {
                                  val = '-';
                                }
                              } else { val = "-" }

                              return val;

                            }

                            else if (e?.field?.CustomComponent === 'Date') {
                              return moment(e?.value).format('DD/MM/YYYY')
                            } else {
                              return e?.value
                            }
                          })()}
                        </td>
                      </tr>

                    )
                  }))}
                </tbody>
              </Table>
            </div>
            <div className="section">
              <div className="d-flex align-items-center gap-1 mb-2">
                <h4 className="fw-bold">{t("CONTRACTS.OVERVIEW.COMPANIES.COMPANIES")} ({props?.contracts?.contractOverview?.contractCompanies?.length || 0})</h4>
                <Button onClick={() => props?.toggleGenericAddContractCompaniesModal({})}
                  variant="primary" size="sm">
                  {t("CONTRACTS.TABLE.ADD_NEW")}
                </Button>

              </div>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {t('CONTRACTS.ADD_CONTRACT.PARTIES_INTERNAL')}


                  </Accordion.Header>
                  <Accordion.Body>

                    <Companies partiesSide="internal" />

                    <div className="">
                      <Button
                        onClick={() => setInternalCompanyList(!internalCompanyList)}
                        className="d-flex align-items-center justify-content-between my-3" variant="primary" size="sm">
                        <FeatherIcons icon="plus" size={16} /> <span>{t("CONTRACTS.OVERVIEW.COMPANIES.ADD_COMPANY_TO_CONTRACT")}</span>
                      </Button>
                    </div>

                    {internalCompanyList && (<CompaniesFlatList partiesSide="internal" />)}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>{t('CONTRACTS.ADD_CONTRACT.PARTIES_EXTERNAL')}</Accordion.Header>
                  <Accordion.Body>
                    <Companies partiesSide="external" />
                    <div className="">
                      <Button
                        onClick={() => setExternalCompanyList(!externalCompanyList)}
                        className="d-flex align-items-center justify-content-between my-3" variant="primary" size="sm">
                        <FeatherIcons icon="plus" size={16} /> <span>{t("CONTRACTS.OVERVIEW.COMPANIES.ADD_COMPANY_TO_CONTRACT")}</span>
                      </Button>
                    </div>
                    {externalCompanyList && (<CompaniesFlatList partiesSide="external" />)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

            </div>
            <div className="section">
              <div className="d-flex align-items-center gap-1 mb-2">
                <h4 className="fw-bold">Dokümanlar</h4>

                <Dropdown>
                  <Dropdown.Toggle className="d-flex align-items-center justify-content-between gap-1" variant="primary" size="sm">
                    <FeatherIcons icon="plus" size={16} />
                    {t("CONTRACTS.OVERVIEW.FILES.ADD_FILES")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setShowUploadModal(!showUploadModal)} eventKey="1">{t("CONTRACTS.OVERVIEW.FILES.UPLOAD_FILES")}</Dropdown.Item>
                    <Dropdown.Item onClick={toggleSelectFileModal} eventKey="2">{t("CONTRACTS.OVERVIEW.FILES.SELECT_UPLOADED_FILES")}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {showUploadModal && <CDNUploadPopup defaultContract={{
                _id: props?.contracts?.contractOverview?._id,
                title: props?.contracts?.contractOverview?.title,
              }} />}



              <Files
                overview={props?.overview}
                onToggleUploadModal={() => { setShowUploadModal(true) }}
              />

            </div>
            <div className="section">
              <div className="d-flex align-items-center gap-1 mb-2">
                <h4 className="fw-bold">{t('CONTRACTS.OVERVIEW.REMINDER.REMINDERS')}</h4>
                <Button
                  onClick={() => setShowReminderModal(!showReminderModal)}
                  variant="primary" size="sm" className="d-flex align-items-center gap-1">
                  <FeatherIcons icon="plus" size={16} />
                  {t('CONTRACTS.OVERVIEW.REMINDER.ADD_REMINDER')}
                </Button>
              </div>
              <Reminder
                toggleNewReminderModal={toggleNewReminderModal}
                showReminderModal={showReminderModal} />

            </div>
          </Col>

          <Col className="overview-modal-right" md={5}>
            <ContractOverviewRightPanel contractId={props?.contracts?.contractOverviewModalContractId} />
          </Col>
        </Row>
      </div>

      <Modal
        contentClassName="border shadow"
        show={selectFileModal}
        onHide={toggleSelectFileModal}
        centered
        size="lg"
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title>{t("CONTRACTS.OVERVIEW.FILES.SELECT_UPLOADED_FILES")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="section">
            <ContractsSelect
              isMulti={true}
              onValueSubmit={(value: any) => {
                setSelectedFiles(value);
              }}


            />

            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px'

            }}>
              <Button
                onClick={() => {
                  if (selectedFiles?.length > 0) {
                    props?.addFilesToContract(
                      {
                        contractId: props?.contracts?.contractOverviewModalContractId,
                        files: selectedFiles
                      }
                    )
                  }
                  toggleSelectFileModal();
                }}
                variant="primary" size="lg" className="d-flex align-items-center gap-1">
                <FeatherIcons icon="plus" size={20} />
                Kayıt ile ilişkilendir
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ContractOverviewModal);
