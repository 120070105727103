import React, { useEffect, useState } from "react";
import FlatList from "@components/FlatList";
import { connect } from "react-redux";
import FeatherIcons from "feather-icons-react";
import Avatar from "react-avatar";
import { getCompanyUserList } from '@redux/companies/index.actions';
import { addAssignedUserToContract, removeAssignedUserToContract } from '@redux/contract/index.actions';
import { DefaultLoader } from '@components/Loader';
import _ from "lodash";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const mapStateToProps = (state: any) => ({
    users: state.users,
    companies: state.companies,
    contracts: state.contracts
})

const mapActionsToProps = { getCompanyUsers: getCompanyUserList, addAssignedUserToContract, removeAssignedUserToContract }


const ContactsFlatList: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshed, setRefreshed] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [batchSize, setBatchSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [more, setMore] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const loadMore = () => {
        props?.getCompanyUsers({
            page,
            batchSize,
            more: {
                searchText: searchText
            }
        }).then((res: any) => {
            ((res?.pagination?.page * batchSize) < total) ? setMore(true) : setMore(false)
            if ((res?.pagination?.page * batchSize) < total) {
                setMore(true);
                setPage(res?.pagination?.page + 1)
            } else {
                setMore(false);
            }
            setData([...data, ...res?.node?.users])

        })

    }

    const handleSearch = (event: any) => {
        setLoading(true)
        setPage(1);
        setSearchText(event?.target?.value)
    }


    const handleRemove = (data: {
        _id: string
    }) => {
        const requestData: {
            contractId: string
            companyId: string
            removedItem: string
        } = {
            contractId: props?.contracts?.contractOverview?._id,
            companyId: props?.companies?.currentCompany,
            removedItem: data?._id
        }

        props?.removeAssignedUserToContract(requestData);
    }

    const handleAdd = (data: {
        _id: string
    }) => {
        const requestData: {
            contractId: string
            companyId: string
            addedItem: string
        } = {
            contractId: props?.contracts?.contractOverview?._id,
            companyId: props?.companies?.currentCompany,
            addedItem: data?._id
        }

        props?.addAssignedUserToContract(requestData);
    }
    const debounceSearch = _.debounce(handleSearch, 600);

    useEffect(() => {
        props?.getCompanyUsers({
            page,
            batchSize,
            more: {
                searchText: searchText
            }
        }).then((res: any) => {
            if (res?.pagination?.totalCount) setTotal(res?.pagination?.totalCount);
            if ((res?.pagination?.page * batchSize) < res?.pagination?.totalCount) {
                setMore(true);
                setPage(page + 1);
            } else {
                setMore(false)
            }
            setData([...res?.node?.users])
            setLoading(false);
        })
    }, [searchText])

    return (
        <>
            <input
                className="my-2 mt-1 form-control form-control-sm"
                placeholder={t("CONTRACTS.OVERVIEW.ASSIGNED_USERS.SEARCH_PLACEHOLDER")}
                type="text"
                name="searchText"
                defaultValue={searchText}
                onInput={debounceSearch}
            />
            <div className="flat-scroll" id="contractContactsFlatList">
                {
                    (loading) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {
                    (!refreshed) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {refreshed && <FlatList
                    scrollableParentTarget={"contractContactsFlatList"}
                    data={data}
                    renderItem={(item, index) => (
                        <div key={item?._id} className="d-flex justify-content-between align-items-center gap-1 flex-wrap p-1">
                            <span className="d-flex justify-content-center align-items-center gap-1">
                                <span><Avatar name={item?.name + ' ' + item?.surname} className="rounded-circle" size="25" /></span>
                                <span className="text-dark text-nowrap">{item?.name + ' ' + item?.surname}</span>
                            </span>
                            {
                                props?.contracts?.contractOverview?.assignedUsers.includes(item?._id) ? (
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => handleRemove(item)}
                                    >
                                        <FeatherIcons icon="x" size={14} />
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outline-success"
                                        size="sm"
                                        onClick={() => handleAdd(item)}

                                    >
                                        <FeatherIcons icon="plus" size={14} />
                                    </Button>
                                )

                            }

                        </div>
                    )}
                    hasMore={more}
                    endMessage={
                        <div className="d-flex align-items-center justify-content-center no-more">
                            {t("CONTRACTS.OVERVIEW.ASSIGNED_USERS.ALL_DATA_LOADED")}
                        </div>
                    }
                    onEndReached={() => {
                        loadMore()
                    }}
                    onEndReachedThreshold={0.5}
                    keyExtractor={(item, index) => index.toString()}
                />
                }

            </div>
        </>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(ContactsFlatList);
