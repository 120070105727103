import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

// components
import PageTitle from '@components/PageTitle';

import { connect } from 'react-redux';
import { getContractFiles } from '@redux/contract/index.actions';

import FilesTableOverview from "@pages/contracts/Overview/components/FilesTableOverview";
import { recentFiles } from './FilesTableOverview/data'
const mapStateToProps = (state: any) => ({
    companies: state.companies,
    users: state.users,
    contracts: state.contracts,
    files: state.files
})

const mapActionsToProps = {
    getContractFiles: getContractFiles,
}


const FilesTable: React.FunctionComponent<any> = (props) => {

    const [data, setData] = useState<any[]>([]);
    const [sort, setSort] = useState<any>(null);
    const [page, setPage] = useState<number>(1);
    const [searchTextChange, setSearchTextChange] = useState<string>('');
    const [searchText, setSearchText] = useState<string | null>(null);
    const [requestData, setRequestData] = useState<any>({});
    const contractOverview = props?.contracts?.contractOverview;
    useEffect(() => {
        const data = props.contracts?.files.get(props?.contracts?.pagination?.page);
        if (data) {
            setPage(props.contracts?.pagination.page);
            setData(data)
        }
        //alert(JSON.stringify(props.files?.files.get(props?.files?.pagination?.page)))
    }, [props.contracts?.pagination])

    useEffect(() => {
        if (contractOverview?._id) {
            if (searchText === undefined || searchText === null || searchText === '') {
                setSearchText(null);
                props?.getContractFiles({
                    contractId: props?.contracts?.contractOverview?._id,
                    page: page,
                    batchSize: 50,
                    more: {
                        sort: sort
                    }
                })
            } else {
                props?.getContractFiles({
                    contractId: props?.contracts?.contractOverview?._id,
                    page: page,
                    batchSize: 50,
                    more: {
                        searchText: searchText,
                        sort: sort
                    }
                })
            }
        }
    }, [page, sort, searchText, contractOverview?._id])

    return (
        <>


            {contractOverview?._id && <FilesTableOverview
                onSortChange={(value: any) => {
                    setSort(value);
                }}
                onPageChange={(value: any) => {
                    setPage(value);
                }}
                pagination={props?.contracts?.pagination}
                data={data}
                onFetch={() => {
                    props?.getContractFiles({
                        contractId: props?.contracts?.contractOverview?._id,
                        page: page,
                        batchSize: 50,
                        more: {
                            searchText: searchText,
                            sort: sort
                        }
                    })
                }}
                recentFiles={recentFiles}
            />}
        </>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(FilesTable);
