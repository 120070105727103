import React, { useEffect, useState } from 'react';
import FlatListSelect from '@components/FlatListSelect';
import api from '@clients/api.client';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
const randomIdGenerator = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const mapStateToProps = (state: any) => ({
    companies: state.companies,
    users: state.users,
})

const mapActionsTopProps = {

};


const ContractsSelect: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const batchSize = 30;
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [value, setValue] = useState(null);
    const [data, setData] = useState<{ label: string, value: string }[]>([]);


    const fetchContracts = async () => {
        console.log(searchText)
        return
    }


    return (
        <FlatListSelect
            data={data}
            value={value}
            cacheUniqs={[]}
            onChange={(currentItem: any) => {
                props?.onValueSubmit(currentItem?.value);
                setValue(currentItem);
            }}
            placeholder={t("SELECTS.CONTRACTS.SEARCH_CONTRACTS")}
            defaultOption={props?.defaultOption?.value ? props?.defaultOption : null}
            className="select-contracts-type"
            onEndReached={async (search: string, additional: any) => {
                if (searchText !== search) {
                    if (!(search === "" || search === null || search === undefined)) {
                        console.warn("SEARCH CHANGING");
                        setSearchText(search)
                        setPage(1);
                    }
                } else {
                    setPage(page + 1);
                }
                const response = await api.get(`/contracts/list/${props?.companies?.company?._id}`, {
                    params: {
                        batchSize: batchSize,
                        page: page,
                        searchText: search
                    }
                }).then((response: any) => {
                    toast.success(t("SELECTS.CONTRACTS.CONTRACT_CHANGE_SUCCESS"))
                    return response.data?.node?.contracts;
                }).catch((error: any) => {
                    console.log(error);
                });
                const newData = (response || []).map((item: any) => {
                    return {
                        label: item?.title,
                        value: item?._id
                    }
                })

                const finalNewData = _.difference(newData, data);
                setData([...data, ...finalNewData]);
                console.warn(finalNewData.length)
                if (newData.length < batchSize) {
                    return {
                        options: finalNewData,
                        hasMore: false,
                        additional: {
                            page: page + 1
                        }
                    };
                }
                return {
                    options: [...finalNewData],
                    hasMore: newData.length !== 0,
                    additional: {
                        page: page + 1
                    }
                };
                //setPage(page + 1);
            }}
        />
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(ContractsSelect);