//TYPES

import { } from 'react-icons/bs'

import { folderDetailTypes, RecentFileTypes } from "@pages/files/FilesManagement/interfaces/data.interfaces";

const fileTypesDetails: folderDetailTypes[] = [
    {
        icon: 'bi bi-file-earmark-zip',
        name: 'Zip Files',
        size: '18',
        variant: 'warning',
    },
    {
        icon: 'bi-file-earmark-word',
        name: 'Doc Files',
        size: '17',
        variant: 'info',
    },
    {
        icon: 'bi bi-file-earmark-text',
        name: 'Text Files',
        size: '13',
        variant: 'secondary',
    },
    {
        icon: 'bi bi-images',
        name: 'Picture Files',
        size: '28',
        variant: 'danger',
    },
    {
        icon: 'bi bi-file-earmark-pdf',
        name: 'PDF Files',
        size: '30',
        variant: 'danger',
    },
    {
        icon: 'bi bi-file-earmark-ppt',
        name: 'PPT Files',
        size: '42',
        variant: 'primary',
    },
    {
        icon: 'bi bi-file-earmark-excel',
        name: 'XLS Files',
        size: '28',
        variant: 'success',
    },
    {
        icon: 'bi bi-folder',
        name: 'All Files',
        size: '120',
        variant: 'info',
    },
];

const recentFiles: RecentFileTypes[] = [
    {
        name: 'App Design & Devlopment',
        folderName: 'Work',
        fileSize: '5.6 GB',
        lastviewed: '03 Dec',
    },
    {
        name: 'Shreyu-admin.zip',
        folderName: 'Shreyu',
        fileSize: '1.3 GB',
        lastviewed: '05 Oct',
    },
    {
        name: 'Profile.jpg',
        folderName: 'Photo',
        fileSize: '2.6 MB',
        lastviewed: '11 Nov',
    },
    {
        name: 'Sign.jpeg',
        folderName: 'Photo',
        fileSize: '1.3 MB',
        lastviewed: '11 Nov',
    },
    {
        name: 'Licence.pdf',
        folderName: 'Personal',
        fileSize: '7.8 MB',
        lastviewed: '15 Mar',
    },
    {
        name: 'Photo',
        folderName: 'Personal',
        fileSize: '17.3 GB',
        lastviewed: '15 Mar',
    },
    {
        name: 'Teri Ladki.mp3',
        folderName: 'Song',
        fileSize: '8.9 MB',
        lastviewed: '14 Mar',
    },
    {
        name: 'Data.doc',
        folderName: 'Shreyu',
        fileSize: '233 KB',
        lastviewed: '13 Mar',
    },
];

export { fileTypesDetails, recentFiles };
