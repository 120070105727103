import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { connect } from "react-redux";

// components
import EditContract from "@pages/contracts/EditContract";

import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({});
const mapActionsToProps = {};

const EditModal = (props: any) => {
  const { t } = useTranslation();


  return (
    <Modal
      show={props?.show}
      onHide={props?.toggle}
      centered
      size="lg"
      backdropClassName="contract-overview-modal-backdrop"
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">{t("CONTRACTS.EDIT_CONTRACT.MODAL_TITLE")}</h4>
      </Modal.Header>
      <Modal.Body className="scrollableModal">
        <EditContract close={props?.toggle} />
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(EditModal);
