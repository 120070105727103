import React, { useEffect, useState } from "react";
import ScreenModal from "@components/Modals/ScreenModal";
import { connect } from 'react-redux';
import "./CreateCompany.scss";
import globalStyles from "@styles/globalStyles";
import { Form, Spinner } from "react-bootstrap";
import ButtonLoader from "@components/Buttons/ButtonLoader";
import { GrChapterAdd, GrAdd } from 'react-icons/gr';
import { BsFillCursorFill, BsSearch } from 'react-icons/bs';
import { HiViewGridAdd } from 'react-icons/hi';
import { createNewCompany } from "@redux/companies/index.actions";
import { setNewCompanyData } from "@redux/components/index.actions";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import Image1 from "./image/onboarding-1.png";
import Image2 from "./image/onboarding-2.png";

const mapStateToProps = (state: any) => ({
    users: state.users,
    components: state.components,
    companies: state.companies
});

const mapActionsTopProps = {
    setNewCompanyData,
    createNewCompany: createNewCompany
}


const RenderFooterWithRedux: React.FunctionComponent<{
    users: any,
    components: any,
    companies: any,
    setNewCompanyData: any,
    batchOperations: {
        onClose: () => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    }
}> = (props) => {
    const { t } = useTranslation()
    const [disabled, setDisabled] = useState<boolean>(true);
    useEffect(() => {
        const keys: string[] = Object.keys(props?.components?.newCompanyData?.companyData);
        const isEmpty = keys.map((key: string) => {
            return props?.components?.newCompanyData?.companyData[key];
        }).some((value: any) => {
            return value === null || value === "" || value === undefined;
        })
        if (isEmpty) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [props?.components?.newCompanyData?.companyData])
    return (
        <React.Fragment>
            {props?.batchOperations?.index > 0 && (
                <button className="btn btn-outline-primary companyOnboard-previous" onClick={props?.batchOperations?.previous}>
                    {t("POPUPS.COMPANY_ONBOARDING.PREVIOUS")}
                </button>
            )
            }
            {(
                <button className="btn btn-primary companyOnboard-next" disabled={disabled} onClick={() => {
                    props?.batchOperations?.next()

                    if (!isEmpty) {
                        props?.batchOperations?.next()
                    }
                }}>
                    {t("POPUPS.COMPANY_ONBOARDING.NEXT")}
                </button>
            )}
        </React.Fragment>
    )
}


export const renderFooter = connect(mapStateToProps, mapActionsTopProps)(RenderFooterWithRedux);

const CreateCompany: React.FunctionComponent<{
    users: any,
    components: any,
    companies: any,
    setNewCompanyData: any,
    createNewCompany: any,
    batchOperations: {
        onClose: (isLastStep?: boolean) => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    }
}> = (props) => {
    const { t } = useTranslation();
    const onInput = (e: any) => {
        const key = e.currentTarget.name;
        const value = e.currentTarget.value;
        props?.setNewCompanyData({
            key: "companyData",
            value: {
                ...props?.components?.newCompanyData?.companyData,
                [key]: value
            }
        })

    }
    const handleSubmit = (e: any) => {
    }

    return (
        <div className="container-fluid create-company">
            <div className="row create-company-row">
                <div className="col-lg-6 col-md-12">
                    <div className="innerContent">
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mb-4" controlId="emailValidation">
                                <Form.Label htmlFor="title">
                                    {t("POPUPS.COMPANY_ONBOARDING.TITLE_LABEL")}
                                </Form.Label>
                                <Form.Control
                                    onInput={onInput}
                                    id="title"
                                    name="title"
                                    type="text"
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.TITLE_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.companyData?.title}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.TITLE_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* <Form.Group className="mb-4" controlId="emailValidation">
                                <Form.Label htmlFor="website">
                                    {t("POPUPS.COMPANY_ONBOARDING.WEBSITE_LABEL")}
                                </Form.Label>
                                <Form.Control
                                    onInput={onInput}
                                    id="website"
                                    name="website"
                                    type="text"
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.WEBSITE_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.companyData?.website}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.WEBSITE_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4" controlId="emailValidation">
                                <Form.Label htmlFor="description">
                                    {t("POPUPS.COMPANY_ONBOARDING.DESCRIPTION_LABEL")}
                                </Form.Label>
                                <Form.Control
                                    onInput={onInput}
                                    id="description"
                                    name="description"
                                    type="text"
                                    as="textarea"
                                    rows={10}
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.DESCRIPTION_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.companyData?.description}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.DESCRIPTION_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4" style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                                controlId="companyNameValidation">
                                <div>

                                </div>
                            </Form.Group> */}

                            <button
                                disabled={
                                    props?.components?.newCompanyData?.companyData?.title === null ||
                                    props?.components?.newCompanyData?.companyData?.title.trim() === "" ||
                                    props?.components?.newCompanyData?.companyData?.title === undefined ||
                                    props?.companies?.creatingCompany === true
                                }
                                className="btn btn-primary w-100 companyOnboard-next" onClick={async (e: any) => {
                                    //props?.batchOperations?.next();
                                    e?.preventDefault();
                                    await props?.createNewCompany({
                                        ...props?.components?.newCompanyData?.companyData,
                                        survey: props?.components?.newCompanyData?.surveyData
                                    })
                                    //props.batchOperations.onClose(true);
                                }}>
                                {props?.companies?.creatingCompany === true ? (
                                    <div className="d-flex align-items-center justify-content-center gap-1">

                                        <span className="d-flex align-items-center justify-content-center">
                                            {t("POPUPS.COMPANY_ONBOARDING.CREATING")}
                                        </span>
                                        <span className="d-flex align-items-center justify-content-center">
                                            <Spinner animation="border" size="sm" />
                                        </span>
                                    </div>
                                ) : (<>{t("POPUPS.COMPANY_ONBOARDING.FINISH")}</>)
                                }

                            </button>
                        </Form>
                    </div>
                </div>
                <div className="col-lg-6 d-lg-block d-md-none">
                    <div
                        style={{
                            width: '100%',
                            objectFit: 'contain'
                        }}>
                        <img style={{
                            width: '100%'
                        }} src={Image2} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(CreateCompany);