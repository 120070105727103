import * as ActionDispatchTypes from "@redux/ActionDispatchTypes/index.types";
import { Reducer } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

const initialState = {
    currentPipeline: null,
    currentPipelinesStatuses: [],
    pipelines: [],
    fetchingPipelines: false,
    types: {
        ContractsTypes: []
    },
    newContractType: {
        title: '',
        showStartDate: false,
        showEndDate: false,
        showEffectiveDate: false,
        showRenewalDate: false,
        showSignedDate: false,
        customFields: []
    },
    editedContractType: {
        _id: '',
        title: '',
        showStartDate: false,
        showEndDate: false,
        showEffectiveDate: false,
        showRenewalDate: false,
        showSignedDate: false,
        customFields: []
    },
    fetchingContracts: false,
    kanban: {},
    kanbanPagination: {},
    fetchingStatuses: false,
    statuses: [],
    newPipeline: {
        data: {},
        statuses: []
    },
    newStatus: {
        title: '',
        description: ''
    },
    editedPipeline: {
        statuses: []
    },
    editedStatus: {
        _id: '',
        title: '',
        description: ''
    },
    cratingStatuses: false,
    fields: [],
    fetchingFields: false,
    tables: {
        refreshContractListTable: false,
        contractList: new Map(),
        contractListPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
        refreshContractCompaniesTable: false,
        contractCompanies: new Map(),
        contractCompaniesPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
        refreshContractFiltersTable: false,
        contractFilters: new Map(),
        contractFiltersPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
        refreshContractContactsTable: false,
        contractContacts: new Map(),
        contractContactsPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
        refreshContractTypesTable: false,
        contractTypes: new Map(),
        contractTypesPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
        refreshContractCustomFieldsTable: false,
        contractCustomFields: new Map(),
        contractCustomFieldsPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
    },
    contractOverview: {},
    contractOverviewRefresh: false,
    fetchingExcel: false,
    exportExcel: {
        contractList: new Map(),
        contractListPagination: {
            page: 1,
            hasMore: false
        },

    },
    pagination: {
        totalCount: 0,
        searchText: null,
        page: 1,
        batchSize: 50,
    },
    uploadedFiles: [],
    files: new Map(),
    endedFetchingFiles: false,
    editingContractType: false,
    contractOverviewModalContractId: null,
    savingContractOverview: false,
};

const ContractsReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {


        case ActionDispatchTypes.SET_CONTRACT_ID_TO_OVERVIEW_MODAL:
            return {
                ...state,
                contractOverviewModalContractId: action?.payload?.contractId,
                contractOverview: action?.payload?.contractId == null ? {} : state.contractOverview,

            }
        case ActionDispatchTypes.GET_CONTRACT_FILES:
            return {
                ...state,
                endedFetchingFiles: false
            }
        case ActionDispatchTypes.GET_CONTRACT_FILES_SUCCESSFUL:
            state.files.set(
                action.payload?.pagination?.page,
                action.payload?.node?.files.map((item: any, index: number) => {
                    return {
                        index: (action.payload?.pagination?.page * action.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        updateDelete: true
                    }
                }));
            return {
                ...state,
                endedFetchingFiles: true,
                pagination: {
                    ...state.pagination,
                    ...action.payload?.pagination
                }
            }

        case ActionDispatchTypes.GET_CONTRACT_FILES_FAILED:
            state.files.set(
                action.payload?.pagination?.page,
                []
            );
            return {
                ...state,
                endedFetchingFiles: true,
                pagination: {
                    ...state.pagination,
                    ...action.payload?.pagination
                }
            }
        case ActionDispatchTypes.GET_CONTRACT_CONTRACT_COMPANIES_SUCCESSFUL:


            state?.tables?.contractCompanies?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.contractCompanies.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...state?.tables?.refreshContractCompaniesTable && { refreshContractCompaniesTable: false },
                    contractCompaniesPagination: {
                        ...state?.tables?.contractCompaniesPagination,
                        ...action?.payload?.pagination
                    }
                }
            }
        case ActionDispatchTypes.GET_CONTRACT_FILTERS_SUCCESSFUL:


            state?.tables?.contractFilters?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.contractsFilters.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...state?.tables?.refreshContractFiltersTable && { refreshContractFiltersTable: false },
                    contractFiltersPagination: {
                        ...state?.tables?.contractFiltersPagination,
                        ...action?.payload?.pagination
                    }
                }
            }

        case ActionDispatchTypes.GET_CONTRACT_LIST_SUCCESSFUL:


            state?.tables?.contractList?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.contracts.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...state?.tables?.refreshContractListTable && { refreshContractListTable: false },
                    contractListPagination: {
                        ...state?.tables?.contractListPagination,
                        ...action?.payload?.pagination
                    }
                }
            }


        case ActionDispatchTypes.GET_CONTRACT_CONTRACT_CONTACTS_SUCCESSFUL:


            state?.tables?.contractContacts?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.contracts.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...state?.tables?.refreshContractContactsTable && { refreshContractContactsTable: false },
                    contractContactsPagination: {
                        ...state?.tables?.contractContactsPagination,
                        ...action?.payload?.pagination
                    }
                }
            }

        case ActionDispatchTypes.GET_TABLE_CONTRACT_TYPES_SUCCESSFUL:


            state?.tables?.contractTypes?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.contractTypes.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...state?.tables?.refreshContractTypesTable && { refreshContractTypesTable: false },
                    contractTypesPagination: {
                        ...state?.tables?.contractTypesPagination,
                        ...action?.payload?.pagination
                    }
                }
            }

        case ActionDispatchTypes.GET_TABLE_CONTRACT_CUSTOM_FIELD_SUCCESSFUL:


            state?.tables?.contractCustomFields?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.customFields.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...state?.tables?.refreshContractCustomFieldsTable && { refreshContractCustomFieldsTable: false },
                    contractCustomFieldsPagination: {
                        ...state?.tables?.contractCustomFieldsPagination,
                        ...action?.payload?.pagination
                    }
                }
            }

        case ActionDispatchTypes.LOGOUT:
            return {
                ...initialState
            }

        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }
        /* GETTING CURRENT PIPELINE */
        case ActionDispatchTypes.GET_SINGLE_PIPELINE:
            return {
                ...state,
                currentPipeline: {
                    ...action?.payload, // Adding nested changes
                },
            };
        case ActionDispatchTypes.GET_SINGLE_STATUSES:
            return {
                ...state,
                currentPipelinesStatuses: {
                    ...action?.payload, // Adding nested changes
                },
            };
        case ActionDispatchTypes.GET_CONTRACT_TYPES_BY_COMPANY:
            return {
                ...state,
                types: {
                    ...action?.payload, // Adding nested changes
                },
            };
        /* GET CDURRENT COMPANY */
        case ActionDispatchTypes.GET_CONTRACTS_BY_STATUS:
            return {
                ...state,
                kanban: {},
            };
        case ActionDispatchTypes.GET_CONTRACTS_BY_STATUS_LOAD_MORE:
            return {
                ...state,
            };
        case ActionDispatchTypes.GET_CONTRACTS_BY_STATUS_SUCCESSFUL:
            return {
                ...state,
                kanban: {
                    ...state?.kanban,
                    [action.status]: Array.from(
                        new Set([
                            ...(state?.kanban[action.status] || []),
                            ...action?.payload?.contracts,
                        ])
                    ),
                },
                kanbanPagination: {
                    ...state?.kanbanPagination,
                    [action.status]: action?.pagination,
                },
            };
        case ActionDispatchTypes.GET_CONTRACTS_BY_STATUS_FAILED:
            return {
                ...state,
            };

        /* CREATE CONTRACT */
        case ActionDispatchTypes.CREATE_NEW_CONTRACT:
            return {
                ...state,
            };
        case ActionDispatchTypes.CREATE_NEW_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                // kanban: {
                //     ...state?.kanban,
                //     [action.status]: Array.from(
                //         new Set([
                //             action?.payload?.contract,
                //             ...state?.kanban[action.status],
                //         ])
                //     ),
                // },
            };
        case ActionDispatchTypes.CREATE_NEW_CONTRACT_FAILED:
            return {
                ...state,
            };

        /* EDIT CONTRACT */
        case ActionDispatchTypes.EDIT_CONTRACT:
            return {
                ...state,
                savingContractOverview: true,
            };
        case ActionDispatchTypes.EDIT_CONTRACT_SUCCESSFUL:
            console.log(action?.payload)
            return {
                ...state,
                contractOverview: {
                    ...state.contractOverview,
                    ...action?.payload?.contract

                },
                savingContractOverview: false,
            };
        case ActionDispatchTypes.EDIT_CONTRACT_FAILED:
            return {
                ...state,
                savingContractOverview: false,
            };
        /* EDIT CONTRACT */
        case ActionDispatchTypes.DELETE_CONTRACT:
            return {
                ...state,
            };
        case ActionDispatchTypes.DELETE_CONTRACT_SUCCESSFUL:
            console.log(action?.payload)
            return {
                ...state,
                contractOverview: {}
            };
        case ActionDispatchTypes.DELETE_CONTRACT_FAILED:
            return {
                ...state,
            };

        /* GET CONTRACT BY ID */
        case ActionDispatchTypes.GET_CONTRACT:
            return {
                ...state,
                contractOverview: {},
            };
        case ActionDispatchTypes.GET_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                contractOverview: action?.payload?.contract
            };
        case ActionDispatchTypes.GET_CONTRACT_FAILED:
            return {
                ...state,
            };

        case ActionDispatchTypes.RESET_CONTRACT_OVERVIEW:
            return {
                ...state,
                contractOverview: initialState.contractOverview
            }

        /* KANBAN CHANGE CONTRACT STATUS - DRAG AND DROP */
        case ActionDispatchTypes.KANBAN_CONTRACT_STATUS_CHANGE:
            return {
                ...state,
            };
        case ActionDispatchTypes.KANBAN_CONTRACT_STATUS_CHANGE_SUCCESSFUL:
            const destinationStatus = action?.payload?.destination?.droppableId;
            const destinationIndex = action?.payload?.destination?.index;
            const sourceStatus = action?.payload?.source?.droppableId;
            const sourceIndex = action?.payload?.source?.index;

            const removedState = state?.kanban[sourceStatus][sourceIndex];

            const addedState = state?.kanban[destinationStatus];

            addedState.splice(destinationIndex, 0, removedState);

            return {
                ...state,
                kanban: {
                    ...state?.kanban,
                    ...state.kanban[sourceStatus].splice(sourceIndex, 1),
                    [destinationStatus]: Array.from(
                        new Set([...addedState, ...state?.kanban[destinationStatus]])
                    ),
                },
            };
        case ActionDispatchTypes.KANBAN_CONTRACT_STATUS_CHANGE_FAILED:
            return {
                ...state,
            };


        /* GET COMPANY OF PIPELINES */
        case ActionDispatchTypes.GET_COMPANY_PIPELINES:
            return {
                ...state,
                fetchingPipelines: true,
            };
        case ActionDispatchTypes.GET_COMPANY_PIPELINES_SUCCESSFUL:
            return {
                ...state,
                pipelines: action?.payload?.pipelines,
                fetchingPipelines: false,
            };
        case ActionDispatchTypes.GET_COMPANY_PIPELINES_FAILED:
            return {
                ...state,
            };

        /* GET COMPANY OF STATUSES */
        case ActionDispatchTypes.GET_COMPANY_STATUSES:
            return {
                ...state,
                fetchingStatuses: true,
                statuses: []
            };
        case ActionDispatchTypes.GET_COMPANY_STATUSES_SUCCESSFUL:
            return {
                ...state,
                statuses: action?.payload?.statuses,
                fetchingStatuses: false,
            };
        case ActionDispatchTypes.GET_COMPANY_STATUSES_FAILED:
            return {
                ...state,
            };


        case ActionDispatchTypes.NEW_PIPELINE_ADD_STATUS:


            state?.newPipeline?.statuses.splice(action?.payload?.destination?.index, 0, state.statuses[action?.payload?.source?.index]);

            return {
                ...state,
                ...state.statuses.splice(action?.payload?.source?.index, 1),
                newPipeline: {
                    ...state?.newPipeline,
                    statuses: Array.from(
                        new Set([...state?.newPipeline?.statuses, ...state?.newPipeline?.statuses])
                    ),

                },
            };


        case ActionDispatchTypes.NEW_PIPELINE_ORDER_STATUS:

            const roDestIndex = action?.payload?.destination?.index;
            const roSrcIndex = action?.payload?.source?.index;

            const roDestDroppableId = action?.payload?.destination?.droppableId;
            const roSrcDroppableId = action?.payload?.source?.droppableId;

            const reRemoved = state?.newPipeline?.statuses[roSrcIndex];
            state?.newPipeline?.statuses.splice(roSrcIndex, 1); // Removed old
            state?.newPipeline?.statuses.splice(roDestIndex, 0, reRemoved);

            return {
                ...state,
                ...state.statuses.splice(action?.payload?.source?.index, 1),
                newPipeline: {
                    ...state?.newPipeline,
                    statuses: Array.from(
                        new Set([...state?.newPipeline?.statuses, ...state?.newPipeline?.statuses])
                    ),

                },
            };

        case ActionDispatchTypes.NEW_PIPELINE_REMOVE_STATUS:

            const rmDestIndex = action?.payload?.destination?.index;
            const rmSrcIndex = action?.payload?.source?.index;

            const rmDestDroppableId = action?.payload?.destination?.droppableId;
            const rmSrcDroppableId = action?.payload?.source?.droppableId;

            const rmRemoved = state?.newPipeline?.statuses[rmSrcIndex];
            state?.newPipeline?.statuses.splice(rmSrcIndex, 1); // Removed old
            state?.statuses.splice(rmDestIndex, 0, rmRemoved);

            return {
                ...state,
                statuses: Array.from(
                    new Set([...state.statuses])
                ),
                newPipeline: {
                    ...state?.newPipeline,
                    statuses: Array.from(
                        new Set([...state?.newPipeline?.statuses, ...state?.newPipeline?.statuses])
                    ),

                },
            };


        case ActionDispatchTypes.SET_NEW_PIPELINE_DATA:

            return {
                ...state,

                newPipeline: {
                    ...state?.newPipeline,
                    data: {
                        ...state?.newPipeline?.data,
                        ...action?.payload
                    },

                },
            };
        case ActionDispatchTypes.SET_EDIT_PIPELINE_DATA:

            return {
                ...state,
                editedPipeline: { ...action?.payload },
            };
        case ActionDispatchTypes.SET_EDIT_PIPELINE_TITLE:

            return {
                ...state,
                editedPipeline: {
                    ...state.editedPipeline,
                    ...action?.payload
                },
            };
        case ActionDispatchTypes.SET_EDIT_PIPELINE_REORDER:

            const roEditDestIndex = action?.payload?.destination?.index;
            const roEditSrcIndex = action?.payload?.source?.index;
            const reEditRemoved = state?.editedPipeline?.statuses[roEditSrcIndex];
            state?.editedPipeline?.statuses.splice(roEditSrcIndex, 1);
            state?.editedPipeline?.statuses.splice(roEditDestIndex, 0, reEditRemoved);

            return {
                ...state,
                editedPipeline: {
                    ...state?.editedPipeline,
                    statuses: Array.from(
                        new Set([...state?.editedPipeline?.statuses])
                    ),

                },
            };

        case ActionDispatchTypes.SET_EDIT_PIPELINE_ADD_NEW_STATUS:

            return {
                ...state,
                editedPipeline: {
                    ...state?.editedPipeline,
                    statuses: _.unionWith(state?.editedPipeline?.statuses, action?.payload, _.isEqual),

                },
            };

        case ActionDispatchTypes.SET_EDIT_PIPELINE_REMOVE_STATUS:
            return {
                ...state,
                editedPipeline: {
                    ...state?.editedPipeline,
                    statuses: _.filter(state?.editedPipeline?.statuses, function (currentObject) {
                        return currentObject._id !== action?.payload?.statusId;
                    }),
                },
            };
        //CONTRACT_MIGRATION

        case ActionDispatchTypes.CONTRACT_MIGRATION:
            return {
                ...state,
            };

        case ActionDispatchTypes.CONTRACT_MIGRATION_SUCCESSFUL:
            return {
                ...state,
            };

        case ActionDispatchTypes.CONTRACT_MIGRATION_FAILED:
            return {
                ...state,
            };

        //EDIT_PIPELINE

        case ActionDispatchTypes.EDIT_PIPELINE:
            return {
                ...state,
                fetchingPipelines: true,
            };

        case ActionDispatchTypes.EDIT_PIPELINE_SUCCESSFUL:


            state?.pipelines
                .splice(_.findIndex(state?.pipelines, { _id: action?.payload?._id }), 1, action?.payload)

            return {
                ...state,
                pipelines: state?.pipelines,
                fetchingPipelines: false,
            };

        case ActionDispatchTypes.EDIT_PIPELINE_FAILED:
            return {
                ...state,
                fetchingPipelines: false,
            };

        //EDIT_PIPELINE

        case ActionDispatchTypes.DELETE_PIPELINE:
            return {
                ...state,
            };
        case ActionDispatchTypes.DELETE_PIPELINE_SUCCESSFUL:
            return {
                ...state,
                pipelines: _.filter(state?.pipelines, function (currentObject) {
                    return currentObject._id !== action?.payload?.pipelineId
                })
            };
        case ActionDispatchTypes.DELETE_PIPELINE_FAILED:
            return {
                ...state,
            };



        case ActionDispatchTypes.CREATE_NEW_PIPELINE:
            return {
                ...state,
                cratingStatuses: true,
            };
        case ActionDispatchTypes.CREATE_NEW_PIPELINE_SUCCESSFUL:
            return {
                ...state,
                pipelines: [action?.payload?.pipeline, ...state?.pipelines],
                newPipeline: {
                    data: {},
                    statuses: [],
                },
                cratingStatuses: false,
            };
        case ActionDispatchTypes.CREATE_NEW_PIPELINE_FAILED:
            return {
                ...state,
                cratingStatuses: false,
            };



        case ActionDispatchTypes.SET_NEW_STATUS_DATA:
            return {
                ...state,

                newStatus: {
                    ...state?.newStatus,
                    ...action?.payload
                }
            };

        case ActionDispatchTypes.SET_EDITED_STATUS_DATA:
            return {
                ...state,

                editedStatus: {
                    ...state?.editedStatus,
                    ...action?.payload
                }
            };

        case ActionDispatchTypes.CREATE_NEW_STATUS:
            return {
                ...state,
            };
        case ActionDispatchTypes.CREATE_NEW_STATUS_SUCCESSFUL:
            return {
                ...state,
                statuses: [action?.payload?.ContractsStatus, ...state?.statuses],
            };
        case ActionDispatchTypes.CREATE_NEW_STATUS_FAILED:
            return {
                ...state,
                // cratingStatuses: false,
            };

        //EDIT_NEW_STATUS
        case ActionDispatchTypes.EDIT_STATUS:
            return {
                ...state,
            };
        case ActionDispatchTypes.EDIT_STATUS_SUCCESSFUL:
            state?.statuses.splice(_.findIndex(state?.statuses, { _id: action?.payload?.ContractsStatuses?._id }), 1, action?.payload?.ContractsStatuses)

            return {
                ...state,
                statuses: state?.statuses
            };
        case ActionDispatchTypes.EDIT_STATUS_FAILED:
            return {
                ...state,
                // cratingStatuses: false,
            };

        case ActionDispatchTypes.DELETE_STATUS:
            return {
                ...state,
            };
        case ActionDispatchTypes.DELETE_STATUS_SUCCESSFUL:
            return {
                ...state,
                statuses: _.filter(state?.statuses, function (currentObject) {
                    return currentObject._id !== action?.payload?.statusId
                })
            };
        case ActionDispatchTypes.DELETE_STATUS_FAILED:
            return {
                ...state,
            };


        case ActionDispatchTypes.CREATE_NEW_CUSTOM_FIELD:
            return {
                ...state,
            };
        case ActionDispatchTypes.CREATE_NEW_CUSTOM_FIELD_SUCCESSFUL:
            return {
                ...state,
                fields: [action?.payload?.customField, ...state?.fields],
                tables: {
                    ...state.tables,
                    refreshContractCustomFieldsTable: true
                }
            };
        case ActionDispatchTypes.CREATE_NEW_CUSTOM_FIELD_FAILED:
            return {
                ...state,
            };

        //LIST CUSTOM FIELD
        case ActionDispatchTypes.LIST_CUSTOM_FIELD:
            return {
                ...state,
                fetchingFields: true,
            };
        case ActionDispatchTypes.LIST_CUSTOM_FIELD_SUCCESSFUL:
            return {
                ...state,
                fetchingFields: false,
                fields: [...action?.payload?.contractsCustomFields]
            };
        case ActionDispatchTypes.LIST_CUSTOM_FIELD_FAILED:
            return {
                ...state,
                fetchingFields: false,
            };
        //EDIT CUSTOM FIELD
        case ActionDispatchTypes.EDIT_CUSTOM_FIELD:
            return {
                ...state,
            };
        case ActionDispatchTypes.EDIT_CUSTOM_FIELD_SUCCESSFUL:
            state?.fields
                .splice(_.findIndex(state?.fields, { _id: action?.payload?.contractsCustomField?._id }), 1, action?.payload?.contractsCustomField)
            return {
                ...state,
                fields: state?.fields,
                tables: {
                    ...state.tables,
                    refreshContractCustomFieldsTable: true
                }
            };
        case ActionDispatchTypes.EDIT_CUSTOM_FIELD_FAILED:
            return {
                ...state,
                fetchingFields: false,
            };
        //DELETE CUSTOM FIELD
        case ActionDispatchTypes.DELETE_CUSTOM_FIELD:
            return {
                ...state,
            };
        case ActionDispatchTypes.DELETE_CUSTOM_FIELD_SUCCESSFUL:
            return {
                ...state,
                fields: _.filter(state?.fields, function (currentObject) {
                    return currentObject._id !== action?.payload?.deleted?._id
                }),
                tables: {
                    ...state.tables,
                    refreshContractCustomFieldsTable: true
                }
            };
        case ActionDispatchTypes.DELETE_CUSTOM_FIELD_FAILED:
            return {
                ...state,
                fetchingFields: false,
            };

        // CONTRACT TYPES

        case ActionDispatchTypes.NEW_CONTRACT_TYPE_ADD_CUSTOM_FIELD:

            state?.newContractType?.customFields.splice(action?.payload?.destinationIndex, 0, ...action?.addedItem)

            return {
                ...state,
                newContractType: {
                    ...state?.newContractType,
                    customFields: _.unionWith(state?.newContractType?.customFields, _.isEqual),
                },
            };
        case ActionDispatchTypes.NEW_CONTRACT_TYPE_INPUT_CUSTOM_FIELD:
            return {
                ...state,
                newContractType: {
                    ...state?.newContractType,
                    ...action?.payload
                },

            };

        case ActionDispatchTypes.NEW_CONTRACT_TYPE_SET_DATE:
            return {
                ...state,
                newContractType: {
                    ...state?.newContractType,
                    ...action?.payload
                },

            };
        case ActionDispatchTypes.NEW_CONTRACT_TYPE_RESET:
            return {
                ...state,
                newContractType: {
                    title: '',
                    customFields: [],
                    showStartDate: false,
                    showEndDate: false,
                    showEffectiveDate: false,
                    showRenewalDate: false,
                    showSignedDate: false,

                },
            };

        case ActionDispatchTypes.NEW_CONTRACT_TYPE_REMOVE_CUSTOM_FIELD:

            state?.fields.splice(action?.payload?.destinationIndex, 0, ...action?.addedItem)

            return {
                ...state,
                fields: _.unionWith(state?.fields, _.isEqual),
            };

        case ActionDispatchTypes.SET_EDIT_CONTRACT_TYPE_DATA:

            return {
                ...state,
                editedContractType: {
                    ...state?.editedContractType,
                    ...action?.payload
                }
            };

        case ActionDispatchTypes.DELETE_CONTRACT_TYPE_CUSTOM_FIELD:
            return {
                ...state,
                editingContractType: true
            };
        case ActionDispatchTypes.DELETE_CONTRACT_TYPE_CUSTOM_FIELD_SUCCESSFUL:
            return {
                ...state,
                editedContractType: {
                    ...state?.editedContractType,
                    customFields: _.filter(state?.editedContractType?.customFields, function (currentObject) {
                        return currentObject._id !== action?.deleted
                    })
                },
                tables: {
                    ...state.tables,
                    refreshContractTypesTable: true
                },
                editingContractType: false
            };

        // ADD CUSTOM FIELD TO CONTRACT TYPE
        case ActionDispatchTypes.DELETE_CONTRACT_TYPE_CUSTOM_FIELD_FAILED:
            return {
                ...state,
                editingContractType: false
            };

        case ActionDispatchTypes.ADD_CONTRACT_TYPE_CUSTOM_FIELD:
            return {
                ...state,
                editingContractType: true
            };
        case ActionDispatchTypes.ADD_CONTRACT_TYPE_CUSTOM_FIELD_SUCCESSFUL:

            //action?.addedItemId

            return {
                ...state,
                editedContractType: {
                    ...state?.editedContractType,
                    customFields: _.unionWith(state?.editedContractType?.customFields, action?.addedItem, _.isEqual),
                },
                tables: {
                    ...state.tables,
                    refreshContractTypesTable: true
                },
                editingContractType: false
            };

        // CREATE A NEW CONTRACT TYPE
        case ActionDispatchTypes.CREATE_CONTRACT_TYPE:
            return {
                ...state
            };

        case ActionDispatchTypes.CREATE_CONTRACT_TYPE_SUCCESSFUL:

            return {
                ...state,
                types: {
                    ContractsTypes: [
                        action?.payload?.ContractsTypes,
                        ...state?.types?.ContractsTypes
                    ]
                },
                tables: {
                    ...state.tables,
                    refreshContractTypesTable: true
                }
            };
        case ActionDispatchTypes.CREATE_CONTRACT_TYPE_FAILED:
            return {
                ...state

            };

        case ActionDispatchTypes.ADD_CONTRACT_TYPE_CUSTOM_FIELD_FAILED:
            return {
                ...state,
                editingContractType: false
            };


        case ActionDispatchTypes.SAVE_CONTRACT_TYPE_DATA:
            return {
                ...state,
                editingContractType: true,
            };
        case ActionDispatchTypes.SAVE_CONTRACT_TYPE_DATA_SUCCESSFUL:
            return {
                ...state,
                editedContractType: {
                    ...state?.editedContractType,

                    showStartDate: action?.payload?.ContractsTypes?.showStartDate,
                    showEndDate: action?.payload?.ContractsTypes?.showEndDate,
                    showEffectiveDate: action?.payload?.ContractsTypes?.showEffectiveDate,
                    showRenewalDate: action?.payload?.ContractsTypes?.showRenewalDate,
                    showSignedDate: action?.payload?.ContractsTypes?.showSignedDate,

                },
                tables: {
                    ...state.tables,
                    refreshContractTypesTable: true
                },
                editingContractType: false,

            };
        case ActionDispatchTypes.SAVE_CONTRACT_TYPE_DATA_FAILED:
            return {
                ...state,
                editingContractType: false,
            };

        // DELETE_CONTRACT_TYPE
        case ActionDispatchTypes.DELETE_CONTRACT_TYPE:
            return {
                ...state,
            };
        case ActionDispatchTypes.DELETE_CONTRACT_TYPE_SUCCESSFUL:

            return {
                ...state,
                types: {
                    ...state?.types,
                    ContractsTypes: _.filter(state?.types?.ContractsTypes, function (currentObject) {
                        return currentObject._id !== action?.payload?.ContractsTypes?._id
                    })
                },
                tables: {
                    ...state.tables,
                    refreshContractTypesTable: true
                }
            };
        case ActionDispatchTypes.DELETE_CONTRACT_TYPE_FAILED:
            return {
                ...state
            };


        case ActionDispatchTypes.SET_EDIT_CONTRACT_TYPE_REORDER:

            state?.editedContractType?.customFields.splice(action?.payload?.destinationIndex, 0, ...action?.addedItem)

            return {
                ...state,
                editedContractType: {
                    ...state?.editedContractType,
                    customFields: _.unionWith(state?.editedContractType?.customFields, _.isEqual),
                },
            };


        // CREATE A CONTRACT COMPANY
        case ActionDispatchTypes.CREATE_CONTRACT_COMPANY:
            return {
                ...state
            };

        case ActionDispatchTypes.CREATE_CONTRACT_COMPANY_SUCCESSFUL:
            return {
                ...state,
                tables: {
                    ...state.tables,
                    refreshContractCompaniesTable: true
                }
            };
        case ActionDispatchTypes.CREATE_CONTRACT_COMPANY_FAILED:
            return {
                ...state

            };
        // EDIT A CONTRACT COMPANY
        case ActionDispatchTypes.EDIT_CONTRACT_COMPANY:
            return {
                ...state
            };

        case ActionDispatchTypes.EDIT_CONTRACT_COMPANY_SUCCESSFUL:
            return {
                ...state,
                tables: {
                    ...state.tables,
                    refreshContractCompaniesTable: true
                }
            };
        case ActionDispatchTypes.EDIT_CONTRACT_COMPANY_FAILED:
            return {
                ...state

            };
        // DELETE A CONTRACT COMPANY
        case ActionDispatchTypes.DELETE_CONTRACT_COMPANY:
            return {
                ...state,
            };

        case ActionDispatchTypes.DELETE_CONTRACT_COMPANY_SUCCESSFUL:
            return {
                ...state,
                tables: {
                    ...state.tables,
                    refreshContractCompaniesTable: true
                }
            };
        case ActionDispatchTypes.DELETE_CONTRACT_COMPANY_FAILED:
            return {
                ...state

            };

        // CREATE A CONTRACT CONTACT
        case ActionDispatchTypes.CREATE_CONTRACT_CONTACT:
            return {
                ...state
            };

        case ActionDispatchTypes.CREATE_CONTRACT_CONTACT_SUCCESSFUL:
            return {
                ...state,
                tables: {
                    ...state.tables,
                    refreshContractContactsTable: true
                }
            };
        case ActionDispatchTypes.CREATE_CONTRACT_CONTACT_FAILED:
            return {
                ...state

            };
        // EDIT A CONTRACT CONTACT
        case ActionDispatchTypes.EDIT_CONTRACT_CONTACT:
            return {
                ...state
            };

        case ActionDispatchTypes.EDIT_CONTRACT_CONTACT_SUCCESSFUL:
            return {
                ...state,
                tables: {
                    ...state.tables,
                    refreshContractContactsTable: true
                }
            };
        case ActionDispatchTypes.EDIT_CONTRACT_CONTACT_FAILED:
            return {
                ...state

            };
        // DELETE A CONTRACT CONTACT
        case ActionDispatchTypes.DELETE_CONTRACT_CONTACT:
            return {
                ...state
            };

        case ActionDispatchTypes.DELETE_CONTRACT_CONTACT_SUCCESSFUL:
            return {
                ...state,
                tables: {
                    ...state.tables,
                    refreshContractContactsTable: true
                }
            };
        case ActionDispatchTypes.DELETE_CONTRACT_CONTACT_FAILED:
            return {
                ...state

            };

        case ActionDispatchTypes.ADD_TAG_TO_CONTRACT:
            return {
                ...state,
                savingContractOverview: true,

            };
        case ActionDispatchTypes.ADD_TAG_TO_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                contractOverview: {
                    ...state.contractOverview,
                    tags: action?.payload?.contract?.tags
                },
                savingContractOverview: false,

            };

        case ActionDispatchTypes.REMOVE_TAG_TO_CONTRACT:
            return {
                ...state,
                savingContractOverview: true,

            };
        case ActionDispatchTypes.REMOVE_TAG_TO_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                contractOverview: {
                    ...state.contractOverview,
                    tags: action?.payload?.contract?.tags
                },
                savingContractOverview: false,

            };
        case ActionDispatchTypes.ADD_CONTRACT_COMPANY_TO_CONTRACT:
            return {
                ...state,
                savingContractOverview: true,

            };
        case ActionDispatchTypes.ADD_CONTRACT_COMPANY_TO_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                contractOverview: {
                    ...state.contractOverview,
                    contractCompanies: action?.payload?.contract?.contractCompanies
                },
                savingContractOverview: false,

            };
        case ActionDispatchTypes.REMOVE_CONTRACT_COMPANY_TO_CONTRACT:
            return {
                ...state,
                savingContractOverview: true,

            };
        case ActionDispatchTypes.REMOVE_CONTRACT_COMPANY_TO_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                contractOverview: {
                    ...state.contractOverview,
                    contractCompanies: action?.payload?.contract?.contractCompanies
                },
                savingContractOverview: false,

            };
        case ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT:
            return {
                ...state,
                savingContractOverview: true,

            };
        case ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                contractOverview: {
                    ...state.contractOverview,
                    assignedUsers: action?.payload?.contract?.assignedUsers
                },
                savingContractOverview: false,

            };
        case ActionDispatchTypes.ADD_CONTRACT_CONTACT_TO_CONTRACT:
            return {
                ...state,
                savingContractOverview: true,

            };

        case ActionDispatchTypes.ADD_CONTRACT_CONTACT_TO_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                contractOverview: {
                    ...state.contractOverview,
                    contractContacts: action?.payload?.contract?.contractContacts
                },
                savingContractOverview: false,

            };

        case ActionDispatchTypes.REMOVE_ASSIGNED_USER_TO_CONTRACT:
            return {
                ...state,
                savingContractOverview: true,

            };
        case ActionDispatchTypes.REMOVE_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                contractOverview: {
                    ...state.contractOverview,
                    assignedUsers: action?.payload?.contract?.assignedUsers
                },
                savingContractOverview: false,

            };
        case ActionDispatchTypes.REMOVE_CONTRACT_CONTACT_TO_CONTRACT:
            return {
                ...state,
                savingContractOverview: false,

            };
        case ActionDispatchTypes.REMOVE_CONTRACT_CONTACT_TO_CONTRACT_SUCCESSFUL:
            return {
                ...state,
                contractOverview: {
                    ...state.contractOverview,
                    contractContacts: action?.payload?.contract?.contractContacts
                },
                savingContractOverview: false,

            };



        case ActionDispatchTypes.EXPORT_CONTRACT_TO_EXCEL:
            return {
                ...state,
                fetchingExcel: true
            };

        case ActionDispatchTypes.EXPORT_CONTRACT_TO_EXCEL_SUCCESSFUL:
            return {
                ...state,
                fetchingExcel: false
            };
        case ActionDispatchTypes.EXPORT_CONTRACT_TO_EXCEL_FAILED:
            return {
                ...state,
                fetchingExcel: false
            };

        default:
            return state;
    }
};

export default ContractsReducer;
