import React, { useEffect, useState } from 'react';
import FlatListSelect from '@components/FlatListSelect';
import api from '@clients/api.client';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { Button } from 'react-bootstrap';
import { removeAllSteps, setApproveShowButtons } from "@redux/approve/index.actions"
import FeatherIcons from 'feather-icons-react';

const randomIdGenerator = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const mapStateToProps: any = (state: any) => ({
    companies: state.companies,
    users: state.users,
})

const mapActionsTopProps = {
    removeAllSteps: removeAllSteps,
    setApproveShowButtons: setApproveShowButtons
};

const AddNewPipeline: React.FunctionComponent<any> = (props: any) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(removeAllSteps({}));
        dispatch(setApproveShowButtons({
            show: false
        }));
        /* Close Menü */

    }
    return (
        <components.MenuList {...props}>
            <div className='d-flex align-items-center justify-content-center p-2'>
                <Button
                    variant='outline-primary'
                    onClick={handleClick}
                    className="p-2 pointer"
                    style={{ width: '90%' }}
                >
                    <i className="icon">
                        <span>
                            <FeatherIcons icon="plus-square"></FeatherIcons>
                        </span>

                    </i> Yeni Akış Oluştur
                </Button>
            </div>
            {props.children}
        </components.MenuList>
    );
};

const ApproveTemplatesSelect: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const batchSize = 30;
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [value, setValue] = useState(null);
    const [data, setData] = useState<{ label: string, value: string }[]>([]);

    const fetchContracts = async () => {
        console.log(searchText)
        return
    }

    useEffect(() => {
        setValue(props?.value);
    }, [props?.value])



    return (
        <FlatListSelect
            setShowButtons={props?.setShowButtons}
            components={{
                MenuList: AddNewPipeline
            }}
            isMulti={props?.isMulti || false}
            data={data}
            value={value}
            cacheUniqs={[]}
            onChange={(currentItem: any) => {
                props?.onValueSubmit(currentItem);
                setValue(currentItem);
            }}
            placeholder={t("APPROVAL_PROCESS_FILE_MODAL.SELECT_APPROVAL_FLOW")}
            defaultOption={props?.defaultOption?.value ? props?.defaultOption : null}
            className="select-approve-template"
            onEndReached={async (search: string, additional: any) => {
                if (searchText !== search) {
                    if (!(search === "" || search === null || search === undefined)) {
                        console.warn("SEARCH CHANGING");
                        setSearchText(search)
                        setPage(1);
                    }
                } else {
                    setPage(page + 1);
                }
                const response = await api.get(`/approve-templates/list/${props?.companies?.company?._id}`, {
                    params: {
                        batchSize: batchSize,
                        page: page,
                        searchText: search
                    }
                }).then((response: any) => {
                    return response.data?.node?.data;
                }).catch((error: any) => {
                    console.log(error);
                });
                const newData = (response || []).map((item: any) => {
                    return {
                        label: item?.title,
                        value: item?._id
                    }
                }).filter((item: any) => {
                    if ((props?.hiddenIds || []).includes(item?.value)) {
                        return false;
                    } else {
                        return true;
                    }
                })

                const finalNewData = _.difference(newData, data);
                setData([...data, ...finalNewData]);
                console.warn(finalNewData.length)
                if (newData.length < batchSize) {
                    return {
                        options: finalNewData,
                        hasMore: false,
                        additional: {
                            page: page + 1
                        }
                    };
                }
                return {
                    options: [...finalNewData],
                    hasMore: newData.length !== 0,
                    additional: {
                        page: page + 1
                    }
                };
                //setPage(page + 1);
            }}
        />
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(ApproveTemplatesSelect);