import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';

import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import request from '@clients/api.client';

import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";


export const toggleGenericErrorModalAction: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_ERROR_MODAL,
        payload: {
            show: !getState().components.genericErrorModal.show,
            code: payload?.code,
            message: payload?.message,
            status: payload?.status
        }
    });
}

export const toggleGenericSuccessModalAction: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_SUCCESS_MODAL,
        payload: {
            show: !getState().components.genericSuccessModal.show,
            code: payload?.code,
            message: payload?.message,
            status: payload?.status
        }
    });
}


/* NEW COMPANY MODAL */

export const toggleNewCompanyModal = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_NEW_COMPANY_MODAL,
        payload: payload
    });
}

export const toggleUploadFileModal = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_UPLOAD_FILES_MODAL,
        payload: payload
    });
}


export const setNewCompanyData: TAction = (payload: {
    key: string,
    value: any
}) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.SET_NEW_COMPANY_DATA,
        payload: payload
    })
}

export const deleteNewCompanyData: TAction = (payload: {
    key: string
}) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.DELETE_NEW_COMPANY_DATA,
        payload: payload
    })
}


/* ADD USER TO COMPANY */
export const toggleAddUserToCompany = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_ADD_USER_TO_COMPANY_MODAL,
        payload: payload || null
    });
}


export const toggleCompanySwitchModal = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_COMPANY_SWITCH_MODAL,
        payload: payload || null
    });
}


export const toggleGenericAddCustomFieldModal: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_ADD_CUSTOM_FIELD_MODAL,
        payload: !getState().components.newCustomFieldModal
    });
}

export const toggleGenericAddContractModal: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_ADD_CONTRACT_MODAL,
        payload: !getState().components.newContractModal
    });
}
export const toggleGenericAddPipelineModal: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_ADD_PIPELINE_MODAL,
        payload: !getState().components.newPipelineModal
    });
}

export const toggleGenericAddContractTypeModal: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_ADD_CONTRACT_TYPE_MODAL,
        payload: !getState().components.newContractTypeModal
    });
}

export const toggleGenericAddStatusModal: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_ADD_STATUS_MODAL,
        payload: !getState().components.newStatusModal
    });
}
export const toggleGenericAddContractCompaniesModal: TAction = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_ADD_CONTRACT_COMPANIES_MODAL,
        payload: !getState().components.newContractCompaniesModal,
        partiesSide: payload?.partiesSide
    });
}


export const toggleFilesVersionModal: TAction = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_FILES_VERSION_MODAL,
        payload: payload || null
    });
}

export const setPageTitle = (payload: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.SET_PAGE_TITLE,
        payload: payload?.title || ""
    });
}


export const toggleGenericGoogleDocsIframeModal: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_GOOGLE_DOCS_IFAME_MODAL,
        payload: !getState().components.googleDocsIFrameModal
    });
}
export const toggleGoogleFilePermissionModal: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_GOOGLE_FILE_PERMISSION_MODAL,
        payload: !getState().components.googleFilePermissionModal,
    });
}
export const toggleGenericTemplateGoogleDocsIframeModal: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_TEMPLATE_GOOGLE_DOCS_IFAME_MODAL,
        payload: !getState().components.templateGoogleDocsIFrameModal
    });
}


export const toggleGenericEditCustomFormsModal: TAction = (payload: TGenericResponse) => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_EDIT_CUSTOM_FORMS_MODAL,
        payload: !getState().components.editCustomFormsModal
    });
}
export const toggleGenericContractOverviewModal: TAction = (payload: any) => async (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.SET_CONTRACT_ID_TO_OVERVIEW_MODAL,
        payload: payload
    });

    if (payload?.show !== false) {
        dispatch({
            type: ActionDispatchTypes.TOGGLE_GENERIC_CONTRACT_OVERVIEW_MODAL,
            payload: payload?.show || !getState().components.contractOverviewModal
        });
    }


}
export const toggleFilesPdfViewerModal: TAction = (payload: any) => async (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.TOGGLE_FILES_PDF_VIEWER,
        payload: payload
    });

}
export const toggleApprovalTemplateModal: TAction = (payload: any) => async (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.TOGGLE_APPROVAL_TEMPLATE_MODAL,
        payload: payload?.show || !getState().components.approvalTemplateModal
    });

}
export const toggleApprovalProcessFileModal: TAction = (payload: any) => async (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.TOGGLE_APPROVAL_PROCESS_MODAL,
        payload: payload?.show || !getState().components.approvalProcessFileModal
    });

}