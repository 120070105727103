import React, { useEffect, useState } from "react";
import FlatList from "@components/FlatList";
import { connect } from "react-redux";
import FeatherIcons from "feather-icons-react";
import Avatar from "react-avatar";
import { getContractAssigedUsersDetail, removeAssignedUserToContract } from '@redux/contract/index.actions';
import { DefaultLoader } from '@components/Loader';
import _ from "lodash";
import { Button, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import AssignedUsersFlatList from "./AssignedUsersFlatList";
const mapStateToProps = (state: any) => ({
    users: state.users,
    companies: state.companies,
    contracts: state.contracts
})

const mapActionsToProps = { getContractAssigedUsersDetail, removeAssignedUserToContract }


const Companies: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const assignedUsers = props?.contracts?.contractOverview?.assignedUsers;
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<[]>([]);

    const handleRemove = (data: {
        _id: string
    }) => {
        const requestData: {
            contractId: string
            companyId: string
            removedItem: string
        } = {
            contractId: props?.contracts?.contractOverview?._id,
            companyId: props?.companies?.currentCompany,
            removedItem: data?._id
        }

        props?.removeAssignedUserToContract(requestData);
    }

    useEffect(() => {
        if (assignedUsers) {
            props?.getContractAssigedUsersDetail({
                contractId: props?.contracts?.contractOverview?._id,
                companyId: props?.companies?.currentCompany,
            }).then((res: any) => {
                setData(res?.node?.assignedUsers);
                setLoading(false)
            }).catch(() => setLoading(false))
        } else {
            setData([]);
            setLoading(false)
        }

    }, [assignedUsers])
    return (
        <>
            {loading ? (
                <div className="d-flex align-items-center justify-content-center" style={{
                    minHeight: '150px',
                    width: '100%'
                }}>
                    <DefaultLoader />
                </div>
            ) : (

                <>

                    <Dropdown as={'div'} autoClose={'outside'} >
                        <Dropdown.Toggle className="user">
                            <FeatherIcons icon="plus" size={20} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu align={'end'} as={'div'} style={{ width: '300px', marginTop: '5px', padding: '10px' }}>

                            <AssignedUsersFlatList />

                        </Dropdown.Menu>
                    </Dropdown>
                    {
                        data.length ? (data || [])?.map((item: any, index: number) => {
                            return (

                                <div key={'assignedUsers1' + item?._id} className="user">
                                    <Avatar name={item?.name + ' ' + item?.surname} size="40" round={true} />
                                </div>

                            )
                        }) : (<div className="d-flex align-items-center justify-content-start" style={{
                            minHeight: '50px',
                            width: '100%',
                            color: '#ccc',
                            marginRight: '14px'
                        }}>
                            {t("CONTRACTS.OVERVIEW.ASSIGNED_USERS.NO_ASSIGNED_USERS")}
                        </div>)
                    }

                </>

            )}



        </>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(Companies);
