import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import FilesTable from "@pages/contracts/Overview/components/FilesTable";
const mapStateToProps = (state: any) => ({
  files: state.files
});

const mapActionsToProps = {};

const Files: React.FC<any> = (props) => {
  const { t } = useTranslation();
  return (
    <>

      {/*
      <Button className="mb-1" variant="secondary " size="sm" onClick={() => {
        props?.onToggleUploadModal()
      }}>
        <i className="bi-plus-circle me-1"></i>{t("CONTRACTS.OVERVIEW.FILES.ADD_FILES")}
      </Button>
      */}

      <Card style={{ border: 'none' }} bsPrefix="card">
        {!props?.files?.refreshTableList && <FilesTable overview={props?.overview} />}

      </Card>

    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(Files);
