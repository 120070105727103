import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./index.scss";
import { Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { } from "@redux/contract/index.actions"
import ReminderList from "@pages/contracts/Overview/contract/ActivityField/Reminder/ReminderList"
import AddReminderModal from "@pages/contracts/Overview/contract/ActivityField/Reminder/components/AddReminderModal";
import AddToCalendarModal from "@pages/contracts/Overview/contract/ActivityField/Reminder/components/AddToCalendarModal";
import DeleteConfirmPopup from "@pages/contracts/Overview/contract/ActivityField/Reminder/components/DeleteConfirmPopup";
import EditReminderModal from "@pages/contracts/Overview/contract/ActivityField/Reminder/components/EditReminderModal";
import { useTranslation } from "react-i18next";
const mapStateToProps = (state: any) => ({
    contracts: state.contracts
});

const mapActionsToProps = {};

const Reminder: React.FC<any> = (props) => {
    const { t } = useTranslation();
    const [refreshList, setRefreshList] = useState(false);
    const contractOverview = props?.contracts?.contractOverview;

    const [newStatusModal, setNewStatusModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [addToCalendarModal, setAddToCalendarModal] = useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [calendarData, setCalendarData] = useState({});
    const [deletedData, setDeletedData] = useState({});
    const [editData, setEditData] = useState({});

    const toggleEditModal = () => {
        setEditModal((prevstate) => !prevstate);
    };

    const toggleNewStatusModal = () => {
        setNewStatusModal((prevstate) => !prevstate);
    };

    const toggleDeleteConfirmModal = () => {
        setDeleteConfirmModal((prevstate) => !prevstate);
    };

    const toggleAddToCalendarModal = () => {
        setAddToCalendarModal((prevstate) => !prevstate);
    };

    const handleSetCalendarData = (data: any) => {
        setCalendarData(data);
    };

    const handleDelete = (id: any) => {
        setDeletedData({ id: id });
        setDeleteConfirmModal(true);
    };

    const handleEdit = (data: any) => {
        setEditData(data);
        setEditModal(true);
        console.log('Edit', data)
    };

    const handleRefresh = () => {
        setRefreshList((prevstate) => !prevstate);

    };



    return (
        <>
            {props?.showReminderModal && (
                <AddReminderModal
                    newStatusModal={props?.showReminderModal}
                    toggleNewStatusModal={props?.toggleNewReminderModal}
                    toggleAddToCalendarModal={toggleAddToCalendarModal}
                    handleSetCalendarData={handleSetCalendarData}
                    handleRefresh={handleRefresh}
                />
            )}
            {editModal && (
                <EditReminderModal
                    editModal={editModal}
                    toggleEditModal={toggleEditModal}
                    toggleAddToCalendarModal={toggleAddToCalendarModal}
                    handleSetCalendarData={handleSetCalendarData}
                    handleRefresh={handleRefresh}
                    editData={editData}
                />
            )}
            {addToCalendarModal && (
                <AddToCalendarModal
                    addToCalendarModal={addToCalendarModal}
                    toggleAddToCalendarModal={toggleAddToCalendarModal}
                    calendarData={calendarData}
                    handleRefresh={handleRefresh}
                />
            )}
            {deleteConfirmModal && (
                <DeleteConfirmPopup
                    deleteConfirmModal={deleteConfirmModal}
                    toggleDeleteConfirmModal={toggleDeleteConfirmModal}
                    deletedData={deletedData}
                    handleRefresh={handleRefresh}
                />
            )}



            <div>
                <ReminderList
                    toggleAddToCalendarModal={toggleAddToCalendarModal}
                    handleSetCalendarData={handleSetCalendarData}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    handleRefresh={handleRefresh}
                    refreshList={refreshList}
                />
            </div>

        </>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(Reminder);
