import React, { useEffect, useState } from "react";

import { Button, Form, FormGroup, Modal } from "react-bootstrap";

import { connect } from "react-redux";
import Dropzone from 'react-dropzone';
// components
import StatusEdit from "@pages/statuses/StatusEdit";

import { useTranslation } from "react-i18next";
import cdnServicesInstance from "@clients/cdnservices.client";
import { FileUploaderProps, FileType } from '@components/FileUploader/interfaces/index.interfaces';
import classNames from "classnames";
import { toast } from "react-toastify";
import apiInstance from "@clients/api.client";
import Loader from "@components/Loader";

const mapStateToProps = (state: any) => ({
  approve: state.approve,
  companies: state.companies,
});
const mapActionsToProps = {};

const ApproveProcessModal = (props: any) => {
  const { t } = useTranslation();
  const submitHandle = (e: any) => {
    e.preventDefault();
    props?.setLoading(true);
    // Get form data
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    console.log(data, filesMetaArr);



    formData.append('files', filesMetaArr[0]?.file);
    formData.append('token', props?.token);


    if (props?.originalFile?.contract) {
      formData.append('contract', props?.originalFile?.contract);
    }

    formData.append('versionOf', props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[0]?.originalFile?._id);
    formData.append('approveFileId', props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length - 1]?._id);
    formData.append('company', props?.companies?.company?._id);


    cdnServicesInstance.post(`gdrive/internal/approve/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res: any) => {

      props?.getReadApproveProcess({
        id: props?.process?._id
      })
      props?.toggle();

    })
      .catch((error: any) => {
        const errorMessage = error?.response?.data?.response?.message;
        toast.error(errorMessage || 'Onay verilecek bir dosya bulunamadı. Detaylı bilgi için onay sürecini kontrol ediniz.');
        props?.getReadApproveProcess({
          id: props?.process?._id
        })
        props.toggle();
      })
      .finally(() => {
        props?.setLoading(false);
      })

  };

  const downloadFile = () => {
    console.log('download file');
  }

  useEffect(() => {
    console.log(props);
  }, []);


  const generateDownloadUrl = (file: any) => {
    if (file?.source?.sourceKey === "google-drive") {
      console.log('token', props?.token, 'key', file?.key, 'baseURL', cdnServicesInstance.defaults.baseURL, 'download-approve-file')
      return cdnServicesInstance.defaults.baseURL + `/gdrive/files/${file?.key}/${props?.token}/download-approve-file`;
    } else {
      return ""
    }
  }

  /**
 * Handled the accepted files and shows the preview
 */
  const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);
  const [filesMetaArr, setFilesMetaArr] = useState<any[]>([]);
  const handleAcceptedFiles = (files: FileType[]) => {

    var allFiles = files;

    if (props.showPreview) {
      (files || []).map((file) =>
        Object.assign(file, {
          preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
          formattedSize: formatBytes(file.size),
        })
      );
      allFiles = [...selectedFiles];
      allFiles.push(...files);
      setSelectedFiles(allFiles.slice(0, 20));
    }

    const filesMetaArr = files.map((file: any) => {
      return {
        isUploaded: false,
        filename: file.name,
        type: file.type,
        fileSize: file.formattedSize,
        file: file
      }
    })

    setFilesMetaArr(filesMetaArr);

    console.log("allFiles", selectedFiles);
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  /*
   * Removes the selected file
   */
  const removeFile = (e: any, fileIndex: number) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(fileIndex, 1);
    setSelectedFiles(newFiles);
    if (props.onFileUpload) props.onFileUpload(newFiles);
  };
  return (
    <Modal
      show={props?.show}
      onHide={props?.toggle}
      size="lg"
      centered
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_CHANGE_MODAL.TITLE')}</h4>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-1">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_CHANGE_MODAL.DESCRIPTION')}.</p>

        <div className="mb-3">
          <span
            onClick={(e: any) => window.open(generateDownloadUrl(props?.originalFile), "_blank")}
            style={{ color: '#007bff', cursor: 'pointer' }}>
            {props?.originalFile?.filename}
          </span>
        </div>

        <Form onSubmit={submitHandle}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_CHANGE_MODAL.FILE_INPUT_LABEL')}</Form.Label>
            <Dropzone
              onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div
                  className={classNames('dropzone', 'dz-clickable', {
                    'dz-started': selectedFiles && selectedFiles.length > 0,
                  }) + "d-flex"} style={{
                    height: "100%"
                  }}>
                  <div {...getRootProps()} style={{
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap"
                  }}>
                    <div className="dz-message needsclick"
                      style={{
                        flexDirection: "column",
                        display: selectedFiles.length === 0 ? "flex" : "none",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "80%"
                      }}>
                      <input {...getInputProps()} id="cdn-upload-from-computer" />
                      {(selectedFiles || []).length === 0 && (
                        <div style={{
                          flexDirection: "column",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%"
                        }}>
                          <i className="h1 text-muted uil-cloud-upload"></i>

                          <span className="text-muted">
                            {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_CHANGE_MODAL.FILE_INPUT_PLACEHOLDER')}
                          </span>
                          <div className="btn btn-primary mt-2" onClick={(e) => e.stopPropagation()}>
                            {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_CHANGE_MODAL.SELECT_FILE')}
                          </div>
                        </div>
                      )}
                    </div>
                    {props.showPreview &&
                      (selectedFiles || []).map((f, i) => {
                        return (
                          <React.Fragment key={i}>
                            {f.preview && (
                              <div
                                onClick={(e) => e.stopPropagation()}
                                className="dz-preview dz-processing dz-error dz-complete dz-image-preview">
                                <div className="dz-image">
                                  <img
                                    key={i}
                                    data-dz-thumbnail=""
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </div>

                                <div className="dz-details">
                                  <div className="dz-size">
                                    <span data-dz-size="">
                                      <strong>{f.formattedSize}</strong> KB
                                    </span>
                                  </div>
                                  <div className="dz-filename">
                                    <span data-dz-name="">{f.name}</span>
                                  </div>
                                </div>

                                <div className="dz-remove">
                                  <Button variant="" onClick={(e) => removeFile(e, i)}>
                                    <i className="uil uil-multiply"></i>
                                  </Button>
                                </div>
                              </div>
                            )}
                            {!f.preview && (
                              <div
                                onClick={(e) => e.stopPropagation()}
                                className="dz-preview dz-file-preview dz-processing dz-error dz-complete">
                                <div className="dz-image">
                                  <img data-dz-thumbnail="" alt="" />
                                </div>
                                <div className="dz-details">
                                  <div className="dz-size">
                                    <span data-dz-size="">
                                      <strong>{f.formattedSize}</strong> KB
                                    </span>
                                  </div>
                                  <div className="dz-filename">
                                    <span data-dz-name="">{f.name}</span>
                                  </div>
                                </div>

                                <div className="dz-remove">
                                  <Button variant="" onClick={(e) => removeFile(e, i)}>
                                    <i className="uil uil-multiply"></i>
                                  </Button>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              )}
            </Dropzone>


          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_CHANGE_MODAL.MESSAGE_INPUT_LABEL')}</Form.Label>
            <Form.Control
              name="message"
              placeholder="Onaylamadan önce bu alana bir metin ekleyebilirsiniz. Bu metin onay adımındaki ilgili alıcıya iletilecektir."
              as="textarea" rows={3} />
          </Form.Group>


          <Button disabled={props?.loading} variant="primary" type="submit">
            {props?.loading && <Loader />} Gönder
          </Button>
        </Form>


      </Modal.Body>
    </Modal>
  );
};
ApproveProcessModal.defaultProps = {
  showPreview: true,
};

export default connect(mapStateToProps, mapActionsToProps)(ApproveProcessModal);
