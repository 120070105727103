import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { connect } from "react-redux";

// components
import ReminderEdit from "@pages/contracts/Overview/contract/ActivityField/Reminder/ReminderEdit";

import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({});
const mapActionsToProps = {};

const AddNewStatusModal = (props: any) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={props?.editModal}
      onHide={props?.toggleEditModal}
      size={'lg'}
      centered
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">{t("Edit Reminder")}</h4>
      </Modal.Header>
      <Modal.Body>
        <ReminderEdit
          handleRefresh={props.handleRefresh}
          handleSetCalendarData={props.handleSetCalendarData}
          toggleAddToCalendarModal={props?.toggleAddToCalendarModal}
          modalClose={props?.toggleEditModal}
          editData={props?.editData}
        />
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(AddNewStatusModal);
