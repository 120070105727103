import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { excelCreator } from "@utils/export/excelCreator"
import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import api from '@clients/api.client';
import { toast } from 'react-toastify';
import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";

import { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import { toggleGenericSuccessModal } from '@redux/utils/components/GenerateSuccessModal';
import moment from 'moment';

/* Login a user */
export const getSinglePipeline: TAction = (payload: any) => async (dispatch, getState) => {

    const company = getState().companies?.company?._id;

    return await api.get(`/contracts-pipelines/${payload?.id}/${company}`).then(response => {

        if (response?.data?.node) {

            dispatch({
                type: ActionDispatchTypes.GET_SINGLE_PIPELINE,
                payload: response?.data?.node
            });


            return response?.data?.node

        }

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CONFIRM_MAIL_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return error;
    })
}
export const getSinglePipelineStatus: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    return await api.get(`/contracts-statuses/507f191e810c19729de860ea`).then(response => {
        if (response?.data?.node?.ContractsStatuses) {
            dispatch({
                type: ActionDispatchTypes.GET_SINGLE_STATUSES,
                payload: response?.data?.node?.ContractsStatuses
            });
            return response?.data?.node?.ContractsStatuses
        }

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CONFIRM_MAIL_FAILED,
            payload: errorResponse
        });
        return error;
    })
}

export const getContractTypes: TAction = (payload: any) => async (dispatch, getState) => {

    const company = getState().companies?.company?._id;
    return await api.get(`contracts-types/company/${company}`).then(response => {
        if (response?.data?.node) {
            dispatch({
                type: ActionDispatchTypes.GET_CONTRACT_TYPES_BY_COMPANY,
                payload: response?.data?.node
            });
            return response?.data?.node
        }

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CONFIRM_MAIL_FAILED,
            payload: errorResponse
        });
        return error;
    })
}

export const getContractsByStatusId: TAction = (payload: any) => async (dispatch, getState) => {

    const company = getState().companies?.company?._id;

    const page = payload?.page || 1;

    if (page == 1) {
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACTS_BY_STATUS,
            payload: payload
        });
    } else {
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACTS_BY_STATUS_LOAD_MORE,
            payload: payload
        });
    }

    return await api.get(`contracts/status/${company}/${payload.statusID}/${payload?.pipelineId || ''}/?page=${payload?.page || 1}`).then(response => {
        //toggleGenericSuccessModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACTS_BY_STATUS_SUCCESSFUL,
            payload: response?.data?.node,
            pagination: response?.data?.pagination,
            status: payload.statusID
        }); // Sends the response to the reducer
        return response?.data?.node;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACTS_BY_STATUS_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}


export const getCompanyPipelines: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_COMPANY_PIPELINES,
        payload: payload
    });
    return await api.get(`contracts-pipelines/list/company/${company}`).then(response => {
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_PIPELINES_SUCCESSFUL,
            payload: response?.data?.node
        }); // Sends the response to the reducer
        return response?.data?.node;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_PIPELINES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const getContractsByPipeline: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    return await api.get(`/contracts-statuses/507f191e810c19729de860ea`).then(response => {
        if (response?.data?.node?.ContractsStatuses) {
            dispatch({
                type: ActionDispatchTypes.GET_SINGLE_STATUSES,
                payload: response?.data?.node?.ContractsStatuses
            });
            return response?.data?.node?.ContractsStatuses
        }

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CONFIRM_MAIL_FAILED,
            payload: errorResponse
        });
        return error;
    })
}

export const createNewContract: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.CREATE_NEW_CONTRACT,
        payload: payload
    });

    return await api.post(`/contracts/${company}`, { ...payload }).then(response => {
        toast.success(response.data.response?.message || 'Contract created successfully');
        dispatch({
            type: ActionDispatchTypes.CREATE_NEW_CONTRACT_SUCCESSFUL,
            payload: response?.data?.node,
            status: payload.status
        });

        return response?.data?.node?.contract

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CREATE_NEW_CONTRACT_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const deleteContract: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.DELETE_CONTRACT,
        payload: payload
    });

    return await api.delete(`/contracts/${payload?.id}/${company}`).then(response => {
        toast.success(response.data.response?.message || 'Contract created successfully');
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_SUCCESSFUL,
            payload: response?.data?.node,
            status: payload.status
        });

        return response?.data?.node?.contract

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const editContractDetails: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.EDIT_CONTRACT,
        payload: payload
    });

    return await api.put(`/contracts/${payload?._id}/${company}`, { ...payload }).then(response => {
        //toast.success(response.data.response?.message || 'Contract created successfully');
        dispatch({
            type: ActionDispatchTypes.EDIT_CONTRACT_SUCCESSFUL,
            payload: response?.data?.node,
            status: payload.status
        });

        return response?.data?.node?.contract

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.EDIT_CONTRACT_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const getContractById: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_CONTRACT,
        payload: payload
    });

    return await api.get(`/contracts/${company}/${payload?.contractId}`).then(response => {

        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_SUCCESSFUL,
            payload: response?.data?.node
        });

        return response?.data?.node?.contract

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const resetContractOverview: any = () => async (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.RESET_CONTRACT_OVERVIEW
    });
}

export const contractStatusChange: TAction = (payload: any) => async (dispatch, getState) => {

    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.KANBAN_CONTRACT_STATUS_CHANGE,
        payload: payload
    });

    const destinationStatus = payload?.destination?.droppableId;
    const sourceStatus = payload?.source?.droppableId;
    const sourceIndex = payload?.source?.index;

    const removedState = getState()?.contracts?.kanban[sourceStatus][sourceIndex];


    return await api.put(`/contracts/${removedState?._id}/${company}`, { status: destinationStatus }).then(response => {
        toast.success(response.data.response.message);
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.KANBAN_CONTRACT_STATUS_CHANGE_SUCCESSFUL,
            payload: payload
        });
        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.KANBAN_CONTRACT_STATUS_CHANGE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const newPipelineAddStatus: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.NEW_PIPELINE_ADD_STATUS,
        payload: payload
    });

}

export const newPipelineOrderStatus: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.NEW_PIPELINE_ORDER_STATUS,
        payload: payload
    });

}

export const newPipelineRemoveStatus: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.NEW_PIPELINE_REMOVE_STATUS,
        payload: payload
    });

}

export const setNewPipelineData: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.SET_NEW_PIPELINE_DATA,
        payload: payload
    });

}

export const createNewPipeline: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.CREATE_NEW_PIPELINE,
        payload: payload
    });

    const pipeline: any = {};
    const newPipeline = getState()?.contracts?.newPipeline;

    const statusesArray: any = [];

    newPipeline?.statuses.map((item: any) => {
        statusesArray.push(item._id)
    });

    pipeline.title = newPipeline?.data?.title;
    pipeline.description = newPipeline?.data?.description;
    pipeline.statuses = statusesArray;
    pipeline.initialStatus = statusesArray[0];
    pipeline.company = company;

    return await api.post(`/contracts-pipelines/${company}`, { ...pipeline }).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.CREATE_NEW_PIPELINE_SUCCESSFUL,
            payload: response?.data?.node,
        });
        return true;

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CREATE_NEW_PIPELINE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const setEditPipelineData: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.SET_EDIT_PIPELINE_DATA,
        payload: payload
    });

}


export const setEditedPipeline: TAction = (payload: any) => async (dispatch, getState) => {
    if (payload?.key == 'changeTitle') {
        dispatch({
            type: ActionDispatchTypes.SET_EDIT_PIPELINE_TITLE,
            payload: payload
        });

    }

    if (payload?.key == 'statusReOrder') {

        dispatch({
            type: ActionDispatchTypes.SET_EDIT_PIPELINE_REORDER,
            payload: {
                source: payload?.source,
                destination: payload?.destination
            }
        });

    }

    if (payload?.key == 'addStatus') {
        dispatch({
            type: ActionDispatchTypes.SET_EDIT_PIPELINE_ADD_NEW_STATUS,
            payload: [payload?.data]
        });

    }

    if (payload?.key == 'removeStatus') {
        dispatch({
            type: ActionDispatchTypes.SET_EDIT_PIPELINE_REMOVE_STATUS,
            payload: payload
        });

    }

}

export const editPipeline: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.EDIT_PIPELINE,
        payload: payload
    });

    const pipeline: any = {};
    const editedPipeline = getState()?.contracts?.editedPipeline;


    const statusesArray: any = [];

    editedPipeline?.statuses.map((item: any) => {
        statusesArray.push(item._id)
    });

    pipeline.title = editedPipeline?.title;
    pipeline.statuses = statusesArray;
    pipeline.initialStatus = statusesArray[0];



    return await api.put(`/contracts-pipelines/${editedPipeline?._id}/${company}`, { ...pipeline }).then(response => {
        toast.success(response.data.response.message);
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.EDIT_PIPELINE_SUCCESSFUL,
            payload: { ...editedPipeline },
        });
        return true;

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.EDIT_PIPELINE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const deletePipeline: TAction = (payload: any) => async (dispatch, getState) => {

    const company = getState().companies?.company?._id;

    dispatch({
        type: ActionDispatchTypes.DELETE_PIPELINE,
        payload: payload
    });


    return await api.delete(`/contracts-pipelines/${payload?.pipelineId}/${company}`).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);

        dispatch({
            type: ActionDispatchTypes.DELETE_PIPELINE_SUCCESSFUL,
            payload: payload,
        });
        return true;

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_PIPELINE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const checkBeforeDeletePipelineStatus: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;

    return await api.get(`contracts/status/${company}/${payload.statusId}/${payload?.pipelineId}`).then(response => {
        return response?.data?.pagination;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        return false;
    })
}

export const contractMigration: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;

    dispatch({
        type: ActionDispatchTypes.CONTRACT_MIGRATION,
        payload: payload
    });

    return await api.put(`contracts/migrate/${company}/${payload.pipelineId}/${payload?.statusId}`, { newPipelineId: payload?.newPipeline, newStatusId: payload?.newStatus }).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.CONTRACT_MIGRATION_SUCCESSFUL,
            payload: response?.data?.node,
        });
        return true;

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CONTRACT_MIGRATION_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const getCompanyStatuses: TAction = (payload: any) => async (dispatch, getState) => {

    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_COMPANY_STATUSES,
        payload: payload
    });

    return await api.get(`contracts-statuses/list/company/${getState()?.companies?.currentCompany}`).then(response => {
        //toggleGenericSuccessModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_STATUSES_SUCCESSFUL,
            payload: response?.data?.node,
        }); // Sends the response to the reducer
        return true;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_STATUSES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}
export const getStatuses: TAction = (payload: any) => async (dispatch, getState) => {

    const company = getState().companies?.company?._id;

    return await api.get(`contracts-statuses/list/company/${getState()?.companies?.currentCompany}`).then(response => {

        return response?.data?.node;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_COMPANY_STATUSES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const editStatus: TAction = (payload: any) => async (dispatch, getState) => {

    const status: any = {}
    const company = getState().companies?.company?._id;
    status._id = getState()?.contracts?.editedStatus?._id;
    status.title = getState()?.contracts?.editedStatus?.title;
    status.description = getState()?.contracts?.editedStatus?.description;
    status.company = company;

    dispatch({
        type: ActionDispatchTypes.EDIT_STATUS,
        payload: payload
    });

    return await api.put(`/contracts-statuses/${status._id}/${company}`, { ...status }).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.EDIT_STATUS_SUCCESSFUL,
            payload: response?.data?.node,
        });
        return true;

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.EDIT_STATUS_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const createNewStatus: TAction = (payload: any) => async (dispatch, getState) => {

    const status: any = {}
    const company = getState().companies?.company?._id;
    status.title = getState()?.contracts?.newStatus?.title;
    status.description = getState()?.contracts?.newStatus?.description;
    status.company = company;



    dispatch({
        type: ActionDispatchTypes.CREATE_NEW_STATUS,
        payload: payload
    });

    return await api.post(`/contracts-statuses/${company}`, { ...status }).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.CREATE_NEW_STATUS_SUCCESSFUL,
            payload: response?.data?.node,
        });
        return true;

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CREATE_NEW_STATUS_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

export const setNewStatusData: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    if (payload?.key == 'changeData') {
        dispatch({
            type: ActionDispatchTypes.SET_NEW_STATUS_DATA,
            payload: { ...payload?.data }
        });

    }

}

export const setEditedStatusData: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    if (payload?.key == 'changeData') {
        dispatch({
            type: ActionDispatchTypes.SET_EDITED_STATUS_DATA,
            payload: { ...payload?.data }
        });

    }

}

export const deleteStatus: TAction = (payload: any) => async (dispatch, getState) => {

    dispatch({
        type: ActionDispatchTypes.DELETE_STATUS,
        payload: payload
    });

    const company = getState().companies?.company?._id;
    return await api.delete(`/contracts-statuses/${payload?.statusId}/${company}`).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);

        dispatch({
            type: ActionDispatchTypes.DELETE_STATUS_SUCCESSFUL,
            payload: payload,
        });
        return true;

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_STATUS_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return false;
    })
}

// CUSTOM FIELDS OPERATIONS

export const createNewCustomField: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;

    dispatch({
        type: ActionDispatchTypes.CREATE_NEW_CUSTOM_FIELD,
        payload: payload
    });

    return await api.post(`/contracts-custom-fields/${company}`, { ...payload }).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        toast.success(response.data.response.message)
        dispatch({
            type: ActionDispatchTypes.CREATE_NEW_CUSTOM_FIELD_SUCCESSFUL,
            payload: response?.data?.node,
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message);
        //toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CREATE_NEW_CUSTOM_FIELD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}
export const getListCustomFields: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.LIST_CUSTOM_FIELD,
        payload: payload
    });

    return await api.get(`/custom-fields/${getState()?.companies?.currentCompany}`).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.LIST_CUSTOM_FIELD_SUCCESSFUL,
            payload: response?.data?.node,
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.LIST_CUSTOM_FIELD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}
export const getListCustomFieldsNoErrorResponse: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.LIST_CUSTOM_FIELD,
        payload: payload
    });

    return await api.get(`/custom-fields/${getState()?.companies?.currentCompany}`).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.LIST_CUSTOM_FIELD_SUCCESSFUL,
            payload: response?.data?.node,
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.LIST_CUSTOM_FIELD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const editCustomField: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.EDIT_CUSTOM_FIELD,
        payload: payload
    });

    return await api.put(`/contracts-custom-fields/${payload?._id}/${company}`, { ...payload }).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.EDIT_CUSTOM_FIELD_SUCCESSFUL,
            payload: response?.data?.node,
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.EDIT_CUSTOM_FIELD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const deleteCustomField: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;

    dispatch({
        type: ActionDispatchTypes.DELETE_CUSTOM_FIELD,
        payload: payload
    });

    return await api.delete(`/contracts-custom-fields/${payload?._id}/${company}`).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.DELETE_CUSTOM_FIELD_SUCCESSFUL,
            payload: response?.data?.node,
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_CUSTOM_FIELD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}


export const deleteCustomFieldOption: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;


    return await api.delete(`/custom-fields/options/${company}/${payload?.customFieldId}/${payload?.customFieldOptionId}`).then(response => {
        toast.success(response.data.response?.message || 'SUCCESSFUL');
        return true
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message || 'Error')
        return false;
    })
}

export const setNewContractTypeData: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;


    if (payload?.key == 'reset') {
        dispatch({
            type: ActionDispatchTypes.NEW_CONTRACT_TYPE_RESET,
            payload: payload,
        });
    }

    if (payload?.key == 'addCustomField') {



        const data = payload?.data;

        let addedItem = getState().contracts.fields.splice(data.sourceIndex, 1);

        dispatch({
            type: ActionDispatchTypes.NEW_CONTRACT_TYPE_ADD_CUSTOM_FIELD,
            payload: data,
            addedItem: addedItem
        });
    }

    if (payload?.key == 'reOrder') {

        const data = payload?.data;
        let addedItem = getState().contracts.newContractType.customFields.splice(data.sourceIndex, 1);

        dispatch({
            type: ActionDispatchTypes.NEW_CONTRACT_TYPE_ADD_CUSTOM_FIELD,
            payload: data,
            addedItem: addedItem
        });

    }

    if (payload?.key == 'removeCustomField') {

        const data = payload?.data;
        let addedItem = getState()?.contracts?.newContractType?.customFields.splice(data.sourceIndex, 1);

        dispatch({
            type: ActionDispatchTypes.NEW_CONTRACT_TYPE_REMOVE_CUSTOM_FIELD,
            payload: data,
            addedItem: addedItem
        });
    }

    if (payload?.key == 'changeInput') {
        const inputData = payload?.data;

        dispatch({
            type: ActionDispatchTypes.NEW_CONTRACT_TYPE_INPUT_CUSTOM_FIELD,
            payload: inputData
        });
    }

    if (payload?.key == 'setDate') {
        const inputData = payload?.data;

        dispatch({
            type: ActionDispatchTypes.NEW_CONTRACT_TYPE_SET_DATE,
            payload: inputData
        });
    }



}

export const createNewContractType: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.CREATE_CONTRACT_TYPE,
        payload: payload
    });

    return await api.post(`/contracts-types/${company}`, { ...payload }).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.CREATE_CONTRACT_TYPE_SUCCESSFUL,
            payload: response?.data?.node,
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CREATE_CONTRACT_TYPE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const deleteContractTypeCustomField: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.DELETE_CONTRACT_TYPE_CUSTOM_FIELD,
        payload: payload
    });

    return await api.delete(`/contracts-types/custom-fields/${payload?.contractTypeId}/${payload?.customFieldId}/${company}`).then(response => {
        //toast.success(response.data.response?.message || 'SUCCESSFUL');
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_TYPE_CUSTOM_FIELD_SUCCESSFUL,
            payload: response?.data?.node,
            deleted: payload?.customFieldId
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message || 'Error')
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_TYPE_CUSTOM_FIELD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const deleteContractType: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.DELETE_CONTRACT_TYPE,
        payload: payload
    });
    const company = getState().companies?.company?._id;
    return await api.delete(`/contracts-types/${payload?.contractTypeId}/${company}`, { data: { company: company } }).then(response => {
        toast.success(response.data.response?.message || 'SUCCESSFUL');
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_TYPE_SUCCESSFUL,
            payload: response?.data?.node
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message || 'Error')
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_TYPE_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const setSaveContractTypeData: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.SAVE_CONTRACT_TYPE_DATA,
        payload: payload
    });

    return await api.put(`/contracts-types/${payload?.contractTypeId}/${company}`, { ...payload }).then(response => {
        //toast.success(response.data.response?.message);
        dispatch({
            type: ActionDispatchTypes.SAVE_CONTRACT_TYPE_DATA_SUCCESSFUL,
            payload: response?.data?.node
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message || 'An error has occurred')
        dispatch({
            type: ActionDispatchTypes.SAVE_CONTRACT_TYPE_DATA_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const setEditContractTypeData: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    if (payload?.key == 'setEditData') {
        dispatch({
            type: ActionDispatchTypes.SET_EDIT_CONTRACT_TYPE_DATA,
            payload: payload?.data,
        });
    }

    if (payload?.key == 'reOrder') {

        const data = payload?.data;
        let addedItem = getState().contracts.editedContractType.customFields.splice(data.sourceIndex, 1);

        dispatch({
            type: ActionDispatchTypes.SET_EDIT_CONTRACT_TYPE_REORDER,
            payload: data,
            addedItem: addedItem
        });

    }


}



export const addCustomFieldToContractType: TAction = (payload: any) => async (dispatch, getState) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.ADD_CONTRACT_TYPE_CUSTOM_FIELD,
        payload: payload
    });

    return await api.post(`/contracts-types/custom-fields/${payload?.contractTypeId}/${company}`, { ...payload }).then(response => {
        //toast.success(response.data.response?.message || 'SUCCESSFUL');

        const addedItem = getState()?.contracts?.fields.filter((item: any) => {
            return item?._id == payload?.customFieldId
        })


        dispatch({
            type: ActionDispatchTypes.ADD_CONTRACT_TYPE_CUSTOM_FIELD_SUCCESSFUL,
            payload: response?.data?.node,
            addedItem: addedItem
        });

        return response?.data?.node

    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message || 'Error')
        dispatch({
            type: ActionDispatchTypes.ADD_CONTRACT_TYPE_CUSTOM_FIELD_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}

export const getContractTableList: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_CONTRACT_LIST,
        payload
    })


    return api.get(`/contracts/tablelist/${company}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_LIST_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        if (company) {
            toggleGenericErrorModal(dispatch, errorResponse);
        }
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_LIST_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const getFilteredContractTableList: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;

    return api.post(`/contracts/filter-table/${company}`, {
        searchText: payload?.searchText || null,
        searchIn: payload?.searchIn || null,
        page: payload?.page || 1,
        batchSize: payload?.batchSize || 10,
        filters: payload?.filters || {},
        sortBy: payload?.sortBy || [],
        columns: payload?.columns || [],
    }).then((response: any) => {
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        if (company) {
            toggleGenericErrorModal(dispatch, errorResponse);
        }
        return errorResponse;
    })
}
let exportExcelData: any[] = [];
export const excelExportFilteredTable: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    console.log(payload)
    if (payload?.page == 1) {
        toast.loading('Please wait...')
    }

    return api.post(`/contracts/filter-table/export-excel/${company}`, {
        searchText: payload?.searchText || null,
        searchIn: payload?.searchIn || null,
        page: payload?.page || 1,
        batchSize: payload?.batchSize || 10,
        filters: payload?.filters || {},
        sortBy: payload?.sortBy || [],
        columns: payload?.columns || [],
    }).then((response: any) => {


        exportExcelData = Array.from(new Set([...exportExcelData, ...response?.data?.node?.FinalExportData]))
        if (response?.data?.pagination?.hasMore) {
            console.log('response?.data?.pagination?.hasMore', response?.data?.pagination?.hasMore)
            excelExportFilteredTable({
                searchText: payload?.searchText || null,
                searchIn: payload?.searchIn || null,
                page: response?.data?.pagination?.page + 1,
                batchSize: payload?.batchSize || 10,
                filters: payload?.filters || {},
                sortBy: payload?.sortBy || [],
                columns: payload?.columns || [],
            })(dispatch, getState);
            return;
        }

        const date = moment().format('DD-mm-YYYY_HH-mm-ss');
        const filename: string = 'contracts_export_' + date;
        const headers = Object.keys(exportExcelData[0]);
        const values = exportExcelData;
        excelCreator(filename, headers, values)
        exportExcelData = [];
        toast.dismiss()
        toast.success('Document has generated...')


    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        if (company) {
            toggleGenericErrorModal(dispatch, errorResponse);
        }
        return errorResponse;
    })
}

export const getContractCompanies: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_CONTRACT_CONTRACT_COMPANIES,
        payload
    })


    return api.get(`/contracts-companies/list/${company}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_CONTRACT_COMPANIES_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_CONTRACT_COMPANIES_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const getContractContacts: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_CONTRACT_CONTRACT_CONTACTS,
        payload
    })

    return api.get(`/contracts-contacts/list/${getState()?.companies?.currentCompany}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_CONTRACT_CONTACTS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_CONTRACT_CONTACTS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const getContractCustomFields: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_TABLE_CONTRACT_CUSTOM_FIELD,
        payload
    })

    return api.get(`/custom-fields/list/${getState()?.companies?.currentCompany}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_TABLE_CONTRACT_CUSTOM_FIELD_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_TABLE_CONTRACT_CUSTOM_FIELD_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const getTableContractTypes: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_TABLE_CONTRACT_TYPES,
        payload
    })

    return api.get(`/contracts-types/list/${company}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_TABLE_CONTRACT_TYPES_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_TABLE_CONTRACT_TYPES_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const createContractCompany: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.CREATE_CONTRACT_COMPANY,
        payload
    })

    return api.post(`/contracts-companies/${company}`, { ...payload }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.CREATE_CONTRACT_COMPANY_SUCCESSFUL,
            payload: response.data
        })
        return true;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CREATE_CONTRACT_COMPANY_FAILED,
            payload: errorResponse
        });
        return false;
    })
}

export const editContractCompany: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.EDIT_CONTRACT_COMPANY,
        payload
    })

    return api.put(`/contracts-companies/${payload?.id}/${company}`, { ...payload }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.EDIT_CONTRACT_COMPANY_SUCCESSFUL,
            payload: response.data
        })
        return true;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.EDIT_CONTRACT_COMPANY_FAILED,
            payload: errorResponse
        });
        return false;
    })
}

export const deleteContractCompany: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.DELETE_CONTRACT_COMPANY,
        payload
    })

    return api.delete(`/contracts-companies/${payload?.id}/${company}`, { data: { company: payload?.company } }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_COMPANY_SUCCESSFUL,
            payload: response.data
        })
        return true;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_COMPANY_FAILED,
            payload: errorResponse
        });
        return false;
    })
}

export const createContractContact: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.CREATE_CONTRACT_CONTACT,
        payload
    })

    return api.post(`/contracts-contacts/${company}`, { ...payload }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.CREATE_CONTRACT_CONTACT_SUCCESSFUL,
            payload: response.data
        })
        return true;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CREATE_CONTRACT_CONTACT_FAILED,
            payload: errorResponse
        });
        return false;
    })
}

export const editContractContact: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.EDIT_CONTRACT_CONTACT,
        payload
    })

    return api.put(`/contracts-contacts/${payload?.id}/${company}`, { ...payload }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.EDIT_CONTRACT_CONTACT_SUCCESSFUL,
            payload: response.data
        })
        return true;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.EDIT_CONTRACT_CONTACT_FAILED,
            payload: errorResponse
        });
        return false;
    })
}

export const deleteContractContact: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.DELETE_CONTRACT_CONTACT,
        payload
    })

    return api.delete(`/contracts-contacts/${payload?.id}/${company}`, { data: { company: payload?.company } }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_CONTACT_SUCCESSFUL,
            payload: response.data
        })
        return true;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_CONTRACT_CONTACT_FAILED,
            payload: errorResponse
        });
        return false;
    })
}

export const getContractCompaniesDetail: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;

    return api.get(`/contracts/companies/${payload?.contractId}/${company}`)
        .then((response: any) => {
            return response?.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_CONTRACT_CONTACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const getContractContactsDetail: TAction = (payload: any) => (dispatch: any, getState: any) => {

    const company = getState().companies?.company?._id;

    return api.get(`/contracts/contacts/${payload?.contractId}/${company}`)
        .then((response: any) => {
            return response?.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_CONTRACT_CONTACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const getContractAssigedUsersDetail: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    return api.get(`/contracts/users/${payload?.contractId}/${company}`)
        .then((response: any) => {
            return response?.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_CONTRACT_CONTACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const addTagToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.ADD_TAG_TO_CONTRACT,
        payload
    })
    return api.post(`/contracts/tags/${company}/${payload?.contractId}`, { tag: payload?.tag })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Tag added the contract!');
            dispatch({
                type: ActionDispatchTypes.ADD_TAG_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.ADD_TAG_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const removeTagToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.REMOVE_TAG_TO_CONTRACT,
        payload
    })
    return api.delete(`/contracts/tags/${payload?.companyId}/${payload?.contractId}`, { data: { tag: payload?.tag } })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Tag removed from the contract!');
            dispatch({
                type: ActionDispatchTypes.REMOVE_TAG_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.REMOVE_TAG_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const addCompanyToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;

    dispatch({
        type: ActionDispatchTypes.ADD_CONTRACT_COMPANY_TO_CONTRACT,
        payload
    })
    return api.post(`/contracts/companies/${company}/${payload?.contractId}`, { company: payload?.addedCompany })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Tag removed from the contract!');
            dispatch({
                type: ActionDispatchTypes.ADD_CONTRACT_COMPANY_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.ADD_CONTRACT_COMPANY_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const removeCompanyToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.REMOVE_CONTRACT_COMPANY_TO_CONTRACT,
        payload
    })
    return api.delete(`/contracts/companies/${company}/${payload?.contractId}`, { data: { company: payload?.removedCompany } })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Tag removed from the contract!');
            dispatch({
                type: ActionDispatchTypes.REMOVE_CONTRACT_COMPANY_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.REMOVE_CONTRACT_COMPANY_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const addContactToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.ADD_CONTRACT_CONTACT_TO_CONTRACT,
        payload
    })
    return api.post(`/contracts/contacts/${company}/${payload?.contractId}`, { contact: payload?.addedItem })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Tag removed from the contract!');
            dispatch({
                type: ActionDispatchTypes.ADD_CONTRACT_CONTACT_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.ADD_CONTRACT_CONTACT_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const removeContactToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.REMOVE_CONTRACT_CONTACT_TO_CONTRACT,
        payload
    })
    return api.delete(`/contracts/contacts/${company}/${payload?.contractId}`, { data: { contact: payload?.removedItem } })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Tag removed from the contract!');
            dispatch({
                type: ActionDispatchTypes.REMOVE_CONTRACT_CONTACT_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.REMOVE_CONTRACT_CONTACT_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const addAssignedUserToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT,
        payload
    })
    return api.post(`/contracts/assign-user/${company}/${payload?.contractId}`, { assignUser: payload?.addedItem })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Tag removed from the contract!');
            dispatch({
                type: ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const removeAssignedUserToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.REMOVE_ASSIGNED_USER_TO_CONTRACT,
        payload
    })
    return api.delete(`/contracts/assign-user/${company}/${payload?.contractId}`, { data: { assignUser: payload?.removedItem } })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Tag removed from the contract!');
            dispatch({
                type: ActionDispatchTypes.REMOVE_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.REMOVE_ASSIGNED_USER_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}



let exportData: any[] = [];
export const exportContractToExcel = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    if (payload?.page == 1) {
        toast.loading('Please wait...')
    }
    dispatch({
        type: ActionDispatchTypes.EXPORT_CONTRACT_TO_EXCEL,
        payload
    })

    return api.get(`/contracts/export/excel/${company}`, {
        params: {
            page: payload?.page || 1,
            ...payload?.params
        }
    }).then((response: any) => {


        exportData = Array.from(new Set([...exportData, ...response?.data?.node?.contracts]))
        if (response?.data?.pagination?.hasMore) {

            exportContractToExcel({
                params: {
                    ...payload?.params,
                    page: response?.data?.pagination?.page + 1,

                }

            })(dispatch, getState);
            return;
        }
        const date = moment().format('DD-mm-YYYY_HH-mm-ss');
        const filename: string = 'contracts_export_' + date;
        const headers = Object.keys(exportData[0]);
        const values = exportData;
        excelCreator(filename, headers, values)
        exportData = [];
        toast.dismiss()
        toast.success('Document has generated...')
        dispatch({
            type: ActionDispatchTypes.EXPORT_CONTRACT_TO_EXCEL_SUCCESSFUL,
            payload: response?.data?.node
        })

    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.dismiss()
        toast.error(errorResponse?.message || 'Error')

        dispatch({
            type: ActionDispatchTypes.EXPORT_CONTRACT_TO_EXCEL_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })

}


export const getContractFiles: TAction = (payload: any) => async (dispatch, getState) => {

    dispatch({
        type: ActionDispatchTypes.GET_CONTRACT_FILES,
        payload: payload
    });
    const contractId = payload.contractId;
    const company = getState()?.companies?.company?._id;
    return api.get(`/contracts/${company}/${contractId}/files`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 50,
            ...payload?.more
        }
    }).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_FILES_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_FILES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}


export const getContractReminders: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id
    const contractId = payload?.contractId
    dispatch({
        type: ActionDispatchTypes.GET_CONTRACT_REMINDERS,
        payload
    })
    return api.get(`/reminders/${company}/${contractId}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_REMINDERS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_REMINDERS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const addReminderToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const contractId = getState().contracts?.contractOverview?._id;
    dispatch({
        type: ActionDispatchTypes.ADD_REMINDER_TO_CONTRACT,
        payload
    })
    return api.post(`/reminder/${company}/${contractId}`, { ...payload })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Reminder added.');
            dispatch({
                type: ActionDispatchTypes.ADD_REMINDER_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.ADD_REMINDER_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const deleteReminder: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const reminderId = payload?.reminderId;
    dispatch({
        type: ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT,
        payload
    })
    return api.delete(`/reminder/${company}/${reminderId}`)
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Reminder added.');
            dispatch({
                type: ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return true;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const editReminderToContract: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const contractId = getState().contracts?.contractOverview?._id;
    const reminderId = payload?.reminderId;
    dispatch({
        type: ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT,
        payload
    })
    return api.put(`/reminder/${company}/${reminderId}`, { ...payload?.requestData })
        .then((response: any) => {
            //toast.success(response.data.response?.message || 'Reminder added.');
            dispatch({
                type: ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL,
                payload: response?.data?.node
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.ADD_ASSIGNED_USER_TO_CONTRACT_FAILED,
                payload: errorResponse
            });
            return false;
        })
}


export const getContractFilters: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_CONTRACT_FILTERS,
        payload
    })


    return api.get(`/contract/filter/list/${company}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_FILTERS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_CONTRACT_FILTERS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}