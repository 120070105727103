import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toggleApprovalProcessFileModal } from "@redux/components/index.actions";
import {
  addApproveProcessFileUserIntoStep, addApproveProcessFileStep, removeApproveProcessFileRecipient, removeAllSteps, deleteApproveProcessFileStep,
  updateApproveProcessFileStep, getReadApproveProcess, updateApproveProcessFileStepUser, updateApproveTemplate, copyTemplateStepsToProcessFile, startApproving
} from "@redux/approve/index.actions";

import AddContract from "@pages/contracts/AddContract";
import { useTranslation } from "react-i18next";

import './index.scss'
import FeatherIcons from "feather-icons-react";
import apiInstance from "@clients/api.client";
import { toast } from "react-toastify";
import { FiSave } from "react-icons/fi";
import { VscSaveAs, VscSave } from "react-icons/vsc";
import ApproveTemplatesSelect from "@components/SelectsPaginated/ApproveTemplates";
import Loader from "@components/Loader";
import Lootie from "@components/ReactLottie";
import { RiDeleteBinLine } from "react-icons/ri";

const MapStateToProps = (state: any) => ({
  components: state.components,
  approve: state.approve,
  companies: state.companies,
});

const MapActionsToProps = {
  toggleApprovalProcessFileModal: toggleApprovalProcessFileModal,
  addUserIntoStep: addApproveProcessFileUserIntoStep,
  addStep: addApproveProcessFileStep,
  removeRecipient: removeApproveProcessFileRecipient,
  removeAllSteps: removeAllSteps,
  deleteStep: deleteApproveProcessFileStep,
  updateStep: updateApproveProcessFileStep,
  updateStepUser: updateApproveProcessFileStepUser,
  updateApproveTemplate: updateApproveTemplate,
  copyTemplateStepsToProcessFile: copyTemplateStepsToProcessFile,
  startApproving: startApproving,
  getReadApproveProcess: getReadApproveProcess
};

const ApprovalProcessFileModal: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();



  const listRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)


  const [userMessage, setUserMessage] = useState<string>("")

  const [initialSteps, setInitialSteps] = useState<any>({})

  const [approveFile, setApproveFile] = useState<any>(initialSteps)

  const [suggestions, setSuggestions] = useState<any>({})

  const [emailValues, setEmailValues] = useState<any>({})


  /*  if (listRef && listRef.current && headerRef && headerRef.current) {
     listRef.current.addEventListener('scroll', (e: any) => {
       if (e.target.scrollTop === 0) {
         headerRef.current?.classList.remove('shadow')
       } else {
         headerRef.current?.classList.add('shadow')
       }
 
     })
   } */


  const handleDeleteStep = (step: any) => {
    const id = step._id;
    if (!id) {
      toast.error(t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.STEP_NOT_FOUND'))
      return;
    }

    props?.deleteStep({ id });
    setShowButtons(true)
  }


  const handleAddUser = (data: any) => {

    if (!data?.email) {
      return;
    }
    //email validation with regex
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(data?.email)) {
      return toast.error(t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.EMAIL_VALIDATION_ERROR'))
    }

    console.log('data?.step', data?.step)
    props?.addUserIntoStep({
      step: data?.step,
      email: data?.email
    }).then((res: any) => {
      console.log('BURADA', res)
      setEmailValues({})
      setSuggestions({})
      setShowButtons(true)
    })
  }

  const handleRemoveRecipient = (stepUserItem: any, stepItem: any) => {
    props?.removeRecipient({
      recipientId: stepUserItem._id,
      stepId: stepItem._id,
    })
    setShowButtons(true)

  }
  const handleRemoveAllSteps = () => {
    props?.removeAllSteps({})
    setShowButtons(true)

  }
  const changeApproveAll = (result: any, stepItem: any) => {
    console.log('RESULT', result, stepItem?._id)

    props?.updateStep({
      id: stepItem?._id,
      fieldName: 'aproveAll',
      fieldValue: result === 'all' ? true : false
    }
    ).then((res: any) => {
      setShowButtons(true)
    })
  }
  const changeStepUserConfig = (result: any, stepItem: any, stepUserItem: any, fieldName: any) => {
    console.log('RESULT', result, stepItem?._id)

    props?.updateStepUser({
      stepId: stepItem?._id,
      stepUserId: stepUserItem?._id,
      fieldName: fieldName,
      fieldValue: result
    }).then((res: any) => {
      setShowButtons(true)
    })
  }
  const handleUpdateApproveTemplate = (fieldName: any, fieldValue: any) => {

    if (!fieldName || !fieldValue) {
      return;
    }

    props?.updateApproveTemplate({
      fieldName: fieldName,
      fieldValue: fieldValue
    })
  }

  const [approveTemplateId, setApproveTemplateId] = useState<any>(null)
  const [approveTemplateName, setApproveTemplateName] = useState<any>(null)

  const handleSelectApproveTemplate = (data: any) => {
    let approveTemplateId = data?.value;
    if (!approveTemplateId) {
      return;
    }
    console.log('approveTemplateId', approveTemplateId)
    props?.copyTemplateStepsToProcessFile({
      approveTemplateId: approveTemplateId
    })
    setShowButtons(false)

  }


  useEffect(() => {
    setShowButtons(props?.approve?.approveShowButtons)
  }, [props?.approve?.approveShowButtons])

  const [saveAsName, setSaveAsName] = useState<any>(null)
  const [showSaveAsModal, setShowSaveAsModal] = useState<any>(false)

  const [saveName, setSaveName] = useState<any>(null)
  const [showSaveModal, setShowSaveModal] = useState<any>(false)

  const [loading, setLoading] = useState<any>(false)
  const [showButtons, setShowButtons] = useState<any>(false)


  return (
    <>
      <Modal
        show={props?.components?.approvalProcessFileModal}
        onHide={props.toggleApprovalProcessFileModal}
        keyboard={false}
        backdrop={true}
        centered
        size={'xl'}
        contentClassName="shadow">
        <Modal.Header className="bg-secondary">
          <Modal.Title className="text-white"
            style={{
              paddingLeft: 10
            }}
          >{t('APPROVAL_PROCESS_FILE_MODAL.TITLE')}</Modal.Title>
          <div className="gap-1 d-flex align-items-center justify-content-end">
            <Button variant="primary"
              disabled={props?.approve?.startApproving}
              className="text-white d-flex align-items-center justify-content-center gap-1"
              onClick={() => {
                if (props?.approve?.selectedApproveProcessFile?.steps.some((step: any) => !step.stepUsers.length)) {
                  toast.error('Lütfen adımları boş bırakmayınız.')
                  return;
                }
                props?.startApproving({
                  userMessage: userMessage
                }).then((res: any) => {
                  props?.getReadApproveProcess({
                    id: props?.approve?.selectedApproveProcess?._id
                  })
                })
              }}
            >
              <FeatherIcons size={14} icon="send" /> <span>{t('APPROVAL_PROCESS_FILE_MODAL.SEND_APPROVE')}</span>
            </Button>
            <Button variant="link" className="text-white" onClick={props.toggleApprovalProcessFileModal}>
              <FeatherIcons size={18} icon="x" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">

          <div ref={listRef} className="aprrove-process">


            {props?.approve?.startApproving === true ? (
              <div
                className="preloader"
                style={{
                  height: '100%',
                  background: 'white',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                  textAlign: 'center',
                  minHeight: '400px'
                }}>
                <div style={{ width: '300px' }}>
                  <Lootie></Lootie>
                  <p>{t('APPROVAL_PROCESS_FILE_MODAL.LOADING')}</p>
                </div>

              </div>
            ) : (
              <>

                <div ref={headerRef} className="approve__header">

                  <div className="approve__header__top">
                    <textarea
                      value={userMessage}
                      onChange={(e) => {
                        setUserMessage(e.target.value)
                      }}
                      className="approve-message-textarea" placeholder={t('APPROVE_PROCESS_MODAL.CREATE_MODAL.USER_NOTE_PLACEHOLDER')}></textarea>
                  </div>
                  <div className="approve__header__body">
                    <div className="approve__header__title">
                      <ApproveTemplatesSelect
                        value={() => {
                          if (approveTemplateId && approveTemplateName) {
                            return {
                              value: approveTemplateId,
                              label: approveTemplateName
                            }
                          }
                        }}
                        setShowButtons={setShowButtons}
                        onValueSubmit={(value: any) => {
                          handleSelectApproveTemplate(value)
                          setApproveTemplateId(value?.value)
                          setApproveTemplateName(value?.label)
                        }}
                      />
                    </div>
                    <div className="approve__header__actions">
                      {(showButtons && props?.approve?.selectedApproveProcessFile?.steps?.length > 0) && (<>
                        <Button
                          onClick={() => {
                            setShowSaveAsModal(true)
                          }}
                          variant="link" className="approve__header__actions__button">
                          <FeatherIcons size={14} icon="bookmark" /> {t('APPROVE_PROCESS_MODAL.CREATE_MODAL.SAVE_AS_TEMPLATE')}
                        </Button>
                        {approveTemplateId && (
                          <Button
                            onClick={() => {
                              setShowSaveModal(true)
                            }}
                            variant="link" className="approve__header__actions__button"> <FeatherIcons size={14} icon="save" /> {t('APPROVE_PROCESS_MODAL.CREATE_MODAL.SAVE')}</Button>
                        )}
                      </>)}



                    </div>
                  </div>


                </div>
                <div className="steps">
                  {/*    {props?.approve?.selectedApproveProcessFile?.steps?.length > 0 && (
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        style={{ paddingRight: 0 }}
                        onClick={e => handleRemoveAllSteps()}
                        variant="link" className="text-danger d-flex align-items-center justify-content-between gap-1">
                        <span
                          className="d-flex align-items-center justify-content-between"
                        ><RiDeleteBinLine size={14} /></span>
                        <span
                          className="d-flex align-items-center justify-content-between"
                          style={{ fontSize: '14px', lineHeight: '14px' }}>
                          {t('APPROVAL_PROCESS_FILE_MODAL.DELETE_ALL')}
                        </span>
                      </Button>
                    </div>
                  )} */}
                  {(props?.approve?.selectedApproveProcessFile?.steps || []).map((stepItem: any, index: number) => {
                    console.log('stepItem', stepItem)
                    return (
                      <div key={'step__' + index} className="step">
                        <div className="step-number" style={{ marginBottom: 20 }}>
                          <h4 className="m-0">{index + 1}. {t('APPROVAL_PROCESS_FILE_MODAL.STEP')}</h4>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteStep(stepItem)
                            }}
                            style={{ paddingRight: 0 }}
                            className="btn btn-link btn-sm text-danger">{t('APPROVAL_PROCESS_FILE_MODAL.DELETE')}</button>
                        </div>
                        <div className="recipients">

                          {(stepItem?.stepUsers || []).map((stepUserItem: any, recipientIndex: number) => {
                            return (
                              <div key={'recipient' + stepUserItem?._id} className="recipient">
                                <div className="recipient-group">
                                  <div className="recipient-label">{stepUserItem?.email}</div>
                                  <div className="recipient-settings">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id={"inlineRadio1" + stepUserItem?._id}
                                        onChange={(e) => changeStepUserConfig(e.target.checked, stepItem, stepUserItem, 'canChangeRequest')}
                                        checked={stepUserItem?.canChangeRequest} />
                                      <label className="form-check-label" htmlFor={"inlineRadio1" + stepUserItem?._id}>{t('APPROVAL_PROCESS_FILE_MODAL.CAN_CHANGE_REQUEST')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        onChange={(e) => changeStepUserConfig(e.target.checked, stepItem, stepUserItem, 'canAssign')}
                                        className="form-check-input" type="checkbox" name="inlineRadioOptions" id={"inlineRadio2" + stepUserItem?._id} checked={stepUserItem?.canAssign} />
                                      <label className="form-check-label" htmlFor={"inlineRadio2" + stepUserItem?._id}>{t('APPROVAL_PROCESS_FILE_MODAL.CAN_ASSIGN')}</label>
                                    </div>
                                    <button className="btn btn-link text-dark py-0 px-0 m-0" onClick={e => handleRemoveRecipient(stepUserItem, stepItem)}>
                                      <FeatherIcons size={18} icon="x" />
                                    </button>
                                  </div>

                                </div>
                              </div>
                            )
                          })}
                          <div className="recipients-new">
                            <input
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  handleAddUser({
                                    step: { ...stepItem, index },
                                    email: emailValues[index]
                                  })
                                }
                              }}
                              key={'recipient__input_email' + index}
                              value={emailValues[index] || ''}
                              onChange={(e) => {
                                setEmailValues({
                                  ...emailValues,
                                  [index]: e.target.value
                                })
                              }}
                              onInput={(e: any) => {

                                if (e.target.value.length > 2) {
                                  apiInstance.get(`/companies/${props?.companies?.currentCompany}/users`, {
                                    params: {
                                      page: 1,
                                      batchSize: 10,
                                      searchText: e?.target?.value
                                    }
                                  }).then((res: any) => {
                                    console.log(res?.data?.node?.users)
                                    setSuggestions({
                                      ...suggestions,
                                      [index]: res?.data?.node?.users
                                    })
                                  })
                                } else {
                                  setSuggestions({
                                    ...suggestions,
                                    [index]: []
                                  })
                                }
                              }}
                              type="email" className="form-control"
                              placeholder={t('APPROVAL_PROCESS_FILE_MODAL.RECIPIENT_EMAIL')} />


                            <Button variant="primary" size="sm" className="add-recipient-button"
                              onClick={() => handleAddUser({
                                step: { ...stepItem, index },
                                email: emailValues[index]
                              })}
                            >
                              <FeatherIcons icon="plus" />
                            </Button>

                          </div>
                          {suggestions?.[index]?.length > 0 && (
                            <div className="recipient-suggestions">
                              {suggestions[index]?.map((item: any) => {
                                return (
                                  <div className="recipient-suggestions-item"
                                    onClick={(e: any) => {
                                      handleAddUser({
                                        step: { ...stepItem, index },
                                        email: e?.target?.innerText
                                      })
                                    }}
                                  >
                                    <span>{item?.email}</span>
                                  </div>
                                )
                              })}
                            </div>
                          )}


                        </div>

                        <div className="step-settings">
                          <span>
                            {t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM')}:
                          </span>
                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                value={'all'}
                                onChange={(e) => changeApproveAll(e.target.value, stepItem)}
                                className="form-check-input" type="radio" id={'inlineRadio1' + index} key={'key_inline_radio_1' + index} checked={stepItem?.aproveAll === true ? true : false} />
                              <label className="form-check-label" htmlFor={'inlineRadio1' + index}>{t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM_ALL')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                value={'one'}
                                onChange={(e) => changeApproveAll(e.target.value, stepItem)}
                                className="form-check-input" type="radio" id={'inlineRadio2' + index} key={'key_inline_radio_2' + index} checked={stepItem?.aproveAll !== true ? true : false} />
                              <label className="form-check-label" htmlFor={'inlineRadio2' + index}>{t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM_ONE')}</label>
                            </div>
                          </div>
                        </div>

                      </div>
                    )
                  })}

                  <div className="d-flex align-items-center justify-content-start">
                    <Button
                      onClick={() => {
                        props?.addStep()
                      }}
                      variant="outline-primary" className="d-flex align-items-center justify-content-center">
                      <FeatherIcons size={16} icon="plus" />
                      <span>{t('APPROVAL_PROCESS_FILE_MODAL.ADD_STEP')}</span>
                    </Button>
                  </div>
                </div>
              </>

            )}



          </div>

        </Modal.Body>
      </Modal>


      <Modal
        show={showSaveAsModal}
        onHide={() => setShowSaveAsModal(false)}
        backdropClassName="shadow"
        contentClassName="shadow"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('APPROVAL_PROCESS_FILE_MODAL.SAVE_AS_TEMPLATE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="save-as-modal">
            <div className="form-group mb-3">
              <label htmlFor="">{t('APPROVAL_PROCESS_FILE_MODAL.SAVE_AS_TEMPLATE')}</label>
              <input
                key={'saveAsName'}
                value={saveAsName}
                onChange={(e) => setSaveAsName(e.target.value)}
                type="text" className="form-control" />
            </div>
            <div className="form-group">
              <Button
                disabled={(saveAsName || loading) ? false : true}
                onClick={() => {
                  setLoading(true)
                  if (!saveAsName) {
                    toast.error('Lütfen şablon adı giriniz');
                    return
                  }
                  if (!props?.approve?.selectedApproveProcessFile?._id || !props?.companies?.company?._id) {
                    toast.error('Bir hata oluştu. Sayfayı yenileyip tekrar deneyiniz.');
                    return
                  }

                  apiInstance.post(`/approve-templates/save-as/${props?.companies?.company?._id}`, {
                    fileId: props?.approve?.selectedApproveProcessFile?._id,
                    saveAsName: saveAsName
                  }).then((res: any) => {

                    toast.success('Şablon başarıyla kaydedildi');
                    setShowSaveAsModal(false)

                    setApproveTemplateId(res?.data?.node?.data?.newApproveTemplate?._id)
                    setApproveTemplateName(res?.data?.node?.data?.newApproveTemplate?.title)
                    setShowButtons(false)

                  }).finally(() => {
                    setLoading(false)
                  })
                }}
                variant="primary" className="save-as-button">
                {t('APPROVAL_PROCESS_FILE_MODAL.SAVE')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSaveModal}
        onHide={() => setShowSaveModal(false)}
        backdropClassName="shadow"
        contentClassName="shadow"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('APPROVAL_PROCESS_FILE_MODAL.EDIT_TEMPLATE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="save-modal">
            <p>{t('APPROVAL_PROCESS_FILE_MODAL.EDIT_TEMPLATE_MESSAGE')}</p>
            <div className="form-group">
              <Button
                disabled={!loading ? false : true}
                onClick={() => {

                  setLoading(true)
                  if (!props?.approve?.selectedApproveProcessFile?._id || !props?.companies?.company?._id || !approveTemplateId) {
                    toast.error('Bir hata oluştu. Sayfayı yenileyip tekrar deneyiniz.');
                    setLoading(false)
                    return
                  }

                  apiInstance.post(`/approve-templates/save/${props?.companies?.company?._id}`, {
                    templateId: approveTemplateId,
                    fileId: props?.approve?.selectedApproveProcessFile?._id,
                  }).then((res: any) => {
                    toast.success('Şablon başarıyla güncellendi');
                    setShowSaveModal(false)

                  }).finally(() => {
                    setLoading(false)
                  })
                }}
                variant="primary" className="save-button">
                {t('APPROVAL_PROCESS_FILE_MODAL.SAVE')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(ApprovalProcessFileModal);
