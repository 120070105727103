import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Row, Col, Form, Container, Card } from "react-bootstrap";
import Select, { components } from "react-select";
import _ from "lodash";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect, useDispatch } from "react-redux";
import {
    getContractTypes,
    createContractCompany,
    getCompanyStatuses,
    getCompanyPipelines,
    createNewContract,
} from "@redux/contract/index.actions";
import {
    getCompanyUserList
} from "@redux/companies/index.actions";

import {
    toggleGenericAddPipelineModal, toggleGenericAddContractTypeModal
} from "@redux/components/index.actions";
import {
    toggleGenericAddContractModal
} from "@redux/components/index.actions";
import FormInput from "@components/FormInput";
import CustomFieldArea, { TCustomComponent } from "@components/CustomFieldArea";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Loader from '@components/Loader';

import CompaniesSelectInternal from "@components/SelectsPaginated/Companies/internal"
import CompaniesSelectExternal from "@components/SelectsPaginated/Companies/external"

import './AddContract.scss';
import { useHistory } from "react-router-dom";
import FeatherIcons from "feather-icons-react";

const mapStateToProps = (state: any) => ({
    users: state.users,
    contracts: state.contracts,
    companies: state.companies,
});

const mapActionsToProps = {
    createContractCompany,
    getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    createNewContract,
    toggleGenericAddContractModal,
    getCompanyUsers: getCompanyUserList,
    toggleGenericAddPipelineModal,
    toggleGenericAddContractTypeModal,
};


const AddNewPipeline = (props: any) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(toggleGenericAddPipelineModal({}));
    }
    return (
        <components.MenuList {...props}>
            <Button
                onClick={handleClick}
                className="react-select-inner-button btn btn-sm btn-primary outline-0 border-0 pointer"
            >
                <i className="icon">
                    <span>
                        <FeatherIcons icon="plus-square"></FeatherIcons>
                    </span>

                </i> Add New
            </Button>
            {props.children}
        </components.MenuList>
    );
};

const AddNewContractType = (props: any) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(toggleGenericAddContractTypeModal({}));
    }
    return (
        <components.MenuList {...props}>
            <Button
                onClick={handleClick}
                className="react-select-inner-button btn btn-sm btn-primary outline-0 border-0 pointer"
            >
                <i className="icon">
                    <span>
                        <FeatherIcons icon="plus-square"></FeatherIcons>
                    </span>

                </i> Add New
            </Button>
            {props.children}
        </components.MenuList>
    );
};

const StatusAdd = (props: any) => {
    const { t } = useTranslation();
    useEffect(() => {
        props.getCompanyPipelines()
    }, [])

    const [data, setData] = useState<{
        title: string
        description?: string
        startDate?: string
        endDate?: string
        renewalDate?: string
        signedDate?: string
        effectiveDate?: string
        status: string
        pipeline?: string
        type: string
        private: boolean
        assignedUsers: any[]
        contractCompanies: any[]
    }>({
        title: "",
        description: "",
        startDate: "",
        endDate: "",
        renewalDate: "",
        signedDate: "",
        effectiveDate: "",
        status: "",
        pipeline: "",
        type: "",
        private: false,
        assignedUsers: [],
        contractCompanies: []
    });
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsData, setCustomFieldsData] = useState<any>({});

    const [newPipeline, setNewPipeline] = useState(null);
    const [newStatus, setNewStatus] = useState(null);
    const [error, setError] = useState("");

    const [users, setUsers] = useState([])
    const [assignedUsers, setAssignedUsers] = useState([])
    const [contractCompaniesInternal, setContractCompaniesInternal] = useState([])
    const [contractCompaniesExternal, setContractCompaniesExternal] = useState([])

    useEffect(() => {
        props?.getCompanyUsers({
            batchSize: 100,
        }).then((res: any) => {
            setUsers(res?.node?.users);
        })
    }, [])

    const [selectedPipeline, setSelectedPipeline] = useState<any>({
        statuses: [],
    });


    const handleInput = (target: any) => {
        setData({ ...data, ...{ [target?.name]: target?.value } })
    }

    const handleSwitch = (switchData: {
        name: string,
        value: boolean
    }) => {
        setData({ ...data, ...{ [switchData?.name]: switchData?.value } })
    }
    const handleOnChange = (event: any, e: any) => {
        console.log('handleOnChange', event)
        console.log('handleOnChangeEEE', e)
        //e?.field?._id
        setCustomFieldsData({
            ...customFieldsData, ...{
                [e?.field?._id]: {
                    field: e?.field?._id,
                    value: event?.opt?.value,
                    relatedOption: event?.value,
                    label: event?.label
                }
            }
        })

    }

    const handleCustomFields = (event: any) => {
        console.log('handleCustomFields', event)
        setCustomFieldsData({
            ...customFieldsData, ...{
                [event?.target?.name]: {
                    field: event?.target?.name,
                    value: event?.target?.value,
                    relatedOption: null,
                }
            }
        })
    }

    const [showStartDate, setShowStartDate] = useState(false);
    const [showEndDate, setShowEndDate] = useState(false);
    const [showRenewalDate, setShowRenewalDate] = useState(false);
    const [showEffectiveDate, setShowEffectiveDate] = useState(false);
    const [showSignedDate, setShowSignedDate] = useState(false);

    const handleContractType = (target: any) => {
        setData({ ...data, ...{ [target?.name]: target?.value } })

        const contractType = _.filter(props?.contracts?.types?.ContractsTypes, { _id: target?.value });



        setShowStartDate(contractType[0]?.showStartDate);
        setShowEndDate(contractType[0]?.showEndDate);
        setShowRenewalDate(contractType[0]?.showRenewalDate);
        setShowEffectiveDate(contractType[0]?.showEffectiveDate);
        setShowSignedDate(contractType[0]?.showSignedDate);



        setCustomFieldsData({});
        setCustomFields([])

        const cf = contractType[0]?.customFields
        const newData: any = {};
        contractType[0]?.customFields?.map((e: any) => {
            newData[e?.field?._id] = {
                field: e?.field?._id,
                value: ""
            }
        })
        setCustomFieldsData(newData);
        setCustomFields(cf?.length ? cf : []);
    }


    const handlePipeline = (target: any) => {
        console.log('handlePipeline', target)
        setData({ ...data, ...{ [target?.name]: target?.value } })
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        console.log('submit')
        setLoading(true);
        let customFieldValues = _.values(customFieldsData)
        const requestData: {
            title: string
            description?: string
            startDate?: string
            endDate?: string
            renewalDate?: string
            effectiveDate?: string
            signedDate?: string
            status?: string
            pipeline?: string
            type: string
            private: boolean
            company: string
            customFields?: any[]
            assignedUsers?: any[]
            contractCompanies?: any[]
        } = {
            title: data?.title,
            description: data?.description,
            startDate: data?.startDate,
            endDate: data?.endDate,
            renewalDate: data?.renewalDate,
            effectiveDate: data?.effectiveDate,
            signedDate: data?.signedDate,
            status: data?.status,
            pipeline: data?.pipeline,
            type: data?.type,
            private: data?.private,
            company: props?.companies?.currentCompany,
            customFields: customFieldValues,
            assignedUsers,
            contractCompanies: [...contractCompaniesInternal, ...contractCompaniesExternal]

        }
        if (!requestData?.title) {
            toast.warning(t("CONTRACTS.ADD_CONTRACT.NO_TITLE"))
            document.getElementById("contractTitle")?.focus();

            setLoading(false);
            return;
        }
        if (!requestData?.type) {
            toast.warning(t("CONTRACTS.ADD_CONTRACT.NO_TYPE"))
            setLoading(false);
            return;
        }

        if (!requestData?.pipeline) {
            toast.warning(t("CONTRACTS.ADD_CONTRACT.PIPELINE"))
            setLoading(false);
            return;
        }
        if (!requestData?.status) {
            toast.warning(t("CONTRACTS.ADD_CONTRACT.NO_STATUS"))
            setLoading(false);
            return;
        }

        if (!requestData?.company) {
            toast.warning(t("CONTRACTS.ADD_CONTRACT.NO_COMPANY"))
            setLoading(false);
            return;
        }

        await props.createNewContract(requestData).then((res: any) => {
            if (res === false) {
                setLoading(false);
            } else {
                window.location.href = `/contract/overview/${res?._id}`
                setLoading(false);
                setCustomFieldsData([]);
                setCustomFields([])
                props?.handleToggle();
            }


        }).catch(() => {
            setLoading(false);
        })
    }

    const handleAssignedUsers = (event: any) => {
        setAssignedUsers(event)
    }


    useEffect(() => {
        props.getContractTypes().then((res: any) => {
            console.log('getContractTypes', res?.ContractsTypes[0])
            handleContractType({ name: 'type', value: res?.ContractsTypes[0]?._id })


            setShowStartDate(res?.ContractsTypes[0]?.showStartDate);
            setShowEndDate(res?.ContractsTypes[0]?.showEndDate);
            setShowRenewalDate(res?.ContractsTypes[0]?.showRenewalDate);
            setShowEffectiveDate(res?.ContractsTypes[0]?.showEffectiveDate);
            setShowSignedDate(res?.ContractsTypes[0]?.showSignedDate);


        }
        ).catch(() => {

        }
        )
    }, [])

    const firstBlock = (
        <>

        </>
    )


    return (
        <form onSubmit={event => handleSubmit(event)}>
            {
                (props?.modalPage === 1 || props?.modalPage === null || props?.modalPage === undefined)
                && (
                    <Container className="p-3">
                        <Form.Group>
                            <Form.Label>{t('CONTRACTS.ADD_CONTRACT.PARTIES_INTERNAL')}</Form.Label>
                            <CompaniesSelectInternal
                                partiesSide="internal"
                                values={contractCompaniesInternal}
                                onValueSubmit={(value: any) => { setContractCompaniesInternal(value) }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t('CONTRACTS.ADD_CONTRACT.PARTIES_EXTERNAL')}</Form.Label>
                            <CompaniesSelectExternal
                                partiesSide="external"
                                values={contractCompaniesExternal}
                                onValueSubmit={(value: any) => { setContractCompaniesExternal(value) }}
                            />
                        </Form.Group>
                        <Row className="mb-2">
                            <Col md={12} className="d-flex align-items-center justify-content-between">

                                <div className="text-start mt-3"></div>
                                <div className="text-end mt-3">
                                    <Button
                                        disabled={loading}
                                        variant="outline-primary"
                                        className="me-1"
                                        onClick={props?.toggleGenericAddContractModal}>
                                        {t("CONTRACTS.ADD_CONTRACT.CANCEL")}
                                    </Button>
                                    <Button onClick={event => props?.setModalPage(2)} variant="primary" type="button">
                                        {loading && <Loader />}
                                        {t('POPUPS.ADD_CONTRACT.NEXT')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )
            }
            {
                (props?.modalPage === 2)
                && (
                    <Container>

                        <Row>
                            <Col className="bg-white" md={8}>
                                <Row className="mb-2 p-3">
                                    <Col className="mb-2" md={12}>
                                        <Form.Group>
                                            <Form.Label>{t("CONTRACTS.ADD_CONTRACT.TITLE_LABEL")} (*)</Form.Label>
                                            <FormInput
                                                id="contractTitle"
                                                disabled={loading}
                                                className="form-control form-control-xs"
                                                name="title"
                                                type="text"
                                                value={data?.title}
                                                placeholder={t("CONTRACTS.ADD_CONTRACT.TITLE_PLACEHOLDER")}
                                                onInput={(event) => handleInput(event?.target)}
                                            />
                                            <Form.Text id="titleError" className="d-none text-danger">Lütfen başlık giriniz.</Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col className="mb-2" md={12}>
                                        <Form.Group>
                                            <Form.Label>{t("CONTRACTS.ADD_CONTRACT.DESCRIPTION_LABEL")}</Form.Label>
                                            <FormInput
                                                disabled={loading}
                                                value={data?.description}
                                                className="form-control form-control-xs"
                                                name="description"
                                                type="textarea"
                                                placeholder={t("CONTRACTS.ADD_CONTRACT.DESCRIPTION_PLACEHOLDER")}
                                                onInput={(event) => handleInput(event?.target)}
                                            />
                                        </Form.Group>

                                    </Col>


                                    {showStartDate && (
                                        <Col className="mb-2" md={6}>
                                            <Form.Group>
                                                <Form.Label>{t("CONTRACTS.ADD_CONTRACT.START_DATE_LABEL")}</Form.Label>
                                                <FormInput
                                                    disabled={loading}
                                                    value={data?.startDate}
                                                    className="form-control form-control-xs"
                                                    name="startDate"
                                                    type="date"
                                                    onInput={(event) => handleInput(event?.target)}
                                                />
                                            </Form.Group>

                                        </Col>
                                    )}
                                    {showEndDate && (
                                        <Col className="mb-2" md={6}>
                                            <Form.Group>
                                                <Form.Label>{t("CONTRACTS.ADD_CONTRACT.END_DATE_LABEL")}</Form.Label>
                                                <FormInput
                                                    disabled={loading}
                                                    className="form-control form-control-xs"
                                                    value={data?.endDate}
                                                    name="endDate"
                                                    type="date"
                                                    onInput={(event) => handleInput(event?.target)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    {showEffectiveDate && (
                                        <Col className="mb-2" md={6}>
                                            <Form.Group>
                                                <Form.Label>{t("CONTRACTS.ADD_CONTRACT.EFFECTIVE_DATE_LABEL")}</Form.Label>
                                                <FormInput
                                                    disabled={loading}
                                                    value={data?.effectiveDate}
                                                    className="form-control form-control-xs"
                                                    name="effectiveDate"
                                                    type="date"
                                                    onInput={(event) => handleInput(event?.target)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    {showRenewalDate && (
                                        <Col className="mb-2" md={6}>
                                            <Form.Group>
                                                <Form.Label>{t("CONTRACTS.ADD_CONTRACT.RENEWAL_DATE_LABEL")}</Form.Label>
                                                <FormInput
                                                    disabled={loading}
                                                    value={data?.renewalDate}
                                                    className="form-control form-control-xs"
                                                    name="renewalDate"
                                                    type="date"
                                                    onInput={(event) => handleInput(event?.target)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    {showSignedDate && (
                                        <Col className="mb-2" md={6}>
                                            <Form.Group>
                                                <Form.Label>{t("CONTRACTS.ADD_CONTRACT.SIGNED_DATE_LABEL")}</Form.Label>
                                                <FormInput
                                                    disabled={loading}
                                                    value={data?.signedDate}
                                                    className="form-control form-control-xs"
                                                    name="signedDate"
                                                    type="date"
                                                    onInput={(event) => handleInput(event?.target)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    <Col className="mb-2" md={12}>
                                        <Form.Group>
                                            <Form.Label>{t("CONTRACTS.ADD_CONTRACT.TYPE_LABEL")} (*)</Form.Label>
                                            <Select
                                                id="contractType"
                                                components={{ MenuList: AddNewContractType }}
                                                isDisabled={loading}
                                                isClearable={true}
                                                value={data?.type ? { value: data?.type, label: props?.contracts?.types?.ContractsTypes?.filter((item: any) => item?._id === data?.type)?.[0]?.title } : null}
                                                isMulti={false}
                                                placeholder={t("CONTRACTS.ADD_CONTRACT.TYPE_PLACEHOLDER")}
                                                options={
                                                    props?.contracts?.types?.ContractsTypes
                                                        ? props?.contracts?.types?.ContractsTypes.map((status: any) => {
                                                            return {
                                                                value: status?._id,
                                                                label: status?.title,
                                                            };
                                                        })
                                                        : []
                                                }
                                                onChange={(e: any) => {
                                                    setCustomFieldsData({})
                                                    handleContractType({ name: 'type', value: e?.value })
                                                }}
                                                className="react-select react-select-container"
                                                classNamePrefix="react-select"
                                            ></Select>
                                        </Form.Group>
                                    </Col>

                                    <Col className="mb-2" md="12">

                                        {customFields.length > 0 && (<CustomFieldArea>
                                            {(customFields || []).map((e: {
                                                field: {
                                                    title: string;
                                                    key: string;
                                                    CustomComponent: TCustomComponent
                                                    isOptional: boolean
                                                    _id: string
                                                    existOptionValues: boolean
                                                    extraProps: any
                                                },
                                                _id: string;
                                                value: any;
                                            }, index: number) => {

                                                console.log('e', customFields[index])

                                                let customField = props?.contracts?.fields?.filter((item: any) => item?._id === e?.field?._id)

                                                console.log('CustomField', customField[0])
                                                console.log('props?.contracts?.fields', props?.contracts?.fields)



                                                if (e?.field?.existOptionValues === true) {





                                                    return React.createElement(CustomFieldArea[e?.field?.CustomComponent], {
                                                        size: 6,
                                                        name: e?.field?._id,
                                                        label: e?.field?.title,
                                                        value: {
                                                            value: customFieldsData[e?.field?._id]?.value,
                                                            label: customFieldsData[e?.field?._id]?.label,
                                                        },
                                                        onChange: (event: any) => handleOnChange(event, e),
                                                        require: e?.field?.isOptional,
                                                        data: e
                                                    })
                                                } else {

                                                    if (e?.field?.CustomComponent === 'Formula') {

                                                        return React.createElement(CustomFieldArea[e?.field?.CustomComponent], {
                                                            size: 6,
                                                            name: e?.field?._id,
                                                            label: e?.field?.title,
                                                            value: e?.field?.extraProps?.formula,
                                                            onInput: (event: any) => handleCustomFields(event),
                                                            require: e?.field?.isOptional,
                                                            data: e
                                                        })
                                                    }
                                                    else {
                                                        return React.createElement(CustomFieldArea[e?.field?.CustomComponent], {
                                                            size: 6,
                                                            name: e?.field?._id,
                                                            label: e?.field?.title,
                                                            value: customFieldsData[e?.field?._id]?.value,
                                                            onInput: (event: any) => handleCustomFields(event),
                                                            require: e?.field?.isOptional,
                                                            data: e
                                                        })
                                                    }

                                                }


                                            })}

                                        </CustomFieldArea>)}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} className="p-3">
                                <Row>
                                    <Col md={12} className="mb-2">
                                        <Form.Group>
                                            <Form.Label>{t("CONTRACTS.ADD_CONTRACT.PIPELINE_LABEL")} (*)</Form.Label>
                                            <Select
                                                components={{ MenuList: AddNewPipeline }}
                                                value={data?.pipeline ? {
                                                    value: data?.pipeline,
                                                    label: props?.contracts?.pipelines.filter((e: any) => e?._id === data?.pipeline)[0]?.title
                                                } : null}
                                                isDisabled={loading}
                                                isClearable={true}
                                                isMulti={false}
                                                placeholder={t("CONTRACTS.ADD_CONTRACT.PIPELINE_PLACEHOLDER")}
                                                options={props?.contracts?.pipelines.map((pipeline: any) => {
                                                    return {
                                                        value: pipeline?._id,
                                                        label: pipeline?.title,
                                                        data: pipeline,
                                                    };
                                                })}
                                                onChange={(e: any) => {
                                                    if (!e || e == null) {
                                                        setSelectedPipeline({
                                                            statuses: [],
                                                        })
                                                        setNewStatus(null);
                                                    }
                                                    setSelectedPipeline(e?.data);
                                                    setNewPipeline(e?.value);
                                                    setNewStatus(null);
                                                    handlePipeline({ name: 'status', value: null })
                                                    handlePipeline({ name: 'pipeline', value: e?.value })
                                                }}
                                                className="react-select react-select-container"
                                                classNamePrefix="react-select"
                                            ></Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className="mb-2">
                                        <Form.Group>
                                            <Form.Label>{t("CONTRACTS.ADD_CONTRACT.STATUS_LABEL")} (*)</Form.Label>
                                            <Select
                                                isClearable={true}
                                                isDisabled={loading || selectedPipeline?.statuses?.length <= 0}
                                                isMulti={false}
                                                placeholder={t("CONTRACTS.ADD_CONTRACT.STATUS_PLACEHOLDER")}
                                                value={data?.status ? { value: data?.status, label: selectedPipeline?.statuses.filter((e: any) => e?._id === data?.status)[0]?.title } : null}
                                                options={
                                                    selectedPipeline?.statuses
                                                        ? selectedPipeline?.statuses.map((status: any) => {
                                                            return {
                                                                value: status?._id,
                                                                label: status?.title,
                                                            };
                                                        })
                                                        : []
                                                }
                                                onChange={(e: any) => {
                                                    setNewStatus(e?.value);
                                                    handlePipeline({ name: 'status', value: e?.value })
                                                }}
                                                className="react-select react-select-container"></Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className="mb-2">
                                        <Form.Group>
                                            <Form.Label>{t("CONTRACTS.ADD_CONTRACT.ASSIGNED_USERS")}</Form.Label>
                                            <Select
                                                isClearable={false}
                                                isMulti={true}
                                                value={assignedUsers?.map((e: any) => {
                                                    console.log('assignedUsers', assignedUsers)
                                                    return {
                                                        value: e?.value,
                                                        label: e?.label,
                                                    }
                                                })}
                                                options={(users || []).map((user: any) => {
                                                    return {
                                                        label: `${user?.name} ${user?.surname}`,
                                                        value: user?._id
                                                    }
                                                })}
                                                onChange={event => handleAssignedUsers(event)}
                                                className="react-select react-select-container"
                                            ></Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} className="mb-2">
                                        <Form.Group>
                                            <Form.Label>{t("CONTRACTS.ADD_CONTRACT.MAKE_PRIVATE")}</Form.Label>
                                            <Form.Check
                                                name={"private"}
                                                type="switch"

                                                checked={data?.private}
                                                onChange={(event: any) => {
                                                    handleSwitch({
                                                        name: "private",
                                                        value: event.target?.checked,
                                                    })

                                                    /* if (event.target?.value === "on") {
                                                        handleSwitch({
                                                            name: "private",
                                                            value: true
                                                        })
                                                    } else {
                                                        handleSwitch({
                                                            name: "private",
                                                            value: false
                                                        })
                                                    } */
                                                    console.log("Switch:", event.target?.value)
                                                }}
                                                className="add-contract-switch"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8} className="d-flex align-items-center justify-content-between p-3 bg-white">

                                <div className="text-start mt-3">

                                    <Button
                                        disabled={loading}
                                        variant="secondary"
                                        className="me-1"
                                        onClick={event => props?.setModalPage(1)}
                                    >
                                        <FeatherIcons icon={'arrow-left'} />
                                    </Button>
                                </div>

                            </Col>
                            <Col md={4} className="d-flex align-items-center justify-content-end p-3">

                                <div className="text-end mt-3">
                                    <Button
                                        disabled={loading}
                                        variant="outline-primary"
                                        className="me-1"
                                        onClick={props?.toggleGenericAddContractModal}
                                    >
                                        {t("CONTRACTS.ADD_CONTRACT.CANCEL")}
                                    </Button>
                                    <Button disabled={loading} name="btn" value={1} variant="primary" type="submit">
                                        {loading && <Loader />}
                                        {t("CONTRACTS.ADD_CONTRACT.CREATE")}
                                    </Button>
                                </div>

                            </Col>
                        </Row>

                    </Container>

                )
            }




        </form>);
};

export default connect(mapStateToProps, mapActionsToProps)(StatusAdd);
