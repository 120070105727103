import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DefaultLoader } from "@components/Loader";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

class FlatList extends React.Component<
  {
    data: any[];
    renderItem: (item: any, index: number) => JSX.Element;
    keyExtractor: (item: any, index: number) => string;
    onEndReached: () => void;
    onEndReachedThreshold?: number;
    hasMore: boolean;
    loader?: JSX.Element;
    scrollableParentTarget?: string;
    endMessage?: any;
  },
  any,
  any
> {
  fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.props?.onEndReached();
    }, 300);
  };

  render() {
    return (
      <InfiniteScroll
        dataLength={this.props.data.length}
        next={this.fetchMoreData}
        hasMore={this.props?.hasMore}
        style={{ overflowY: "hidden" }}
        scrollThreshold={this.props?.onEndReachedThreshold || 0.5}
        scrollableTarget={this.props?.scrollableParentTarget}
        endMessage={this.props?.endMessage}
        loader={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 40,
            }}
          >
            {this.props?.loader || <DefaultLoader /> || <h4>Loading...</h4>}
          </div>
        }
      >
        {this.props.data.map((item, index) =>
          this.props?.renderItem(item, index)
        )}
      </InfiniteScroll>
    );
  }
}

const randomIdGenerator = (length = 7): string => {
  let result: string = "";
  const characters: string =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength: number = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const ExampleComponent: React.FunctionComponent<any> = (props) => {
  const [data, setData] = useState<string[]>(["Ali", "Ayşe", "Mehmet"]);
  return (
    <FlatList
      data={data}
      renderItem={(item, index) => (
        <div
          style={{
            height: 30,
            border: "1px solid green",
            margin: 6,
            padding: 8,
          }}
          key={index}
        >
          div - #{index} - {item}
        </div>
      )}
      hasMore={true}
      onEndReached={() => {
        setData([
          ...data,
          "Ali",
          "Ayşe",
          "Mehmet",
          "Ali",
          "Ayşe",
          "Mehmet",
          "Ali",
          "Ayşe",
          "Mehmet",
          "Ali",
          "Ayşe",
          "Mehmet",
          "Ali",
          "Ayşe",
          "Mehmet",
        ]);
      }}
      onEndReachedThreshold={0.5}
      keyExtractor={(item, index) => index.toString()}
    />
  );
};

export default FlatList;
