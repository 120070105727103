import React, { useEffect, useState } from "react";
import FlatList from "@components/FlatList";
import { connect } from "react-redux";
import FeatherIcons from "feather-icons-react";
import Avatar from "react-avatar";
import { addAssignedUserToContract, getContractReminders } from '@redux/contract/index.actions';
import { DefaultLoader } from '@components/Loader';
import _ from "lodash";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import ShowMoreList from "@components/ShowMoreList"
import { useTranslation } from "react-i18next";
import moment from "moment";



const mapStateToProps = (state: any) => ({
    companies: state.companies,
    contracts: state.contracts
})

const mapActionsToProps = { getContractReminders, addAssignedUserToContract }


const ContactsFlatList: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshed, setRefreshed] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [batchSize, setBatchSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [more, setMore] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>("");


    const handleAddReminderToggle = () => {
        console.log('New reminder modal!');
    }

    const handleAddToCalendar = (data: any) => {
        console.log(data);
        // toggleAddToCalendarModal
        // handleSetCalendarData

        props?.handleSetCalendarData(data);
        props?.toggleAddToCalendarModal();

    }
    const loadMore = () => {
        props?.getContractReminders({
            contractId: props.contracts?.contractOverview?._id,
            page,
            batchSize,
            more: {
                searchText: searchText
            }
        }).then((res: any) => {
            ((res?.pagination?.page * batchSize) < total)
                ? setMore(true)
                : setMore(false)
            if ((res?.pagination?.page * batchSize) < total) {
                setMore(true);
                setPage(res?.pagination?.page + 1)
            } else {
                setMore(false);
            }
            setData([...data, ...res?.node?.reminders])

        })

    }

    const handleSearch = (event: any) => {
        setLoading(true)
        setPage(1);
        setSearchText(event?.target?.value)
    }

    const handleAdd = (data: {
        _id: string
    }) => {
        const requestData: {
            contractId: string
            companyId: string
            addedItem: string
        } = {
            contractId: props?.contracts?.contractOverview?._id,
            companyId: props?.companies?.currentCompany,
            addedItem: data?._id
        }

        props?.addAssignedUserToContract(requestData);
    }
    const debounceSearch = _.debounce(handleSearch, 600);

    useEffect(() => {
        if (props.contracts?.contractOverview?._id && props?.contracts?.contractOverviewModalContractId) {
            props?.getContractReminders({
                contractId: props.contracts?.contractOverview?._id,
                page,
                batchSize,
                more: {
                    searchText: searchText
                }
            }).then((res: any) => {
                if (res?.pagination?.totalCount) setTotal(res?.pagination?.totalCount);
                if ((res?.pagination?.page * batchSize) < res?.pagination?.totalCount) {
                    setMore(true);
                    setPage(page + 1);
                } else {
                    setMore(false)
                }
                setData(res?.node?.reminders)
                setLoading(false);
            })
        }

    }, [searchText, props.contracts?.contractOverview?._id])

    useEffect(() => {
        if (props.contracts?.contractOverview?._id && props?.contracts?.contractOverviewModalContractId) {
            props?.getContractReminders({
                contractId: props.contracts?.contractOverview?._id,
                page: 1,
                batchSize,
                more: {
                    searchText: searchText
                }
            }).then((res: any) => {
                if (res?.pagination?.totalCount) setTotal(res?.pagination?.totalCount);
                if ((res?.pagination?.page * batchSize) < res?.pagination?.totalCount) {
                    setMore(true);
                    setPage(page + 1);
                } else {
                    setMore(false)
                }
                setData(res?.node?.reminders)
                setLoading(false);
            })
        }

    }, [props.refreshList])


    if (!data?.length) {
        return (
            <></>
        )
    }

    return (
        <>
            {/*
          
            <input className="my-2 mt-1 form-control form-control-sm"
                placeholder={t('CONTRACTS.OVERVIEW.REMINDER.SEARCH_A_USER')}
                type="text"
                name="searchText"
                defaultValue={searchText}
                onInput={debounceSearch}
            />
          
          */}
            <div className="reminder-flat-scroll" id="remindersFlatList">
                {
                    (loading) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {
                    (!refreshed) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {data?.length
                    ? (<FlatList
                        scrollableParentTarget={"remindersFlatList"}
                        data={data || []}
                        renderItem={(item, index) => (
                            <div key={item?._id} className="reminder">
                                <div className="reminder-item">
                                    <div className="reminder-detail">
                                        <div className="mb-2 d-flex">
                                            <div>
                                                <h4 className="fw-normal text-primary">
                                                    <span>
                                                        {item?.title}
                                                    </span>
                                                    <span className="mx-3">
                                                        {(item?.guests || []).map((guest: any, index: number) => (
                                                            <OverlayTrigger key={'tooltip' + index} placement="right" overlay={<Tooltip>
                                                                {guest?.name + ' ' + guest?.surname}
                                                                <br />
                                                                {guest?.email}
                                                            </Tooltip>}>
                                                                <span style={{
                                                                    marginLeft: '-8px',
                                                                }}>
                                                                    <Avatar
                                                                        title="Avatar"

                                                                        name={guest?.name + ' ' + guest?.surname} className="rounded-circle" size={'30'}></Avatar>
                                                                </span>
                                                            </OverlayTrigger>
                                                        ))}
                                                    </span>
                                                </h4>
                                                <p className="my-1">
                                                    {item?.description}
                                                </p>
                                            </div>

                                        </div>
                                        <div className="d-flex align-items-center justify-content-start gap-1">

                                            <span className="d-flex align-items-center justify-content-start gap-1">
                                                <FeatherIcons icon={'calendar'}></FeatherIcons>
                                                {moment(item?.startDate).format('DD/MM/YYYY')}
                                            </span>
                                            <span className="d-flex align-items-center justify-content-start gap-1 mx-3">
                                                <FeatherIcons icon={'clock'}></FeatherIcons>
                                                {moment(item?.startDate).format('H:mm A')}
                                            </span>
                                        </div>

                                    </div>
                                    <div className="reminder-action d-flex">
                                        <Button onClick={event => handleAddToCalendar(item)} title="Add to calendar" className="btn btn-sm btn-link link text-muted border-0">
                                            <i className="bi-calendar"></i>
                                        </Button>
                                        <Button onClick={event => props.handleEdit(item)} title="Edit" className="btn btn-sm btn-link link text-muted border-0">
                                            <i className="bi-pencil"></i>
                                        </Button>
                                        <Button onClick={event => props.handleDelete(item?._id)} title="Delete" className="btn btn-sm btn-link link text-muted border-0">
                                            <i className="bi-trash"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        hasMore={more}
                        endMessage={
                            <div className="d-flex align-items-center justify-content-center no-more">
                                {
                                    t("CONTRACTS.OVERVIEW.REMINDER.NO_MORE")
                                }
                            </div>
                        }
                        onEndReached={() => {
                            loadMore()
                        }}
                        onEndReachedThreshold={0.5}
                        keyExtractor={(item, index) => index.toString()}
                    />)
                    : (<div className="d-flex align-items-center justify-content-center"
                        style={{ minHeight: '50px' }}>
                        <span style={{ color: '#cccccc', textAlign: 'center' }}>
                            {t("CONTRACTS.OVERVIEW.REMINDER.NO_DATA_FOUND_IN_THE_REMINDERS")}
                            <br />
                            {/* <button
                                className="btn btn-link"
                                onClick={handleAddReminderToggle}>
                                {t("CONTRACTS.OVERVIEW.REMINDER.CREATE_FIRST_REMINDER")}
                            </button> */}
                        </span>
                    </div>)
                }

            </div>
        </>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(ContactsFlatList);
