import React, { useEffect, useRef, useState } from "react";
import ScreenModal from "@components/Modals/ScreenModal";
import { connect } from 'react-redux';
import "./index.scss";
import globalStyles from "@styles/globalStyles";
import { Form, Button, Toast, Col, Row } from "react-bootstrap";
import ButtonLoader from "@components/Buttons/ButtonLoader";
import { GrChapterAdd, GrAdd } from 'react-icons/gr';
import { BsFillCursorFill, BsSearch, BsPlusCircle } from 'react-icons/bs';
import { HiViewGridAdd } from 'react-icons/hi';
import { RiDeleteBinFill } from 'react-icons/ri';
import ShouldCreateOrJoinACompany, { renderFooter as renderCreateOrJoinCompanyFooter } from '@popups/CompanyOnboarding/ShouldCreateOrJoinACompany';
import CreateCompany, { renderFooter as renderCreateCompanyFooter } from "@popups/CompanyOnboarding/CreateCompany";
import CreateCompanySurvey, { renderFooter as renderCreateCompanySurveyFooter } from "@popups/CompanyOnboarding/CreateCompanySurvey";
import Select from 'react-select';

import { setNewCompanyData, toggleAddUserToCompany } from "@redux/components/index.actions";
import { addUserToCompany } from "@redux/users/index.actions";
import { getRoles } from "@redux/roles/index.actions";
import Loader from "@components/Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


export const UserEmailComponentWithRedux: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState(props?.value?.email || '');
    const [roles, setRoles] = useState(props?.value?.roles || []);
    const roleOptions: {
        value: string,
        label: string
    }[] = (props?.roles?.roles || []).map((item: {
        _id: string,
        title: string,
        active: boolean
    }, index: number) => {
        return {
            value: item?._id,
            label: item?.title
        }
    });
    /* [
        {
            value: 'owner',
            label: 'Yönetici'
        },
        {
            value: 'role-1',
            label: 'Rol 1'
        },
        {
            value: 'user',
            label: 'Kullanıcı'
        },
    ]; */

    return (
        <div
            id={props?.id}
            style={{
                width: '100%',
                marginBottom: "20px"
            }}>
            <div className="departments-flex">
                <Form.Control
                    value={props?.value?.email || ''}
                    style={{ width: '100%' }}
                    onInput={(e) => {
                        setEmail(e.currentTarget.value);
                        props?.onInput(e.currentTarget.value, roles)
                    }}
                    id={"email" + props?.id}
                    name={"email" + props?.id}
                    type="email"
                    placeholder={t("POPUPS.ADD_USER_TO_COMPANY.PROVIDE_VALID_EMAIL")}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    {t("POPUPS.ADD_USER_TO_COMPANY.PROVIDE_VALID_EMAIL")}
                </Form.Control.Feedback>
                <button onClick={props?.dismiss} className="btn btn-outline-primary">
                    <RiDeleteBinFill size={20} />
                </button>
            </div>
            <Select
                styles={{
                    menu: ({ width, ...css }) => ({
                        ...css,
                        width: "auto"
                    }),
                }}
                onChange={(e: any) => {
                    //setRole(e.value);
                    const roles = e.map((item: any, index: number) => {
                        return item.value;
                    });
                    setRoles(roles)
                    props?.onInput(email, roles);
                }}
                isMulti={true}
                options={roleOptions}
                placeholder={t("POPUPS.ADD_USER_TO_COMPANY.SELECT_ROLES")}
                className="react-select react-select-container select-role-type max-height-100"
                classNamePrefix="react-select" />
        </div>
    )

}


const ShowToast: React.FunctionComponent<{
    show: boolean,
    onClose: () => void
}> = (props) => {
    return (
        <Toast onClose={props?.onClose}
            show={props?.show}
            delay={5000}
            color="success"
            style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                zIndex: 9999
            }}
            autohide={true}>
            <Toast.Header>
                <strong className="me-auto">Jetlexa</strong>
                <small>now</small>
            </Toast.Header>
            <Toast.Body>Link is copied into your clipboard</Toast.Body>
        </Toast>
    );
}







const randomIdGenerator = (length: number = 6): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

type TValue = {
    email: string,
    roles: any[]
}

const mapStateToProps = (state: any) => ({
    users: state.users,
    components: state.components,
    roles: state.roles
});

const mapActionsTopProps = {
    setNewCompanyData,
    toggleAddUserToCompany,
    addUserToCompany,
    getRoles
}


export const UserEmailComponent = connect(mapStateToProps, mapActionsTopProps)(UserEmailComponentWithRedux);

const AddUserToCompany: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(0);
    const inviteRef = useRef<HTMLInputElement>(null);
    const [emailIdsArr, setEmailIdsArr] = useState<string[]>([randomIdGenerator()]);
    const [values, setValues] = useState<TValue[]>([{
        email: "",
        roles: []
    }]);
    const next = () => {

    }
    const previous = () => {

    }

    const batchOperations: {
        onClose: () => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    } = {
        onClose: () => {
            props?.toggleAddUserToCompany(false)
        },
        next: next,
        previous: previous,
        index: index,
        length: 1
    }


    const addNewEmailLayer: any = () => {
        setEmailIdsArr([
            ...emailIdsArr,
            randomIdGenerator()
        ]);
        setValues([
            ...values,
            {
                email: "",
                roles: []
            }
        ]);
    }

    useEffect(() => {
        if (props?.roles?.roles.length === 0
            && !props?.roles?.endedFetchingRoles
            && props?.users?.user?.companies?.length !== 0
        ) {
            props?.getRoles()
        }
    }, [])

    const removeEmailLayer: any = (indexNumber: number) => {
        const newEmailIdsArr = emailIdsArr.filter((emailId: string, index: number) => {
            return index !== indexNumber;
        })
        const newValues = values.filter((item: TValue, index: number) => {
            return index !== indexNumber;
        })
        setEmailIdsArr(newEmailIdsArr);
        setValues(newValues);
    }


    const setValuesFromUserEmail = (index: number, value: {
        email: string,
        roles: string[]
    }) => {
        const newValues: TValue[] = [...values];
        newValues[index] = {
            ...newValues[index],
            email: value.email,
            ...value?.roles && {
                roles: (value?.roles)
            }
        };
        setValues(newValues)
    }
    const onInput = (e: any) => {

    }
    const handleSubmit = (e: any) => {

    }

    function copyText(entryText: string) {
        navigator.clipboard.writeText(entryText)
    }


    return (
        <React.Fragment>

            <ScreenModal
                show={props?.components?.showAddNewUserToCompanyModal}
                renderHeader={(<h4>{t("POPUPS.ADD_USER_TO_COMPANY.INVITE_USER_TO_COMPANY")}</h4>)}
                size={"lg"}
                renderFooter={(
                    <React.Fragment>
                        <button
                            disabled={disabled}
                            className="btn btn-primary addUserToCompany-finish"
                            onClick={async (e) => {
                                const invites: any[] = values.filter((item: TValue, index: number) => {
                                    if (item?.email === "" || item?.email === null || item?.email === undefined) {
                                        return false
                                    }
                                    return true;
                                })

                                if (invites.length === 0) {
                                    return toast.error(t("POPUPS.ADD_USER_TO_COMPANY.NO_EMAIL_ADDRESS_ADDED"));
                                }

                                setDisabled(true)
                                await props?.addUserToCompany({
                                    invites: invites,
                                    company: props?.users?.user?.companies[0]?.company
                                })
                                setDisabled(false)

                            }}>
                            Finish
                        </button>
                    </React.Fragment>
                )}
                onClose={batchOperations?.onClose}>
                <div className="company-onboarding">
                    <div className="container-fluid add-user-to-company">
                        <div className="row add-user-to-company-row">
                            <div className="col-lg-8 col-md-12">
                                <div className="innerContent">
                                    <p>{t("POPUPS.ADD_USER_TO_COMPANY.INVITE_USER_TO_COMPANY_DESCRIPTION")}</p>
                                    {/* <Form.Group className="mb-1" controlId="emailValidation">
                                        <Form.Label htmlFor="title">
                                            <b>Bağlantıyla davet et</b>
                                        </Form.Label>
                                        <div className="d-flex">
                                            <Form.Control
                                                defaultValue={"https://www.jetlexa.com/join/kdflmdfgklmslkdfdfklmsglk"}
                                                id="invite"
                                                ref={inviteRef}
                                                style={{ borderRight: "none" }}
                                                disabled
                                                name="invite"
                                                type="text"
                                                placeholder="Bağlantı ile davet et"
                                                required />
                                            <button
                                                onClick={(e: any) => {
                                                    if (inviteRef?.current) {
                                                        const value = inviteRef?.current.value;
                                                        copyText(value);
                                                        setShowToast(true);
                                                        setTimeout(() => { setShowToast(false) }, 1500)
                                                    }
                                                }}
                                                type="button"
                                                className="btn btn-outline-primary">

                                                Kopyala
                                            </button>


                                        </div>
                                        <Form.Control.Feedback type="invalid">Please provide a valid company name</Form.Control.Feedback>
                                    </Form.Group> */}
                                    <br />
                                    <Form.Group className="mb-4" controlId="emailValidation">
                                        <Form.Label htmlFor="title">
                                            {t("POPUPS.ADD_USER_TO_COMPANY.INVITE_VIA_EMAIL")}
                                        </Form.Label>
                                        {values.map((_, index) => {
                                            return <UserEmailComponent
                                                id={emailIdsArr[index]}
                                                onInput={(email: string, roles: any[]) => {
                                                    setValuesFromUserEmail(index, {
                                                        email,
                                                        roles
                                                    })
                                                }}
                                                value={_}
                                                index={index}
                                                dismiss={() => {
                                                    removeEmailLayer(index);
                                                }}
                                            />
                                        })}
                                        <div className="add-new-email-layer">
                                            <button role="btn"
                                                className="btn btn-outline-primary"
                                                onClick={(e) => {
                                                    addNewEmailLayer();
                                                }}>
                                                <BsPlusCircle size={21} />
                                                <span>{t("POPUPS.ADD_USER_TO_COMPANY.ADD_NEW_EMAIL")}</span>
                                            </button>
                                        </div>
                                    </Form.Group>
                                    {/* <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="mb-4" controlId="emailValidation">
                                        <Form.Label htmlFor="title">Company Name:</Form.Label>
                                        <Form.Control onInput={onInput} id="title" name="title" type="text" placeholder="Company Name" defaultValue={props?.components?.newCompanyData?.companyData?.title} required />
                                        <Form.Control.Feedback type="invalid">Please provide a valid company name</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="emailValidation">
                                        <Form.Label htmlFor="website">Company Website:</Form.Label>
                                        <Form.Control onInput={onInput} id="website" name="website" type="text" placeholder="Company Website" defaultValue={props?.components?.newCompanyData?.companyData?.website} required />
                                        <Form.Control.Feedback type="invalid">Please provide a valid company website</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="emailValidation">
                                        <Form.Label htmlFor="description">Company Description:</Form.Label>
                                        <Form.Control onInput={onInput} id="description" name="description" type="text" as="textarea" rows={10} placeholder="Company Description" defaultValue={props?.components?.newCompanyData?.companyData?.description} required />
                                        <Form.Control.Feedback type="invalid">Please provide a valid company description</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-4" style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                        controlId="companyNameValidation">
                                        <div>

                                        </div>
                                    </Form.Group>
                                </Form> */}
                                </div>
                            </div>
                            <div className="col-lg-4 d-lg-block d-md-none">
                                <div className="sideBarContent">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScreenModal>
            {showToast && <ShowToast show={showToast} onClose={() => {
                setShowToast(false)
            }} />}
        </React.Fragment>
    )
}


export default connect(mapStateToProps, mapActionsTopProps)(AddUserToCompany);