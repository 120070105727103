/* USERS */

/* Login User */

export const LOGIN: string = 'USERS/LOGIN_USER';
export const LOGIN_SUCCESSFUL: string = 'USERS/LOGIN_USER_SUCCESSFUL';
export const LOGIN_FAILED = 'USERS/LOGIN_USER_FAILED';

/* Register a user */
export const REGISTER: string = 'USERS/REGISTER_USER';
export const REGISTER_SUCCESSFUL: string = 'USERS/REGISTER_USER_SUCCESSFUL';
export const REGISTER_FAILED: string = 'USERS/REGISTER_USER_FAILED';

/* Get Currently Logged User */
export const GET_CURRENT_USER = 'USERS/GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESSFUL = 'USERS/GET_CURRENT_USER_SUCCESSFUL';
export const GET_CURRENT_USER_FAILED = 'USERS/GET_CURRENT_USER_FAILED';

/* Updates the user's information */
export const UPDATE_USER = 'USERS/UPDATE_USER';
export const UPDATE_USER_SUCCESSFUL = 'USERS/UPDATE_USER_SUCCESSFUL';
export const UPDATE_USER_FAILED = 'USERS/UPDATE_USER_FAILED';

/* Deletes the user's information */
export const DELETE_USER = 'USERS/DELETE_USER';
export const DELETE_USER_SUCCESSFUL = 'USERS/DELETE_USER_SUCCESSFUL';
export const DELETE_USER_FAILED = 'USERS/DELETE_USER_FAILED';

/* Lock */
export const LOCK = 'USERS/LOCK';

/* Logout User */
export const LOGOUT: string = 'USERS/LOGOUT_USER';

/* Confirm mail */
export const CONFIRM_MAIL: string = 'USERS/CONFIRM_MAIL';
export const CONFIRM_MAIL_SUCCESSFUL: string = 'USERS/CONFIRM_MAIL_SUCCESSFUL';
export const CONFIRM_MAIL_FAILED: string = 'USERS/CONFIRM_MAIL_FAILED';

export const FORGET_PASSWORD: string = 'USERS/FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESSFUL: string = 'USERS/FORGET_PASSWORD_SUCCESSFUL';
export const FORGET_PASSWORD_FAILED: string = 'USERS/FORGET_PASSWORD_FAILED';

export const CHECK_RESET_PASSWORD: string = 'USERS/CHECK_RESET_PASSWORD';
export const CHECK_RESET_PASSWORD_SUCCESSFUL: string = 'USERS/CHECK_RESET_PASSWORD_SUCCESSFUL';
export const CHECK_RESET_PASSWORD_FAILED: string = 'USERS/CHECK_RESET_PASSWORD_FAILED';

export const RESET_PASSWORD: string = 'USERS/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESSFUL: string = 'USERS/RESET_PASSWORD_SUCCESSFUL';
export const RESET_PASSWORD_FAILED: string = 'USERS/RESET_PASSWORD_FAILED';


export const GET_COMPANY_USER_INVITE: string = 'USERS/GET_COMPANY_USER_INVITE';
export const GET_COMPANY_USER_INVITE_SUCCESSFUL: string = 'USERS/GET_COMPANY_USER_INVITE_SUCCESSFUL';
export const GET_COMPANY_USER_INVITE_FAILED: string = 'USERS/GET_COMPANY_USER_INVITE_FAILED';

export const ACCEPT_COMPANY_USER_INVITE: string = 'USERS/ACCEPT_COMPANY_USER_INVITE';
export const ACCEPT_COMPANY_USER_INVITE_SUCCESSFUL: string = 'USERS/ACCEPT_COMPANY_USER_INVITE_SUCCESSFUL';
export const ACCEPT_COMPANY_USER_INVITE_FAILED: string = 'USERS/ACCEPT_COMPANY_USER_INVITE_FAILED';

export const DECLINE_COMPANY_USER_INVITE: string = 'USERS/DECLINE_COMPANY_USER_INVITE';
export const DECLINE_COMPANY_USER_INVITE_SUCCESSFUL: string = 'USERS/DECLINE_COMPANY_USER_INVITE_SUCCESSFUL';
export const DECLINE_COMPANY_USER_INVITE_FAILED: string = 'USERS/DECLINE_COMPANY_USER_INVITE_FAILED';

export const CHANGE_USERS_LOCALE: string = 'USERS/CHANGE_USERS_LOCALE';
export const CHANGE_USERS_LOCALE_SUCCESSFUL: string = 'USERS/CHANGE_USERS_LOCALE_SUCCESSFUL';
export const CHANGE_USERS_LOCALE_FAILED: string = 'USERS/CHANGE_USERS_LOCALE_FAILED';

export const GET_USERS_INTEGRATIONS: string = 'USERS-INTEGRATIONS/GET_USERS_INTEGRATIONS';
export const GET_USERS_INTEGRATIONS_SUCCESSFUL: string = 'USERS-INTEGRATIONS/GET_USERS_INTEGRATIONS_SUCCESSFUL';
export const GET_USERS_INTEGRATIONS_FAILED: string = 'USERS-INTEGRATIONS/GET_USERS_INTEGRATIONS_FAILED';

export const CREATE_DRIVE_INTEGRATION_FOR_USERS: string = 'USERS-INTEGRATIONS/CREATE_DRIVE_INTEGRATION_FOR_USERS';
export const CREATE_DRIVE_INTEGRATION_FOR_USERS_SUCCESSFUL: string = 'USERS-INTEGRATIONS/CREATE_DRIVE_INTEGRATION_FOR_USERS_SUCCESSFUL';
export const CREATE_DRIVE_INTEGRATION_FOR_USERS_FAILED: string = 'USERS-INTEGRATIONS/CREATE_DRIVE_INTEGRATION_FOR_USERS_FAILED';


export const CREATE_ONEDRIVE_INTEGRATION_FOR_COMPANY: string = 'USERS-INTEGRATIONS/CREATE_ONEDRIVE_INTEGRATION_FOR_COMPANY';
export const CREATE_ONEDRIVE_INTEGRATION_FOR_COMPANY_SUCCESSFUL: string = 'USERS-INTEGRATIONS/CREATE_ONEDRIVE_INTEGRATION_FOR_COMPANY_SUCCESSFUL';
export const CREATE_ONEDRIVE_INTEGRATION_FOR_COMPANY_FAILED: string = 'USERS-INTEGRATIONS/CREATE_ONEDRIVE_INTEGRATION_FOR_COMPANY_FAILED';

export const CREATE_SHAREPOINT_INTEGRATION_FOR_COMPANY: string = 'USERS-INTEGRATIONS/CREATE_SHAREPOINT_INTEGRATION_FOR_COMPANY';
export const CREATE_SHAREPOINT_INTEGRATION_FOR_COMPANY_SUCCESSFUL: string = 'USERS-INTEGRATIONS/CREATE_SHAREPOINT_INTEGRATION_FOR_COMPANY_SUCCESSFUL';
export const CREATE_SHAREPOINT_INTEGRATION_FOR_COMPANY_FAILED: string = 'USERS-INTEGRATIONS/CREATE_SHAREPOINT_INTEGRATION_FOR_COMPANY_FAILED';

export const DELETE_INTEGRATION_FOR_USERS: string = 'USERS-INTEGRATIONS/DELETE_INTEGRATION_FOR_USERS';
export const DELETE_INTEGRATION_FOR_USERS_SUCCESSFUL: string = 'USERS-INTEGRATIONS/DELETE_INTEGRATION_FOR_USERS_SUCCESSFUL';
export const DELETE_INTEGRATION_FOR_USERS_FAILED: string = 'USERS-INTEGRATIONS/DELETE_INTEGRATION_FOR_USERS_FAILED';


/* COMPONENTS */
/* Error Modal */
export const TOGGLE_GENERIC_ERROR_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_ERROR_MODAL';
export const TOGGLE_GENERIC_SUCCESS_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_SUCCESS_MODAL';

/* Custom Fields */
export const TOGGLE_GENERIC_ADD_CUSTOM_FIELD_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_ADD_CUSTOM_FIELD_MODAL';

/* Add new company Modal */
export const TOGGLE_NEW_COMPANY_MODAL: string = 'COMPONENTS/TOGGLE_NEW_COMPANY_MODAL'


export const TOGGLE_FILES_VERSION_MODAL: string = 'COMPONENTS/TOGGLE_FILES_VERSION_MODAL';

export const TOGGLE_FILES_PDF_VIEWER: string = 'COMPONENTS/TOGGLE_FILES_PDF_VIEWER';
export const TOGGLE_APPROVAL_TEMPLATE_MODAL: string = 'COMPONENTS/TOGGLE_APPROVAL_TEMPLATE_MODAL';

/* New Company Modal Actions */
export const SET_NEW_COMPANY_DATA: string = 'COMPONENTS/SET_NEW_COMPANY_DATA';
export const DELETE_NEW_COMPANY_DATA: string = 'COMPONENTS/DELETE_NEW_COMPANY_DATA';


/* COMPANIES */
export const CREATE_COMPANY: string = 'COMPANIES/CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESSFUL: string = 'COMPANIES/CREATE_COMPANY_SUCCESSFUL';
export const CREATE_COMPANY_FAILED: string = 'COMPANIES/CREATE_COMPANY_FAILED';

export const UPDATE_COMPANY: string = 'COMPANIES/UPDATE_COMPANY';
export const UPDATE_COMPANY_SUCCESSFUL: string = 'COMPANIES/UPDATE_COMPANY_SUCCESSFUL';
export const UPDATE_COMPANY_FAILED: string = 'COMPANIES/UPDATE_COMPANY_FAILED';

export const DELETE_COMPANY: string = 'COMPANIES/DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESSFUL: string = 'COMPANIES/DELETE_COMPANY_SUCCESSFUL';
export const DELETE_COMPANY_FAILED: string = 'COMPANIES/DELETE_COMPANY_FAILED';

export const GET_CURRENT_COMPANY: string = 'COMPANIES/GET_CURRENT_COMPANY';
export const GET_CURRENT_COMPANY_SUCCESSFUL: string = 'COMPANIES/GET_CURRENT_COMPANY_SUCCESSFUL';
export const GET_CURRENT_COMPANY_FAILED: string = 'COMPANIES/GET_CURRENT_COMPANY_FAILED';


export const GET_COMPANIES: string = 'COMPANIES/GET_COMPANIES';
export const GET_COMPANIES_SUCCESSFUL: string = 'COMPANIES/GET_COMPANIES_SUCCESSFUL';
export const GET_COMPANIES_FAILED: string = 'COMPANIES/GET_COMPANIES_FAILED';


export const SET_CURRENT_COMPANY: string = 'COMPANIES/SET_CURRENT_COMPANY';
export const SET_CURRENT_COMPANY_SUCCESSFUL: string = 'COMPANIES/SET_CURRENT_COMPANY_SUCCESSFUL';
export const SET_CURRENT_COMPANY_FAILED: string = 'COMPANIES/SET_CURRENT_COMPANY_FAILED';


export const CREATE_CUSTOM_CDN_ENDPOINT: string = 'COMPANIES/CREATE_CUSTOM_CDN_ENDPOINT';
export const CREATE_CUSTOM_CDN_ENDPOINT_SUCCESSFUL: string = 'COMPANIES/CREATE_CUSTOM_CDN_ENDPOINT_SUCCESSFUL';
export const CREATE_CUSTOM_CDN_ENDPOINT_FAILED: string = 'COMPANIES/CREATE_CUSTOM_CDN_ENDPOINT_FAILED';


/* Users in the company */
export const GET_COMPANY_USERS: string = 'COMPANIES/GET_COMPANY_USERS';
export const GET_COMPANY_USERS_SUCCESSFUL: string = 'COMPANIES/GET_COMPANY_USERS_SUCCESSFUL';
export const GET_COMPANY_USERS_FAILED: string = 'COMPANIES/GET_COMPANY_USERS_FAILED';

/* Update Company User */
export const UPDATE_COMPANY_USER: string = 'COMPANIES/UPDATE_COMPANY_USER';
export const UPDATE_COMPANY_USER_SUCCESSFUL: string = 'COMPANIES/UPDATE_COMPANY_USER_SUCCESSFUL';
export const UPDATE_COMPANY_USER_FAILED: string = 'COMPANIES/UPDATE_COMPANY_USER_FAILED';

/* Delete user from company types */
export const DELETE_USER_FROM_COMPANY: string = 'COMPANIES/DELETE_USER_FROM_COMPANY';
export const DELETE_USER_FROM_COMPANY_SUCCESSFUL: string = 'COMPANIES/DELETE_USER_FROM_COMPANY_SUCCESSFUL';
export const DELETE_USER_FROM_COMPANY_FAILED: string = 'COMPANIES/DELETE_USER_FROM_COMPANY_FAILED';

/* UsersInvites in the company */
export const GET_COMPANY_USERS_INVITES: string = 'COMPANIES/GET_COMPANY_USERS_INVITES';
export const GET_COMPANY_USERS_INVITES_SUCCESSFUL: string = 'COMPANIES/GET_COMPANY_USERS_INVITES_SUCCESSFUL';
export const GET_COMPANY_USERS_INVITES_FAILED: string = 'COMPANIES/GET_COMPANY_USERS_INVITES_FAILED';

/* Update Users Invites */
export const UPDATE_COMPANY_USERS_INVITES: string = 'COMPANIES/UPDATE_COMPANY_USERS_INVITES';
export const UPDATE_COMPANY_USERS_INVITES_SUCCESSFUL: string = 'COMPANIES/UPDATE_COMPANY_USERS_INVITES_SUCCESSFUL';
export const UPDATE_COMPANY_USERS_INVITES_FAILED: string = 'COMPANIES/UPDATE_COMPANY_USERS_INVITES_FAILED';


/* Delete Users Invites */
export const DELETE_COMPANY_USERS_INVITES: string = 'COMPANIES/DELETE_COMPANY_USERS_INVITES';
export const DELETE_COMPANY_USERS_INVITES_SUCCESSFUL: string = 'COMPANIES/DELETE_COMPANY_USERS_INVITES_SUCCESSFUL';
export const DELETE_COMPANY_USERS_INVITES_FAILED: string = 'COMPANIES/DELETE_COMPANY_USERS_INVITES_FAILED';


/* Add new user to company modal */
export const TOGGLE_ADD_USER_TO_COMPANY_MODAL: string = 'COMPONENTS/TOGGLE_ADD_USER_TO_COMPANY_MODAL'

/* Add user to company types */
export const CREATE_ADD_USER_TO_COMPANY: string = 'COMPANIES/CREATE_ADD_USER_TO_COMPANY';
export const CREATE_ADD_USER_TO_COMPANY_SUCCESSFUL: string = 'COMPANIES/CREATE_ADD_USER_TO_COMPANY_SUCCESSFUL';
export const CREATE_ADD_USER_TO_COMPANY_FAILED: string = 'COMPANIES/CREATE_ADD_USER_TO_COMPANY_FAILED';


export const GET_COMPANY_INTEGRATIONS: string = 'COMPANIES-INTEGRATIONS/GET_COMPANY_INTEGRATIONS';
export const GET_COMPANY_INTEGRATIONS_SUCCESSFUL: string = 'COMPANIES-INTEGRATIONS/GET_COMPANY_INTEGRATIONS_SUCCESSFUL';
export const GET_COMPANY_INTEGRATIONS_FAILED: string = 'COMPANIES-INTEGRATIONS/GET_COMPANY_INTEGRATIONS_FAILED';

export const CREATE_DRIVE_INTEGRATION_FOR_COMPANY: string = 'COMPANIES-INTEGRATIONS/CREATE_DRIVE_INTEGRATION_FOR_COMPANY';
export const CREATE_DRIVE_INTEGRATION_FOR_COMPANY_SUCCESSFUL: string = 'COMPANIES-INTEGRATIONS/CREATE_DRIVE_INTEGRATION_FOR_COMPANY_SUCCESSFUL';
export const CREATE_DRIVE_INTEGRATION_FOR_COMPANY_FAILED: string = 'COMPANIES-INTEGRATIONS/CREATE_DRIVE_INTEGRATION_FOR_COMPANY_FAILED';


export const DELETE_INTEGRATION_FOR_COMPANY: string = 'COMPANIES-INTEGRATIONS/DELETE_INTEGRATION_FOR_COMPANY';
export const DELETE_INTEGRATION_FOR_COMPANY_SUCCESSFUL: string = 'COMPANIES-INTEGRATIONS/DELETE_INTEGRATION_FOR_COMPANY_SUCCESSFUL';
export const DELETE_INTEGRATION_FOR_COMPANY_FAILED: string = 'COMPANIES-INTEGRATIONS/DELETE_INTEGRATION_FOR_COMPANY_FAILED';

/* Company Departments */
export const GET_COMPANY_DEPARTMENTS: string = 'COMPANIES/GET_COMPANY_DEPARTMENTS';
export const GET_COMPANY_DEPARTMENTS_SUCCESSFUL: string = 'COMPANIES/GET_COMPANY_DEPARTMENTS_SUCCESSFUL';
export const GET_COMPANY_DEPARTMENTS_FAILED: string = 'COMPANIES/GET_COMPANY_DEPARTMENTS_FAILED';


export const TOGGLE_COMPANY_SWITCH_MODAL: string = 'COMPONENTS/TOGGLE_COMPANY_SWITCH_MODAL';

export const TOGGLE_UPLOAD_FILES_MODAL: string = 'COMPONENTS/TOGGLE_UPLOAD_FILES_MODAL';

/* Departments */
export const CREATE_DEPARTMENTS: string = 'DEPARTMENTS/CREATE_DEPARTMENTS';
export const CREATE_DEPARTMENTS_SUCCESSFUL: string = 'DEPARTMENTS/CREATE_DEPARTMENTS_SUCCESSFUL';
export const CREATE_DEPARTMENTS_FAILED: string = 'DEPARTMENTS/CREATE_DEPARTMENTS_FAILED';

export const UPDATE_DEPARTMENTS: string = 'DEPARTMENTS/UPDATE_DEPARTMENTS';
export const UPDATE_DEPARTMENTS_SUCCESSFUL: string = 'DEPARTMENTS/UPDATE_DEPARTMENTS_SUCCESSFUL';
export const UPDATE_DEPARTMENTS_FAILED: string = 'DEPARTMENTS/UPDATE_DEPARTMENTS_FAILED';


export const GET_DEPARTMENTS: string = 'DEPARTMENTS/GET_DEPARTMENTS';
export const GET_DEPARTMENTS_SUCCESSFUL: string = 'DEPARTMENTS/GET_DEPARTMENTS_SUCCESSFUL';
export const GET_DEPARTMENTS_FAILED: string = 'DEPARTMENTS/GET_DEPARTMENTS_FAILED';


export const DELETE_DEPARTMENTS: string = 'DEPARTMENTS/DELETE_DEPARTMENTS';
export const DELETE_DEPARTMENTS_SUCCESSFUL: string = 'DEPARTMENTS/DELETE_DEPARTMENTS_SUCCESSFUL';
export const DELETE_DEPARTMENTS_FAILED: string = 'DEPARTMENTS/DELETE_DEPARTMENTS_FAILED';
/* Roles */

export const GET_COMPANY_ROLES: string = 'ROLES/GET_ROLES';
export const GET_COMPANY_ROLES_SUCCESSFUL: string = 'ROLES/GET_COMPANY_ROLES_SUCCESSFUL';
export const GET_COMPANY_ROLES_FAILED: string = 'ROLES/GET_COMPANY_ROLES_FAILED';

export const GET_ROLES: string = 'ROLES/GET_ROLES';
export const GET_ROLES_SUCCESSFUL: string = 'ROLES/GET_ROLES_SUCCESSFUL';
export const GET_ROLES_FAILED: string = 'ROLES/GET_ROLES_FAILED';

export const GET_NOMINATIONS: string = 'ROLES/GET_NOMINATIONS';
export const GET_NOMINATIONS_SUCCESSFUL: string = 'ROLES/GET_NOMINATIONS_SUCCESSFUL';
export const GET_NOMINATIONS_FAILED: string = 'ROLES/GET_NOMINATIONS_FAILED';

export const CREATE_ROLES: string = 'ROLES/CREATE_ROLES';
export const CREATE_ROLES_SUCCESSFUL: string = 'ROLES/CREATE_ROLES_SUCCESSFUL';
export const CREATE_ROLES_FAILED: string = 'ROLES/CREATE_ROLES_FAILED';

export const UPDATE_ROLES: string = 'ROLES/UPDATE_ROLES';
export const UPDATE_ROLES_SUCCESSFUL: string = 'ROLES/UPDATE_ROLES_SUCCESSFUL';
export const UPDATE_ROLES_FAILED: string = 'ROLES/UPDATE_ROLES_FAILED';

export const DELETE_ROLES: string = 'ROLES/DELETE_ROLES';
export const DELETE_ROLES_SUCCESSFUL: string = 'ROLES/DELETE_ROLES_SUCCESSFUL';
export const DELETE_ROLES_FAILED: string = 'ROLES/DELETE_ROLES_FAILED';

/*CONTRACT*/

/*Pipelines*/
export const GET_SINGLE_PIPELINE: string = 'CONTRACT/GET_SINGLE_PIPELINE'
export const GET_SINGLE_STATUSES: string = 'CONTRACT/GET_SINGLE_STATUSES'
export const GET_CONTRACT_TYPES_BY_COMPANY: string = 'CONTRACT/GET_CONTRACT_TYPES_BY_COMPANY'

export const SET_CONTRACT_ID_TO_OVERVIEW_MODAL: string = 'CONTRACT/SET_CONTRACT_ID_TO_OVERVIEW_MODAL';

export const RESET_CONTRACT_OVERVIEW: string = 'CONTRACT/RESET_CONTRACT_OVERVIEW';


export const GET_CONTRACTS_BY_STATUS: string = 'CONTRACT/GET_CONTRACTS_BY_STATUS'
export const GET_CONTRACTS_BY_STATUS_LOAD_MORE: string = 'CONTRACT/GET_CONTRACTS_BY_STATUS_LOAD_MORE'
export const GET_CONTRACTS_BY_STATUS_SUCCESSFUL: string = 'CONTRACT/GET_CONTRACTS_BY_STATUS_SUCCESSFUL'
export const GET_CONTRACTS_BY_STATUS_FAILED: string = 'CONTRACT/GET_CONTRACTS_BY_STATUS_FAILED'


export const CREATE_NEW_CONTRACT: string = 'CONTRACT/CREATE_CONTRACT_NEW_CONTRACT'
export const CREATE_NEW_CONTRACT_SUCCESSFUL: string = 'CONTRACT/CREATE_CONTRACT_NEW_CONTRACT_SUCCESS'
export const CREATE_NEW_CONTRACT_FAILED: string = 'CONTRACT/CREATE_CONTRACT_NEW_CONTRACT_FAILED'


export const KANBAN_CONTRACT_STATUS_CHANGE: string = 'CONTRACT/KANBAN_CONTRACT_STATUS_CHANGE'
export const KANBAN_CONTRACT_STATUS_CHANGE_SUCCESSFUL: string = 'CONTRACT/KANBAN_CONTRACT_STATUS_CHANGE_SUCCESSFUL'
export const KANBAN_CONTRACT_STATUS_CHANGE_FAILED: string = 'CONTRACT/KANBAN_CONTRACT_STATUS_CHANGE_FAILED'


export const GET_COMPANY_PIPELINES: string = 'CONTRACT/GET_COMPANY_PIPELINES'
export const GET_COMPANY_PIPELINES_SUCCESSFUL: string = 'CONTRACT/GET_COMPANY_PIPELINES_SUCCESSFUL'
export const GET_COMPANY_PIPELINES_FAILED: string = 'CONTRACT/GET_COMPANY_PIPELINES_FAILED'


export const GET_COMPANY_STATUSES: string = 'CONTRACT/GET_COMPANY_STATUSES'
export const GET_COMPANY_STATUSES_SUCCESSFUL: string = 'CONTRACT/GET_COMPANY_STATUSES_SUCCESSFUL'
export const GET_COMPANY_STATUSES_FAILED: string = 'CONTRACT/GET_COMPANY_STATUSES_FAILED'


export const NEW_PIPELINE_ADD_STATUS: string = 'CONTRACT/NEW_PIPELINE_ADD_STATUS'
export const NEW_PIPELINE_ORDER_STATUS: string = 'CONTRACT/NEW_PIPELINE_ORDER_STATUS'
export const NEW_PIPELINE_REMOVE_STATUS: string = 'CONTRACT/NEW_PIPELINE_REMOVE_STATUS'
export const SET_NEW_PIPELINE_DATA: string = 'CONTRACT/SET_NEW_PIPELINE_DATA'


export const CREATE_NEW_PIPELINE: string = 'CONTRACT/CREATE_NEW_PIPELINE'
export const CREATE_NEW_PIPELINE_SUCCESSFUL: string = 'CONTRACT/CREATE_NEW_PIPELINE_SUCCESSFUL'
export const CREATE_NEW_PIPELINE_FAILED: string = 'CONTRACT/CREATE_NEW_PIPELINE_FAILED'

export const CREATE_FILES: string = 'CONTRACT/CREATE_FILES'
export const CREATE_FILES_SUCCESSFUL: string = 'CONTRACT/CREATE_FILES_SUCCESSFUL'
export const CREATE_FILES_FAILED: string = 'CONTRACT/CREATE_FILES_FAILED'

export const GET_FILES = 'FILES/GET_FILES';
export const GET_FILES_SUCCESSFUL = 'FILES/GET_FILES_SUCCESSFUL';
export const GET_FILES_FAILED = 'FILES/GET_FILES_FAILED';

export const UPDATE_FILES = 'FILES/UPDATE_FILES';
export const UPDATE_FILES_SUCCESSFUL = 'FILES/UPDATE_FILES_SUCCESSFUL';
export const UPDATE_FILES_FAILED = 'FILES/UPDATE_FILES_FAILED';

export const DELETE_FILES = 'FILES/DELETE_FILES';
export const DELETE_FILES_SUCCESSFUL = 'FILES/DELETE_FILES_SUCCESSFUL';
export const DELETE_FILES_FAILED = 'FILES/DELETE_FILES_FAILED';

export const GET_FILE_VERSIONS: string = "FILES/GET_FILE_VERSIONS";
export const GET_FILE_VERSIONS_SUCCESSFUL: string = "FILES/GET_FILE_VERSIONS_SUCCESSFUL";
export const GET_FILE_VERSIONS_FAILED: string = "FILES/GET_FILE_VERSIONS_FAILED";

export const REMOVE_FILE_FROM_VERSIONS: string = "FILES/REMOVE_FILE_FROM_VERSIONS";
export const REMOVE_FILE_FROM_VERSIONS_SUCCESSFUL: string = "FILES/REMOVE_FILE_FROM_VERSIONS_SUCCESSFUL";
export const REMOVE_FILE_FROM_VERSIONS_FAILED: string = "FILES/REMOVE_FILE_FROM_VERSIONS_FAILED";

export const REMOVE_FILE_VERSION: string = "FILES/REMOVE_FILE_VERSION";

export const SET_EDIT_PIPELINE_DATA: string = 'CONTRACT/SET_EDIT_PIPELINE_DATA'
export const SET_EDIT_PIPELINE_TITLE: string = 'CONTRACT/SET_EDIT_PIPELINE_TITLE'
export const SET_EDIT_PIPELINE_REORDER: string = 'CONTRACT/SET_EDIT_PIPELINE_REORDER'
export const SET_EDIT_PIPELINE_ADD_NEW_STATUS: string = 'CONTRACT/SET_EDIT_PIPELINE_ADD_NEW_STATUS'
export const SET_EDIT_PIPELINE_REMOVE_STATUS: string = 'CONTRACT/SET_EDIT_PIPELINE_REMOVE_STATUS'

export const EDIT_PIPELINE: string = 'CONTRACT/EDIT_PIPELINE'
export const EDIT_PIPELINE_SUCCESSFUL: string = 'CONTRACT/EDIT_PIPELINE_SUCCESSFUL'
export const EDIT_PIPELINE_FAILED: string = 'CONTRACT/EDIT_PIPELINE_FAILED'


export const DELETE_PIPELINE: string = 'CONTRACT/DELETE_PIPELINE'
export const DELETE_PIPELINE_SUCCESSFUL: string = 'CONTRACT/DELETE_PIPELINE_SUCCESSFUL'
export const DELETE_PIPELINE_FAILED: string = 'CONTRACT/DELETE_PIPELINE_FAILED'


export const CONTRACT_MIGRATION: string = 'CONTRACT/CONTRACT_MIGRATION'
export const CONTRACT_MIGRATION_SUCCESSFUL: string = 'CONTRACT/CONTRACT_MIGRATION_SUCCESSFUL'
export const CONTRACT_MIGRATION_FAILED: string = 'CONTRACT/CONTRACT_MIGRATION_FAILED'


export const SET_NEW_STATUS_DATA: string = 'CONTRACT/CREATE_NEW_STATUS'
export const SET_EDITED_STATUS_DATA: string = 'CONTRACT/SET_EDITED_STATUS_DATA'
export const CREATE_NEW_STATUS: string = 'CONTRACT/CREATE_NEW_STATUS'
export const CREATE_NEW_STATUS_SUCCESSFUL: string = 'CONTRACT/CREATE_NEW_STATUS_SUCCESSFUL'
export const CREATE_NEW_STATUS_FAILED: string = 'CONTRACT/CREATE_NEW_STATUS_FAILED'

export const EDIT_STATUS: string = 'CONTRACT/EDIT_STATUS'
export const EDIT_STATUS_SUCCESSFUL: string = 'CONTRACT/EDIT_STATUS_SUCCESSFUL'
export const EDIT_STATUS_FAILED: string = 'CONTRACT/EDIT_STATUS_FAILED'

export const DELETE_STATUS: string = 'CONTRACT/DELETE_STATUS'
export const DELETE_STATUS_SUCCESSFUL: string = 'CONTRACT/DELETE_STATUS_SUCCESSFUL'
export const DELETE_STATUS_FAILED: string = 'CONTRACT/DELETE_STATUS_FAILED'



// CUSTOM FIELDS OPERATIONS

export const CREATE_NEW_CUSTOM_FIELD: string = 'CONTRACT/CREATE_NEW_CUSTOM_FIELD'
export const CREATE_NEW_CUSTOM_FIELD_SUCCESSFUL: string = 'CONTRACT/CREATE_NEW_CUSTOM_FIELD_SUCCESSFUL'
export const CREATE_NEW_CUSTOM_FIELD_FAILED: string = 'CONTRACT/CREATE_NEW_CUSTOM_FIELD_FAILED'


export const EDIT_CUSTOM_FIELD: string = 'CONTRACT/EDIT_CUSTOM_FIELD'
export const EDIT_CUSTOM_FIELD_SUCCESSFUL: string = 'CONTRACT/EDIT_CUSTOM_FIELD_SUCCESSFUL'
export const EDIT_CUSTOM_FIELD_FAILED: string = 'CONTRACT/EDIT_CUSTOM_FIELD_FAILED'

export const LIST_CUSTOM_FIELD: string = 'CONTRACT/LIST_CUSTOM_FIELD'
export const LIST_CUSTOM_FIELD_SUCCESSFUL: string = 'CONTRACT/LIST_CUSTOM_FIELD_SUCCESSFUL'
export const LIST_CUSTOM_FIELD_FAILED: string = 'CONTRACT/LIST_CUSTOM_FIELD_FAILED'


export const DELETE_CUSTOM_FIELD: string = 'CONTRACT/DELETE_CUSTOM_FIELD'
export const DELETE_CUSTOM_FIELD_SUCCESSFUL: string = 'CONTRACT/DELETE_CUSTOM_FIELD_SUCCESSFUL'
export const DELETE_CUSTOM_FIELD_FAILED: string = 'CONTRACT/DELETE_CUSTOM_FIELD_FAILED'

// CONTRACT TYPES
export const NEW_CONTRACT_TYPE_ADD_CUSTOM_FIELD: string = 'CONTRACT/NEW_CONTRACT_TYPE_ADD_CUSTOM_FIELD'
export const NEW_CONTRACT_TYPE_SET_DATE: string = 'CONTRACT/NEW_CONTRACT_TYPE_SET_DATE'
export const NEW_CONTRACT_TYPE_REMOVE_CUSTOM_FIELD: string = 'CONTRACT/NEW_CONTRACT_TYPE_REMOVE_CUSTOM_FIELD'
export const NEW_CONTRACT_TYPE_INPUT_CUSTOM_FIELD: string = 'CONTRACT/NEW_CONTRACT_TYPE_INPUT_CUSTOM_FIELD'
export const NEW_CONTRACT_TYPE_RESET: string = 'CONTRACT/NEW_CONTRACT_TYPE_RESET'

export const CREATE_CONTRACT_TYPE: string = 'CONTRACT/CREATE_CONTRACT_TYPE'
export const CREATE_CONTRACT_TYPE_SUCCESSFUL: string = 'CONTRACT/CREATE_CONTRACT_TYPE_SUCCESSFUL'
export const CREATE_CONTRACT_TYPE_FAILED: string = 'CONTRACT/CREATE_CONTRACT_TYPE_FAILED'

export const DELETE_CONTRACT_TYPE: string = 'CONTRACT/DELETE_CONTRACT_TYPE'
export const DELETE_CONTRACT_TYPE_SUCCESSFUL: string = 'CONTRACT/DELETE_CONTRACT_TYPE_SUCCESSFUL'
export const DELETE_CONTRACT_TYPE_FAILED: string = 'CONTRACT/DELETE_CONTRACT_TYPE_FAILED'

export const DELETE_CONTRACT_TYPE_CUSTOM_FIELD: string = 'CONTRACT/DELETE_CONTRACT_TYPE_CUSTOM_FIELD'
export const DELETE_CONTRACT_TYPE_CUSTOM_FIELD_SUCCESSFUL: string = 'CONTRACT/DELETE_CONTRACT_TYPE_CUSTOM_FIELD_SUCCESSFUL'
export const DELETE_CONTRACT_TYPE_CUSTOM_FIELD_FAILED: string = 'CONTRACT/DELETE_CONTRACT_TYPE_CUSTOM_FIELD_FAILED'

export const ADD_CONTRACT_TYPE_CUSTOM_FIELD: string = 'CONTRACT/ADD_CONTRACT_TYPE_CUSTOM_FIELD'
export const ADD_CONTRACT_TYPE_CUSTOM_FIELD_SUCCESSFUL: string = 'ADD_CONTRACT_TYPE_CUSTOM_FIELD_SUCCESSFUL'
export const ADD_CONTRACT_TYPE_CUSTOM_FIELD_FAILED: string = 'CONTRACT/ADD_CONTRACT_TYPE_CUSTOM_FIELD_FAILED'

export const SAVE_CONTRACT_TYPE_DATA: string = 'CONTRACT/SAVE_CONTRACT_TYPE_DATA'
export const SAVE_CONTRACT_TYPE_DATA_SUCCESSFUL: string = 'CONTRACT/SAVE_CONTRACT_TYPE_DATA_SUCCESSFUL'
export const SAVE_CONTRACT_TYPE_DATA_FAILED: string = 'CONTRACT/SAVE_CONTRACT_TYPE_DATA_FAILED'

export const SET_EDIT_CONTRACT_TYPE_DATA: string = 'CONTRACT/SET_EDIT_CONTRACT_TYPE_DATA'
export const SET_EDIT_CONTRACT_TYPE_REORDER: string = 'CONTRACT/SET_EDIT_CONTRACT_TYPE_REORDER'


export const GET_CONTRACT_CONTRACT_COMPANIES: string = 'CONTRACT/GET_CONTRACT_CONTRACT_COMPANIES'
export const GET_CONTRACT_CONTRACT_COMPANIES_SUCCESSFUL: string = 'CONTRACT/GET_CONTRACT_CONTRACT_COMPANIES_SUCCESSFUL'
export const GET_CONTRACT_CONTRACT_COMPANIES_FAILED: string = 'CONTRACT/GET_CONTRACT_CONTRACT_COMPANIES_FAILED'


export const GET_CONTRACT_FILTERS: string = 'CONTRACT/GET_CONTRACT_FILTERS'
export const GET_CONTRACT_FILTERS_SUCCESSFUL: string = 'CONTRACT/GET_CONTRACT_FILTERS_SUCCESSFUL'
export const GET_CONTRACT_FILTERS_FAILED: string = 'CONTRACT/GET_CONTRACT_FILTERS_FAILED'


export const GET_CONTRACT_CONTRACT_CONTACTS: string = 'CONTRACT/GET_CONTRACT_CONTRACT_CONTACTS'
export const GET_CONTRACT_CONTRACT_CONTACTS_SUCCESSFUL: string = 'GET_CONTRACT_CONTRACT_CONTACTS_SUCCESSFUL'
export const GET_CONTRACT_CONTRACT_CONTACTS_FAILED: string = 'CONTRACT/GET_CONTRACT_CONTRACT_CONTACTS_FAILED'

export const GET_TABLE_CONTRACT_TYPES: string = 'CONTRACT/GET_TABLE_CONTRACT_TYPES'
export const GET_TABLE_CONTRACT_TYPES_SUCCESSFUL: string = 'GET_TABLE_CONTRACT_TYPES_SUCCESSFUL'
export const GET_TABLE_CONTRACT_TYPES_FAILED: string = 'CONTRACT/GET_TABLE_CONTRACT_TYPES_FAILED'


export const GET_TABLE_CONTRACT_CUSTOM_FIELD: string = 'CONTRACT/GET_TABLE_CONTRACT_CUSTOM_FIELD'
export const GET_TABLE_CONTRACT_CUSTOM_FIELD_SUCCESSFUL: string = 'GET_TABLE_CONTRACT_CUSTOM_FIELD_SUCCESSFUL'
export const GET_TABLE_CONTRACT_CUSTOM_FIELD_FAILED: string = 'CONTRACT/GET_TABLE_CONTRACT_CUSTOM_FIELD_FAILED'


export const CREATE_CONTRACT_COMPANY: string = 'CONTRACT/CREATE_CONTRACT_COMPANY'
export const CREATE_CONTRACT_COMPANY_SUCCESSFUL: string = 'CONTRACT/CREATE_CONTRACT_COMPANY_SUCCESSFUL'
export const CREATE_CONTRACT_COMPANY_FAILED: string = 'CONTRACT/CREATE_CONTRACT_COMPANY_FAILED'

export const DELETE_CONTRACT_COMPANY: string = 'CONTRACT/DELETE_CONTRACT_COMPANY'
export const DELETE_CONTRACT_COMPANY_SUCCESSFUL: string = 'CONTRACT/DELETE_CONTRACT_COMPANY_SUCCESSFUL'
export const DELETE_CONTRACT_COMPANY_FAILED: string = 'CONTRACT/DELETE_CONTRACT_COMPANY_FAILED'

export const EDIT_CONTRACT_COMPANY: string = 'CONTRACT/EDIT_CONTRACT_COMPANY'
export const EDIT_CONTRACT_COMPANY_SUCCESSFUL: string = 'CONTRACT/EDIT_CONTRACT_COMPANY_SUCCESSFUL'
export const EDIT_CONTRACT_COMPANY_FAILED: string = 'CONTRACT/EDIT_CONTRACT_COMPANY_FAILED'


export const CREATE_CONTRACT_CONTACT: string = 'CONTRACT/CREATE_CONTRACT_CONTACT'
export const CREATE_CONTRACT_CONTACT_SUCCESSFUL: string = 'CONTRACT/CREATE_CONTRACT_CONTACT_SUCCESSFUL'
export const CREATE_CONTRACT_CONTACT_FAILED: string = 'CONTRACT/CREATE_CONTRACT_CONTACT_FAILED'

export const DELETE_CONTRACT_CONTACT: string = 'CONTRACT/DELETE_CONTRACT_CONTACT'
export const DELETE_CONTRACT_CONTACT_SUCCESSFUL: string = 'CONTRACT/DELETE_CONTRACT_CONTACT_SUCCESSFUL'
export const DELETE_CONTRACT_CONTACT_FAILED: string = 'CONTRACT/DELETE_CONTRACT_CONTACT_FAILED'

export const EDIT_CONTRACT_CONTACT: string = 'CONTRACT/EDIT_CONTRACT_CONTACT'
export const EDIT_CONTRACT_CONTACT_SUCCESSFUL: string = 'CONTRACT/EDIT_CONTRACT_CONTACT_SUCCESSFUL'
export const EDIT_CONTRACT_CONTACT_FAILED: string = 'CONTRACT/EDIT_CONTRACT_CONTACT_FAILED'

export const GET_CONTRACT: string = 'CONTRACT/GET_CONTRACT'
export const GET_CONTRACT_SUCCESSFUL: string = 'CONTRACT/GET_CONTRACT_SUCCESSFUL'
export const GET_CONTRACT_FAILED: string = 'CONTRACT/GET_CONTRACT_FAILED'

// GENERIC ADD CONTRACT MODAL
export const TOGGLE_GENERIC_ADD_CONTRACT_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_ADD_CONTRACT_MODAL'

// GENERIC ADD PIPELINE MODAL

export const TOGGLE_GENERIC_ADD_PIPELINE_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_ADD_PIPELINE_MODAL'
export const TOGGLE_GENERIC_ADD_CONTRACT_TYPE_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_ADD_CONTRACT_TYPE_MODAL'
export const TOGGLE_GENERIC_ADD_STATUS_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_ADD_STATUS_MODAL'
export const TOGGLE_GENERIC_ADD_CONTRACT_COMPANIES_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_ADD_CONTRACT_COMPANIES_MODAL'
export const TOGGLE_GENERIC_EDIT_CUSTOM_FORMS_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_EDIT_CUSTOM_FORMS_MODAL'
export const TOGGLE_GENERIC_CONTRACT_OVERVIEW_MODAL: string = 'COMPONENTS/TOGGLE_GENERIC_CONTRACT_OVERVIEW_MODAL'

export const ADD_ASSIGNED_USER_TO_CONTRACT: string = 'CONTRACT/ADD_ASSIGNED_USER_TO_CONTRACT'
export const ADD_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL: string = 'CONTRACT/ADD_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL'
export const ADD_ASSIGNED_USER_TO_CONTRACT_FAILED: string = 'CONTRACT/ADD_ASSIGNED_USER_TO_CONTRACT_FAILED'

export const ADD_CONTRACT_CONTACT_TO_CONTRACT: string = 'CONTRACT/ADD_CONTRACT_CONTACT_TO_CONTRACT'
export const ADD_CONTRACT_CONTACT_TO_CONTRACT_SUCCESSFUL: string = 'CONTRACT/ADD_CONTRACT_CONTACT_TO_CONTRACT_SUCCESSFUL'
export const ADD_CONTRACT_CONTACT_TO_CONTRACT_FAILED: string = 'CONTRACT/ADD_CONTRACT_CONTACT_TO_CONTRACT_FAILED'

export const ADD_CONTRACT_COMPANY_TO_CONTRACT: string = 'CONTRACT/ADD_CONTRACT_COMPANY_TO_CONTRACT'
export const ADD_CONTRACT_COMPANY_TO_CONTRACT_SUCCESSFUL: string = 'CONTRACT/ADD_CONTRACT_COMPANY_TO_CONTRACT_SUCCESSFUL'
export const ADD_CONTRACT_COMPANY_TO_CONTRACT_FAILED: string = 'CONTRACT/ADD_CONTRACT_COMPANY_TO_CONTRACT_FAILED'

export const ADD_TAG_TO_CONTRACT: string = 'CONTRACT/ADD_TAG_TO_CONTRACT'
export const ADD_TAG_TO_CONTRACT_SUCCESSFUL: string = 'CONTRACT/ADD_TAG_TO_CONTRACT_SUCCESSFUL'
export const ADD_TAG_TO_CONTRACT_FAILED: string = 'CONTRACT/ADD_TAG_TO_CONTRACT_FAILED'

export const ADD_REMINDER_TO_CONTRACT: string = 'CONTRACT/ADD_REMINDER_TO_CONTRACT'
export const ADD_REMINDER_TO_CONTRACT_SUCCESSFUL: string = 'CONTRACT/ADD_REMINDER_TO_CONTRACT_SUCCESSFUL'
export const ADD_REMINDER_TO_CONTRACT_FAILED: string = 'CONTRACT/ADD_REMINDER_TO_CONTRACT_FAILED'

export const REMOVE_ASSIGNED_USER_TO_CONTRACT: string = 'CONTRACT/REMOVE_ASSIGNED_USER_TO_CONTRACT'
export const REMOVE_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL: string = 'CONTRACT/REMOVE_ASSIGNED_USER_TO_CONTRACT_SUCCESSFUL'
export const REMOVE_ASSIGNED_USER_TO_CONTRACT_FAILED: string = 'CONTRACT/REMOVE_ASSIGNED_USER_TO_CONTRACT_FAILED'

export const REMOVE_CONTRACT_CONTACT_TO_CONTRACT: string = 'CONTRACT/REMOVE_CONTRACT_CONTACT_TO_CONTRACT'
export const REMOVE_CONTRACT_CONTACT_TO_CONTRACT_SUCCESSFUL: string = 'CONTRACT/REMOVE_CONTRACT_CONTACT_TO_CONTRACT_SUCCESSFUL'
export const REMOVE_CONTRACT_CONTACT_TO_CONTRACT_FAILED: string = 'CONTRACT/REMOVE_CONTRACT_CONTACT_TO_CONTRACT_FAILED'

export const REMOVE_CONTRACT_COMPANY_TO_CONTRACT: string = 'CONTRACT/REMOVE_CONTRACT_COMPANY_TO_CONTRACT'
export const REMOVE_CONTRACT_COMPANY_TO_CONTRACT_SUCCESSFUL: string = 'CONTRACT/REMOVE_CONTRACT_COMPANY_TO_CONTRACT_SUCCESSFUL'
export const REMOVE_CONTRACT_COMPANY_TO_CONTRACT_FAILED: string = 'CONTRACT/REMOVE_CONTRACT_COMPANY_TO_CONTRACT_FAILED'

export const REMOVE_TAG_TO_CONTRACT: string = 'CONTRACT/REMOVE_TAG_TO_CONTRACT'
export const REMOVE_TAG_TO_CONTRACT_SUCCESSFUL: string = 'CONTRACT/REMOVE_TAG_TO_CONTRACT_SUCCESSFUL'
export const REMOVE_TAG_TO_CONTRACT_FAILED: string = 'CONTRACT/REMOVE_TAG_TO_CONTRACT_FAILED'

export const GET_CONTRACT_LIST: string = 'CONTRACT/GET_CONTRACT_LIST'
export const GET_CONTRACT_LIST_SUCCESSFUL: string = 'CONTRACT/GET_CONTRACT_LIST_SUCCESSFUL'
export const GET_CONTRACT_LIST_FAILED: string = 'CONTRACT/GET_CONTRACT_LIST_FAILED'

export const EDIT_CONTRACT: string = 'CONTRACT/EDIT_CONTRACT'
export const EDIT_CONTRACT_SUCCESSFUL: string = 'CONTRACT/EDIT_CONTRACT_SUCCESSFUL'
export const EDIT_CONTRACT_FAILED: string = 'CONTRACT/EDIT_CONTRACT_FAILED'

export const DELETE_CONTRACT: string = 'CONTRACT/DELETE_CONTRACT'
export const DELETE_CONTRACT_SUCCESSFUL: string = 'CONTRACT/DELETE_CONTRACT_SUCCESSFUL'
export const DELETE_CONTRACT_FAILED: string = 'CONTRACT/DELETE_CONTRACT_FAILED'

export const EXPORT_CONTRACT_TO_EXCEL: string = 'CONTRACT/EXPORT_CONTRACT_TO_EXCEL'
export const EXPORT_CONTRACT_TO_EXCEL_SUCCESSFUL: string = 'CONTRACT/EXPORT_CONTRACT_TO_EXCEL_SUCCESSFUL'
export const EXPORT_CONTRACT_TO_EXCEL_FAILED: string = 'CONTRACT/EXPORT_CONTRACT_TO_EXCEL_FAILED'


export const GET_CONTRACT_FILES: string = 'CONTRACT/GET_CONTRACT_FILES'
export const GET_CONTRACT_FILES_SUCCESSFUL: string = 'CONTRACT/GET_CONTRACT_FILES_SUCCESSFUL'
export const GET_CONTRACT_FILES_FAILED: string = 'CONTRACT/GET_CONTRACT_FILES_FAILED'


export const GET_DASHBOARD_STATISTICS: string = 'DASHBOARD/GET_DASHBOARD_STATISTICS'
export const GET_DASHBOARD_STATISTICS_SUCCESSFUL: string = 'DASHBOARD/GET_DASHBOARD_STATISTICS_SUCCESSFUL'
export const GET_DASHBOARD_STATISTICS_FAILED: string = 'DASHBOARD/GET_DASHBOARD_STATISTICS_FAILED'



export const GET_CONTRACT_REMINDERS: string = 'DASHBOARD/GET_CONTRACT_REMINDERS'
export const GET_CONTRACT_REMINDERS_SUCCESSFUL: string = 'DASHBOARD/GET_CONTRACT_REMINDERS_SUCCESSFUL'
export const GET_CONTRACT_REMINDERS_FAILED: string = 'DASHBOARD/GET_CONTRACT_REMINDERS_FAILED'



export const SET_PAGE_TITLE: string = 'COMPONENTS/SET_PAGE_TITLE'


/* Default Pipeline */

export const SET_USER_DEFAULT_PIPELINE: string = 'COMPANIES/SET_USER_DEFAULT_PIPELINE';
export const SET_USER_DEFAULT_PIPELINE_SUCCESSFUL: string = 'COMPANIES/SET_USER_DEFAULT_PIPELINE_SUCCESSFUL';
export const SET_USER_DEFAULT_PIPELINE_FAILED: string = 'COMPANIES/SET_USER_DEFAULT_PIPELINE_FAILED';

/* GMail Integration */

export const GET_EMAIL_INTEGRATIONS: string = 'COMPANIES-INTEGRATIONS/GET_EMAIL_INTEGRATIONS';
export const GET_EMAIL_INTEGRATIONS_SUCCESSFUL: string = 'COMPANIES-INTEGRATIONS/GET_EMAIL_INTEGRATIONS_SUCCESSFUL';
export const GET_EMAIL_INTEGRATIONS_FAILED: string = 'COMPANIES-INTEGRATIONS/GET_EMAIL_INTEGRATIONS_FAILED';



export const UPLOAD_GMAIL_ATTACHMENT: string = 'COMPANIES-INTEGRATIONS/UPLOAD_GMAIL_ATTACHMENT';
export const UPLOAD_GMAIL_ATTACHMENT_SUCCESSFUL: string = 'COMPANIES-INTEGRATIONS/UPLOAD_GMAIL_ATTACHMENT_SUCCESSFUL';
export const UPLOAD_GMAIL_ATTACHMENT_FAILED: string = 'COMPANIES-INTEGRATIONS/UPLOAD_GMAIL_ATTACHMENT_FAILED';


export const TOGGLE_GENERIC_GOOGLE_DOCS_IFAME_MODAL: string = 'POPUPS/TOGGLE_GENERIC_GOOGLE_DOCS_IFAME_MODAL'
export const TOGGLE_GENERIC_TEMPLATE_GOOGLE_DOCS_IFAME_MODAL: string = 'POPUPS/TOGGLE_GENERIC_TEMPLATE_GOOGLE_DOCS_IFAME_MODAL'
export const TOGGLE_GENERIC_GOOGLE_FILE_PERMISSION_MODAL: string = 'POPUPS/TOGGLE_GENERIC_GOOGLE_FILE_PERMISSION_MODAL'

export const SET_FILE_DATA: string = 'FILES/SET_FILE_DATA'
export const SET_FILE_DATA_SUCCESSFUL: string = 'FILES/SET_FILE_DATA_SUCCESSFUL'
export const SET_FILE_DATA_FAILED: string = 'FILES/SET_FILE_DATA_FAILED'

export const CREATE_TEMPLATE: string = 'FILES/CREATE_TEMPLATE'
export const CREATE_TEMPLATE_SUCCESSFUL: string = 'FILES/CREATE_TEMPLATE_SUCCESSFUL'
export const CREATE_TEMPLATE_FAILED: string = 'FILES/CREATE_TEMPLATE_FAILED'

export const EDIT_TEMPLATE: string = 'FILES/EDIT_TEMPLATE'
export const EDIT_TEMPLATE_SUCCESSFUL: string = 'FILES/EDIT_TEMPLATE_SUCCESSFUL'
export const EDIT_TEMPLATE_FAILED: string = 'FILES/EDIT_TEMPLATE_FAILED'

export const CREATE_DOCUMENT_FROM_TEMPLATE: string = 'FILES/CREATE_DOCUMENT_FROM_TEMPLATE'
export const CREATE_DOCUMENT_FROM_TEMPLATE_SUCCESSFUL: string = 'FILES/CREATE_DOCUMENT_FROM_TEMPLATE_SUCCESSFUL'
export const CREATE_DOCUMENT_FROM_TEMPLATE_FAILED: string = 'FILES/CREATE_DOCUMENT_FROM_TEMPLATE_FAILED'

export const GET_TEMPLATES_LIST: string = 'FILES/GET_TEMPLATES_LIST'
export const GET_TEMPLATES_LIST_SUCCESSFUL: string = 'FILES/GET_TEMPLATES_LIST_SUCCESSFUL'
export const GET_TEMPLATES_LIST_FAILED: string = 'FILES/GET_TEMPLATES_LIST_FAILED'

export const GET_EXPORT_LINKS: string = 'FILES/GET_EXPORT_LINKS'
export const GET_EXPORT_LINKS_SUCCESSFUL: string = 'FILES/GET_EXPORT_LINKS_SUCCESSFUL'
export const GET_EXPORT_LINKS_FAILED: string = 'FILES/GET_EXPORT_LINKS_FAILED'

export const DELETE_TEMPLATE: string = 'FILES/DELETE_TEMPLATE'
export const DELETE_TEMPLATE_SUCCESSFUL: string = 'FILES/DELETE_TEMPLATE_SUCCESSFUL'
export const DELETE_TEMPLATE_FAILED: string = 'FILES/DELETE_TEMPLATE_FAILED'

export const SET_TEMPLATE_DATA_IN_CUSTOM_FORMS: string = 'FILES/SET_TEMPLATE_DATA_IN_CUSTOM_FORMS'
export const SET_TEMPLATE_DATA: string = 'FILES/SET_TEMPLATE_DATA'

export const SET_FILE_TO_GENERIC_PERMISSION_MODAL: string = 'FILES/SET_FILE_TO_GENERIC_PERMISSION_MODAL'


/* CDN */

export const CLOSE_CDN_POPUP: string = 'CDN/CLOSE_CDN_POPUP';

export const CDN_START_UPLOAD: string = 'CDN/CDN_START_UPLOAD';
export const CDN_SET_FILES: string = 'CDN/CDN_SET_FILES';
export const SET_REMOVE_CDN_EXPORT_SOURCE: string = 'CDN/SET_REMOVE_CDN_EXPORT_SOURCE';
export const CDN_SET_PICKER_FILES: string = 'CDN/CDN_SET_PICKER_FILES';
export const CDN_IS_IMPORTED_FILES: string = 'CDN/CDN_IS_IMPORTED_FILES';

export const UPLOAD_FILES_CDN: string = 'CDN/UPLOAD_FILES_CDN';
export const UPLOAD_FILES_CDN_SUCCESSFUL: string = 'CDN/UPLOAD_FILES_CDN_SUCCESSFUL';
export const UPLOAD_FILES_CDN_FAILED: string = 'CDN/UPLOAD_FILES_CDN_FAILED';

export const RESET_STATE_ON_UPLOAD_COMPLETED: string = 'CDN/RESET_STATE_ON_UPLOAD_COMPLETED';

export const GET_USER_FILES_PICKER: string = 'CDN/GET_USER_FILES_PICKER';
export const GET_USER_FILES_PICKER_SUCCESSFUL: string = 'CDN/GET_USER_FILES_PICKER_SUCCESSFUL';
export const GET_USER_FILES_PICKER_FAILED: string = 'CDN/GET_USER_FILES_PICKER_FAILED';
/**
 * ? Custom Forms Constants
 */

export const ADD_CUSTOM_FORMS: string = 'CUSTOM-FORMS/ADD_CUSTOM_FORMS'
export const ADD_CUSTOM_FORMS_SUCCESSFUL: string = 'CUSTOM-FORMS/ADD_CUSTOM_FORMS_SUCCESSFUL'
export const ADD_CUSTOM_FORMS_FAILED: string = 'CUSTOM-FORMS/ADD_CUSTOM_FORMS_FAILED'

export const GET_TABLE_CUSTOM_FORMS: string = 'CUSTOM-FORMS/GET_TABLE_CUSTOM_FORMS'
export const GET_TABLE_CUSTOM_FORMS_SUCCESSFUL: string = 'CUSTOM-FORMS/GET_TABLE_CUSTOM_FORMS_SUCCESSFUL'
export const GET_TABLE_CUSTOM_FORMS_FAILED: string = 'CUSTOM-FORMS/GET_TABLE_CUSTOM_FORMS_FAILED'


export const ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS: string = 'CUSTOM-FORMS/ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS'
export const ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS_SUCCESSFUL: string = 'CUSTOM-FORMS/ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS_SUCCESSFUL'
export const ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS_FAILED: string = 'CUSTOM-FORMS/ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS_FAILED'

export const REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS: string = 'CUSTOM-FORMS/REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS'
export const REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS_SUCCESSFUL: string = 'CUSTOM-FORMS/REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS_SUCCESSFUL'
export const REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS_FAILED: string = 'CUSTOM-FORMS/REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS_FAILED'

export const SET_CUSTOM_FORMS_FIELD_EDIT_DATA: string = 'CUSTOM-FORMS/SET_CUSTOM_FORMS_FIELD_EDIT_DATA'
export const SET_EDIT_CUSTOM_FORMS_DATA: string = 'CUSTOM-FORMS/SET_EDIT_CUSTOM_FORMS_DATA'


export const REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS: string = 'CUSTOM-FORMS/REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS'
export const REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS_SUCCESSFUL: string = 'CUSTOM-FORMS/REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS_SUCCESSFUL'
export const REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS_FAILED: string = 'CUSTOM-FORMS/REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS_FAILED'

export const DELETE_CUSTOM_FORMS: string = 'CUSTOM-FORMS/DELETE_CUSTOM_FORMS'
export const DELETE_CUSTOM_FORMS_SUCCESSFUL: string = 'CUSTOM-FORMS/DELETE_CUSTOM_FORMS_SUCCESSFUL'
export const DELETE_CUSTOM_FORMS_FAILED: string = 'CUSTOM-FORMS/DELETE_CUSTOM_FORMS_FAILED'


export const EDIT_CUSTOM_FORMS: string = 'CUSTOM-FORMS/EDIT_CUSTOM_FORMS'
export const EDIT_CUSTOM_FORMS_SUCCESSFUL: string = 'CUSTOM-FORMS/EDIT_CUSTOM_FORMS_SUCCESSFUL'
export const EDIT_CUSTOM_FORMS_FAILED: string = 'CUSTOM-FORMS/EDIT_CUSTOM_FORMS_FAILED'



export const SET_CUSTOM_FORMS_FIELD_DATA_TO_REDUCER: string = 'CUSTOM-FORMS/SET_CUSTOM_FORMS_FIELD_DATA_TO_REDUCER'

export const EDIT_CUSTOM_FORMS_FIELD_DATA: string = 'CUSTOM-FORMS/EDIT_CUSTOM_FORMS_FIELD_DATA'
export const EDIT_CUSTOM_FORMS_FIELD_DATA_SUCCESSFUL: string = 'CUSTOM-FORMS/EDIT_CUSTOM_FORMS_FIELD_DATA_SUCCESSFUL'
export const EDIT_CUSTOM_FORMS_FIELD_DATA_FAILED: string = 'CUSTOM-FORMS/EDIT_CUSTOM_FORMS_FIELD_DATA_FAILED'


export const CUSTOM_FORMS_SELECT_TEMPLATE: string = 'CUSTOM-FORMS/CUSTOM_FORMS_SELECT_TEMPLATE'
export const CUSTOM_FORMS_SELECT_TEMPLATE_SUCCESSFUL: string = 'CUSTOM-FORMS/CUSTOM_FORMS_SELECT_TEMPLATE_SUCCESSFUL'
export const CUSTOM_FORMS_SELECT_TEMPLATE_FAILED: string = 'CUSTOM-FORMS/CUSTOM_FORMS_SELECT_TEMPLATE_FAILED'



export const CUSTOM_FORMS_REMOVE_FROM_TEMPLATE: string = 'CUSTOM-FORMS/CUSTOM_FORMS_REMOVE_FROM_TEMPLATE'
export const CUSTOM_FORMS_REMOVE_FROM_TEMPLATE_SUCCESSFUL: string = 'CUSTOM-FORMS/CUSTOM_FORMS_REMOVE_FROM_TEMPLATE_SUCCESSFUL'
export const CUSTOM_FORMS_REMOVE_FROM_TEMPLATE_FAILED: string = 'CUSTOM-FORMS/CUSTOM_FORMS_REMOVE_FROM_TEMPLATE_FAILED'



export const CUSTOM_FORMS_TEMPLATE_REFRESH: string = 'CUSTOM-FORMS/CUSTOM_FORMS_TEMPLATE_REFRESH'
export const CUSTOM_FORMS_TEMPLATE_REFRESH_SUCCESSFUL: string = 'CUSTOM-FORMS/CUSTOM_FORMS_TEMPLATE_REFRESH_SUCCESSFUL'

export const ADD_FILES_TO_CONTACT: string = 'FILES/ADD_FILES_TO_CONTACT'
export const ADD_FILES_TO_CONTACT_SUCCESSFUL: string = 'FILES/ADD_FILES_TO_CONTACT_SUCCESSFUL'
export const ADD_FILES_TO_CONTACT_FAILED: string = 'FILES/ADD_FILES_TO_CONTACT_FAILED'


export const CREATE_APPROVE_TEMPLATE: string = 'APPROVE/CREATE_APPROVE_TEMPLATE'
export const CREATE_APPROVE_TEMPLATE_SUCCESSFUL: string = 'APPROVE/CREATE_APPROVE_TEMPLATE_SUCCESSFUL'
export const CREATE_APPROVE_TEMPLATE_FAILED: string = 'APPROVE/CREATE_APPROVE_TEMPLATE_FAILED'



export const CREATE_APPROVE_TEMPLATE_STEP: string = 'APPROVE/CREATE_APPROVE_TEMPLATE_STEP'
export const CREATE_APPROVE_TEMPLATE_STEP_SUCCESSFUL: string = 'APPROVE/CREATE_APPROVE_TEMPLATE_STEP_SUCCESSFUL'
export const CREATE_APPROVE_TEMPLATE_STEP_FAILED: string = 'APPROVE/CREATE_APPROVE_TEMPLATE_STEP_FAILED'


export const EDIT_APPROVE_TEMPLATE: string = 'APPROVE/EDIT_APPROVE_TEMPLATE'
export const EDIT_APPROVE_TEMPLATE_SUCCESSFUL: string = 'APPROVE/EDIT_APPROVE_TEMPLATE_SUCCESSFUL'
export const EDIT_APPROVE_TEMPLATE_FAILED: string = 'APPROVE/EDIT_APPROVE_TEMPLATE_FAILED'



export const DELETE_APPROVE_TEMPLATE: string = 'APPROVE/DELETE_APPROVE_TEMPLATE'
export const DELETE_APPROVE_TEMPLATE_SUCCESSFUL: string = 'APPROVE/DELETE_APPROVE_TEMPLATE_SUCCESSFUL'
export const DELETE_APPROVE_TEMPLATE_FAILED: string = 'APPROVE/DELETE_APPROVE_TEMPLATE_FAILED'




export const UPDATE_APPROVE_TEMPLATE_STEP: string = 'APPROVE/UPDATE_APPROVE_TEMPLATE_STEP'
export const UPDATE_APPROVE_TEMPLATE_STEP_SUCCESSFUL: string = 'APPROVE/UPDATE_APPROVE_TEMPLATE_STEP_SUCCESSFUL'
export const UPDATE_APPROVE_TEMPLATE_STEP_FAILED: string = 'APPROVE/UPDATE_APPROVE_TEMPLATE_STEP_FAILED'



export const UPDATE_APPROVE_TEMPLATE: string = 'APPROVE/UPDATE_APPROVE_TEMPLATE'
export const UPDATE_APPROVE_TEMPLATE_SUCCESSFUL: string = 'APPROVE/UPDATE_APPROVE_TEMPLATE_SUCCESSFUL'
export const UPDATE_APPROVE_TEMPLATE_FAILED: string = 'APPROVE/UPDATE_APPROVE_TEMPLATE_FAILED'


// @ ---------------------- APPROVE PROCESS ---------------------- @


export const CREATE_APPROVE_PROCESS: string = 'APPROVE/CREATE_APPROVE_PROCESS'
export const CREATE_APPROVE_PROCESS_SUCCESSFUL: string = 'APPROVE/CREATE_APPROVE_PROCESS_SUCCESSFUL'
export const CREATE_APPROVE_PROCESS_FAILED: string = 'APPROVE/CREATE_APPROVE_PROCESS_FAILED'






export const UPDATE_APPROVE_TEMPLATE_STEP_USER: string = 'APPROVE/UPDATE_APPROVE_TEMPLATE_STEP_USER'
export const UPDATE_APPROVE_TEMPLATE_STEP_USER_SUCCESSFUL: string = 'APPROVE/UPDATE_APPROVE_TEMPLATE_STEP_USER_SUCCESSFUL'
export const UPDATE_APPROVE_TEMPLATE_STEP_USER_FAILED: string = 'APPROVE/UPDATE_APPROVE_TEMPLATE_STEP_USER_FAILED'




export const DELETE_APPROVE_TEMPLATE_STEP: string = 'APPROVE/DELETE_APPROVE_TEMPLATE_STEP'
export const DELETE_APPROVE_TEMPLATE_STEP_SUCCESSFUL: string = 'APPROVE/DELETE_APPROVE_TEMPLATE_STEP_SUCCESSFUL'
export const DELETE_APPROVE_TEMPLATE_STEP_FAILED: string = 'APPROVE/DELETE_APPROVE_TEMPLATE_STEP_FAILED'




export const DELETE_APPROVE_TEMPLATE_STEP_USER: string = 'APPROVE/DELETE_APPROVE_TEMPLATE_STEP_USER'
export const DELETE_APPROVE_TEMPLATE_STEP_USER_SUCCESSFUL: string = 'APPROVE/DELETE_APPROVE_TEMPLATE_STEP_USER_SUCCESSFUL'
export const DELETE_APPROVE_TEMPLATE_STEP_USER_FAILED: string = 'APPROVE/DELETE_APPROVE_TEMPLATE_STEP_USER_FAILED'



export const CREATE_APPROVE_TEMPLATE_STEPUSER: string = 'APPROVE/CREATE_APPROVE_TEMPLATE_STEPUSER'
export const CREATE_APPROVE_TEMPLATE_STEPUSER_SUCCESSFUL: string = 'APPROVE/CREATE_APPROVE_TEMPLATE_STEPUSER_SUCCESSFUL'
export const CREATE_APPROVE_TEMPLATE_STEPUSER_FAILED: string = 'APPROVE/CREATE_APPROVE_TEMPLATE_STEPUSER_FAILED'


export const REFRESH_APPROVE_TEMPLATES_LIST: string = 'APPROVE/REFRESH_APPROVE_TEMPLATES_LIST'
export const GET_APPROVE_TEMPLATES_LIST: string = 'APPROVE/GET_APPROVE_TEMPLATES_LIST'
export const GET_APPROVE_TEMPLATES_LIST_SUCCESSFUL: string = 'APPROVE/GET_APPROVE_TEMPLATES_LIST_SUCCESSFUL'
export const GET_APPROVE_TEMPLATES_LIST_FAILED: string = 'APPROVE/GET_APPROVE_TEMPLATES_LIST_FAILED'

export const TOGGLE_APPROVAL_PROCESS_MODAL: string = 'COMPONENTS/TOGGLE_APPROVAL_PROCESS_MODAL'

// @ ---------------------- APPROVE PROCESS FILE ---------------------- @

export const UPDATE_APPROVE_PROCESS_FILE_STEP_USER: string = 'APPROVE/UPDATE_APPROVE_PROCESS_FILE_STEP_USER'
export const UPDATE_APPROVE_PROCESS_FILE_STEP_USER_SUCCESSFUL: string = 'APPROVE/UPDATE_APPROVE_PROCESS_FILE_STEP_USER_SUCCESSFUL'
export const UPDATE_APPROVE_PROCESS_FILE_STEP_USER_FAILED: string = 'APPROVE/UPDATE_APPROVE_PROCESS_FILE_STEP_USER_FAILED'

export const DELETE_APPROVE_PROCESS_FILE_STEP: string = 'APPROVE/DELETE_APPROVE_PROCESS_FILE_STEP'
export const DELETE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL: string = 'APPROVE/DELETE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL'
export const DELETE_APPROVE_PROCESS_FILE_STEP_FAILED: string = 'APPROVE/DELETE_APPROVE_PROCESS_FILE_STEP_FAILED'

export const DELETE_APPROVE_PROCESS_FILE_STEP_USER: string = 'APPROVE/DELETE_APPROVE_PROCESS_FILE_STEP_USER'
export const DELETE_APPROVE_PROCESS_FILE_STEP_USER_SUCCESSFUL: string = 'APPROVE/DELETE_APPROVE_PROCESS_FILE_STEP_USER_SUCCESSFUL'
export const DELETE_APPROVE_PROCESS_FILE_STEP_USER_FAILED: string = 'APPROVE/DELETE_APPROVE_PROCESS_FILE_STEP_USER_FAILED'

export const DELETE_ALL_APPROVE_PROCESS_FILE_STEPS: string = 'APPROVE/DELETE_ALL_APPROVE_PROCESS_FILE_STEPS'
export const DELETE_ALL_APPROVE_PROCESS_FILE_STEPS_SUCCESSFUL: string = 'APPROVE/DELETE_ALL_APPROVE_PROCESS_FILE_STEPS_SUCCESSFUL'
export const DELETE_ALL_APPROVE_PROCESS_FILE_STEPS_FAILED: string = 'APPROVE/DELETE_ALL_APPROVE_PROCESS_FILE_STEPS_FAILED'

export const CREATE_APPROVE_PROCESS_FILE_STEPUSER: string = 'APPROVE/CREATE_APPROVE_PROCESS_FILE_STEPUSER'
export const CREATE_APPROVE_PROCESS_FILE_STEPUSER_SUCCESSFUL: string = 'APPROVE/CREATE_APPROVE_PROCESS_FILE_STEPUSER_SUCCESSFUL'
export const CREATE_APPROVE_PROCESS_FILE_STEPUSER_FAILED: string = 'APPROVE/CREATE_APPROVE_PROCESS_FILE_STEPUSER_FAILED'


export const CREATE_APPROVE_PROCESS_FILE_STEP: string = 'APPROVE/CREATE_APPROVE_PROCESS_FILE_STEP'
export const CREATE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL: string = 'APPROVE/CREATE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL'
export const CREATE_APPROVE_PROCESS_FILE_STEP_FAILED: string = 'APPROVE/CREATE_APPROVE_PROCESS_FILE_STEP_FAILED'


export const UPDATE_APPROVE_PROCESS_FILE_STEP: string = 'APPROVE/UPDATE_APPROVE_PROCESS_FILE_STEP'
export const UPDATE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL: string = 'APPROVE/UPDATE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL'
export const UPDATE_APPROVE_PROCESS_FILE_STEP_FAILED: string = 'APPROVE/UPDATE_APPROVE_PROCESS_FILE_STEP_FAILED'


export const COPY_TEMPLATE_STEPS_TO_PROCESS_FILE: string = 'APPROVE/COPY_TEMPLATE_STEPS_TO_PROCESS_FILE'
export const COPY_TEMPLATE_STEPS_TO_PROCESS_FILE_SUCCESSFUL: string = 'APPROVE/COPY_TEMPLATE_STEPS_TO_PROCESS_FILE_SUCCESSFUL'
export const COPY_TEMPLATE_STEPS_TO_PROCESS_FILE_FAILED: string = 'APPROVE/COPY_TEMPLATE_STEPS_TO_PROCESS_FILE_FAILED'


export const START_APPROVING: string = 'APPROVE/START_APPROVING'
export const START_APPROVING_SUCCESSFUL: string = 'APPROVE/START_APPROVING_SUCCESSFUL'
export const START_APPROVING_FAILED: string = 'APPROVE/START_APPROVING_FAILED'


export const GET_APPROVE_PROCESS_LIST: string = 'APPROVE/GET_APPROVE_PROCESS_LIST'
export const GET_APPROVE_PROCESS_LIST_SUCCESSFUL: string = 'APPROVE/GET_APPROVE_PROCESS_LIST_SUCCESSFUL'
export const GET_APPROVE_PROCESS_LIST_FAILED: string = 'APPROVE/GET_APPROVE_PROCESS_LIST_FAILED'


export const GET_APPROVE_PROCESS_DETAIL: string = 'APPROVE/GET_APPROVE_PROCESS_DETAIL'
export const GET_APPROVE_PROCESS_DETAIL_SUCCESSFUL: string = 'APPROVE/GET_APPROVE_PROCESS_DETAIL_SUCCESSFUL'
export const GET_APPROVE_PROCESS_DETAIL_FAILED: string = 'APPROVE/GET_APPROVE_PROCESS_DETAIL_FAILED'


export const TOGGLE_INTERNAL_APPROVAL_POPUP: string = 'APPROVE/TOGGLE_INTERNAL_APPROVAL_POPUP'
export const SET_APPROVE_PROCESS_FILE: string = 'APPROVE/SET_APPROVE_PROCESS_FILE'
export const REFRESH_APPROVE_PROCESS_LIST: string = 'APPROVE/REFRESH_APPROVE_PROCESS_LIST'
export const SET_APPROVE_SHOW_BUTTONS: string = 'APPROVE/SET_APPROVE_SHOW_BUTTONS'