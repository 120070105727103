import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { excelCreator } from "@utils/export/excelCreator"
import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import api from '@clients/api.client';
import { toast } from 'react-toastify';
import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";

import { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import { toggleGenericSuccessModal } from '@redux/utils/components/GenerateSuccessModal';
import moment from 'moment';


export const addApproveTemplate: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;

    dispatch({
        type: ActionDispatchTypes.CREATE_APPROVE_TEMPLATE,
        payload
    })
    return api.post(`/approve-templates/${company}`, {
        title: payload?.title,
    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            dispatch({
                type: ActionDispatchTypes.TOGGLE_APPROVAL_TEMPLATE_MODAL,
                payload: payload?.show || !getState().components.approvalTemplateModal
            });
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const editApproveTemplate: any = (payload: any) => (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.EDIT_APPROVE_TEMPLATE_SUCCESSFUL,
        payload: payload
    })
    dispatch({
        type: ActionDispatchTypes.TOGGLE_APPROVAL_TEMPLATE_MODAL,
        payload: payload?.show || !getState().components.contractOverviewModal
    });
}
export const addStep = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveTemplate?._id;

    dispatch({
        type: ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEP,
        payload
    })

    return api.post(`/approve-templates/steps/step/${company}/${approveTemplate}`, {

    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEP_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEP_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const deleteApprovalTemplate = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = payload?.deleteId

    dispatch({
        type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE,
        payload
    })

    return api.delete(`/approve-templates/${company}/${approveTemplate}`)
        .then((response: any) => {
            toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const addUserIntoStep = (payload: any) => (dispatch: any, getState: any) => {

    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveTemplate?._id;
    const step = payload?.step?._id;
    console.log('payload', payload)
    dispatch({
        type: ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEPUSER,
        payload
    })

    return api.post(`/approve-templates/steps/user/${company}/${approveTemplate}/${step}`, {
        email: payload?.email
    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEPUSER_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEPUSER_FAILED,
                payload: errorResponse
            });
            return false;
        })

}
export const getApproveTemplates: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_APPROVE_TEMPLATES_LIST,
        payload
    })


    return api.get(`/approve-templates/list/${company}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_APPROVE_TEMPLATES_LIST_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_APPROVE_TEMPLATES_LIST_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const deleteStep = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveTemplate?._id;
    const step = payload?.id

    dispatch({
        type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP,
        payload
    })

    return api.delete(`/approve-templates/steps/step/${company}/${approveTemplate}/${step}`, {

    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);

            //Delete step from state
            getState()?.approve?.selectedApproveTemplate?.steps?.map((step: any, index: number) => {
                if (step?._id === payload?.id) {
                    getState()?.approve?.selectedApproveTemplate?.steps?.splice(index, 1);
                }
            })

            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const updateStep = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveTemplate?._id;
    const step = payload?.id
    const fieldName: string = payload?.fieldName
    const fieldValue: boolean = payload?.fieldValue



    console.log('ACTION', payload)
    dispatch({
        type: ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP,
        payload
    })

    return api.put(`/approve-templates/steps/step/${company}/${approveTemplate}/${step}`, {
        fieldName: payload?.fieldName,
        fieldValue: payload?.fieldValue
    })
        .then((response: any) => {

            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_SUCCESSFUL,
                payload: {
                    fieldName,
                    fieldValue,
                    stepId: step
                }
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const updateApproveTemplate = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveTemplate?._id;
    const fieldName: string = payload?.fieldName
    const fieldValue: boolean = payload?.fieldValue

    dispatch({
        type: ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE,
        payload
    })

    return api.put(`/approve-templates/${company}/${approveTemplate}`, {
        fieldName: payload?.fieldName,
        fieldValue: payload?.fieldValue
    })
        .then((response: any) => {

            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_SUCCESSFUL,
                payload: {
                    fieldName,
                    fieldValue
                }
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const updateStepUser = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveTemplate?._id;
    const step = payload?.stepId
    const fieldName: string = payload?.fieldName
    const fieldValue: boolean = payload?.fieldValue



    console.log('ACTION', payload)
    dispatch({
        type: ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_USER,
        payload: {
            fieldName,
            fieldValue,
            stepId: payload?.stepId,
            stepUserId: payload?.stepUserId
        }
    })

    return api.put(`/approve-templates/steps/user/${company}/${approveTemplate}/${step}`, {
        fieldName: payload?.fieldName,
        fieldValue: payload?.fieldValue,
        stepUserId: payload?.stepUserId
    })
        .then((response: any) => {


            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_USER_SUCCESSFUL,
                payload: {
                    fieldName,
                    fieldValue,
                    stepId: payload?.stepId,
                    stepUserId: payload?.stepUserId
                }
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            //toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_USER_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const removeRecipient = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveTemplate?._id;
    const step = payload?.stepId

    const data = {
        recipientId: payload?.recipientId,
        recipientIndex: payload?.recipientIndex,
        stepId: payload?.stepId,
        stepIndex: payload?.stepIndex
    }



    dispatch({
        type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_USER,
        payload
    })

    return api.delete(`/approve-templates/steps/user/${company}/${approveTemplate}/${step}`, {
        data: data
    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);

            getState()?.approve?.selectedApproveTemplate?.steps?.map((item: any, index: any) => {
                if (item?._id === payload?.stepId) {

                    console.log('step', item)
                    if (item?.stepUsers?.length > 0) {
                        item.stepUsers = item.stepUsers.filter((item: any, index: any) => {
                            return item?._id !== payload?.recipientId
                        })
                    }
                }
                return item
            })

            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_USER_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_USER_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const removeStep = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveTemplate?._id;
    const step = payload?.stepId

    const data = {
        recipientId: payload?.recipientId,
        recipientIndex: payload?.recipientIndex,
        stepId: payload?.stepId,
        stepIndex: payload?.stepIndex
    }



    dispatch({
        type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP,
        payload
    })

    return api.delete(`/approve-templates/steps/user/${company}/${approveTemplate}/${step}`, {
        data: data
    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);

            getState()?.approve?.selectedApproveTemplate?.steps?.map((item: any, index: any) => {
                if (item?._id === payload?.stepId) {

                    console.log('step', item)
                    if (item?.stepUsers?.length > 0) {
                        item.stepUsers = item.stepUsers.filter((item: any, index: any) => {
                            return item?._id !== payload?.recipientId
                        })
                    }
                }
                return item
            })

            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

// ? ========================= APPROVE PROCESS =========================

/** 
 ** Create an Approve Process Function
*/

export const createApproveProcess: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;

    dispatch({
        type: ActionDispatchTypes.CREATE_APPROVE_PROCESS,
        payload
    })
    return api.post(`/approve/${company}`, {
        title: payload?.title,
        originalFile: payload?.originalFile,
    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_PROCESS_SUCCESSFUL,
                payload: response?.data?.node
            })

            dispatch({
                type: ActionDispatchTypes.TOGGLE_APPROVAL_PROCESS_MODAL,
                payload: payload?.show || !getState().components.approvalProcessFileModal
            });

            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_PROCESS_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

export const editReSendApproveFile: any = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;


    dispatch({
        type: ActionDispatchTypes.TOGGLE_APPROVAL_PROCESS_MODAL,
        payload: payload?.show || !getState().components.approvalProcessFileModal
    });

    dispatch({
        type: ActionDispatchTypes.SET_APPROVE_PROCESS_FILE,
        payload: {
            process: payload?.process,
            file: payload?.file
        }
    });


}



// @ APPROVE PROVESS : STEP & STEP USER OPERATIONS


export const addApproveProcessFileStep = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveProcessFile?._id;

    dispatch({
        type: ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEP,
        payload
    })

    return api.post(`/approve-files/steps/step/${company}/${approveTemplate}`, {

    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEP_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const addApproveProcessFileUserIntoStep = (payload: any) => (dispatch: any, getState: any) => {

    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveProcessFile?._id;
    const step = payload?.step?._id;
    console.log('payload', payload)
    dispatch({
        type: ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEPUSER,
        payload
    })

    return api.post(`/approve-files/steps/user/${company}/${approveTemplate}/${step}`, {
        email: payload?.email
    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEPUSER_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEPUSER_FAILED,
                payload: errorResponse
            });
            return false;
        })

}
export const deleteApproveProcessFileStep = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveProcessFile?._id;
    const step = payload?.id

    dispatch({
        type: ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP,
        payload
    })

    return api.delete(`/approve-files/steps/step/${company}/${approveTemplate}/${step}`, {

    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);

            //Delete step from state
            getState()?.approve?.selectedApproveProcessFile?.steps?.map((step: any, index: number) => {
                if (step?._id === payload?.id) {
                    getState()?.approve?.selectedApproveProcessFile?.steps?.splice(index, 1);
                }
            })

            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const updateApproveProcessFileStep = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveProcessFile?._id;
    const step = payload?.id
    const fieldName: string = payload?.fieldName
    const fieldValue: boolean = payload?.fieldValue



    console.log('ACTION', payload)
    dispatch({
        type: ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP,
        payload
    })

    return api.put(`/approve-files/steps/step/${company}/${approveTemplate}/${step}`, {
        fieldName: payload?.fieldName,
        fieldValue: payload?.fieldValue
    })
        .then((response: any) => {

            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL,
                payload: {
                    fieldName,
                    fieldValue,
                    stepId: step
                }
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const updateApproveProcessFileStepUser = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveProcessFile?._id;
    const step = payload?.stepId
    const fieldName: string = payload?.fieldName
    const fieldValue: boolean = payload?.fieldValue



    console.log('ACTION', payload)
    dispatch({
        type: ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_USER,
        payload: {
            fieldName,
            fieldValue,
            stepId: payload?.stepId,
            stepUserId: payload?.stepUserId
        }
    })

    return api.put(`/approve-files/steps/user/${company}/${approveTemplate}/${step}`, {
        fieldName: payload?.fieldName,
        fieldValue: payload?.fieldValue,
        stepUserId: payload?.stepUserId
    })
        .then((response: any) => {


            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_USER_SUCCESSFUL,
                payload: {
                    fieldName,
                    fieldValue,
                    stepId: payload?.stepId,
                    stepUserId: payload?.stepUserId
                }
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            //toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_USER_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const removeApproveProcessFileRecipient = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveProcessFile?._id;
    const step = payload?.stepId

    const data = {
        recipientId: payload?.recipientId,
        recipientIndex: payload?.recipientIndex,
        stepId: payload?.stepId,
        stepIndex: payload?.stepIndex
    }



    dispatch({
        type: ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_USER,
        payload
    })

    return api.delete(`/approve-files/steps/user/${company}/${approveTemplate}/${step}`, {
        data: data
    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);

            getState()?.approve?.selectedApproveProcessFile?.steps?.map((item: any, index: any) => {
                if (item?._id === payload?.stepId) {

                    console.log('step', item)
                    if (item?.stepUsers?.length > 0) {
                        item.stepUsers = item.stepUsers.filter((item: any, index: any) => {
                            return item?._id !== payload?.recipientId
                        })
                    }
                }
                return item
            })

            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_USER_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_USER_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const removeAllSteps = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveProcessFile?._id;



    dispatch({
        type: ActionDispatchTypes.DELETE_ALL_APPROVE_PROCESS_FILE_STEPS,
        payload
    })

    return api.delete(`/approve-files/steps/remove-all/${company}/${approveTemplate}`)
        .then((response: any) => {
            //toast.success(response.data.response?.message);

            dispatch({
                type: ActionDispatchTypes.DELETE_ALL_APPROVE_PROCESS_FILE_STEPS_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_ALL_APPROVE_PROCESS_FILE_STEPS_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const removeApproveProcessFileStep = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveTemplate = getState().approve?.selectedApproveProcessFile?._id;
    const step = payload?.stepId

    const data = {
        recipientId: payload?.recipientId,
        recipientIndex: payload?.recipientIndex,
        stepId: payload?.stepId,
        stepIndex: payload?.stepIndex
    }



    dispatch({
        type: ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP,
        payload
    })

    return api.delete(`/approve-files/steps/user/${company}/${approveTemplate}/${step}`, {
        data: data
    })
        .then((response: any) => {
            //toast.success(response.data.response?.message);

            getState()?.approve?.selectedApproveProcessFile?.steps?.map((item: any, index: any) => {
                if (item?._id === payload?.stepId) {

                    console.log('step', item)
                    if (item?.stepUsers?.length > 0) {
                        item.stepUsers = item.stepUsers.filter((item: any, index: any) => {
                            return item?._id !== payload?.recipientId
                        })
                    }
                }
                return item
            })

            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL,
                payload: response?.data?.node?.data
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const copyTemplateStepsToProcessFile = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveProcessFile = getState().approve?.selectedApproveProcessFile?._id;

    dispatch({
        type: ActionDispatchTypes.COPY_TEMPLATE_STEPS_TO_PROCESS_FILE,
        payload
    })


    return api.get(`/approve-files/template/copy/${company}/${payload.approveTemplateId}/${approveProcessFile}`)
        .then((response: any) => {

            console.log('action', response?.data?.node)
            dispatch({
                type: ActionDispatchTypes.COPY_TEMPLATE_STEPS_TO_PROCESS_FILE_SUCCESSFUL,
                payload: response?.data?.node?.approveFile
            })
            return response?.data?.node;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.COPY_TEMPLATE_STEPS_TO_PROCESS_FILE_FAILED,
                payload: errorResponse
            });
            return false;
        })
}
export const startApproving = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const approveProcessFile = getState().approve?.selectedApproveProcessFile?._id;

    dispatch({
        type: ActionDispatchTypes.START_APPROVING,
        payload
    })


    return api.post(`/approve-files/approving/${company}/${approveProcessFile}`, {
        userMessage: payload?.userMessage
    })
        .then((response: any) => {

            console.log('action', response?.data?.node)
            toast.success(response?.data?.response?.message);

            dispatch({
                type: ActionDispatchTypes.START_APPROVING_SUCCESSFUL,
                payload: response?.data?.node?.approveFile
            })
            dispatch({
                type: ActionDispatchTypes.TOGGLE_APPROVAL_PROCESS_MODAL,
                payload: false
            });
            dispatch({
                type: ActionDispatchTypes.TOGGLE_GENERIC_GOOGLE_DOCS_IFAME_MODAL,
                payload: false
            });



            return response?.data;
        }).catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            dispatch({
                type: ActionDispatchTypes.START_APPROVING_FAILED,
                payload: errorResponse
            });
            return false;
        })
}

/** 
 ** Get All Approve Proces
*/

export const getAllApproveProcess: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_APPROVE_PROCESS_LIST,
        payload
    })


    return api.get(`/approve/list/${company}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_APPROVE_PROCESS_LIST_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_APPROVE_PROCESS_LIST_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const refreshApproveTable: any = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.REFRESH_APPROVE_PROCESS_LIST,
        payload
    })
}

export const toggleInternalApprovalPopup: TAction = (payload: any) => async (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.TOGGLE_INTERNAL_APPROVAL_POPUP,
        payload: {
            show: payload?.show || !getState().approve.internalApprovePopup?.show,
            data: payload?.data || getState().approve.internalApprovePopup?.data
        }
    });

}

export const getReadApproveProcess: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_APPROVE_PROCESS_DETAIL,
        payload: {
            show: true,
            data: {},
            loading: true
        }
    })


    return api.get(`/approve/read/${company}/${payload?.id}`).then((response: any) => {
        console.log('approveProcess', response.data);
        dispatch({
            type: ActionDispatchTypes.GET_APPROVE_PROCESS_DETAIL_SUCCESSFUL,
            payload: response?.data?.node
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_APPROVE_PROCESS_DETAIL_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const setApproveShowButtons = (payload: any) => (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.SET_APPROVE_SHOW_BUTTONS,
        payload: payload.show
    })

}

