import React, { useState } from 'react';
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from 'react-bootstrap';
import classNames from 'classnames';
//TYPES
import { useTranslation } from 'react-i18next';

import FormInput from '@components/FormInput';
import {
    createApproveProcess
} from '@redux/approve/index.actions';

const mapStateToProps = (state: any) => ({
    files: state.files,
});

const mapActionsToProps = {
    createApproveProcess: createApproveProcess
};


const ApprovalButton: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();

    const [file, setFile] = useState<any>(props?.file || null);
    const [approvalProcessTitle, setApprovalProcessTitle] = useState<string>(file?.filename || '');

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleShowModal = () => {
        setShowModal(!showModal);
    }

    return (
        <>
            <Button
                className={classNames('approval-button', props.className)}
                variant={props.variant || 'primary'}
                onClick={(e) => {
                    e.preventDefault();
                    toggleShowModal();
                    console.log('file', file);
                }}
                disabled={props.disabled}
            >
                {t('APPROVE_BUTTON_COMPONENT.SEND')}
            </Button>

            <Modal
                show={showModal}
                onHide={toggleShowModal}
                centered
                contentClassName="modal-shadow-border"
            >
                <Modal.Header closeButton className="bg-secondary">
                    <h4 className="modal-title text-white">
                        {t('APPROVE_BUTTON_COMPONENT.TITLE')}
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <label htmlFor="">{t('APPROVE_BUTTON_COMPONENT.NAME_LABEL')}</label>
                            <FormInput
                                key={'approval-process-title-' + file?._id}
                                className="form-control mb-2"
                                onInput={(e: any) => setApprovalProcessTitle(e.target.value)}
                                name="title"
                                type="text"
                                value={approvalProcessTitle}
                                rows={5}
                            />
                            <Button
                                disabled={props?.files?.creatingDocumentFromTemplate}
                                onClick={(() => {
                                    console.log('first', file?._id)
                                    props.createApproveProcess({
                                        title: approvalProcessTitle,
                                        originalFile: file?._id
                                    }).then((response: any) => {
                                        console.log('response', response);
                                        toggleShowModal();
                                    })
                                })}>
                                {t('APPROVE_BUTTON_COMPONENT.SAVE')}

                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    )
}

export const ReSendApprovalButton: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();

    const [file, setFile] = useState<any>(props?.file || null);
    const [approvalProcessTitle, setApprovalProcessTitle] = useState<string>(file?.filename || '');

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleShowModal = () => {
        setShowModal(!showModal);
    }

    return (
        <>
            <Button
                className={classNames('approval-button', props.className)}
                variant={props.variant || 'primary'}
                onClick={(e) => {
                    e.preventDefault();
                    toggleShowModal();
                    console.log('file', file);
                }}
                disabled={props.disabled}
            >
                {'Onaya Gönder'}
            </Button>

            <Modal
                show={showModal}
                onHide={toggleShowModal}
                centered
                contentClassName="modal-shadow-border"
            >
                <Modal.Header closeButton className="bg-secondary">
                    <h4 className="modal-title text-white">
                        {t('APPROVE_TEMPLATES.CREATE_MODAL.TITLE')}
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <label htmlFor="">{t('Onay Sürecini İsimlendir')}</label>
                            <FormInput
                                key={'approval-process-title-' + file?._id}
                                className="form-control mb-2"
                                onInput={(e: any) => setApprovalProcessTitle(e.target.value)}
                                name="title"
                                type="text"
                                value={approvalProcessTitle}
                                rows={5}
                            />
                            <Button
                                disabled={props?.files?.creatingDocumentFromTemplate}
                                onClick={(() => {
                                    console.log('first', file?._id)
                                    props.createApproveProcess({
                                        title: approvalProcessTitle,
                                        originalFile: file?._id
                                    }).then((response: any) => {
                                        console.log('response', response);
                                        toggleShowModal();
                                    })
                                })}>
                                Kaydet

                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default connect(mapStateToProps, mapActionsToProps)(ApprovalButton);
