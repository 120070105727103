import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Dropdown, Form } from "react-bootstrap";
import FormInput from "@components/FormInput";
import { Tags } from "@pages/recep/components";
import { toast } from "react-toastify";
import FeatherIcons from "feather-icons-react";
import { addTagToContract, removeTagToContract } from "@redux/contract/index.actions"
import { useTranslation } from "react-i18next";
const tags: string[] = ["React", "Bootstrap", "Scss"];

const mapStateToProps = (state: any) => ({
  companies: state.companies,
  contracts: state.contracts
});

const mapActionsToProps = { addTagToContract, removeTagToContract };

const ContractTags: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();
  const tags = props?.contracts?.contractOverview?.tags;

  const [inputData, setInputData] = useState({
    tag: ""
  })

  const [showForm, setShowForm] = useState(false)

  const handleShowForm = () => {
    setShowForm(!showForm)
  }

  const removeTag = (data: any) => {
    const requestData: {
      contractId: string
      companyId: string
      tag: string
    } = {
      contractId: props?.contracts?.contractOverview?._id,
      companyId: props?.companies?.currentCompany,
      tag: data
    }

    props?.removeTagToContract(requestData);
  }
  const handleSubmit = (event: any) => {
    event.preventDefault();




    const requestData: {
      contractId: string
      companyId: string
      tag: string
    } = {
      contractId: props?.contracts?.contractOverview?._id,
      companyId: props?.companies?.currentCompany,
      tag: inputData?.tag
    }

    props?.addTagToContract(requestData)
      .then((res: any) => {
        if (res != false) {
          setInputData({ ...inputData, tag: "" })
        }
      })

  }
  const handleInput = (event: any) => {
    setInputData({ ...inputData, [event?.target?.name]: event?.target?.value })
  }

  const colors = ["bg-primary text-white", "bg-secondary text-white", "bg-success text-white", "bg-danger text-white", "bg-warning text-white", "bg-info text-white", "bg-dark text-white"];


  return (
    <>

      <div>
        <div className={"d-flex"}>
          <div className={"m-0 p-0 d-flex flex-row gap-1 "}>

            <div className="d-flex gap-1 flex-wrap">
              {tags?.length ? (
                tags?.map((t: any, index: number) => (
                  <span
                    role="button"
                    key={t}

                    style={{
                      cursor: "pointer", height: '30px', padding: '6px 15px', borderRadius: '3px', fontSize: '12px', fontWeight: 400, borderColor: 'transparent', textShadow: '0 1px 0 rgba(0,0,0,.2)'
                    }}
                    className={"d-flex align-items-center justify-content-between " + colors[index % colors.length]}>
                    <span>{t}</span>
                    <FeatherIcons
                      icon="x"
                      size="14"
                      className="ms-1"
                      onClick={() => removeTag(t)}
                    />
                  </span>
                ))


              ) : (
                <div className="d-flex align-items-center justify-content-start" style={{
                  minHeight: '50px',
                  width: '100%',
                  color: '#ccc'
                }}>
                  {t("CONTRACTS.OVERVIEW.TAGS.NO_TAGS")}
                </div>
              )}
              <Dropdown as={'div'} autoClose={'outside'} >
                <Dropdown.Toggle className="user" style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  padding: "0",
                  backgroundColor: "#fff",
                  color: "#495057",
                  border: "1px dashed #495057",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                  <FeatherIcons className="p-0 m-0" icon="tag" size="20" />
                </Dropdown.Toggle>

                <Dropdown.Menu as={'div'} style={{ width: '300px', marginTop: '5px', padding: '10px', height: '400px', overflowY: 'auto' }}>

                  <form onSubmit={handleSubmit} className="d-flex justify-content-between align-items-center w-100">
                    <FormInput
                      defaultValue={inputData?.tag}
                      value={inputData?.tag}
                      containerClass="w-100"
                      placeholder={t("CONTRACTS.OVERVIEW.TAGS.ADD_NEW_TAG_PLACEHOLDER")}
                      className="form-control btn-sm form-control-sm w-100"
                      name="tag"
                      onInput={handleInput}
                      type="text"
                    />
                    <Form.Group className="d-flex justify-content-end">
                      <Button type="submit"
                        className="btn btn-sm btn-link text-danger outline-0 border-0"
                      >
                        <i className="icon">
                          <span>
                            <FeatherIcons icon="plus"></FeatherIcons>
                          </span>
                        </i>
                      </Button>
                    </Form.Group>
                  </form>
                  <div>
                    {
                      tags?.map((tag: any, index: number) => {
                        return (
                          <div key={index} className="d-flex justify-content-between align-items-center w-100">
                            <span className="text-truncate">{tag}</span>
                            <Button
                              variant="link"
                              onClick={() => removeTag(tag)}
                            >
                              <FeatherIcons icon="trash-2" size="16"></FeatherIcons>
                            </Button>
                          </div>
                        )
                      })
                    }
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>


          </div>

        </div>


      </div>
    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ContractTags);
