import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSinglePipeline, editContractDetails } from "@redux/contract/index.actions"
import { toast } from "react-toastify"
import Select from 'react-select';
import StatusItem from "@pages/contracts/Overview/contract/Pipes/StatusItem"
import _ from "lodash";
const mapStateToProps = (state: any) => ({
  contracts: state.contracts
});

const mapActionsToProps = { getSinglePipeline, editContractDetails };

const Pipes = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState(
    {
      value: "",
      label: ""
    });
  const [pipeline, setPipeline] = useState({
    statuses: []
  });

  const handleChange = (e: any) => {
    if (props?.status?._id === props?.contracts?.contractOverview?.status) {
      return;
    }

    const requestData: {
      _id: string
      status?: string
    } = {
      _id: props?.contracts?.contractOverview?._id,
      status: e?.value,
    }


    props.editContractDetails(requestData);

    setDefaultValue({
      value: e?.value,
      label: e?.label
    })


  }


  return (
    <>



      {props?.contracts?.contractOverview?.status &&
        <Select

          hideSelectedOptions={false}
          isOptionSelected={(option: any, value: any) => option.value === props?.contracts?.contractOverview?.status?._id}
          className="react-select react-select-container"
          classNamePrefix="react-select"
          defaultValue={{
            value: props?.contracts?.contractOverview?.status?._id,
            label: props?.contracts?.contractOverview?.status?.title,
          }}
          value={{
            value: props?.contracts?.contractOverview?.status?._id,
            label: props?.contracts?.contractOverview?.status?.title,
          }}
          onChange={handleChange}
          options={props?.contracts?.contractOverview?.pipeline?.statuses?.map((e: any) => {
            return {
              value: e?._id,
              label: e?.title
            }
          })}
        />
      }


    </>
  );
};


export default connect(mapStateToProps, mapActionsToProps)(Pipes);
