import React, { useEffect, useState } from "react";
import FlatList from "@components/FlatList";
import { connect } from "react-redux";
import FeatherIcons from "feather-icons-react";
import Avatar from "react-avatar";
import { getContractCompanies, addCompanyToContract } from '@redux/contract/index.actions';
import { DefaultLoader } from '@components/Loader';
import _ from "lodash";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const mapStateToProps = (state: any) => ({
    users: state.users,
    companies: state.companies,
    contracts: state.contracts
})

const mapActionsToProps = { getContractCompanies, addCompanyToContract }


const CompaniesFlatList: React.FunctionComponent<any> = (props: any) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshed, setRefreshed] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [batchSize, setBatchSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [more, setMore] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const loadMore = () => {
        props?.getContractCompanies({
            page,
            batchSize,
            more: {
                searchText: searchText,
                partiesSide: props?.partiesSide
            }
        }).then((res: any) => {
            ((res?.pagination?.page * batchSize) < total) ? setMore(true) : setMore(false)
            if ((res?.pagination?.page * batchSize) < total) {
                setMore(true);
                setPage(res?.pagination?.page + 1)
            } else {
                setMore(false);
            }
            setData([...data, ...res?.node?.contractCompanies])

        })

    }

    const handleSearch = (event: any) => {
        setLoading(true)
        setPage(1);
        setSearchText(event?.target?.value)
    }

    const handleAdd = (data: {
        _id: string
    }) => {
        const requestData: {
            contractId: string
            companyId: string
            addedCompany: string
        } = {
            contractId: props?.contracts?.contractOverview?._id,
            companyId: props?.companies?.currentCompany,
            addedCompany: data?._id
        }

        props?.addCompanyToContract(requestData);
    }


    const debounceSearch = _.debounce(handleSearch, 600);

    useEffect(() => {
        props?.getContractCompanies({
            page,
            batchSize,
            more: {
                searchText: searchText,
                partiesSide: props?.partiesSide
            }
        }).then((res: any) => {
            if (res?.pagination?.totalCount) setTotal(res?.pagination?.totalCount);
            if ((res?.pagination?.page * batchSize) < res?.pagination?.totalCount) {
                setMore(true);
                setPage(page + 1);
            } else {
                setMore(false)
            }
            setData([...res?.node?.contractCompanies])
            setLoading(false);
        })
    }, [searchText])

    return (
        <>
            <input className="my-2 mt-1 form-control form-control-sm" placeholder="Search a company" type="text" name="searchText" defaultValue={searchText} onInput={debounceSearch} />
            <div className="flat-scroll" id={"contractCompaniesFlatList" + (props?.partiesSide || 'noside')}>
                {
                    (loading) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {
                    (!refreshed) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {refreshed && <FlatList
                    scrollableParentTarget={"contractCompaniesFlatList" + (props?.partiesSide || 'noside')}
                    data={data.filter((item: any) => {

                        return !props?.contracts.contractOverview.contractCompanies?.includes(item._id)

                    })}
                    renderItem={(item, index) => (
                        <div key={item?._id}
                            className="d-flex justify-content-between align-items-center gap-1 flex-wrap p-1">
                            <span className="d-flex justify-content-center align-items-center gap-1">
                                <span>
                                    <Avatar name={item?.title}
                                        className="rounded-circle"
                                        size="25"
                                    />
                                </span>
                                <span className="text-dark text-nowrap">
                                    {item?.title}
                                </span>
                            </span>
                            <Button
                                onClick={event => handleAdd(item)}
                                className="btn btn-sm btn-link text-danger outline-0 border-0"
                            >
                                <i className="icon">
                                    <span>
                                        <FeatherIcons icon="plus-square"></FeatherIcons>
                                    </span>
                                </i>
                            </Button>
                        </div>
                    )}
                    hasMore={more}
                    endMessage={
                        <div className="d-flex align-items-center justify-content-center no-more">
                            {t("CONTRACTS.OVERVIEW.COMPANIES.NO_MORE")}
                        </div>
                    }
                    onEndReached={() => {
                        loadMore()
                    }}
                    onEndReachedThreshold={0.5}
                    keyExtractor={(item, index) => index.toString()}
                />
                }

            </div>
        </>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(CompaniesFlatList);
