import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toggleApprovalTemplateModal } from "@redux/components/index.actions";
import { addUserIntoStep, addStep, removeRecipient, deleteStep, updateStep, updateStepUser, updateApproveTemplate, deleteApprovalTemplate } from "@redux/approve/index.actions";

import AddContract from "@pages/contracts/AddContract";
import { useTranslation } from "react-i18next";

import './index.scss'
import FeatherIcons from "feather-icons-react";
import apiInstance from "@clients/api.client";
import { toast } from "react-toastify";
import { FiSave } from "react-icons/fi";
import { MdDataSaverOff } from "react-icons/md";
import { Col, Row } from "react-bootstrap";


const MapStateToProps = (state: any) => ({
  components: state.components,
  approve: state.approve,
  companies: state.companies,
});

const MapActionsToProps = {
  toggleApprovalTemplateModal: toggleApprovalTemplateModal,
  addUserIntoStep: addUserIntoStep,
  addStep: addStep,
  removeRecipient: removeRecipient,
  deleteStep: deleteStep,
  updateStep: updateStep,
  updateStepUser: updateStepUser,
  updateApproveTemplate: updateApproveTemplate,
  deleteApprovalTemplate: deleteApprovalTemplate
};

const ApprovalModal: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();



  const listRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)



  const [suggestions, setSuggestions] = useState<any>({})

  const [emailValues, setEmailValues] = useState<any>({})


  if (listRef && listRef.current && headerRef && headerRef.current) {
    listRef.current.addEventListener('scroll', (e: any) => {
      if (e.target.scrollTop === 0) {
        headerRef.current?.classList.remove('shadow')
      } else {
        headerRef.current?.classList.add('shadow')
      }

    })
  }


  const handleDeleteStep = (step: any) => {
    const id = step._id;
    if (!id) {
      toast.error(t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.STEP_NOT_FOUND'))
      return;
    }

    props?.deleteStep({ id });
  }


  const handleAddUser = (data: any) => {

    if (!data?.email) {
      return;
    }
    //email validation with regex
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(data?.email)) {
      return toast.error(t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.EMAIL_VALIDATION_ERROR'))
    }

    console.log('data?.step', data?.step)
    props?.addUserIntoStep({
      step: data?.step,
      email: data?.email
    }).then((res: any) => {
      console.log('BURADA', res)
      setEmailValues({})
      setSuggestions({})
    })
  }

  const handleRemoveRecipient = (stepUserItem: any, stepItem: any) => {
    props?.removeRecipient({
      recipientId: stepUserItem._id,
      stepId: stepItem._id,
    })

  }
  const changeApproveAll = (result: any, stepItem: any) => {
    console.log('RESULT', result, stepItem?._id)

    props?.updateStep({
      id: stepItem?._id,
      fieldName: 'aproveAll',
      fieldValue: result === 'all' ? true : false
    }
    )
  }
  const changeStepUserConfig = (result: any, stepItem: any, stepUserItem: any, fieldName: any) => {
    console.log('RESULT', result, stepItem?._id)

    props?.updateStepUser({
      stepId: stepItem?._id,
      stepUserId: stepUserItem?._id,
      fieldName: fieldName,
      fieldValue: result
    })
  }
  const handleUpdateApproveTemplate = (fieldName: any, fieldValue: any) => {

    if (!fieldName || !fieldValue) {
      return;
    }

    props?.updateApproveTemplate({
      fieldName: fieldName,
      fieldValue: fieldValue
    })
  }

  const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);

  const toggleDeleteTemplate = () => {
    setDeleteTemplateModal((prevstate) => !prevstate);
  }

  const handleConfirmDelete = () => {
    if (!props?.approve?.selectedApproveTemplate?._id) {
      toast.error(t('APPROVE_TEMPLATES.REACH_ERROR'));
      return false;
    }
    setDeleteTemplateModal(false);
    props?.deleteApprovalTemplate({ deleteId: props?.approve?.selectedApproveTemplate?._id })
    props.toggleApprovalTemplateModal();

  }

  return (
    <>
      <Modal
        show={props?.components?.approvalTemplateModal}
        onHide={props.toggleApprovalTemplateModal}
        keyboard={false}
        backdrop={true}
        centered
        size={'xl'}
        contentClassName="shadow">
        <Modal.Header className="bg-secondary d-flex align-items-center justify-content-between">
          <Modal.Title className="text-white">{t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.TITLE')}</Modal.Title>
          <div className=" d-flex align-items-center justify-content-end">
            <Button
              style={{
                color: '#f5f5f9'
              }}
              variant="link" className="d-flex align-items-center justify-content-between gap-1">
              <span className="d-flex align-items-center justify-content-center">{props?.approve?.saving ? (<div className="rotate"><MdDataSaverOff size={16} /></div>) : <FiSave size={16} />}</span>
              <span className="d-flex align-items-center justify-content-center"
                style={{
                  fontSize: '14px',
                  lineHeight: '14px'
                }}
              >{props?.approve?.saving ? t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.SAVE') : t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.SAVED')}</span>
            </Button>
            <Button
              onClick={() => {
                props?.toggleApprovalTemplateModal()
              }}
              className="text-white"
              variant="link"
            >
              <FeatherIcons icon="x" />
            </Button>

          </div>
        </Modal.Header>
        <Modal.Body className="p-0">

          <div ref={listRef} className="aprrove">

            <div ref={headerRef} className="approve__header">
              <div className="approve__header__title">
                <input type="text" placeholder="Onay Akış Başlığı"
                  onChange={(e) => console.log(e.target.value)}
                  onBlur={(e) => handleUpdateApproveTemplate('title', e.target.value)}
                  defaultValue={props?.approve?.selectedApproveTemplate?.title}
                />
              </div>
              <div className="approve__header__actions">

                <Button

                  onClick={() => {
                    toggleDeleteTemplate();
                  }
                  }
                  variant="link" className="approve__header__actions__button d-flex align-items-center justify-content-between gap-1 px-0"
                  style={{
                    color: 'red'
                  }}

                > <FeatherIcons size={14} icon="trash" /> {t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.DELETE')}</Button>

              </div>
            </div>
            <div className="steps">

              {(props?.approve?.selectedApproveTemplate?.steps || []).map((stepItem: any, index: number) => {
                console.log('stepItem', stepItem)
                return (
                  <div key={'step__' + index} className="step">
                    <div className="step-number">
                      <h4>{index + 1}. {t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.STEP')}</h4>
                      <button
                        style={{
                          color: 'red'
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeleteStep(stepItem)
                        }}
                        className="btn btn-link btn-sm px-0">{t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.DELETE')}</button>
                    </div>
                    <div className="recipients">

                      {(stepItem?.stepUsers || []).map((stepUserItem: any, recipientIndex: number) => {
                        return (
                          <div key={'recipient' + stepUserItem?._id} className="recipient">
                            <div className="recipient-group">
                              <div className="recipient-label">{stepUserItem?.email}</div>
                              <div className="recipient-settings">
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio1"
                                    onChange={(e) => changeStepUserConfig(e.target.checked, stepItem, stepUserItem, 'canChangeRequest')}
                                    checked={stepUserItem?.canChangeRequest} />
                                  <label className="form-check-label" htmlFor="inlineRadio1">{t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.CAN_CHANGE_REQUEST')}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    onChange={(e) => changeStepUserConfig(e.target.checked, stepItem, stepUserItem, 'canAssign')}
                                    className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio2" checked={stepUserItem?.canAssign} />
                                  <label className="form-check-label" htmlFor="inlineRadio2">{t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.CAN_ASSIGN')}</label>
                                </div>
                                <button className="btn btn-link text-dark py-0 px-2 m-0" onClick={e => handleRemoveRecipient(stepUserItem, stepItem)}>
                                  <FeatherIcons icon="x" />
                                </button>
                              </div>

                            </div>
                          </div>
                        )
                      })}
                      <div className="recipients-new">
                        <input
                          onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              handleAddUser({
                                step: { ...stepItem, index },
                                email: emailValues[index]
                              })
                            }
                          }}
                          key={'recipient__input_email' + index}
                          value={emailValues[index] || ''}
                          onChange={(e) => {
                            setEmailValues({
                              ...emailValues,
                              [index]: e.target.value
                            })
                          }}
                          onInput={(e: any) => {

                            if (e.target.value.length > 2) {
                              apiInstance.get(`/companies/${props?.companies?.currentCompany}/users`, {
                                params: {
                                  page: 1,
                                  batchSize: 10,
                                  searchText: e?.target?.value
                                }
                              }).then((res: any) => {
                                console.log(res?.data?.node?.users)
                                setSuggestions({
                                  ...suggestions,
                                  [index]: res?.data?.node?.users
                                })
                              })
                            } else {
                              setSuggestions({
                                ...suggestions,
                                [index]: []
                              })
                            }
                          }}
                          type="email" className="form-control"
                          placeholder={t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.RECIPIENT_EMAIL')} />


                        <Button variant="primary" size="sm" className="add-recipient-button"
                          onClick={() => handleAddUser({
                            step: { ...stepItem, index },
                            email: emailValues[index]
                          })}
                        >
                          <FeatherIcons icon="plus" />
                        </Button>

                      </div>
                      <div className="recipient-suggestions">
                        {suggestions[index]?.map((item: any) => {
                          return (
                            <div className="recipient-suggestions-item"
                              onClick={(e: any) => {
                                handleAddUser({
                                  step: { ...stepItem, index },
                                  email: e?.target?.innerText
                                })
                              }}
                            >
                              <span>{item?.email}</span>
                            </div>
                          )
                        })}
                      </div>

                    </div>

                    <div className="step-settings">
                      <span>
                        {t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.APPROVE_FROM')}:
                      </span>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            value={'all'}
                            onChange={(e) => changeApproveAll(e.target.value, stepItem)}
                            className="form-check-input" type="radio" id={'inlineRadio1' + index} key={'key_inline_radio_1' + index} checked={stepItem?.aproveAll === true ? true : false} />
                          <label className="form-check-label" htmlFor={'inlineRadio1' + index}>{t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.APPROVE_FROM_ALL')}</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            value={'one'}
                            onChange={(e) => changeApproveAll(e.target.value, stepItem)}
                            className="form-check-input" type="radio" id={'inlineRadio2' + index} key={'key_inline_radio_2' + index} checked={stepItem?.aproveAll !== true ? true : false} />
                          <label className="form-check-label" htmlFor={'inlineRadio2' + index}>{t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.APPROVE_FROM_ONE')}</label>
                        </div>
                      </div>
                    </div>

                  </div>
                )
              })}

              <div className="d-flex align-items-center justify-content-start">
                <Button
                  onClick={() => {
                    props?.addStep()
                  }}
                  variant="outline-primary" className="add-step-button">
                  <FeatherIcons icon="plus" />
                  {t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.ADD_STEP')}
                </Button>
              </div>
            </div>

          </div>

        </Modal.Body>
      </Modal>

      <Modal
        show={deleteTemplateModal}
        onHide={toggleDeleteTemplate}
        centered
        contentClassName="modal-shadow-border"
      >
        <Modal.Header closeButton className="bg-secondary">
          <h4 className="modal-title text-white">
            {t('APPROVE_TEMPLATES.DELETE_MODAL.TITLE')}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <p>

              {t('APPROVE_TEMPLATES.DELETE_MODAL.DESCRIPTION')}

            </p>
            <Col md={12} className="d-flex justify-content-end gap-1">
              <Button
                className="btn btn-sm"
                onClick={toggleDeleteTemplate}
              >
                {t('APPROVE_TEMPLATES.DELETE_MODAL.CANCEL_BUTTON')}
              </Button>
              <Button
                className="btn btn-sm btn-danger"
                onClick={handleConfirmDelete}>
                {t('APPROVE_TEMPLATES.DELETE_MODAL.DELETE_BUTTON')}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(ApprovalModal);
