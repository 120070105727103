/* eslint-disable */
export default {
    LANGUAGE_SHORT_CODE: 'tr',
    CREATE_BUTTON: 'Oluştur',
    LEFT_MENU: {
        APPROVE_TEMPLATES: 'Onay Şablonları',
        REPORTS: "Raporlar",
        PIPELINE: "Pipeline",
        FILES: "Dokümanlar",
        CONTRACTS: "Kayıtlar",
        ASSETS: "Varlıklar",
        STATUSES: "Aşamalar",
        PIPELINES: "Pipeline'lar",
        CUSTOM_FIELDS: "Özel alanlar",
        CUSTOM_FORMS: "Özel Formlar",
        CONTRACT_TYPES: "Kayıt Tipleri",
        CONTRACT_COMPANIES: "Taraflar",
        CONTRACT_CONTACTS: "Taraf Kişiler",
        MY_ACCOUNT: "Hesabım",
        INBOX: "Gelen Kutusu",
        TEMPLATES: "Şablonlar",
        RECORDS: "Kayıtlar",
        TABLE: "Tablo",
        ADMIN: "Yönetim",
        COMPANY_SETTINGS: "Şirket Ayarları",
        USERS: "Kullanıcılar",
        USER_INVITES: "Davetli Kullanıcılar",
        ROLES: "Roller",
        COMPANY_INTEGRATIONS: "Şirket Entegrasyonları",
        APPROVAL_MANAGEMENT: "Onay Yönetimi",
        MY_ACCOUNT_SETTINGS: "Hesap Ayarları",
        MY_ACCOUNT_INTEGRATIONS: "Entegrasyonlarım",
    },
    API_TABLE: {
        SEARCH_FOR_KEYWORDS: "Anahtar Kelime Ara",
        SEARCH: "Ara",
        CLEAR: "Temizle",
        DISPLAY: "Göster:",
        ENTRIES: "Girdi Sayısı",
        TOTAL: "Toplam:",
        NO_DATA_FOUND: "Sistemde veri bulunamadi"
    },
    API_TABLE_CUSTOM_COMPONENT: {
        SEARCH_FOR_KEYWORDS: "Anahtar Kelime Ara",
        SEARCH: "Ara",
        CLEAR: "Temizle",
        EXPORT: "Dışa Aktar",
        EXPORT_TO_EXCEL: "Excel ile Dışa Aktar",
        PLEASE_WAIT: "Lutfen bekleyin...",
        DISPLAY: "Görüntüle:",
        ENTRIES: "Girdi Sayısı",
        TOTAL: "Toplam:",
        NO_DATA_FOUND: "Sistemde veri bulunamadi"
    },
    ACTIONS_DROPDOWN_MENU: {
        GENERAL: "Genel",
        CONTRACT: "Kayıt",
        FILE: "Doküman",
        USER: "Kullanıcı",
        ASSET: "Varlık",
        PIPELINE: "Süreç",
        STATUS: "Aşama",
        CONTRACT_TYPE: "Kayıt Tipi",
        CUSTOM_FIELD: "Özel Alan",
        TYPE: "Kayıt Tipleri",
        COMPANY: "Taraflar",
        CONTACT: "Taraf Kişi",
        CREATE: "Oluştur",
        NEW_RECORD: "Yeni Kayıt",
        NEW_TEMPLATE: "Yeni Şablon",
        NEW_FORM: "Yeni Form",
        UPLOAD: "Yükle",
        UPLOAD_DOCUMENT: "Doküman Yükle",
    },
    PROFILE_DROPDOWN_MENU: {
        WELCOME: "Hoşgeldin !",
        PROFILE: "Profil",
        MY_ACCOUNT: "Hesabım",
        MY_INTEGRATIONS: "Entegrasyonlarım",
        CREATE_COMPANY: "Şirket Hesabı Oluştur",
        COMPANY_SWITCH: "Başka Şirkete Geçiş Yap",
        COMPANY: "Şirket",
        COMPANY_SETTINGS: "Şirket Ayarları",
        COMPANY_USERS: "Şirket Kullanıcıları",
        COMPANY_INVITES: "Davetli Kullanıcılar",
        COMPANY_ROLES: "Şirket Rolleri",
        COMPANY_INTEGRATIONS: "Şirket Entegrasyonları",
        COMPANY_PIPELINES: "Süreçler",
        COMPANY_STATUSES: "Aşamalar",
        LOG_OUT: "Çıkış Yap",
        LOCK_SCREEN: "Ekranı Kilitle",
        LOGOUT: "Çıkış Yap",
        CUSTOM_FIELDS: "Özel Alanlar",
        RECORD_TYPES: "Kayıt Tipleri",
    },
    LANGUAGE: {
        ENGLISH: "English",
        TURKISH: "Türkçe"
    },
    APPS: {
        DRIVE_IMPORT: "Drive İçe Aktarma",
        DRIVE_EXPORT: "Drive Dışa Aktarma",
        GMAIL_IMPORT: "Gmail İçe Aktarma",
        ONEDRIVE_IMPORT: "OneDrive İçe Aktarma",
        ONEDRIVE_EXPORT: "OneDrive Dışa Aktarma",
        SHAREPOINT_IMPORT: "SharePoint İçe Aktarma",
        SHAREPOINT_EXPORT: "SharePoint Dışa Aktarma",
    },
    COMPANIES: {
        DETAILS: {
            TITLE: "Şirket Ayarları",
            DESCRIPTION: "Mevcut şirketin detayları: şirketteki detayları okuyabilir, düzenleyebilir, silebilirsiniz",
            COMPANY_TITLE_LABEL: "Şirket Adı:",
            COMPANY_TITLE_PLACEHOLDER: "Şirket Adı",
            COMPANY_DESCRIPTION_LABEL: "Şirket Açıklaması:",
            COMPANY_DESCRIPTION_PLACEHOLDER: "Şirket Açıklaması",
            COMPANY_DOMAIN_LABEL: "Şirket Domaini:",
            COMPANY_DOMAIN_PLACEHOLDER: "Şirket Domain",
            COMPANY_LOGO_URL_LABEL: "Logo Url (300x300):",
            COMPANY_LOGO_URL_PLACEHOLDER: "https://website.com/logo.png",
            SAVE_CHANGES: "Değişiklikleri Kaydet",
            DELETE_COMPANY_LABEL: "ŞİRKETİ SİL",
            DELETE_COMPANY_DESCRIPTION: "Firmanızı Jetlexa'dan silebilirsiniz. Sil komutundan sonra firma bilgileriniz 30 gün süreyle saklanacaktır. Bilgilerinizi geri yüklemek için sildikten sonra destek kanalı üzerinden bize ulaşabilirsiniz.",
            DELETE_COMPANY_CHECK_DESCRIPTION: "Şirketinizi silmek için giriş kutusuna 'permanent-delete' yazabilirsiniz",
            DELETE_CHECK_LABEL: "Silmeyi Onayla:",
            DELETE_CHECK_PLACEHOLDER: "permanent-delete",
            DELETE_CHECK_INVALID: "Lütfen giriş kutusuna 'permanent-delete' kelimesini girin",
            DELETE_COMPANY_BUTTON: "Şirketi Sil",
            CDN_ENDPOINT_TITLE: "Custom CDN Uç Noktası",
            CDN_ENDPOINT_DESCRIPTION: "Şirket dokümanlarınızı depolamak için kendi CDN uç noktanızı kullanabilirsiniz. Herhangi bir CDN uç noktası sağlarsanız, şirket dokümanlarınızı depolamak için CDN uç noktamızı kullanırız.",
            CDN_ENDPOINT: "CDN Uç Noktası",
            CDN_ENDPOINT_PLACEHOLDER: "https://cdn-jetlexa.example.com",
            CDN_ENDPOINT_LOCKED: "Şirketiniz için CDN uç noktası ayarlanmıştır. Bu ayarı değiştirmek için lütfen destek ile iletişime geçin.",
            SAVE: "Değişiklikleri Kaydet",
            DEMO_DATA_DELETION_TITLE: "Demo Verilerini Sil",
            DEMO_DATA_DELETION_DESCRIPTION: "Şirketiniz için oluşturulan tüm demo kayıtları silinecektir. Bu işlem geri alınamaz.",
            CONTRACTS: "Kayıtlar",
            PIPELINES: "Süreçler",
            STATUSES: "Aşamalar",
            CUSTOM_FIELDS: "Özel Alanlar",
            RECORD_TYPES: "Kayıt Tipleri",
            PARTIES: "Taraflar",
            DELETE: "Sil",
            DELETE_ALL: "Tümünü Sil",
        },
        USERS: {
            TITLE: "ŞİRKET KULLANICILARI",
            DESCRIPTION: "Mevcut şirketin kullanıcıları: şirketteki kullanıcıları arayabilir, okuyabilir, düzenleyebilir, silebilirsiniz.",
            INVITE_USER: "Kullanıcı Davet Et",
            TABLE: {
                ID: "ID",
                NAME: "Ad",
                SURNAME: "Soyadı",
                EMAIL: "E-posta",
                PHONE_NUMBER: "Telefon Numarası",
                USERNAME: "Kullanıcı Adı",
                CREATED_DATE: "Oluşturma Tarihi"
            }
        },
        INVITES: {
            TITLE: "DAVETLİ KULLANICILAR",
            DESCRIPTION: "Mevcut şirketin davetiyeleri: Şirkete davet edilen kullanıcıları arayabilir, okuyabilir, düzenleyebilir, silebilirsiniz.",
            INVITE_USER: "Kullanıcı Davet Et",
            TABLE: {
                ID: "ID",
                EMAIL: "E-posta",
                STATUS: "Aşama",
                CREATED_DATE: "Oluşturma Tarihi"
            }
        },
        ROLES: {
            TITLE: "ŞİRKET ROLLERİ",
            DESCRIPTION: "Mevcut şirketin rolleri: şirketteki rolleri arayabilir, okuyabilir, düzenleyebilir, silebilirsiniz.",
            CREATE_ROLE: "Rol Oluştur",
            TABLE: {
                ID: "ID",
                TITLE: "Ad",
                NOMINATIONS: "İzinler",
                CREATED_DATE: "Oluşturma Tarihi"
            },
            DEFAULT_ROLES: {
                OWNER: "Şirket Sahibi",
                SUPER_ADMIN: "Süper Yönetici",
                ADMIN: "Yönetici",
                USER: "Kullanıcı",
            },
            NOMINATIONS: {
                "edit-company-details": "Şirket Ayarları: Detaylar",
                "edit-company-users": "Şirket Ayarları: Kullanıcılar",
                "edit-company-users-invites": "Şirket Ayarları: Kullanıcı Detayları",
                "edit-company-roles": "Şirket Ayarları: Roller",
                "edit-company-integrations": "Şirket Ayarları: Entegrasyonlar",
                "files-management": "Doküman Yönetimi",
                "contracts-management": "Kayıt Yönetimi",
                "contracts-deletion": "Kayıt Silme",
                "approve-management": "Onay Yönetimi",
                "approve-file-continue-flow": "Onay Yönetimi - Akışa Kaldığı Yerde Devam Edebilme",
                "remove-integration": "Entegrasyon Silme",
            }
        }
    },
    COMPANIES_INTEGRATIONS: {
        TITLE: "ŞİRKET ENTEGRASYONLARI",
        DESCRIPTION: "Mevcut Firmanın Entegrasyonları: Firma içi entegrasyonlar için okuyabilir, silebilirsiniz.",
        GDRIVE_INTEGRATION_TITLE: "Google Drive ile Senkronizasyon",
        ONEDRIVE_INTEGRATION_TITLE: "OneDrive ile Senkronizasyon",
        SHAREPOINT_INTEGRATION_TITLE: "SharePoint ile Senkronizasyon",
        SHAREPOINT_SITES_SELECTION: "SharePoint Sites Seçimi",
        GDRIVE_INTEGRATION_DESCRIPTION: "Dokümanları Google Drive'a aktarabilmek için Google Drive entegrasyonunu kurabilirsiniz.",
        ONEDRIVE_INTEGRATION_DESCRIPTION: "Dokümanları OneDrive'a aktarabilmek için OneDrive entegrasyonunu kurabilirsiniz.",
        SHAREPOINT_INTEGRATION_DESCRIPTION: "Dokümanları SharePoint'e aktarabilmek için SharePoint entegrasyonunu kurabilirsiniz.",
        CONNECT: "Bağlan",
        CONNECTED: "Bağlandı",
        REMOVE_CONNECTION: "Bağlantıyı Kaldır",
        ACTIVE_INTEGRATION: "aktif hesap(lar)",
        CONNTECTED_ACCOUNT: "Bağlı Hesap",
        ACTIVE: "Etkin",
        SHARE_INFO: "Ekibinizle belge paylaşmak istiyorsanız ortak bir depolama konumu seçin.",
        CONNECTED_ACCOUNT_SHARE_INFO: "Dokümanlarınızın ekibinizle paylaşılabilmesi için, ortak bir depolama hesabı seçtiğinizden emin olun.",
    },
    CONTRACTS: {
        PAGE_TITLE: "Kayıt Detayı",
        CONTRACT: {
            TITLE: "Kayıt:",
        },
        REPORTS: {
            ADD: "Ekle",
            NEW: "Yeni",
            FILES: "Dokümanlar",
            DASHBOARD: "Panel",
            TEMPLATES: "Şablonlar",
            CUSTOM_FORMS: "Özel Formlar",
            CONTRACTS: "Kayıtlar",
            STATUSES: "Aşamalar",
            CREATE_COMPANY: "Taraf Oluştur",
            CREATE_CONTRACT: "Kayıt Ekle",
            ADD_USER_TO_COMPANY: "Yeni Kullanıcı Ekle",
            CONTRACT_TYPES: "Kayıt Tipleri",
            MONTHLY_REPORT: "Aylık Kayıtlar",
            TIMELINE: "Zaman Çizelgesi",
            NO_DATA_TIMELINE: "Zaman Çizelgesi için veri bulunamadı.",
        },
        REPORTS_TABLE: {
            TITLE: "Başlık",
            START_DATE: "Başlangıç Tarihi",
            END_DATE: "Bitiş Tarihi",
            RENEWAL_DATE: "Yenileme/Uyarı Tarihi",
            TYPE: "Tip",
            PIPELINE: "Süreç",
            CREATED_AT: "Oluşturan",
        },
        TABLE: {
            TOP_TITLE: "Kayıtlar",
            PAGE_TITLE: "KAYITLAR",
            PAGE_DESCRIPTION: "Kayıt listelendi.",
            ADD_NEW: "Yeni Ekle",
            TITLE: "Başlık",
            START_DATE: "Başlangıç Tarihi",
            END_DATE: "Bitiş Tarihi",
            RENEWAL_DATE: "Yenileme/Uyarı Tarihi",
            EFFECTIVE_DATE: "Yürürlük Tarihi",
            SIGNED_DATE: "İmza Tarihi",
            COMPANIES: "Taraflar",
            COMPANIES_INTERNAL: "Şirket Tarafı (Dahili)",
            COMPANIES_EXTERNAL: "Karşı Taraf",
            CONTACTS: "Taraf Kişiler",
            ASSIGNED_USERS: "Tanımlı Kullanıcılar",
            PIPELINE: "Süreç",
            STATUS: "Aşama",
            TYPE: "Tip",
            CREATED_AT: "Oluşturma Tarihi",
            DELETE_ALL: "Tümünü Sil",
            DELETE_SELECTED: "Seçilenleri Sil",
            SEARCH: {
                PLACEHOLDER: 'Kayıt Ara...',
                TITLE: 'Başlık',
                DESCRIPTION: 'Açıklama',
                CUSTOM_FIELDS: 'Özel Alanlar'
            },
            FILTERS: {
                FILTERS: 'Filtreler',
                ADD_FILTERS: 'Filtre Ekle',
                RESET_FILTERS: 'Filtreyi Sıfırla',
                WHERE: 'NEREDE',
                AND: 'VE',
                OR: 'VEYA',
                CONTAINS: 'İçeren',
                NOT_CONTAINS: 'İçermeyen',
                DATE_RANGE: 'Tarih Aralığı',
                SELECTION: 'Seçim',
                SELECTION_IN: 'İse',
                SELECTION_NOT_IN: 'Değilse',
                FAVORITE_FILTERS: 'Favori Filtreler',
                SAVE_FILTERS: 'Filtreyi Kaydet',
                MAKE_DEFAULT_FILTERS: 'Varsayılan Yap',
                SEARCH_FILTERS: 'Filtre Ara',
                EDIT_FILTERS: 'Filtreyi Düzenle',
                FILTER_TITLE: 'Filtreler',
                DELETE_FILTERS: 'Filtreyi Sil',
                CANCEL: 'İptal',
                ARE_YOU_SURE: 'Emin misiniz?',
                ARE_YOU_SURE_DELETE_FILTERS_DESCRIPTION: 'Bu filtreyi kalıcı olarak silmek istediğinize emin misiniz?',
                DELETE: 'Sil',
                SAVE: 'Kaydet',
                DEFAULT_FILTERS: 'Varsayılan Filtre',
                NO_DEFAULT_FILTERS: 'Varsayılan Filtre Yok',
                NO_FAVORITE_FILTERS: 'Favori Filtre Yok',
                ALL_DATA_LOADED: 'Tüm veriler yüklendi',
                FILTERS_SELECTED: 'Seçili Filtreler',
                REMOVE_DEFAULT_FILTERS: 'Varsayılandan Kaldır',
                EQ: 'Eşitse',
                NEQ: 'Eşit Değilse',
                GT: 'Büyükse',
                LT: 'Küçükse',
                GTE: 'Büyük Eşitse',
                LTE: 'Küçük Eşitse',
                EMPTY: 'Boşsa',
                NOT_EMPTY: 'Boş Değilse',
                DATE_EQ: 'Tarihinde ise',
                DATE_NEQ: 'Tarihinde değilse',
                DATE_GT: 'Tarihinden sonra',
                DATE_LT: 'Tarihinden önce',
                DATE_GTE: 'Tarihinde veya sonrasında',
                DATE_LTE: 'Tarihinde veya öncesinde',
                DATE_EMPTY: 'Tarih yoksa',
                DATE_NOT_EMPTY: 'Tarih varsa',
            },
            SORT_BY: {
                SORT_BY: 'Sırala'
            },
            COLUMNS: {
                COLUMNS: 'Sütunlar'
            },
            EXPORT: {
                EXPORT: 'Dışarı Aktar',
                EXPORT_TO_EXCEL: 'Excel\'e Aktar'
            },
            FOOTER: {
                TOTAL: 'Toplam',
                PER_PAGE: 'Sayfa Başına'
            }
        },
        OVERVIEW: {
            TITLE_ERROR: "Lütfen kaydınız için bir başlık girin.",
            ACTIONS: {
                ACTIONS: "Aksiyonlar",
                EDIT_CONTRACT: "Düzenle",
                REMOVE_CONTRACT: "Sil"
            },
            INFO_CARDS: {
                START_DATE: "Başlangıç Tarihi",
                END_DATE: "Bitiş Tarihi",
                EFFECTIVE_DATE: "Yürürlük Tarihi",
                SIGNED_DATE: "İmza Tarihi",
                RENEWAL_DATE: "Yenileme/Uyarı Tarihi",
                ASSIGNED_USERS: "Tanımlı Kullanıcılar"
            },
            ACTIVITY_FIELD: {
                FILES: "Dokümanlar",
                REMINDER: "Etkinlikler"
            },
            FILES: {
                FILES_DESCRIPTION: "Dokümanlarınızı eklediğinizde etkinlik akışında etkinliklerini görebilirsiniz.",
                ADD_FILES: "Doküman Yükle",
                UPLOAD_FILES: "Doküman Yükle",
                SELECT_UPLOADED_FILES: "Yüklü Dokümanlardan Seç",
                ADDED_FILES: "Yüklenen Dokümanlar",
                SHOW_ALL_FILES: "Yüklenen Tüm Dokümanları Görüntüle",
                FILENAME: "Doküman Adı",
                UPLOADED_BY: "Yükleyen",
                ACTIONS: "Aksiyonlar",
                NO_FILES: "Bu kayıt için doküman bulunamadı",
                SAVE_CHANGES: "Değişiklikleri Kaydet",
                EDIT: "Düzenle",
                CLOSE_EDIT_MODE: "Düzenleme Modunu Kapat",
                DOWNLOAD: "İndir",
                DELETE: "Sil",
                GO_TO_PAGE: "Sayfaya Git",
                TOTAL_FILES: "Toplam Doküman:",
                MAX_PAGE: "Toplam Sayfa:",
                DELETE_FILE: "Dokümanı Sil",
                DELETE_FILE_DESCRIPTION: "Dokümanın silindiğinden emin misiniz?",
                STATUS: "Durum",
            },
            REMINDER: {
                REMINDER_DESCRIPTION: "Önemli şeyleri unutmamak için etkinlik oluşturabilirsiniz.",
                ADD_REMINDER: "Etknlik Oluştur",
                REMINDERS: "Etkinlikler",
                PROVIDE_GUEST_NAME: "Lütfen bir misafir adı girin",
                PROVIDE_GUEST_SURNAME: "Lütfen bir konuk soyadı girin",
                PROVIDE_GUEST_EMAIL: "Lütfen bir konuk e-postası girin",
                TITLE_LABEL: "Başlık",
                TITLE_PLACEHOLDER: "Etkinlik başlığı gir...",
                DATE_LABEL: "Etkinlik Tarihi",
                DESCRIPTION_LABEL: "Açıklama",
                DESCRIPTION_PLACEHOLDER: "Etkinlik açıklaması gir...",
                GUESTS_LABEL: "Misafirler",
                ADD_USER: "Kullanıcı Ekle",
                ADD_OUTSOURCE_GUESTS: "Misafir Ekle",
                CANCEL: "İptal Et",
                CREATE: "Oluştur",
                NAME_LABEL: "Ad",
                NAME_PLACEHOLDER: "Misafir adını girin...",
                SURNAME_LABEL: "Soyadı",
                SURNAME_PLACEHOLDER: "Misafirin soyadını girin...",
                EMAIL_LABEL: "Eposta",
                EMAIL_PLACEHOLDER: "Misafir epostasını girin...",
                ADD_GUEST: "Misafir Ekle",
                SEARCH_A_USER: "Kullanıcı Ara",
                NO_MORE: "Devamı Yok",
                NO_DATA_FOUND_IN_THE_REMINDERS: "Etkinliklerde veri bulunamadı",
                CREATE_FIRST_REMINDER: "İlk Hatırlatmayı Oluştur",
                NEW_REMINDER: "Yeni Etkinlik",
                NEW_STATUS: "Yeni Aşama",
                ADD_TO_CALENDAR: "Takvime Ekle",
                ADD_TO_GOOGLE_CALENDAR: "Google Takvim'e Ekle",
                ADD_TO_OUTLOOK_CALENDAR: "Outlook Takvim'e Ekler",
                ADD_TO_OFFICE_360_CALENDAR: "Office 365 Takvim'e Ekle",
                ARE_YOU_SURE: "Emin misin?",
                ARE_YOU_SURE_MODAL_DESCRIPTION: "Etkinliği kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
                REMOVE: "Kaldır"
            },
            DESCRIPTION: {
                TITLE: "Kayıt Açıklaması",
                DESCRIPTION_PLACEHOLDER: "Kayıt açıklamasını girin...",
                SAVE: "Kaydet"
            },
            TAGS: {
                TAGS: "Etiketler",
                NO_TAGS: "Etiket bulunamadı",
                ADD_TAGS_TO_CONTRACT: " Kayıt için etiket ekleyin",
                ADD_NEW_TAG_PLACEHOLDER: "Yeni bir etiket ekle...",
            },
            COMPANIES: {
                COMPANIES: "Taraflar",
                NO_COMPANY_FOUND: "Kayıt için taraf bulunamadı",
                NO_MORE: "Devamı Bulunamadı",
                ADD_COMPANY_TO_CONTRACT: " Yeni Taraf  Ekle"
            },
            CONTACTS: {
                CONTACTS: "Taraf Kişiler",
                ADD_CONTACT_TO_CONTRACT: "Taraf Kişi Ekle",
                ALL_DATA_LOADED: "Tüm veriler yüklendi",
            },
            CUSTOM_FIELDS: {
                CUSTOM_FIELDS: "Özel Alanlar",
            },
            ASSIGNED_USERS: {
                ASSIGNED_USERS: "Tanımlı Kullanıcılar",
                ASSIGN_USER_TO_CONTRACT: "Kayıt için kullanıcı tanımla",
                NO_ASSIGNED_USERS: "Tanımlı kullanıcı bulunamadı",
                ALL_DATA_LOADED: "Tüm veriler yüklendi.",
                SEARCH_PLACEHOLDER: "Bir kullanıcı ara"
            },
            CONTRACT_TYPE: "Kayıt Tipi",
        },
        ADD_CONTRACT: {
            NEW_CONTRACT: "Yeni Kayıt",
            NO_TITLE: "Lütfen bir başlık girin",
            NO_STATUS: "Lütfen bir aşama ekleyin",
            NO_TYPE: "Lütfen bir kayıt tipi ekleyin",
            PIPELINE: "Lütfen bir kayıt süreci seçin",
            NO_COMPANY: "Lütfen bir şirket ekleyin",
            TITLE_LABEL: "Başlık",
            TITLE_PLACEHOLDER: "Başlığı girin...",
            DESCRIPTION_LABEL: "Açıklama",
            DESCRIPTION_PLACEHOLDER: "Bir açıklama ekleyin...",
            START_DATE_LABEL: "Başlangıç Tarihi",
            END_DATE_LABEL: "Bitiş Tarihi",
            EFFECTIVE_DATE_LABEL: "Yürürlük Tarihi",
            RENEWAL_DATE_LABEL: "Yenileme/Uyarı Tarihi",
            SIGNED_DATE_LABEL: "İmza Tarihi",
            PIPELINE_LABEL: "Süreç",
            PIPELINE_PLACEHOLDER: "Bir süreç seç",
            STATUS_LABEL: "Aşama",
            STATUS_PLACEHOLDER: "Bir aşama belirle",
            TYPE_LABEL: "Kayıt Tipi",
            TYPE_PLACEHOLDER: "Bir kayıt tipi seç",
            CANCEL: "İptal",
            CREATE: "Oluştur",
            MAKE_PRIVATE: "Özel Hale Getir",
            MAKE_PUBLIC: "Herkese Açık Hale Getir",
            PARTIES_INTERNAL: "Şirket Tarafı (Dahili)",
            PARTIES_EXTERNAL: "Karşı Taraf",
            ASSIGNED_USERS: "Tanımlı Kullanıcılar",
        },
        EDIT_CONTRACT: {
            MODAL_TITLE: "Kayıt Düzenle",
            SOMETHING_IS_WRONG: "Bir şeyler yanlış gitti! Lütfen sayfanızı yenileyiniz!",
            NO_TITLE: "Lütfen bir başlık girin",
            NO_STATUS: "Lütfen bir aşama belirleyin",
            NO_TYPE: "Lütfen bir kayıt tipi belirleyin",
            NO_COMPANY: "Lütfen bir şirket belirleyin",
            TITLE_LABEL: "Başlık",
            TITLE_PLACEHOLDER: "Başlığı girin...",
            DESCRIPTION_LABEL: "Açıklama",
            DESCRIPTION_PLACEHOLDER: "Açıklamayı girin...",
            START_DATE_LABEL: "Başlangıç Tarihi",
            END_DATE_LABEL: "Bitiş Tarihi",
            EFFECTIVE_DATE_LABEL: "Yürürlük Tarihi",
            RENEWAL_DATE_LABEL: "Yenileme Tarihi",
            SIGNED_DATE_LABEL: "İmza Tarihi",
            PIPELINE_LABEL: "Süreç",
            PIPELINE_PLACEHOLDER: "Bir süreç belirleyin",
            STATUS_LABEL: "Aşama",
            STATUS_PLACEHOLDER: "Bir aşama seçin",
            TYPE_LABEL: "Kayıt Tipi",
            TYPE_PLACEHOLDER: "Bir kayıt tipi seçin",
            CANCEL: "İptal",
            SAVE: "Kaydet",
            MAKE_PRIVATE: "Make Private",
            MAKE_PUBLIC: "Make Public"
        },
        KANBAN: {
            PAGE_TITLE: "Kanban",
            PIPELINES: "Süreçler",
            SWITCH_PIPELINE: "Başka Sürece Geçiş Yap",
            EDIT_PIPELINE: "Bu Süreci Düzenle",
            MAKE_USER_DEFAULT: "Varsayılan Pipeline Yap",
            ADD_NEW: "Yeni Ekle",
            CONTRACT: "Kayıt",
            ALL_DATA_LOADED: "Tüm veriler yüklendi.",
            NO_CONTRACT_FOUND: "Bu aşamada kayıt bulunamadı.",
            CONTRACT_CONTACTS: "Taraf Kişiler",
            CONTRACT_COMPANIES: "Taraflar"
        }
    },
    CONTRACTS_COMPANIES: {
        PAGE_TITLE: "Taraflar",
        TITLE: "Firma Adı/Ünvan",
        TITLE_COMPANY: "Firma Adı/Ünvan",
        TITLE_CONTACT: "İsim Soyisim",
        DESCRIPTION: "Açıklama",
        CREATED_DATE: "Oluşturma Tarihi",
        ACTIONS: "Aksiyonlar",
        EDIT: "Düzenle",
        DELETE: "Sil",
        CONTRACTS_COMPANIES: "TARAFLAR",
        CONTRACTS_COMPANIES_DESCRIPTION: "taraf listelendi.",
        ADD_NEW: "Yeni Ekle",
        NEW_CONTRACT_COMPANY: "Yeni Taraf",
        NO_TITLE: "Lütfen bir başlık belirleyin",
        NO_COMPANY: "Lütfen sayfayı yenileyin!",
        TITLE_LABEL: "Firma Adı/Ünvan",
        TITLE_LABEL_COMPANY: "Firma Adı/Ünvan",
        TITLE_LABEL_CONTACT: "İsim Soyisim",
        TITLE_PLACEHOLDER: "Firma Adı/Ünvan girin...",
        TITLE_PLACEHOLDER_COMPANY: "Firma Adı/Ünvan girin...",
        TITLE_PLACEHOLDER_CONTACT: "İsim Soyisim girin...",
        DESCRIPTION_LABEL: "Açıklama",
        DESCRIPTION_PLACEHOLDER: "Açıklama ekleyin...",
        TAGS_LABEL: "Etiketler",
        TAGS_PLACEHOLDER: "Taraf etiketleri...",
        CANCEL: "İptal",
        CREATE: "Oluştur",
        SAVE: "Kaydet",
        EDIT_CONTRACT_COMPANY: "Düzenle",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        TAX_ID: "Vergi No",
        TC_ID: "T.C. Kimlik No",
        TAX_OFFICE: "Vergi Dairesi",
        COMPANY_ADDRESS: "Şirket Adresi",
        ADDRESS: "Adres",
        CONTACT_NAME: "Kontak kişisinin adı-soyadı",
        CONTACT_EMAIL: "Kontak kişisinin e-maili",
        EMAIL: "E-posta",
    },
    CONTRACTS_CONTACTS: {
        PAGE_TITLE: "Taraf Kişiler",
        FULLNAME: "Ad Soyadı",
        DESCRIPTION: "Açıklama",
        CREATED_DATE: "Oluşturma Tarihi",
        CREATED_BY: "Oluşturan",
        ACTIONS: "Aksiyonlar",
        CONTRACTS_CONTACTS: "TARAF KİŞİLER",
        CONTRACTS_CONTACTS_DESCRIPTION: "taraf kişi listelendi.",
        ADD_NEW: "Yeni Ekle",
        NEW_CONTRACT_CONTACT: "Yeni Taraf Kişisi",
        NO_NAME: "Lütfen geçerli bir ad girin",
        NO_SURNAME: "Lütfen geçerli bir soyadı girin",
        NO_COMPANY: "Lütfen sayfayı yenileyin!",
        NAME_LABEL: "Adı",
        NAME_PLACEHOLDER: "Taraf kişisinin adını girin...",
        SURNAME_LABEL: "Soyadı",
        SURNAME_PLACEHOLDER: "Taraf kişisinin soyadını girin...",
        DESCRIPTION_LABEL: "Açıklama",
        DESCRIPTION_PLACEHOLDER: "Bir açıklama veya not ekleyin...",
        DELETE: "Sil",
        TAGS_LABEL: "Etiketler",
        TAGS_PLACEHOLDER: "Etiketler oluşturun..",
        CANCEL: "İptal",
        CREATE: "Oluştur",
        SAVE: "Kaydet",
        EDIT_CONTRACT_CONTACT: "Kişiyi Düzenle",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Kişiyi kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?"
    },
    CONTRACTS_CUSTOM_FIELDS: {
        PAGE_TITLE: "Özel Alanlar",
        TITLE: "Başlık",
        TITLE_PLACEHOLDER: "Özel alan ekleyin...",
        INTERNAL_NAME: "Etiket Başlık",
        INTERNAL_NAME_PLACEHOLDER: "Özel alan için etiket belirleyin...",
        FIELD_TYPE: "Özel Alan Tipi",
        REQUIRED: "Zorunlu",
        CREATED_DATE: "Oluşturma Tarihi",
        ACTIONS: "Aksiyonlar",
        CUSTOM_FIELDS: "ÖZEL ALANLAR",
        CUSTOM_FIELDS_DESCRIPTION: "özel alan listelendi.",
        ADD_NEW: "Yeni Ekle",
        CUSTOM_FIELDS_COMPONENT_NAMES: {
            TEXT: "Metin Alanı",
            TEXTAREA: "Uzun Metin Alanı",
            NUMBER: "Numara",
            EMAIL: "Eposta",
            DATE: "Tarih",
            TIME: "Zaman",
            SINGLE_SELECTION: "Tekli Seçim",
            PHONE: "Telefon",
            CURRENCY: "Para Birimi",
            FORMULA: "Formül",
        },
        SELECT_CUSTOM_COMPONENT_FIELD: "Özel alan tipi seç",
        THIS_IS_DEFAULT: "Bu varsayılan",
        FIELD_TYPE_REQUIRED: "Alan Tipi (Gerekli)",
        FIELD_TYPE_FEEDBACK: "Lütfen bir alan tipi girin",
        FIELD_REQUIREMENT: "Alan Zorunluluğu",
        CREATE: "Oluştur",
        SAVE: "Kaydet",
        CANCEL: "İptal",
        DELETE: "Sil",
        EDIT_CUSTOM_FIELD: "Özel Alanı Düzenle",
        WARNING: "Dikkat !",
        WARNING_MESSAGE: "Tüm kayıt tipleri bu değişiklikten etkilenecektir.",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        OPTIONS: "Seçenekler",
        OPTION_TITLE: "Seçenek Başlığı",
        OPTION_VALUE: "Seçenek Değeri",
        OPTION_INTERNAL_NAME: "Seçenek Etiket Başlığı",
        ADD_OPTION: "Seçenek Ekle",
        REMOVE_OPTION: "Seçenek Kaldır",
        DELETE_OPTION: "Seçenek Sil",
    },
    CONTRACTS_PIPELINES: {
        PAGE_TITLE: "Süreçler",
        PIPELINES: "Süreçler",
        NO_TITLE: "Lütfen bir başlık belirleyin",
        NEW_PIPELINE: "Yeni Süreç",
        LOADING: "Yükleniyor...",
        STATUSES: "Aşamalar",
        TITLE: "Başlık",
        TITLE_PLACEHOLDER: "Bir başlık girin...",
        DESCRIPTION: "Açıklama",
        DESCRIPTION_PLACEHOLDER: "Bir açıklama ekleyin...",
        CANCEL: "İptal",
        CREATE: "Oluştur",
        SAVE: "Kaydet",
        EDIT: "Düzenle",
        CHECK_STATUSES: "Aşamaları Kontrol Et",
        PIPELINE_STATUSES: "Süreç Aşamaları",
        AVAILABLE_STATUSES: "Mevcut Aşamalar",
        SELECT_NEW_PIPELINE: "Yeni Süreç Seç",
        SELECT_NEW_STATUS: "Yeni Aşama Seç",
        MIGRATE_YOUR_CONTRACTS: "Kayıtlarını Taşıyın",
        EXISTING_CONTRACTS_WARNING: "kayıtlar bu kayıt durumunda mevcuttur. Silmeden önce kayıtlarınızı taşımanız gerekir.",
        MIGRATE_ALL: "Tümünü Taşı",
        PIPELINE_TITLE_LABEL: "Süreç Başlığı",
        PIPELINE_TITLE_PLACEHOLDER: "Süreç başlığı girin...",
        DRAG_AND_DROP_DESCRIPTION: "Bir süreç belirlemek için aşamaları\n sürükleyip bırakın",
        STATUS_NOT_FOUND: "Sistemde aşama bulunamadı",
        DEFAULT: "Varsayılan",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Süreci kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        ADD_PIPELINE: "Süreç Oluştur",
        KANBAN_VIEW: "Kanban Görüntüle",
        BOARD_DESCRIPTION: "Süreçteki kayıtları listeleyin",
        EDIT_PIPELINE: "Süreci Düzenle",
        DELETE_PIPELINE: "Süreci Sil",
        ARE_YOU_SURE_STATUS: "Emin misin?",
        ARE_YOU_SURE_STATUS_MODAL_DESCRIPTION: "Kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        USER_DEFAULT: "Kullanıcı Varsayılanı",
        SYSTEM_DEFAULT: "Sistem Varsayılanı",

    },
    CONTRACTS_STATUSES: {
        PAGE_TITLE: "Aşamalar",
        ADD_STATUS: "Aşama Ekle",
        NEW_STATUS: "Yeni Aşama",
        EDIT_STATUS: "Aşamayı Düzenle",
        DELETE_STATUS: "Aşamayı Sil",
        NO_TITLE: "Lütfen geçerli bir başlık girin",
        TITLE_LABEL: "Başlık",
        TITLE_PLACEHOLDER: "Aşamanın başlığını girin..",
        DESCRIPTION_LABEL: "Açıklama",
        DESCRIPTION_PLACEHOLDER: "Aşamanın açıklamasını girin...",
        CANCEL: "İptal",
        CREATE: "Oluştur",
        DELETE: "Sil",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Aşamayı kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?"
    },
    CONTRACTS_TYPES: {
        SAVED: "Kaydedildi",
        SAVING: "Kaydediliyor...",
        PAGE_TITLE: "Kayıt Tipleri",
        CONTRACT_TYPES: "KAYIT TİPLERİ",
        NO_TITLE: "Lütfen geçerli bir başlık girin",
        TITLE: "Başlık",
        CUSTOM_FIELDS: "Özel Alanlar",
        CREATED_DATE: "Oluşturma Tarihi",
        ACTIONS: "Aksiyonlar",
        CONTRACT_TYPES_DESCRIPTION: "Kayıt tipi listelendi.",
        ADD_NEW: "Yeni Ekle",
        NEW_CONTRACT_TYPE: "Yeni Kayıt Tipi",
        CREATE: "Oluştur",
        EDIT_CONTRACT_TYPE: "Kayıt Tipini Düzenle",
        SAVE: "Kaydet",
        DEFAULT_FIELDS: {
            TITLE: "Başlık",
            STATUS: "Aşama",
            PIPELINE: "Süreç",
            USER: "Kullanıcı",
            CREATED_DATE: "Oluşturma Tarihi",
            UPDATED_DATE: "Güncelleme Tarihi",
            EFFECTIVE_DATE: "Yürürlük Tarihi",
            SIGNED_DATE: "İmza Tarihi",
            RENEWAL_DATE: "Yenileme Tarihi",
            START_DATE: "Başlangıç Tarihi",
            END_DATE: "Bitiş Tarihi",
        },
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Kayıt tipini kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        ARE_YOU_SURE_CUSTOM_FIELD_MODAL_DESCRIPTION: "Özel alanı kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        DELETE: "Sil",
        CANCEL: "İptal",
        TITLE_LABEL: "Başlık Girin",
        TITLE_PLACEHOLDER: "Kayıt tipinin bir başlığını girin..."
    },
    FILES: {
        PAGE_TITLE: "Doküman Yönetimi",
        FILE_MANAGER: "Doküman Yöneticisi",
        SEARCH_INPUT_PLACEHOLDER: "Doküman ara",
        UPLOAD_FILES: "Doküman Yükle",
        FILES: "Dokümanlar",
        HIDE_LONG_TAGS: "Uzun Etiketleri Gizle",
        SHOW: "Göster",
        MORE_TAGS: "etiketi daha göster",
        NONE: "Hiçbiri",
        NO_TAGS: "Etiket Yok",
        FILENAME: "Doküman adı",
        CREATED_DATE: "Oluşturma Tarihi",
        UPLOADED_BY: "Yükleyen",
        CONTRACT: "Kayıt",
        TAGS: "Etiketler",
        SIZE: "Boyut",
        TYPE: "Tip",
        VERSION: "Ek",
        SOURCE: "Kaynak",
        ACTIONS: "Aksiyonlar",
        EXPORT: "Dışa Aktar",
        SAVE_CHANGES: "Değişiklikleri Kaydet",
        CANCEL: "İptal",
        EDIT: "Düzenle",
        CLOSE_EDIT_MODE: "Düzenleme Modunu Kapat",
        DELETE: "Sil",
        DOWNLOAD: "İndir",
        SEPERATE_TAGS_WITH_COMMAS: "Etiketleri virgülle ayırın",
        DELETE_FILE: "Dokümanı Sil",
        DELETE_FILE_DESCRIPTION: "Dokümanın silindiğinden emin misiniz?",
        TOTAL_FILES: "Toplam Doküman",
        MAX_PAGES: "Toplam Sayfa",
        NO_DATA_FOUND: "Hiçbir veri bulunamadı",
        VERSIONS: "ek",
        NO_VERSIONS: "Ek yok",
        STATUS: "Durum",
        DRAFT: "Taslak",
        COMPLETED: "Tamamlandı",
        ALL: "Tümü",
        VERSIONS_POPUP: {
            FILENAME: "Doküman adı",
            VERSION: "Ek",
            CREATED_DATE: "Oluşturulma Tarihi",
            UPLOADED_BY: "Yükleyen",
            ACTIONS: "Aksiyonlar",
            REMOVE_FROM_VERSIONS: "Eklerden Sil",
            DOWNLOAD: "Dokümanı İndir",
            DELETE: "Dokümanı Sil",
        },
        MIMETYPES: {
            'application/rtf': 'Zengin Metin Biçmi(.rtf)',
            'application/vnd.oasis.opendocument.text': 'OpenDocument Biçmi(.odt)',
            'text/html': 'Web Sayfası(.html)',
            'application/pdf': 'PDF Dokümanı',
            'application/epub+zip': 'EPUB Publication(.epub)',
            'application/zip': 'Web Sayfası(.html, sıkıştırılmış)',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Microsoft Word(.docx)',
            'text/plain': 'Düz Metin(.txt)',
        }
    },
    TEMPLATES: {
        PAGE_TITLE: "Doküman Yönetimi",
        SEARCH_INPUT_PLACEHOLDER: "Doküman ara",
        UPLOAD_FILES: "Doküman Yükle",
        TEMPLATES: "Şablonlar",
        HIDE_LONG_TAGS: "Uzun Etiketleri Gizle",
        SHOW: "Göster",
        MORE_TAGS: "etketi daha göster",
        NONE: "Hiçbiri",
        NO_TAGS: "Etiket Yok",
        TEMPLATE_NAME: "Şablon adı",
        DOCUMENT_NAME: "Doküman adı",
        CREATED_DATE: "Oluşturma Tarihi",
        UPLOADED_BY: "Yükleyen",
        CONTRACT: "Kayıt",
        TAGS: "Etiketler",
        SIZE: "Boyut",
        TYPE: "Tip",
        DOCUMENT_TYPE: "Doküman Tipi",
        SOURCE: "Kaynak",
        ACTIONS: "Aksiyonlar",
        SAVE_CHANGES: "Değişiklikleri Kaydet",
        CANCEL: "İptal",
        EDIT: "Düzenle",
        CLOSE_EDIT_MODE: "Düzenleme Modunu Kapat",
        DELETE: "Sil",
        DOWNLOAD: "İndir",
        SEPERATE_TAGS_WITH_COMMAS: "Etiketleri virgülle ayırın",
        DELETE_FILE: "Şablonu Sil",
        DELETE_FILE_DESCRIPTION: "Şablonu silmek istediğinizden emin misiniz?",
        TOTAL_FILES: "Toplam Doküman",
        MAX_PAGES: "Toplam Sayfa",
        NO_DATA_FOUND: "Hiçbir veri bulunamadı",
        EDIT_TEMPLATE: "Şablonu Düzenle",
        CREATE_DOCUMENT: "Şablondan Belge Yarat",
        CREATE_TEMPLATE: "Şablon Oluştur",
        DOCUMENT: 'Belge',
        SPREADSHEET: 'Elektronik Tablo',
        PRESENTATION: 'Sunum',
        TEMPLATE_EDITOR: 'Şablon Düzenleyici',
        DOCUMENT_EDITOR: 'Doküman Düzenleyici',
        PROVIDE_DOCUMENT_TITLE: 'Dokümanınıza bir isim verin',
        CREATING_DOCUMENT: 'Doküman Oluşturuluyor',
        CREATING_TEMPLATE: 'Şablon Oluşturuluyor',
    },
    USERS: {
        MY_ACCOUNT: "Hesabım",
        DASHBOARD: "Panel",
        PROFILE_TITLE: "Profil",
        CONTACT_INFORMATION: "Kişi Bilgisi",
        EMAIL: "Eposta:",
        PHONENUMBER: "Telefon:",
        PROFILE: {
            GENERAL: {
                TAB_TITLE: "Genel",
                GENERAL: "Genel",
                GENERAL_DESCRIPTION: "In this section, you can edit your general information.",
                NAME_LABEL: "Ad:",
                NAME_PLACEHOLDER: "Adı girin...",
                SURNAME_LABEL: "Soyadı:",
                SURNAME_PLACEHOLDER: "Soyadını girin...",
                USERNAME_LABEL: "Kullanıcı isimleri:",
                USERNAME_PLACEHOLDER: "Kullanıcı adı belirleyin..",
                PHONENUMBER_LABEL: "Numara alanı:",
                PHONENUMBER_PLACEHOLDER: "5XX XXX XX XX",
                SAVE_CHANGES: "Değişiklikleri Kaydet",
            },
            CREDENTIALS: {
                TAB_TITLE: "Giriş ve Kimlik Ayarları",
                CREDENTIALS: "Giriş / Kullanıcı Kimlik Bilgileri",
                CREDENTIALS_DESCRIPTION: "Bu bölümde Jetlexa Panel için giriş ve kullanıcı bilgilerinizi düzenleyebilirsiniz.",
                EMAIL_LABEL: "Eposta:",
                EMAIL_PLACEHOLDER: "E-posta adresinizi girin ve onaylayın",
                EMAIL_FEEDBACK: "Lütfen geçerli bir e-posta girin.",
                OLD_PASSWORD_LABEL: "Önceki Şifre:",
                OLD_PASSWORD_PLACEHOLDER: "Önceki şifreyi girin...",
                OLD_PASSWORD_FEEDBACK: "Lütfen şifre bilgilerinize dikkat edin",
                NEW_PASSWORD_LABEL: "Yeni Şifre:",
                NEW_PASSWORD_PLACEHOLDER: "Yeni şifreyi girin...",
                NEW_PASSWORD_FEEDBACK: "Lütfen şifre bilgilerinize dikkat edin",
                REENTER_NEW_PASSWORD_LABEL: "Yeni Şifreyi Tekrar Giriniz",
                REENTER_NEW_PASSWORD_PLACEHOLDER: "Yeni şifreyi tekrar girin..",
                REENTER_NEW_PASSWORD_FEEDBACK: "Lütfen şifre bilgilerinize dikkat edin",
                SAVE_CHANGES: "Değişiklikleri Kaydet",
            },
            DELETION: {
                TAB_TITLE: "Hesap Silme",
                DELETE_YOUR_ACCOUNT: "Hesabınızı silin",
                DELETE_YOUR_ACCOUNT_DESCRIPTION: "Hesabınızı Jetlexa'dan silebilirsiniz. Hesap bilgileriniz silme komutundan sonra 30 gün süreyle saklanacaktır. Tekrar giriş yaparak silme işlemini iptal edebilirsiniz.",
                DELETE_YOUR_ACCOUNT_INFORM: "Hesabınızı silmek için kutuya 'permanent-delete' yazabilirsiniz.",
                DELETE_CHECK: "Kontrollü Sil:",
                DELETE_CHECK_FEEDBACK: "Lütfen aşağıdaki kutuya 'permanent-delete' yazın.",
                DELETE_ACCOUNT: "Hesabı Silin",
            }
        }
    },
    USERS_INTEGRATIONS: { //done
        TITLE: "KULLANICI ENTEGRASYONLARI",
        DESCRIPTION: "Şirketinizin entegrasyonlarını yönetebilirsiniz",
        GDRIVE_INTEGRATION_TITLE: "Google Drive'dan İçe Aktarma Entegrasyonu",
        ONEDRIVE_INTEGRATION_TITLE: "OneDrive'dan İçe Aktarma Entegrasyonu",
        SHAREPOINT_INTEGRATION_TITLE: "SharePoint'den İçe Aktarma Entegrasyonu",
        GDRIVE_INTEGRATION_DESCRIPTION: "Google Drive'daki dokümanları içe aktarmak için hesabınızı Google Drive ile entegre edebilirsiniz.",
        ONEDRIVE_INTEGRATION_DESCRIPTION: "OneDrive'daki dokümanları içe aktarmak için hesabınızı OneDrive ile entegre edebilirsiniz.",
        SHAREPOINT_INTEGRATION_DESCRIPTION: "SharePoint'teki dokümanları içe aktarmak için hesabınızı SharePoint ile entegre edebilirsiniz.",
        CONNECT: "Bağlan",
        CONNECTED: "Bağlantıyı Kes",
        REMOVE_CONNECTION: "Bağlantıyı Kaldır",
    },
    POPUPS: {
        FILE_PERMISSON: {
            DRIVE: {
                TITLE: "Google Drive İzinleri",
                READ: "Görüntüleme",
                WRITE: "Düzenleme",
                OWNER: "Sahip",
                DOMAIN: "Domain",
                USER: "Kullanıcı",
                ADD_PERMISSION: "İzin Ekle",
                ADD_USER: "Kullanıcı Ekle",
                ADD: "Ekle",
                NOTIFY_USER: "Kullanıcıya Bildir",
                PEOPLE_WITH_ACCESS: "Erişimi olan kişiler",
                INVALID_EMAIL: "Geçersiz e-posta adresi. Lütfen geçerli bir e-posta adresi girin.",
            }
        },
        ACCEPT_OR_CLOSE: {
            ACCEPT: "Kabul Et",
            CANCEL: "İptal Et",
        },
        ADD_CONTRACT: {
            NEW_CONTRACT: "Yeni Kayıt",
            NEXT: "İleri",
        },
        ADD_CUSTOM_FIELD: {
            NEW_CUSTOM_FIELD: "Yeni Özel Alan"
        },
        ADD_USER_TO_COMPANY: {
            INVITE_USER_TO_COMPANY: "Şirkete Kullanıcı Davet Edin",
            INVITE_USER_TO_COMPANY_DESCRIPTION: "Takım arkadaşlarınızı davet edin ve iş akışınızı planlamaya başlayın.",
            NO_EMAIL_ADDRESS_ADDED: "E-posta adresi eklenmedi",
            INVITE_VIA_EMAIL: "E-posta ile davet edin",
            ADD_NEW_EMAIL: "Yeni e-posta ekle",
            PROVIDE_VALID_EMAIL: "Lütfen geçerli bir e-posta girin",
            SELECT_ROLES: "Listeden Rolü seçin...",
            FINISH: "Tamamlandı",
        },
        CDN: {
            SELECT_SOURCE: 'Dokümanları yükleyebilmek için bir depolama alanını seç',
            UPLOAD: 'Dokümanları Yükle',
            ADD_NEW: 'Yeni Ekle',
            FROM_COMPUTER: 'Bilgisayardan içeri aktar',
            FROM_CLOUD: 'Buluttan içeri aktar',
            JETLEXA_ON_PREM: 'Jetlexa On-premises Depolama',
            AWS_S3: 'Amazon S3 Depolama',
            GOOGLE_DRIVE: 'Google Drive Depolama',
            ONE_DRIVE: 'OneDrive Depolama',
            SHARE_POINT: 'SharePoint Depolama',
            UPLOADING_TITLE: 'Dokümanlar seçilen depolama alanına ekleniyor',
            UPLOADED_TITLE: "Dokümanlar yüklendi. Dokümanlar için kayıt, dokümanın eki veya etiket ekleyebilirsiniz",
            FINISH: 'Tamamla',
            IMPORTING: 'Dokümanlar depolama alanından indiriliyor. İndirme işlemi bitene kadar bekleyin...',
            FROM_CLOUD_TITLE: 'Aşağıdaki bir depolama alanından doküman içeri aktar (Maximum 20 doküman)',
            CONTINUING_UPLOAD: 'Yükleme işlemi devam ediyor...',
            COMPLETED_UPLOAD: 'Yükleme işlemi tamamlandı',
            INTEGRATE_WITH_GDRIVE: 'Lütfen şirket adminine başvurarak şirketi Google Drive ile entegre etmesini isteyin',
            INTEGRATE_WITH_ONEDRIVE: 'Lütfen şirket adminine başvurarak şirketi OneDrive ile entegre etmesini isteyin',
            INTEGRATE_WITH_SHAREPOINT: 'Lütfen şirket adminine başvurarak şirketi SharePoint ile entegre etmesini isteyin',
            SELECT_SOURCE_WARNING: "Dokümanları yükleyebilmek için bir depolama alanı seçmelisiniz.",
            SOMETHING_IS_WRONG: "İçeri alma sistemimizle ilgili bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin.",
            ONEDRIVE_IMPORT_ACCOUNT_PROBLEM: 'OneDrive import hesabının onedrive.com üzerinde açık halde bırakılması gerekmektedir',
        },
        COMPANY_ONBOARDING: {
            NO_COMPANY_FOUND: "Lütfen bir şirket oluşturun veya bir şirkete katılın",
            COMPANY_ONBOARDING: "Şirket Hesabı Oluşturma",
            TITLE_LABEL: "Şirket Adı:",
            TITLE_PLACEHOLDER: "Şirketinizin adını girin...",
            TITLE_FEEDBACK: "Lütfen geçerli bir şirket unvanı girin",
            WEBSITE_LABEL: "Şirketinizin Websitesi:",
            WEBSITE_PLACEHOLDER: "Şirketinizin web sitesini girin...",
            WEBSITE_FEEDBACK: "Lütfen geçerli bir web sitesi girin",
            DESCRIPTION_LABEL: "Şirket Hakkında:",
            DESCRIPTION_PLACEHOLDER: "Şirket hakkında bilgi girin...",
            DESCRIPTION_FEEDBACK: "Lütfen şirket hakkında geçerli bilgi girin.",
            WHAT_IS_YOUR_ROLE_LABEL: "Şirketteki rolünüz nedir?",
            WHAT_IS_YOUR_ROLE_PLACEHOLDER: "Şirketteki rolünüzü girin...",
            WHAT_IS_YOUR_ROLE_FEEDBACK: "Lütfen geçerli bir rol girin.",
            WHAT_TO_DO_WITH_SYSTEM_LABEL: "Bu uygulamayı ne için kullanmak istiyorsunuz?",
            WHAT_TO_DO_WITH_SYSTEM_PLACEHOLDER: "Uygulamayı ne için kullandığınızı girin...",
            WHAT_TO_DO_WITH_SYSTEM_FEEDBACK: "Lütfen uygulamayı ne için kullandığınızı belirtin",
            SIZE_OF_COMPANY_LABEL: "Şirketinizde kaç çalışan bulunuyor?",
            SIZE_OF_COMPANY_PLACEHOLDER: "Çalışan sayısını girin...",
            SIZE_OF_COMPANY_FEEDBACK: "Lütfen geçerli çalışan sayısını girin.",
            USAGE_SIZE_LABEL: "Bu uygulamayı şirketinizden kaç kişi kullanacaktır?",
            USAGE_SIZE_PLACEHOLDER: "Kaç kullanıcı olacağını belirtin...",
            USAGE_SIZE_FEEDBACK: "Lütfen bu uygulamayı kaç kişinin kullanacağını belirtin.",
            NEXT: "İleri",
            PREVIOUS: "Geri",
            FINISH: "Şirket Oluştur",
            CREATING: "Şirketiniz oluşturuluyor...",
            JOINING: "Şirkete katılıyorunuz...",
            JOIN_COMPANY: "Mevcut bir şirkete katılın ve birlikte çalışmaya başlayın!",
            CREATE_COMPANY: "Yeni bir şirket oluşturun ve süreçlerinize başlayın!",
            JOIN: "Şirkete Katıl",
            INVITE_CODE_LABEL: "Davet Kodu",
            INVITE_CODE_PLACEHOLDER: "Davet kodunu girin...",
        },
        COMPANY_SWITCH: {
            COMPANY_SWITCH: "Devam Edeceğiniz Şirketi Seçin",
            CHOOSE_COMPANY_DESCRIPTION: "Çalışmaya başlamak için mevcut şirketinizi seçin!",
            NO_COMPANY_FOUND: "Şirket bulunamadı",
        },
        ROLES_MANAGEMENT: {
            EDIT_ROLE: "Rolü Düzenle",
            DELETE_ROLE: "Rolü Sil",
            SAVE: "Kaydet",
            FIELD: "Alan",
            VALUE: "Değer",
            DELETE_ROLE_DESCRIPTION: "Rolün silinmesinden emin misiniz?",
            FIELDS: {
                index: "Dizin",
                _id: "Benzersiz ID",
                title: "Rol Başlığı",
                company: "Şirket",
                nominations: "İzinler",
                createdAt: "Oluşturma Tarihi"
            },
            ACCEPT_OR_CLOSE_TITLE: "Rolü Sil",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Rolün silinmesinden emin misiniz?"
        },
        ROLES_NEW: {
            ADD_NEW_ROLE: "Şirketinize Yeni Rol Ekleyin",
            TITLE_LABEL: "Rol Adı",
            TITLE_PLACEHOLDER: "Rolün adını girin...",
            TITLE_FEEDBACK: "Lütfen geçerli bir rol adını girin",
            NOMINATIONS_OF_ROLE_LABEL: "Rol için İzinler (Zorunlu)",
            NOMINATIONS_OF_ROLE_PLACEHOLDER: "Rol için izinleri seçin.",
            CREATE: "Oluştur",
        },
        UPLOAD_FILES: {
            UPLOAD_STATUS: "Yükleme Durumu",
            FILENAME: "Doküman adı",
            CONTRACT: "Kayıt",
            VERSION_OF: "Dokümanın Eki",
            TAGS: "Etiketler",
            FILE_SIZE: "Doküman Boyutu",
            LOADING: "Yükleniyor...",
            TAGS_PLACEHOLDER: "Virgülle ayrılmış etiketleri girin",
            SEPERATE_TAGS_WITH_COMMAS: "Etiketleri virgülle ayırın",
            COMPANY_NOT_INTEGRATED_WITH_DRIVE: "Şirket, Google Drive ile entegre değil",
            COMPANY_NOT_INTEGRATED_WITH_DRIVE_DESCRIPTION: "Google Drive'ı entegre etmek için lütfen şirket yöneticinizle iletişime geçin.",
            UPLOAD_FILES_MAX_20: "Dokümanlarınızı yükleyin (En fazla 20 doküman)",
            ERROR: "Hata",
            UPLOAD: "Yükle",
            FINISH: "Tamamlandı",
            UPLOAD_WINDOW_GETTING_READY_FOR_YOU: "Yükleme penceresi sizin için hazırlanıyor...",
            DROP_FILES_OR_CLICK: "Dokümanları buraya bırakın veya yüklemek için tıklayın.",
            DROP_FILES_OR_CLICK_DESCRIPTION: "Sürükleyerek veya tıklayarak bu ekranda en fazla 20 doküman yükleyebilirsiniz.",
            FILES_ARE_BEING_DOWNLOADED_FROM_DRIVE: "Dokümanlar Google Drive'ınızdan indiriliyor ve bir yükleme prosedürü başlatılıyor.",
            INTEGRATION_COMPLETED_GOOGLE: "Entegrasyon tamamlandı. Şimdi Google Drive'dan yükleyin.",
            INTEGRATION_COMPLETED_ONEDRIVE: "Entegrasyon tamamlandı. Şimdi OneDrive'dan yükleyin.",
            INTEGRATION_COMPLETED_SHAREPOINT: "Entegrasyon tamamlandı. Şimdi Sharepoint'den yükleyin.",
            ADD_FILES_FROM_GOOGLE_DRIVE: "Google Drive'dan doküman ekleyin",
            DRAFT: "Taslak",
            COMPLETED: "Tamamlanan",
            STATUS: "Durum",
        },
        USERS_INVITES_MANAGEMENT: {
            UPDATE_CURRENT_USER_INVITE: "Mevcut Kullanıcı Davetini Güncelle",
            DELETE_INVITE: "Daveti Sil",
            FIELD: "Alan",
            VALUE: "Değer",
            SAVE: "Kaydet",
            FIELDS: {
                index: "Dizin",
                _id: "Benzersiz ID",
                email: "Eposta",
                createdAt: "Oluşturma Tarihi",
                inviteCode: "Davet Kodu",
                status: "Durum",
            },
            ACCEPT_OR_CLOSE_TITLE: "Daveti Sil",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Daveti silmek istediğinize emin misiniz?",
        },
        USERS_MANAGEMENT: {
            EDIT_COMPANY_USER: "Şirket Kullanıcısını Düzenle",
            REMOVE_USER: "Kullanıcıyı Kaldır",
            SAVE: "Kaydet",
            FIELD: "Alan",
            VALUE: "Değer",
            SELECT_ROLES_PLACEHOLDER: "Kullanıcı için rol seçin.",
            FIELDS: {
                index: "Dizin",
                _id: "Benzersiz ID",
                name: "Ad",
                surname: "Soyadı",
                email: "Eposta",
                phoneNumber: "Telefon Numarası",
                createdAt: "Oluşturma Tarihi",
                username: "Kullanıcı Adı",
                role: "Rol"
            },
            DEFAULT_FILE_PERMISSIONS: {
                READ: "Görüntüleme",
                EDIT: "Düzenleme",
                NOT_ALLOWED: "İzin Yok",
                OWNER: "Sahip",
            },
            ACCEPT_OR_CLOSE_TITLE: "Kullanıcıyı Şirketten Kaldır",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Kullancıyı şirketten kaldırmak istediğinize emin misiniz?",
        }
    },
    SELECTS: {
        CONTRACTS: {
            SEARCH_CONTRACTS: "Kayıt Seç",
            SEARCH_COMPANY: "Şirketleri Seçin",
            CONTRACT_CHANGE_SUCCESS: "Kayıt değiştirme başarılı!",
        },
        FILES: {
            SEARCH_FILES: "Doküman Seç",
        }
    },
    UNAUTHORIZED_PAGES: {
        UNAUTH_LAYOUT: {
            DESCRIPTION: "Kayıt Yönetimi için en kolay, en hızlı ve en güvenli platformdur.",
            AUTHOR: "JETLEXA EKİBİ"
        },
        LOGIN: {
            WELCOME_BACK: "Tekrar hoşgeldiniz!",
            LOGIN_DESCRIPTION: "JETLEXA'ya erişmek için e-posta adresinizi ve şifrenizi girin",
            DONT_HAVE_ACCOUNT: "Hesabınız yok mu?",
            SIGN_UP: "Kaydol",
            EMAIL_FEEDBACK: "Lütfen geçerli bir e-posta girin",
            PASSWORD_FEEDBACK: "Lütfen geçerli bir şifre girin",
            PASSWORD_OR_EMAIL_INCORRECT: "Eposta veya şifre yanlış.",
            PLEASE_LOGIN: "Lütfen giriş yapın veya",
            REGISTER: "kaydolun",
            TO_CONTINUE_WITH: "davetinize devam etmek için",
            LOGIN_SUCCESSFUL: "Başarıyla giriş yapıldı, yönlendiriliyor...",
            EMAIL_LABEL: "Eposta Adresi",
            EMAIL_PLACEHOLDER: "Eposta adresinizi girin...",
            PASSWORD_LABEL: "Şifre",
            PASSWORD_PLACEHOLDER: "Şifrenizi girin..",
            LOGIN: "Giriş Yap",
            REMEMBER_ME: "Beni Hatırla",
            FORGOT_PASSWORD: "Şifrenizi mi unuttunuz?",
        },
        LOGOUT: {
            SEE_YOU_AGAIN: "Başarıyla çıkış yaptınız",
            DESCRIPTION: "Başarıyla çıkış yaptınız, JETLEXA'yı ziyaret ederek istediğiniz zaman giriş yapabilirsiniz.",
            BACK_TO: "Geri dön",
            LOG_IN: "Giriş yap",
        },
        REGISTER: {
            CREATE_ACCOUNT: "Hesabını Oluştur",
            CREATE_ACCOUNT_DESCRIPTION: "Hesabınızı oluşturun ve şirket hesabınızı açın.",
            NAME_LABEL: "İlk Adı:",
            NAME_PLACEHOLDER: "İlk adı girin...",
            NAME_FEEDBACK: "Lütfen geçerli bir ilk adı girin",
            SURNAME_LABEL: "İkinci Ad:",
            SURNAME_PLACEHOLDER: "İkinci adı girin...",
            SURNAME_FEEDBACK: "Lütfen geçerli bir ikinci adı girin.",
            PHONENUMBER_LABEL: "Telefon Numarası:",
            PHONENUMBER_PLACEHOLDER: "54X XXX XX XX",
            PHONENUMBER_FEEDBACK: "Lütfen geçerli bir telefon numarası girin.",
            EMAIL_LABEL: "Eposta Adresi:",
            EMAIL_PLACEHOLDER: "Eposta adresi girin...",
            EMAIL_FEEDBACK: "Lütfen geçerli bir eposta adresi girin",
            PASSWORD_LABEL: "Şifre:",
            PASSWORD_PLACEHOLDER: "Şifreyi girin...",
            PASSWORD_FEEDBACK: "Lütfen geçerli bir şifre girin.",
            PASSWORD_MINIMUM_LENGTH_FEEDBACK: "Şifreniz 5 karakterden uzun olmalıdır.",
            PASSWORD_MAXIMUM_LENGTH_FEEDBACK: "Şifreniz 25 karakterden uzun olmamalıdır.",
            PASSWORD_MINIMUM_CHARACTER_FEEDBACK: "Şifreniz en az 6 karakter içermelidir.",
            PASSWORD_MINUMUM_ONE_UPPERCASE_ONE_LOWER_CASE_FEEDBACK: "Şifreniz bir büyük harf, bir küçük harf içermelidir",
            PASSWORD_SPECIAL_CHARACTER_FEEDBACK: "Şifreniz en az bir özel karakter içermelidir",
            PASSWORD_NUMBER_FEEDBACK: "Şifreniz en az bir sayı içermelidir",
            ACCEPT_TERMS_AND_SERVICES_LABEL: "Şartları ve hizmetleri kabul ediyorum",
            ACCEPT_TERMS_AND_SERVICES_FEEDBACK: "Lütfen şartları ve hizmetleri kabul edin",
            REGISTER: "Kaydol",
            SHOW_PASSWORD: "Şifreyi Göster",
            HIDE_PASSWORD: "Şifreyi Gizle",
            ALREADY_HAVE_ACCOUNT: "Already have an account?",
            LOGIN: "Giriş Yap",
            ALERT_REGISTRATION_COMPLETE: "Kayıtlar başarılı, sonraki sayfaya yönlendirileceksiniz",
            ALERT_INVITE_CODE: "Davetinize devam etmek için lütfen kaydolun",
            ATTEMPTED: "Denendi:",
            REGISTER_CODE: "Kayıt Kodu",
        },
        CONFIRM: {
            TITLE: "Email adresini onayla",
            DESCRIPTION: "Hesabınız başarıyla kaydedildi. Doğrulama işlemini tamamlamak için lütfen doğrulama isteği için e-postanızı kontrol edin.",
            BACK_TO: "Geri Dön",
            LOGIN: "Giriş Yap"
        },
        CONFIRM_SUCCESSFUL: {
            GO_TO: "Git",
            MAIL_CONFIRMATION_SUCCESS: "Posta Onayı Başarılı!",
            DESCRIPTION: "Onaylanan mailiniz başarılı, JETLEXA'ya gidebilirsiniz."
        },
        CONFIRM_FAILED: {
            GO_TO: "Git",
            LOGIN: "Giriş Yap",
            MAIL_CONFIRMATION_FAILED: "Posta Onayı Başarısız!",
            DESCRIPTION: "Posta onayınız başarısız oldu, doğru url için posta kutunuzu kontrol edebilir veya sayfanın altındaki buton aracılığıyla destek ile iletişime geçebilirsiniz."
        },
        FORGET_PASSWORD: {
            TITLE: "Şifremi Unuttum",
            DESCRIPTION: "E-posta adresinizi girin, size şifrenizi sıfırlama talimatlarını içeren bir e-posta gönderelim.",
            GO_TO: "Git",
            LOGIN: "Giriş Yap",
            EMAIL_LABEL: "Eposta Adresi",
            EMAIL_PLACEHOLDER: "Eposta adresi girin...",
            EMAIL_FEEDBACK: "Lütfen geçerli bir eposta adresi girin.",
            ALERT_SUCCESSFULLY_SENT: "E-posta başarıyla e-posta adresine gönderildi. Bir sonraki adıma geçmek için lütfen bağlantıyı tıklayın",
            SEND: "Eposta Gönder",
        },
        RESET_PASSWORD: {
            CHANGE_PASSWORD: "Şifreyi Değiştir",
            CHANGE_PASSWORD_DESCRIPTION: "Aşağıdaki formu doldurarak mevcut şifrenizi değiştirebilir ve JETLEXA'ya giriş yapabilirsiniz.",
            BACK_TO: "Geri Git",
            LOGIN: "Giriş Yap",
            PASSWORDS_DOESNT_MATCH: "Şifre eşleşmiyor",
            PASSWORD_LABEL: "Şifre:",
            CHECK_PASSWORD_LABEL: "Şifre Tekrarı:",
            PASSWORD_PLACEHOLDER: "Şifrenizi girin...",
            CHECK_PASSWORD_PLACEHOLDER: "Şifrenizi tekrar girin...",
            PASSWORD_FEEDBACK: "Lütfen geçerli bir şifre girin.",
            PASSWORD_MINIMUM_LENGTH_FEEDBACK: "Şifreniz 5 karakterden uzun olmalıdır.",
            PASSWORD_MAXIMUM_LENGTH_FEEDBACK: "Şifreniz 25 karakterden uzun olmamalıdır.",
            PASSWORD_MINIMUM_CHARACTER_FEEDBACK: "Şifreniz en az 6 karakter içermelidir.",
            PASSWORD_MINUMUM_ONE_UPPERCASE_ONE_LOWER_CASE_FEEDBACK: "Şifreniz bir büyük harf, bir küçük harf içermelidir",
            PASSWORD_SPECIAL_CHARACTER_FEEDBACK: "Şifreniz en az bir özel karakter içermelidir",
            PASSWORD_NUMBER_FEEDBACK: "Şifreniz en az bir sayı içermelidir",
            ALERT_CHANGE_PASSWORD: "Aşağıdaki formu doldurarak şifrenizi değiştirebilirsiniz",
            SHOW_PASSWORD: "Şifreyi Göster",
            HIDE_PASSWORD: "Şifreyi Gizle"
        },
        LOCK_SCREEN: {
            HI: "Merhaba, ",
            DESCRIPTION: "JETLEXA'ya erişmek için şifrenizi tekrar girin",
            PASSWORD_LABEL: "Şifre:",
            PASSWORD_PLACEHOLDER: "Şifrenizi girin...",
            PASSWORD_FEEDBACK: "Lütfen geçerli bir şifre girin.",
            LOGIN: "Giriş",
            NOT_YOU: "Siz değil misiniz? Buradan devam edin",
            ALERT_LOGIN_SUCCESSFUL: "Başarıyla giriş yapıldı, JETLEXA'ya yönlendiriliyor...",
            ATTEMPTED: "Denendi:",
            MESSAGE: "Şifre veya eposta yanlış."
        }
    },
    MAIL_INBOX: {
        TITLE: "Gelen Kutusu",
        NEW_CONNECTION: "Yeni Bağlantı",
        DISCONNECT: "Bağlantıyı Kes",
        ALL: "Tümü",
        IMPORTED: "İçe aktarılan",
        MAIL_TEXT: "Posta İçeriği",
        IMPORT: "İçe Aktar",
        SELECT_EMAIL: "Eposta Seç",
        IMPORT_SELECTED_FILES: "Seçilen dokümanları içe aktar",
        CONNECT_TO_EMAIL: "E-Posta Adresinize Bağlanın",
        ALL_DATA_LOADED: "Tüm veriler yüklendi",
        ARE_YOU_SURE: "Emin misiniz?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Eposta adresinizi ile bağlantınız kesilecek, bu işlem geri alınamaz.",
        UPLOADING_FILES: "Dokümanlar Yükleniyor",
        UPLOADING_FILES_DESCRIPTION: "Dokümanlar yükleniyor, lütfen bekleyiniz...",
        CANCEL: "İptal",
        DELETE: "Sil",
        FILES_UPLOADED_SUCCESSFULLY: "Dokümanlar başarıyla yüklendi",
        MAXIMUM_20_FILES: "Maksimum 20 doküman yükleyebilirsiniz",
    },
    CUSTOMFORMS: {
        CUSTOMFORMS_EDIT_POPUP: {
            FORM_STATUS_ACTIVE: 'Aktif',
            FORM_STATUS_PASSIVE: 'Pasif',
            FORM: 'Form',
            TEMPLATES: 'Şablonlar',
            SETTINGS: 'Ayarlar',
            SHARE_FORM: 'Paylaş',
            SAVE: 'Kaydet',
            FORM_ELEMENTS: 'Form Elementleri',
            HEADER: 'Başlık',
            DIVIDER: 'Ayraç',
            FIELD_SETTINGS: 'Alan Ayarları',
            FIELD: 'Alan',
            TITLE: 'Başlık',
            LABEL: 'Etiket',
            DESCRIPTION: 'Açıklama',
            HELP_TEXT: 'Yardım Metni',
            REQUIRE: 'Doldurulması zorunlu alan',
            ENTER_DESCRIPTION: 'Bir açıklama giriniz...',
            DEFAULT_VALUE: 'Varsayılan Değer',
            AVAILABLE_TEMPLATES: 'Kullanılabilir Şablonlar',
            SELECTED_TAMPLATES: 'Seçilen Şablonlar',
            FORM_STATUS: 'Form Durumu',
            SHARE: 'Paylaş',
            COPY_LINK: 'Kopyala',
            LINK_COPIED: 'Link kopyalandı!',
            CODE_COPIED: 'Kod kopyalandı',
            PREVIEW: 'Form Ön İzleme',
            SEARCH_TEMPLATE_PLACEHOLDER: 'Kullanılabilir şablonlarda ara...',
            CUSTOM_FORMS_TITLE: 'Özel Formlar',
            CUSTOM_FORMS_DESCRIPTION: 'Özel bir form oluşturun, şablonlarla ilişkilendirin. Belgelerinizi otomatik olarak oluşturun...',
            CREATE_FORM: 'Form Oluştur',
            FORM_PASSIVE_MESSAGE: 'Bu form şu anda mevcut değil.',
            SUBMIT: 'Gönder',
            RESET: 'Sıfırla',
            ARE_YOU_SURE_DESCRIPTION: 'Formu kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?',
            OPEN_FORM: 'Formu Aç',
            PROVIDE_FORM_TITLE: 'Form başlığını girin',
            CREATING_DOCUMENT: 'Form oluşturuluyor',
            INVALID_EMAIL: 'Geçersiz e-posta adresi',
            SEND_EMAIL: 'E-posta gönder',
            RECEIVERS: 'Alıcılar',
            MESSAGE_TEXT: 'Mesaj metni',
            INVALID_MESSAGE_TEXT: 'Geçersiz mesaj metni. Mesaj metni boş bırakılamaz.',
            CONFIRM_AND_SEND: 'Gönder',
        }
    },
    MONTHS: {
        jan: 'Ocak',
        feb: 'Şubat',
        mar: 'Mart',
        apr: 'Nisan',
        may: 'Mayıs',
        jun: 'Haziran',
        jul: 'Temmuz',
        aug: 'Ağustos',
        sep: 'Eylül',
        oct: 'Ekim',
        nov: 'Kasım',
        dec: 'Aralık'
    },
    DASHBOARD: {
        DATE_TYPES: {
            startDate: 'Başlangıç Tarihi',
            endDate: 'Bitiş Tarihi',
            effectiveDate: 'Yürürlük Tarih',
            renewalDate: 'Yenileme Tarihi',
            reminderDate: 'Etkinlik Tarihi',
        },
        CHARTS: {
            NUMBER_OF_CONTRACTS_ADDED: 'Eklenen Kayıt Sayısı',
        }
    },
    APPROVE_TEMPLATES: {
        NAME: 'Onay Şablonları',
        TITLE: 'Onay Şablonları',
        TEMPLATE_NAME: 'Onay Şablonu Adı',
        REACH_ERROR: 'Onay şablonu oluşturulamadı, lütfen sayfa yenileyerek tekrar deneyin.',
        TABLE: {
            NAME: 'Onay Şablonları',
            TITLE: 'Şablon Adı',
            CREATED_AT: 'Oluşturma Tarihi',
            APPROVERS: 'Onaylayanlar',
            STEPS: 'Adımlar',
            ACTIONS: 'Aksiyonlar',
            EDIT: 'Düzenle',
            DELETE: 'Sil',
            CREATE: 'Oluştur',
        },
        CREATE_MODAL: {
            TITLE: 'Onay Şablonu Oluştur',
            NAME: 'Şablon Adı',
            NAME_PLACEHOLDER: 'Şablon adını girin',
            NAME_ERROR: 'Şablon adı boş bırakılamaz',
            CREATE_BUTTON: 'Oluştur',
            CREATING_BUTTON: 'Oluşturuluyor...',
        },
        DELETE_MODAL: {
            TITLE: 'Onay Şablonu Sil',
            DESCRIPTION: 'Onay şablonunu kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?',
            DELETE_BUTTON: 'Sil',
            CANCEL_BUTTON: 'İptal',
        },
        APPROVE_TEMPLATES_GENERIC_MODAL: {
            TITLE: 'Onay Şablonu Düzenle',
            NAME: 'Şablon Adı',
            STEP_NOT_FOUND: 'Adım bulunamadı, sayfayı yenileyerek tekrar deneyin.',
            EMAIL_VALIDATION_ERROR: 'Geçersiz e-posta adresi',
            SAVING: 'Kaydediliyor...',
            SAVE: 'Kaydet',
            SAVED: 'Kaydedildi',
            STEP: 'Adım',
            DELETE: 'Şablonu Sil',
            CAN_CHANGE_REQUEST: "Değişiklik Önerebilir",
            CAN_ASSIGN: "Başkasına Atayabilir",
            APPROVE_FROM: 'Şu kişilerin onayı gerekir',
            APPROVE_FROM_ALL: "Adımdaki herkes",
            APPROVE_FROM_ONE: "Adımdaki herhangi biri",
            ADD_STEP: 'Adım Ekle',
            RECIPIENT_EMAIL: 'Alıcı E-posta adresi giriniz',
        },
    },
    CUSTOM_FIELDS: {
        TOUSHANDS_SEPARATOR: 'Binler ayırıcı nokta (.) ve ondalık ayırıcı virgül (,) olmalıdır.',
    },
    APPROVE_PROCESS: {
        PAGE_TITLE: 'Onay Yönetimi',
        TABLE: {
            TITLE: 'Onay Süreci',
            STATUS: 'Onay Durumu',
            VERSIONS: 'Ekler',
            LAST_ACTION_DATE: 'Son İşlem Tarihi',
            LAST_ACTION_USER: 'Son İşlem Yapan',
            NOTES_COMMENT: 'Notlar/Yorumlar',
            TIME_TRACKING: 'Zaman Takibi',
            TIME_TRACKING_APPROVED: '{{time}} sürdü',
            TIME_TRACKING_PENDING: '{{time}} önce başladı',
            SHOW: 'Göster',
            DELETE: 'Sil',
            NAV: {
                APPROVE_PROCESS: 'Onay Süreçleri',
                APPROVAL_FLOW_TEMPLATES: 'Onay Akış Şablonları',
            }
        },
        APPROVE_PROCESS_POPUP: {
            TITLE: 'Onay Süreci',
            APPROVE: 'Onayla',
            ACCEPT: 'Kabul Et',
            REJECT: 'Reddet',
            REJECT_AND_COMPLATE: 'Reddet ve Akışı Sonlandır',
            REJECT_AND_SUGGEST_CHANGES: 'Reddet ve Değişiklik Öner',
            ASSIGN_TO_SOMEONE_ELSE: 'Başkasına Ata',
            START_APPROVE_PROCESS: 'Onay Akışını Başlat',
            APPROVED: 'Onaylandı',
            REJECTED: 'Reddedildi',
            PENDING: 'Bekliyor',
            CREATED: 'Oluşturuldu',
            CANCELED: 'Vazgeçildi',
            REASSIGNED: 'Başkasına Atadı',
            CHANGE_REQUESTED: 'Değişiklik Önerdi',
            NOT_STARTED: 'Karar Bekleniyor',
            STEP: 'Adım',
            FILE: 'Doküman',
            START_APPROVE_PROCESS_AGAIN: 'Onay Sürecini Tekrar Başlat',
            APPROVE_MODAL: {
                TITLE: 'Onayla',
                DESCRIPTION: 'Şu anda bu belgeyi onaylamak üzeresiniz. Onaylama adımını tamamlamak için "Gönder" butonuna basınız.',
                SEND: 'Gönder',
                MESSAGE_INPUT_LABEL: 'Mesaj Metni',
                MESSAGE_INPUT_PLACEHOLDER: 'Onaylamadan önce bu alana bir metin ekleyebilirsiniz. Bu metin onay adımındaki ilgili alıcıya iletilecektir.',
            },
            REJECT_COMPLATE_MODAL: {
                TITLE: 'Reddet ve Sonlandır',
                DESCRIPTION: 'Şu anda bu belgeyi reddetmek üzeresiniz. Ret adımını tamamlamak için aşağıdaki alana ret nedeninizi girip "Gönder" butonuna basınız.',
                SEND: 'Gönder',
                MESSAGE_INPUT_LABEL: 'Mesaj Metni (*)',
                MESSAGE_INPUT_PLACEHOLDER: 'En az 10 karakter olacak şekilde ret nedeninizi giriniz. Bu metin onay adımındaki ilgili alıcıya iletilecektir.',
            },
            REASSIGN_MODAL: {
                TITLE: 'Başkasına Ata',
                DESCRIPTION: 'Bu belgeyi başkasına atamak üzeresiniz. Aşağıdaki yer alan kutucuğa, belgeyi atamak istediğiniz kişinin e-posta adresini ve mesaj metni alanına açıklamanızı girip "Gönder" butonuna basınız.',
                SEND: 'Gönder',
                RECEIVER_INPUT_LABEL: 'Alıcı',
                RECEIVER_INPUT_PLACEHOLDER: 'Alıcı e-posta adresini giriniz',
                MESSAGE_INPUT_LABEL: 'Mesaj Metni (*)',
                MESSAGE_INPUT_PLACEHOLDER: 'Başkasına atama nedeninizi içeren bir açıklama giriniz. Açıklama en az 10 karakter olmalıdır.',
            },
            REJECT_CHANGE_MODAL: {
                TITLE: 'Reddet ve Değişiklik Öner',
                DESCRIPTION: 'Değişiklik talep etmek istediğiniz belgeyi aşağıdan indirebilir, “Değişiklikleri İzle” açık olacak şekilde revize edebilirsiniz.',
                SEND: 'Gönder',
                MESSAGE_INPUT_LABEL: 'Mesaj Metni (*)',
                MESSAGE_INPUT_PLACEHOLDER: 'Bu alana reddetme ve değişiklik önerme nedeninizi içeren bir açıklama giriniz. Açıklama en az 10 karakter olmalıdır.',
                FILE_INPUT_LABEL: 'Doküman Yükleme (*)',
                FILE_INPUT_PLACEHOLDER: 'Değişiklik yaptığınız yeni belgeyi buraya sürükleyebilir veya “Yükle” butonuna basarak seçebilirsiniz.',
                SELECT_FILE: 'Doküman Seç',
            },
            USER_STATUSES: {
                APPROVED: 'Onaylandı',
                REJECTED: 'Reddedildi',
                PENDING: 'Onayı Bekleniyor',
                CREATED: 'Oluşturuldu',
                CANCELED: 'Vazgeçildi',
                REASSIGNED: 'Başkasına Atadı',
                CHANGE_REQUESTED: 'Değişiklik Önerdi',
                QUEUE: 'Sırası Bekleniyor',
                NOT_NECESSARY: 'İptal',
            },
            FILE_MESSAGES: {
                FROM_ANYONE_MESSAGE: 'Adımdaki herhangi birinin onayı gerekli',
                FROM_EVERYONE_MESSAGE: 'Adımdaki herkesin onayı gerekli',
                FILE_UPLOADED: 'Yeni doküman yüklendi.',
                NO_START_FILE_REJECT: '{{ownerName}}, yeni doküman reddedip değişiklik önerdi.',
                NO_START_FILE_REJECT_COMPLATE: '{{ownerName}}, yeni dokümanı reddederek onay akışını sonlandırdı.',
                NO_START_FILE_PROCESS: '{{ownerName}}, yeni doküman için onayı başlatması bekleniyor.',
                CONTINUE_FROM_SCRATCH: '{{ownerName}} {{startDate}} tarihinde yeni doküman için onay akışını yeniden başlattı.',
                CONTINUE_FLOW: '{{ownerName}} {{startDate}} tarihinde yeni doküman için mevcut onay akışına devam etti.',
                CONTINUE_NEW_FLOW_FIRST: '{{ownerName}} {{startDate}} tarihinde onay akışını başlattı.',
                CONTINUE_NEW_FLOW: '{{ownerName}} {{startDate}} tarihinde yeni doküman için yeni bir onay akışı başlattı.',
            },
            USER_MESSAGES: {
                SUGGECTED_CHANGE_MESSAGE: 'Değişiklik Öner ile bu versiyonun onay akışı sona ermiş ve bu onay kişisinin seçimine gerek kalmamıştır.',
                REJECTED_MESSAGE: 'Bu versiyonun onay akışı reddedilerek sona ermiş ve bu onay kişisinin seçimine gerek kalmamıştır.',
                APPROVE_ANYONE_MESSAGE: 'Adımdaki herhangi birinin onayı gerekli olduğundan bu onay kişisinin seçimine gerek kalmamıştır.',
            }
        },
        APPROVE_PROCESS_DELETE_MODAL: {
            TITLE: 'Onay Sürecini Sil',
            DESCRIPTION: 'Onay sürecini kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?',
            DELETE_BUTTON: 'Sil',
            CANCEL_BUTTON: 'İptal',
        }
    },
    APPROVE_ACTIVITY: {
        MODAL_TITLE: 'Notlar/Yorumlar',
        MODAL_CLOSE: 'Kapat',
        APPROVE: {
            CREATED: 'Onay işlemi oluşturuldu.',
            APPROVED: 'Onay işlemi onaylandı.',
            PENDING: 'Onay işlemi bekliyor.',

        },
        APPROVE_STEP: {
            CREATED: 'Bir onay adımı oluşturuldu.',
            APPROVED: 'Bir onay adımı onaylandı.',
            PENDING: 'Bir onay adımı bekliyor.',
        },
        APPROVE_STEP_USER: {
            CREATED: 'Bir onaylayan oluşturuldu.',
            APPROVED: 'Bir onay verildi.',
            PENDING: 'Onay bekliyor.',
            REASSIGNED: 'Onaylayan değiştirildi.',
        },
        APPROVE_FILE: {
            CREATED: 'Bir doküman eklendi.',
            APPROVED: 'Bir doküman onaylandı.',
            PENDING: 'Bir doküman onay bekliyor.',
        }
    },
    APPROVE_PROCESS_MODAL: {
        CREATE_MODAL: {
            TITLE: 'Onay Sürecini Oluştur',
            NAME_LABEL: 'Onay Sürecini İsimlendir',
            SELECT_APPROVE_TEMPLATE: 'Onay Akışı Seç',
            SAVE_AS_TEMPLATE: 'Şablon Olarak Kaydet',
            SAVE: 'Kaydet',
            SEND_APPROVE: 'Onay Sürecini Başlat',
            USER_NOTE_PLACEHOLDER: 'Onay kişilerini iletmek istediğiniz mesajı bu alana ekleyebilirsiniz. (İsteğe bağlı)'
        },
    },
    APPROVE_BUTTON_COMPONENT: {
        SEND: 'Onaya Gönder',
        TITLE: 'Onay Sürecini Oluştur',
        NAME_LABEL: 'Onay Sürecini İsimlendir',
        SAVE: 'Kaydet',
    },
    APPROVAL_PROCESS_FILE_MODAL: {
        TITLE: 'Onay Akışı Oluştur',
        SEND_APPROVE: 'Onay Akışını Başlat',
        LOADING: 'Onay akışı oluşturuluyor. Lütfen bekleyiniz...',
        STEP: 'Adım',
        DELETE: 'Sil',
        DELETE_ALL: 'Tümünü Sil',
        CAN_CHANGE_REQUEST: 'Değişiklik Önerebilir',
        CAN_ASSIGN: 'Başkasına Atayabilir',
        RECIPIENT_EMAIL: 'Alıcı E-posta Adresi Giriniz',
        APPROVE_FROM: 'Şu kişilerin onayı gerekir',
        APPROVE_FROM_ALL: 'Adımdaki herkes',
        APPROVE_FROM_ONE: 'Adımdaki herhangi biri',
        ADD_STEP: 'Adım Ekle',
        SAVE: 'Kaydet',
        SEND: 'Gönder',
        SAVE_AS_TEMPLATE: 'Şablon Olarak Kaydet',
        TEMPLATE_NAME: 'Şablon Adı',
        TEMPLATE_NAME_PLACEHOLDER: 'Şablon adını giriniz',
        EDIT_TEMPLATE: 'Şablonu Güncelle',
        EDIT_TEMPLATE_MESSAGE: 'Bu onay akışı şablonu, yaptığınız değişikliğe göre güncellenecektir. Bu işleme devam etmek istediğinize emin misiniz?',
        SELECT_APPROVAL_FLOW: 'Onay Akışı Seç',
    }
}