import React, { useState, useEffect } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import ContractsSelect from '@components/SelectsPaginated/Contracts';
import FilesSelect from '@components/SelectsPaginated/Files';
import api from '@clients/api.client';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {
    uploadFilesToS3, uploadFilesToGoogleDrive, uploadFilesToCustomCDN, uploadFilesToOneDrive,
    uploadFilesToSharePoint
} from '@redux/files/index.actions';
import './index.scss';
import cdnServicesInstance from '@clients/cdnservices.client';
import { Button, Form } from 'react-bootstrap';
import { setFileToGenericPermissionModal } from '@redux/files/index.actions';
import FeatherIcons from 'feather-icons-react';
import Select from 'react-select';

const mapStateToProps = (state: any) => ({
    companies: state.companies,
    cdn: state.files.cdn,
    users: state.users
});

const mapActionsTopProps = {
    uploadFilesToS3: uploadFilesToS3,
    uploadFilesToGoogleDrive: uploadFilesToGoogleDrive,
    uploadFilesToCustomCDN: uploadFilesToCustomCDN,
    setFileToGenericPermissionModal: setFileToGenericPermissionModal,
    uploadFilesToOneDrive: uploadFilesToOneDrive,
    uploadFilesToSharePoint: uploadFilesToSharePoint
}

const FilesTable: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();

    const generateFileUrl = (file: any) => {
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance?.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return file?.fileUrl + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return file?.fileUrl;
        } else if (file?.source?.sourceKey === "one-drive") {
            //return file?.fileUrl;
            return cdnServicesInstance.defaults.baseURL + `/onedrive/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "share-point") {
            //return file?.fileUrl;
            //return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }

    useEffect(() => {
        toast.info(t("POPUPS.CDN.CONTINUING_UPLOAD"), {
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            isLoading: true
        })

        const promise = new Promise(async (resolve, reject) => {
            if (props?.cdn?.files?.length > 0) {
                for (let index = 0; index < props?.cdn?.exportSources?.length; index++) {
                    const source = props?.cdn?.exportSources[index];
                    if (source === 'aws-s3') {

                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToS3({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                    if (source === "google-drive") {
                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToGoogleDrive({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                    if (source === "custom-cdn") {
                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToCustomCDN({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                    if (source === "one-drive") {
                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToOneDrive({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                    if (source === "share-point") {
                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToSharePoint({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                }
            }
        })

        Promise.resolve(promise).then(() => { }).catch((error: any) => { })

        /* props?.cdn?.exportSources?.map(async (source: string, index: number) => {
            if (source === 'aws-s3') {

                for (let i = 0; i < props?.cdn?.files?.length; i++) {
                    const file = props?.cdn?.files[i];
                    await props?.uploadFilesToS3({
                        files: [file],
                        index: index,
                        ...props?.defaultContract?._id && {
                            contract: props?.defaultContract?._id
                        }
                    })
                }
            }
            if (source === "google-drive") {
                for (let i = 0; i < props?.cdn?.files?.length; i++) {
                    const file = props?.cdn?.files[i];
                    await props?.uploadFilesToGoogleDrive({
                        files: [file],
                        index: index,
                        ...props?.defaultContract?._id && {
                            contract: props?.defaultContract?._id
                        }
                    })
                }
            }
            if (source === "custom-cdn") {
                for (let i = 0; i < props?.cdn?.files?.length; i++) {
                    const file = props?.cdn?.files[i];
                    await props?.uploadFilesToCustomCDN({
                        files: [file],
                        index: index,
                        ...props?.defaultContract?._id && {
                            contract: props?.defaultContract?._id
                        }
                    })
                }
            }
        }) */

    }, [])

    useEffect(() => {
        if (props?.cdn?.uploadCompleted) {
            toast.dismiss();
            toast.success(t("POPUPS.CDN.COMPLETED_UPLOAD"), {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
        }
    }, [props?.cdn?.uploadCompleted])

    return (
        <div className='cdn-popup-files-table' style={{
            width: '100%',
        }}>
            <table
                className="table table-striped table-centered"
                style={{
                    textAlign: "center",
                    width: "100%",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px"
                }}>
                <thead>
                    <tr>
                        <th style={{ whiteSpace: 'nowrap' }}>
                            {t("POPUPS.UPLOAD_FILES.UPLOAD_STATUS")}
                        </th>
                        <th>
                            {t("POPUPS.UPLOAD_FILES.FILENAME")}
                        </th>
                        {props?.cdn?.uploadCompleted && <th className="contract-header">
                            {t("POPUPS.UPLOAD_FILES.STATUS")}
                        </th>}
                        {props?.cdn?.uploadCompleted && <th className="contract-header">
                            {t("POPUPS.UPLOAD_FILES.CONTRACT")}
                        </th>}
                        {props?.cdn?.uploadCompleted && <th className="contract-header">
                            {t("POPUPS.UPLOAD_FILES.VERSION_OF")}
                        </th>}
                        {props?.cdn?.uploadCompleted && <th className="tags-header">
                            {t("POPUPS.UPLOAD_FILES.TAGS")}
                        </th>}
                        {!props?.cdn?.uploadCompleted && <th>
                            {t("POPUPS.UPLOAD_FILES.FILE_SIZE")}
                        </th>}
                        {props?.cdn?.uploadCompleted && <th>
                            {t("İzinler")}
                        </th>}
                        {props?.cdn?.uploadCompleted && <th>
                            Source
                        </th>}
                    </tr>
                </thead>
                <tbody>
                    {((
                        props?.cdn?.uploadCompleted ? props?.cdn?.uploadedFiles : props?.cdn?.files
                    )
                        || [])
                        .map((
                            file: any,
                            index: number
                        ) => {
                            return (
                                <tr className="upload-file-row">
                                    <td>
                                        {
                                            (props?.cdn?.uploadCompleted || file?.isUploaded) ? <BsCheckCircle color="#43d39e" size={30} />
                                                : <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">
                                                        {t("POPUPS.UPLOAD_FILES.LOADING")}
                                                    </span>
                                                </div>
                                        }
                                    </td>
                                    <td className="filename">
                                        {(props?.cdn?.uploadCompleted || file?.isUploaded)
                                            ? <a href={generateFileUrl(file)} target="__blank">{file?.name || file?.filename}</a>
                                            : (file?.filename)
                                        }


                                    </td>


                                    {props?.cdn?.uploadCompleted && <td className="contracts-edit">
                                        <div style={{
                                            position: "relative",
                                            zIndex: 9,
                                            width: "100%"
                                        }}>
                                            <div style={{
                                                zIndex: 99,
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                width: "80%",
                                                transform: "translate(-50%, -50%)",
                                            }}>


                                                <Select
                                                    key={index + '_input_select'}
                                                    isClearable={false}
                                                    isMulti={false}
                                                    className="react-select react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={[
                                                        {
                                                            label: t("POPUPS.UPLOAD_FILES.DRAFT").toString(),
                                                            value: "draft"
                                                        },
                                                        {
                                                            label: t("POPUPS.UPLOAD_FILES.COMPLETED").toString(),
                                                            value: "completed"
                                                        },
                                                    ]}
                                                    defaultValue={{
                                                        label: file?.status === 'completed' ? t("POPUPS.UPLOAD_FILES.COMPLETED").toString() : t("POPUPS.UPLOAD_FILES.DRAFT").toString(),
                                                        value: file?.status
                                                    }}

                                                    onChange={(value: any) => {
                                                        api.put(`/files/${file?._id}`, {
                                                            status: value?.value,
                                                            company: props?.companies?.company?._id
                                                        }).then((response: any) => {
                                                        })
                                                    }}
                                                ></Select>


                                            </div>
                                        </div>
                                    </td>}


                                    {props?.cdn?.uploadCompleted && <td className="contracts-edit">
                                        <div style={{
                                            position: "relative",
                                            zIndex: 9,
                                            width: "100%"
                                        }}>
                                            <div style={{
                                                zIndex: 9,
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                width: "80%",
                                                transform: "translate(-50%, -50%)",
                                            }}>
                                                <ContractsSelect
                                                    onValueSubmit={(value: any) => {
                                                        api.put(`/files/${file?._id}`, {
                                                            contract: value,
                                                            company: props?.companies?.company?._id
                                                        }).then((response: any) => {

                                                        })
                                                    }}

                                                    defaultOption={props?.defaultContract ? {
                                                        label: props?.defaultContract?.title,
                                                        value: props?.defaultContract?._id
                                                    } : {
                                                        value: file?.contract?._id,
                                                        label: file?.contract?.title
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </td>}



                                    {props?.cdn?.uploadCompleted && <td className="files-edit">
                                        <div style={{
                                            position: "relative",
                                            zIndex: 9999
                                        }}>
                                            <div style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                width: "80%",
                                                transform: "translate(-50%, -50%)",
                                                zIndex: 999
                                            }}>
                                                <FilesSelect
                                                    hiddenIds={(props?.files?.uploadedFiles || []).map((item: any) => item?._id)}
                                                    onValueSubmit={(value: any) => {
                                                        api.put(`/files/version/${props?.companies?.company?._id}/${file?._id}`, {
                                                            fileId: value?.value
                                                        }).then((response: any) => {
                                                            toast.success(response.data?.response?.message);
                                                        }).catch((error: any) => {
                                                            const errorMessage = error?.response?.data?.message || error?.message;
                                                            toast.error(errorMessage)
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </td>}
                                    {props?.cdn?.uploadCompleted && <td className="tags-edit">
                                        <input
                                            type="text"
                                            placeholder={t("POPUPS.UPLOAD_FILES.TAGS_PLACEHOLDER")}
                                            className="form-control"
                                            onBlur={(e: any) => {
                                                const tags = e.target.value.split(",").map((item: any) => item.trim());
                                                if (tags) {
                                                    api.put(`/files/${file?._id}`, {
                                                        tags: tags,
                                                        company: props?.companies?.company?._id
                                                    }).then((response: any) => {
                                                        toast.success(response.data?.response?.message);
                                                    }).catch((error: any) => {
                                                        const errorMessage = error?.response?.data?.message || error?.message;
                                                        toast.error(errorMessage)
                                                    })
                                                }
                                            }}
                                        />
                                        <small>{t("POPUPS.UPLOAD_FILES.SEPERATE_TAGS_WITH_COMMAS")}</small>
                                    </td>}
                                    {!props?.cdn?.uploadCompleted && <td className="file-size">
                                        {file?.fileSize}
                                    </td>
                                    }
                                    {props?.cdn?.uploadCompleted && (
                                        <>
                                            {
                                                file?.source?.sourceKey === "google-drive" ? (<td className="file-size"><Button variant='link' onClick={(e: any) => props?.setFileToGenericPermissionModal({ _id: file?._id, file: file })}><FeatherIcons icon={'lock'}></FeatherIcons></Button></td>) : (<td className="file-size">-</td>)
                                            }
                                        </>
                                    )
                                    }
                                    {props?.cdn?.uploadCompleted && <td className="file-source">
                                        <img src={file?.source?.logo} alt="file" width="35px" height="auto" />
                                    </td>
                                    }
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        </div>
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(FilesTable);