import React, { useEffect, useState } from "react";

import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { setFileData, editTemplate, createDocumentFromTemplate } from "@redux/files/index.actions";
import { toggleGenericTemplateGoogleDocsIframeModal, toggleGenericGoogleDocsIframeModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";

import './index.scss';
import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";

const mapStateToProps = (state: any) => ({
  components: state?.components,
  files: state?.files
});
const mapActionsToProps = {
  toggleGenericTemplateGoogleDocsIframeModal,
  setFileData,
  editTemplate,
  createDocumentFromTemplate,
  toggleGenericGoogleDocsIframeModal
};


const TemplateIndex = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [newDocumentTitle, setNewDocumentTitle] = useState("");
  const [createDocumentModal, setCreateDocumentModal] = useState(false);

  const toggleCreateDocumentModal = () => {
    setCreateDocumentModal((prevstate) => !prevstate);
  }

  const handleSaveTitle = (e: any) => {
    if (!e?.target?.value || e?.target?.value == props?.files?.templateData?.filename || !props?.files?.templateData?._id) {
      return false;
    }
    props?.editTemplate({
      name: e?.target?.value,
      templateId: props?.files?.templateData?._id
    }).then((res: any) => {
      console.log(res)
    })
  }

  return (
    <>
      <Modal
        show={props?.components?.templateGoogleDocsIFrameModal}
        onHide={() => {
          props?.setFileData({});
          props?.toggleGenericTemplateGoogleDocsIframeModal()
        }}
        size="xl"
        centered
        dialogClassName="modal-google-iframe"
        contentClassName="w-100 h-100"
        onClose={(event: any) => console.log('222##################onClose={(event: any) => console.log(event)}')}
      >
        <Modal.Header closeButton className="bg-secondary">
          <h4 className="modal-title text-white">
            {t('TEMPLATES.TEMPLATE_EDITOR')}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="d-flex align-items-center justify-content-between">
              <div>
                <form onSubmit={event => handleSaveTitle(event)} onBlur={event => handleSaveTitle(event)}>
                  <FormInput
                    className="form-control"
                    style={{
                      minWidth: '400px'
                    }}
                    name="title"
                    type="text"
                    rows={5}
                    defaultValue={props?.files?.templateData?.filename}
                  />
                </form>
              </div>
              <div>
                <Button variant="primary" onClick={e => setCreateDocumentModal(true)}>{t('TEMPLATES.CREATE_DOCUMENT')}</Button>
              </div>
            </Col>
          </Row>
          <Row className="h-100">
            <Col md={props?.files?.templateData?.customForm?.formFields?.length > 0 ? 10 : 12}>
              <iframe className="google-docs-iframe"
                src={`https://docs.google.com/${props?.files?.templateData?.docType}/d/${props?.files?.templateData?.key}?rm=embedded`}></iframe>
            </Col>
            {props?.files?.templateData?.customForm?.formFields?.length > 0 && (
              <Col md={2} className="mt-3">
                <h4>Custom Fields</h4>
                <ListGroup>
                  {(props?.files?.templateData?.customForm?.formFields.filter((e: any) => e.type === 'CustomFields' ? e : null) || []).map((formField: any, i: number) => {

                    return (<ListGroup.Item className="list-item">
                      <div className="d-flex align-items-center justify-content-between">
                        <span>{formField?.title}</span>
                        <Button
                          variant="link"
                          onClick={event => {
                            toast.info('Key copied! You can paste to editor.')
                            navigator.clipboard.writeText(`{{${formField?.field?.key}}}`)
                          }}

                        >
                          <i className="icon">
                            <span>
                              <FeatherIcons icon="copy"></FeatherIcons>
                            </span>
                          </i>
                        </Button>
                      </div>
                    </ListGroup.Item>)
                  })}
                </ListGroup>


              </Col>
            )}

          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={createDocumentModal}
        onHide={toggleCreateDocumentModal}
        centered
        contentClassName="modal-shadow-border"
      >
        <Modal.Header closeButton className="bg-secondary">
          <h4 className="modal-title text-white">
            {t('TEMPLATES.DOCUMENT_NAME')}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <label htmlFor="">{t('TEMPLATES.PROVIDE_DOCUMENT_TITLE')}</label>
              <FormInput
                className="form-control mb-2"
                onInput={(e: any) => setNewDocumentTitle(e?.target.value)}
                name="title"
                type="text"
                rows={5}
                defaultValue={props?.files?.templateData?.filename + ' - Copy'}
              />
              <Button
                disabled={props?.files?.creatingDocumentFromTemplate}
                onClick={(() => {

                  if (!newDocumentTitle || newDocumentTitle == "") {
                    toast.error(t('TEMPLATES.PROVIDE_DOCUMENT_TITLE'))
                    return false;
                  }
                  props?.createDocumentFromTemplate({
                    templateId: props?.files?.templateData?._id,
                    newfilename: newDocumentTitle
                  }).then((res: any) => {
                    console.log('Document created', res?.node?.document)
                    props?.setFileData({ file: res?.node?.document, documentTypeForUrl: res?.node?.document?.fileExtra?.documentType });
                    props?.toggleGenericTemplateGoogleDocsIframeModal();
                    toggleCreateDocumentModal();
                    props?.toggleGenericGoogleDocsIframeModal();
                    //history.push('/files-management')
                  })

                })}>

                {props?.files?.creatingDocumentFromTemplate ? (<>
                  <Spinner size="sm" key={0} className="mr-2" color={'#ffffff'}></Spinner> {t('TEMPLATES.CREATING_DOCUMENT')}
                </>) : (<>
                  <FiFilePlus style={{ marginRight: 10 }} size={22} /> {t('TEMPLATES.CREATE_DOCUMENT')}
                </>)}

              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(TemplateIndex);
