import React, { useEffect, useState } from "react";
import FlatList from "@components/FlatList";
import { connect } from "react-redux";
import FeatherIcons from "feather-icons-react";
import Avatar from "react-avatar";
import { getContractCompaniesDetail, removeCompanyToContract } from '@redux/contract/index.actions';
import { DefaultLoader } from '@components/Loader';
import _ from "lodash";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const mapStateToProps = (state: any) => ({
    users: state.users,
    companies: state.companies,
    contracts: state.contracts
})

const mapActionsToProps = { getContractCompaniesDetail, removeCompanyToContract }


const Companies: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const companies = props?.contracts?.contractOverview?.contractCompanies;
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<[]>([]);

    const handleRemove = (data: {
        _id: string
    }) => {
        const requestData: {
            contractId: string
            companyId: string
            removedCompany: string
        } = {
            contractId: props?.contracts?.contractOverview?._id,
            companyId: props?.companies?.currentCompany,
            removedCompany: data?._id
        }

        props?.removeCompanyToContract(requestData);
    }

    useEffect(() => {
        if (companies) {
            props?.getContractCompaniesDetail({
                contractId: props?.contracts?.contractOverview?._id,
                companyId: props?.companies?.currentCompany,
            }).then((res: any) => {
                setData(res?.node?.contractCompanies);
                setLoading(false)
            }).catch(() => setLoading(false))
        } else {
            setData([]);
            setLoading(false)
        }

    }, [companies])
    return (
        <>
            <div className="flat-scroll" id="companiesList">
                {loading ? (
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                ) : (

                    data.length ? (data.filter((e: any) => e.partiesSide === props?.partiesSide) || [])?.map((item: any) => {
                        return (

                            <div key={'companies' + item?._id} className="d-flex justify-content-between align-items-center gap-1 flex-wrap p-1">
                                <span className="d-flex justify-content-center align-items-center gap-1">
                                    <span>
                                        <Avatar name={item?.title} className="rounded-circle" size="25" />
                                    </span>
                                    <span className="text-dark text-nowrap">
                                        {item?.title}
                                    </span>
                                </span>
                                <Button
                                    onClick={event => handleRemove(item)}
                                    className="btn btn-sm btn-link text-danger outline-0 border-0">
                                    <i className="icon">
                                        <span>
                                            <FeatherIcons icon="trash-2"></FeatherIcons>
                                        </span>
                                    </i>
                                </Button>
                            </div>

                        )
                    }) : (<div className="d-flex align-items-center justify-content-start" style={{
                        minHeight: '50px',
                        width: '100%',
                        color: '#ccc'
                    }}>
                        {t("CONTRACTS.OVERVIEW.COMPANIES.NO_COMPANY_FOUND")}
                    </div>)
                )}
            </div>

        </>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(Companies);
