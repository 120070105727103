import React, { useEffect, useMemo, useState } from "react";

import QuillEditor from "@components/QuillEditor";
import { Accordion, Button, Card, Col, Form, ListGroup, Modal, Row, Table } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { setFileData, editTemplate, createDocumentFromTemplate } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";

import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import ContractOverviewRightPanel from "@popups/ContractOverview/components/ContractOverviewRightPanel";
import { BsLock, BsUnlock, BsShieldLock } from "react-icons/bs";
import { MdLock, MdLockOpen, MdLockOutline } from "react-icons/md";

import {
  getContractById, getContractTypes,
  getCompanyStatuses,
  getCompanyPipelines,
  editContractDetails,
  resetContractOverview
} from "@redux/contract/index.actions";


import './index.scss';
import { DefaultLoader } from "@components/Loader";
import InfoCards from "./components/InfoCards";
import StatusBar from "./components/StatusBar";
import Avatar from "react-avatar";
import AssignedUsers from "@popups/ContractOverview/components/AssignedUsers";
import AssignedUsersFlatList from "./components/AssignedUsersFlatList";
import Companies from "./components/Companies";
import CompaniesFlatList from "./components/CompaniesFlatList";
import Tags from "./components/Tags";
import Reminder from "./components/Reminder";
import CDNUploadPopup from "@popups/CDNUploadPopup";
import Files from "./components/Files";
import EditModal from "./components/EditModal";
import DeleteModal from "./components/DeleteModal";
import PageTitle from "@components/PageTitle";
import OverView from "@pages/widgets/OverView";
import Overview from "./Overview";

const mapStateToProps = (state: any) => ({
  components: state?.components,
  contracts: state?.contracts,
  files: state?.files
});
const mapActionsToProps = {
  toggleGenericContractOverviewModal,
  getContractById,
  getContractTypes,
  getCompanyStatuses,
  getCompanyPipelines,
  editContractDetails,
  resetContractOverview
};



const ContractOverviewModal = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();



  if (props?.contracts?.contractOverview?._id) {
    history.push(`?c=${props?.contracts?.contractOverview?._id}`)
  } else {
    history.push(`?`)
  }

  return (
    <>

      <Modal
        show={props?.components?.contractOverviewModal}
        onHide={() => {
          props?.toggleGenericContractOverviewModal({
            contractId: null,
            overview: {}
          });
        }}
        size="xl"
        centered
        contentClassName="contract-overview-modal"
        dialogClassName="contract-overview-modal-dialog"
        backdropClassName="contract-overview-modal-backdrop"
      >

        <Modal.Body className="p-0">

          <Overview modalView={true} padding={true} />

        </Modal.Body>
      </Modal>




    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ContractOverviewModal);
