import axios, { AxiosInstance } from 'axios';
import config from '@config/index';
import { useLocation } from "react-router-dom";

const apiInstance = axios.create({
  baseURL: "https://subservices.jetlexa.com/api",
  //baseURL: "https://subservices-staging.jetlexa.com/api",
  //baseURL: "http://localhost/api",

  headers: {
    "Content-Type": "application/json",
  },
});

export const apiLoginInstance = axios.create({
  baseURL: "https://subservices.jetlexa.com/api",
  //baseURL: "https://subservices-staging.jetlexa.com/api",
  //baseURL: "http://localhost/api",
  headers: {
    "Content-Type": "application/json",
  },
});

const token: string | null = localStorage.getItem('users::token');
const authorization: string | null = localStorage.getItem('users::Authorization');


const logoutRedirect = () => {
  localStorage.removeItem('users::token');
  localStorage.removeItem('users::Authorization');
  localStorage.removeItem('users::refresh_token');
  window.location.href = "/login";
  return;
}

apiInstance.interceptors.response.use(response => {
  return response;

}, async (error: {
  response: any,
  config: {
    headers: {
      Authorization: string
    }
  },
}) => {

  const refresh_token = localStorage.getItem('users::refresh_token');
  const token = localStorage.getItem('users::token');

  if (!refresh_token || !token) {
    return false;
  }

  if (window.location.href.includes('login')) {
    return false;
  }

  if (window.location.href.includes('confirm')) {
    return false;
  }

  if (error?.response?.data?.response?.code === "REFRESH_TOKEN_EXPIRED_OR_NOT_FOUND") {
    logoutRedirect();
    return;
  }

  if (error?.response?.data?.response?.code === "TOKEN_EXPIRED") {

    const refresh_token = localStorage.getItem('users::refresh_token');
    const token = localStorage.getItem('users::token');


    if (!refresh_token) {
      logoutRedirect();
      return;
    }



    await apiInstance.post('/users/verify/refresh-token', {
      refresh_token: refresh_token
    }).then(async (response) => {
      const refreshToken = response?.data?.authorization?.refresh_token;
      const accessToken = response?.data?.authorization?.token;

      if (accessToken && refreshToken) {
        await new Promise((resolve, reject) => {
          return resolve({ accessToken, refreshToken });
        })
          .then(async (res: any) => {
            localStorage.setItem('users::token', res?.accessToken);
            localStorage.setItem('users::refresh_token', res?.refreshToken);
            localStorage.setItem('users::Authorization', 'Bearer ' + res?.accessToken);
            if (res?.accessToken) {
              const Authorization: string = `${"Bearer"} ${res?.accessToken}`;
              apiInstance.defaults.headers.common.Authorization = Authorization;
              error.config.headers.Authorization = Authorization;
            } else {
              logoutRedirect();
            }
            await apiInstance.request(error.config).then(() => window.location.reload())
          })
          .catch(() => {
            logoutRedirect();
          });

      } else {
        logoutRedirect();
      }
    }).catch((error) => {
      logoutRedirect();
    });


  }

  return Promise.reject(error);
});


if (token) {
  const Authorization: string = `${authorization || "Bearer"} ${token}`;
  apiInstance.defaults.headers.common.Authorization = Authorization;
  apiLoginInstance.defaults.headers.common.Authorization = Authorization;
} else {
}



export default apiInstance;
