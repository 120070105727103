import React, { useEffect, useState } from "react";

import { Card, ListGroup, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { getListCustomFields } from "@redux/contract/index.actions";
import { toggleGenericAddCustomFieldModal } from "@redux/components/index.actions";

import { connect } from "react-redux";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts
});
const mapActionsToProps = {
  getListCustomFields,
  toggleGenericAddCustomFieldModal
};

const FieldList = (props: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.getListCustomFields();
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex align-items-center justify-content-between">
        <Card.Title className="m-0 p-0" as={'h5'}>{t("CONTRACTS_TYPES.CUSTOM_FIELDS")}</Card.Title>
        <Button onClick={props.toggleGenericAddCustomFieldModal}>
          + {t("CONTRACTS_TYPES.ADD_NEW")}
        </Button>

      </Card.Header>
      <Card.Body>
        <Droppable key={1} droppableId={"fieldList"}>
          {(provided, snapshot) => (
            <div className="field-list" ref={provided.innerRef}>
              <ListGroup as="ul">
                {(props?.contracts?.fields || []).map((item: any, index: number) => (
                  <Draggable
                    key={item?._id}
                    draggableId={item?._id + ""}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListGroup.Item
                          key={index}
                          as="li"
                          className="rounded mb-1 border-1"
                        >
                          <div className="d-flex gap-1 align-items-center justify-content-between">
                            <span>{item?.title}</span>
                            <OverlayTrigger
                              key={"kanban-tt-dp" + props?.pipeline?._id}
                              placement="right"
                              overlay={<Tooltip>
                                <strong>{t("CONTRACTS_CUSTOM_FIELDS.INTERNAL_NAME")}:</strong> {item?.key}
                                <br /><strong>{t("CONTRACTS_CUSTOM_FIELDS.FIELD_TYPE")}:</strong> {item?.CustomComponent}
                                <br /><strong>{t("CONTRACTS_CUSTOM_FIELDS.REQUIRED")}:</strong> {item?.isOptional === true ? 'Required' : 'Not Required'}
                              </Tooltip>}
                            >
                              <i className="icon">
                                <span>
                                  <FeatherIcons icon={'info'} />
                                </span>
                              </i>
                            </OverlayTrigger>

                          </div>
                        </ListGroup.Item>
                      </div>
                    )}
                  </Draggable>
                ))}
              </ListGroup>
              {provided.placeholder}
            </div>
          )}
        </Droppable>


      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(FieldList);
