import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';

import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import api from '@clients/api.client';

import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";

import { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import { toggleGenericSuccessModal } from '@redux/utils/components/GenerateSuccessModal';
import { toast } from 'react-toastify';
import cdnservices from '@clients/cdnservices.client';
import { AxiosError, AxiosResponse } from 'axios';
import customCDNServices from '@clients/customcdn.client';

function toFormData(o: any) {
    return Object.entries(o).reduce((d: any, e: any) => (d.append(...e), d), new FormData())
}

/* Login a user */
export const getFiles: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.GET_FILES,
        payload: payload
    });
    const company = getState()?.companies?.company?._id;
    return api.get(`/companies/${company}/files`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 50,
            ...payload?.more
        }
    }).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.GET_FILES_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_FILES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}


export const createFiles: TAction = (payload: any) => async (dispatch, getState) => {

    dispatch({
        type: ActionDispatchTypes.CREATE_FILES,
        payload: payload
    });

    const company = getState()?.companies?.company?._id;

    return api.post(`/files`, {
        ...payload,
        company: company,
        source: 'google-drive'
    }).then(response => {
        toast.success(response.data?.response?.message || "File created successfully");
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.CREATE_FILES_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.CREATE_FILES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })

}

export const updateFile: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.UPDATE_FILES,
        payload: payload
    })
    api.put(`/files/${payload?._id}`, {
        filename: payload?.filename || null,
        tags: payload?.tags || null
    }).then((response: any) => {
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.UPDATE_FILES_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPDATE_FILES_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })

}

export const deleteFile: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.DELETE_FILES,
        payload: payload
    })

    api.delete(`/files/${payload?._id}`).then((response: any) => {
        //toggleGenericSuccessModal(dispatch, response?.data?.response);
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.DELETE_FILES_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_FILES_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const getFileVersions: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.GET_FILE_VERSIONS,
        payload: payload
    })

    return api.get(`/files/version/${getState()?.companies?.company?._id}/${payload?._id}`).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_FILE_VERSIONS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;

    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.GET_FILE_VERSIONS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const getExportLinks: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.GET_EXPORT_LINKS,
        payload: payload
    })

    return api.get(`/export/google-docs/export/${getState()?.companies?.company?._id}/${payload?._id}`).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_EXPORT_LINKS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;

    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.GET_EXPORT_LINKS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const removeFileVersionsFromStore: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.REMOVE_FILE_VERSION,
        payload: null
    })
}

export const removeFileFromVersions: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.REMOVE_FILE_FROM_VERSIONS,
        payload: payload
    })

    return api.delete(`/files/version/${getState()?.companies?.company?._id}/${payload?._id}`).then((response: any) => {
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.REMOVE_FILE_FROM_VERSIONS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.REMOVE_FILE_FROM_VERSIONS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const setFileData: TAction = (payload: any) => async (dispatch, getState) => {
    console.log('first', payload)
    dispatch({
        type: ActionDispatchTypes.SET_FILE_DATA_SUCCESSFUL,
        payload: payload
    })
}

export const createTemplate: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.CREATE_TEMPLATE,
        payload: payload
    })

    return api.post(`/templates/${getState()?.companies?.company?._id}`, { type: payload?.type || 'document' }).then((response: any) => {
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.CREATE_TEMPLATE_SUCCESSFUL,
            payload: response.data.node,
            documentType: payload?.type
        })
        dispatch({
            type: ActionDispatchTypes.TOGGLE_GENERIC_TEMPLATE_GOOGLE_DOCS_IFAME_MODAL,
            payload: !getState().components.templateGoogleDocsIFrameModal
        });
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.CREATE_TEMPLATE_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const setTemplateData: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.SET_TEMPLATE_DATA,
        payload: payload
    })

    if (payload?.showModal != false) {
        dispatch({
            type: ActionDispatchTypes.TOGGLE_GENERIC_TEMPLATE_GOOGLE_DOCS_IFAME_MODAL,
            payload: !getState().components.templateGoogleDocsIFrameModal
        });
    }

}
export const setTemplateDataInCustomForms: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.SET_TEMPLATE_DATA_IN_CUSTOM_FORMS,
        payload: payload,
        customFormsData: payload?.setFields === false ? {} : getState().customForms.selectedForm.formFields
    })

    if (payload?.showModal != false) {
        dispatch({
            type: ActionDispatchTypes.TOGGLE_GENERIC_TEMPLATE_GOOGLE_DOCS_IFAME_MODAL,
            payload: !getState().components.templateGoogleDocsIFrameModal
        });
    }

}


export const editTemplate: TAction = (payload: any) => async (dispatch, getState) => {



    dispatch({
        type: ActionDispatchTypes.EDIT_TEMPLATE,
        payload: payload
    })

    return api.put(`/templates/${getState()?.companies?.company?._id}/${payload?.templateId}`, { ...payload }).then((response: any) => {
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.EDIT_TEMPLATE_SUCCESSFUL,
            payload: response.data.node
        })
        dispatch({
            type: ActionDispatchTypes.CUSTOM_FORMS_TEMPLATE_REFRESH,
            payload
        })

        dispatch({
            type: ActionDispatchTypes.CUSTOM_FORMS_TEMPLATE_REFRESH_SUCCESSFUL,
            payload
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.EDIT_TEMPLATE_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const createDocumentFromTemplate: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.CREATE_DOCUMENT_FROM_TEMPLATE,
        payload: payload
    })

    return api.post(`/templates/copy/${getState()?.companies?.company?._id}/${payload?.templateId}`, { ...payload }).then((response: any) => {
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.CREATE_DOCUMENT_FROM_TEMPLATE_SUCCESSFUL,
            payload: response.data.node
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message);
        dispatch({
            type: ActionDispatchTypes.CREATE_DOCUMENT_FROM_TEMPLATE_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const getTemplates: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_TEMPLATES_LIST,
        payload
    })


    return api.get(`/templates/list/${company}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_TEMPLATES_LIST_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_TEMPLATES_LIST_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const getTemplatesForCustomForms: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    dispatch({
        type: ActionDispatchTypes.GET_TEMPLATES_LIST,
        payload
    })


    return api.get(`/templates/list/custom-forms/${company}`, {
        params: {
            page: payload?.page || 1,
            batchSize: payload?.batchSize || 10,
            ...payload?.more
        }
    }).then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_TEMPLATES_LIST_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_TEMPLATES_LIST_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const deleteTemplate: TAction = (payload: any) => (dispatch: any, getState: any) => {
    const company = getState().companies?.company?._id;
    const templateId = payload?.templateId;
    dispatch({
        type: ActionDispatchTypes.DELETE_TEMPLATE,
        payload
    })


    return api.delete(`/templates/delete/${company}/${templateId}`).then((response: any) => {
        toast.success(response?.data?.response?.message);
        dispatch({
            type: ActionDispatchTypes.DELETE_TEMPLATE_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_TEMPLATE_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

/* CDN */

export const cdnStartUpload: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.CDN_START_UPLOAD,
        payload: payload
    })
}

export const cdnSetFiles: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.CDN_SET_FILES,
        payload: payload
    })
}

export const cdnSetPickerFiles: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.CDN_SET_PICKER_FILES,
        payload: payload
    })
}

export const cdnIsImportedFiles: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.CDN_IS_IMPORTED_FILES,
        payload: payload
    })
}

export const setRemoveCDNExportSource: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.SET_REMOVE_CDN_EXPORT_SOURCE,
        payload: payload
    })
}


export const uploadFilesToGoogleDrive: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.UPLOAD_FILES_CDN,
        payload: payload
    })

    const formData = new FormData();
    for (let i = 0; i < payload?.files?.length; i++) {
        formData.append('files', payload?.files[i]?.file);
    }
    //formData.append('files', payload?.files.map((file: any) => file?.file)[0]);

    if (payload?.contract) {
        formData.append('contract', payload?.contract);
    }

    if (payload?.importedSource) {
        formData.append('importedSource', payload?.importedSource);
    }

    return cdnservices.post('/gdrive/files', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((response: AxiosResponse) => {
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_SUCCESSFUL,
            payload: {
                response: response?.data,
                index: payload?.index
            }
        })
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const uploadFilesToOneDrive: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.UPLOAD_FILES_CDN,
        payload: payload
    })

    const formData = new FormData();
    for (let i = 0; i < payload?.files?.length; i++) {
        formData.append('files', payload?.files[i]?.file);
    }
    //formData.append('files', payload?.files.map((file: any) => file?.file)[0]);

    if (payload?.contract) {
        formData.append('contract', payload?.contract);
    }

    if (payload?.importedSource) {
        formData.append('importedSource', payload?.importedSource);
    }

    return cdnservices.post('/onedrive/files', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((response: AxiosResponse) => {
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_SUCCESSFUL,
            payload: {
                response: response?.data,
                index: payload?.index
            }
        })
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const uploadFilesToSharePoint: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.UPLOAD_FILES_CDN,
        payload: payload
    })

    const formData = new FormData();
    for (let i = 0; i < payload?.files?.length; i++) {
        formData.append('files', payload?.files[i]?.file);
    }
    //formData.append('files', payload?.files.map((file: any) => file?.file)[0]);

    if (payload?.contract) {
        formData.append('contract', payload?.contract);
    }

    if (payload?.importedSource) {
        formData.append('importedSource', payload?.importedSource);
    }

    return cdnservices.post('/sharepoint/files', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((response: AxiosResponse) => {
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_SUCCESSFUL,
            payload: {
                response: response?.data,
                index: payload?.index
            }
        })
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const uploadFilesToS3: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.UPLOAD_FILES_CDN,
        payload: payload
    })

    const formData = new FormData();
    for (let i = 0; i < payload?.files?.length; i++) {
        formData.append('files', payload?.files[i]?.file);
    }

    if (payload?.contract) {
        formData.append('contract', payload?.contract);
    }
    //formData.append('files', payload?.files.map((file: any) => file?.file)[0]);
    if (payload?.importedSource) {
        formData.append('importedSource', payload?.importedSource);
    }

    return cdnservices.post('/s3/files', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((response: AxiosResponse) => {
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_SUCCESSFUL,
            payload: {
                response: response?.data,
                index: payload?.index
            }
        })
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const uploadFilesToCustomCDN: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.UPLOAD_FILES_CDN,
        payload: payload
    })

    const formData = new FormData();
    for (let i = 0; i < payload?.files?.length; i++) {
        formData.append('files', payload?.files[i]?.file);
    }

    if (payload?.contract) {
        formData.append('contract', payload?.contract);
    }
    //formData.append('files', payload?.files.map((file: any) => file?.file)[0]);

    if (payload?.importedSource) {
        formData.append('importedSource', payload?.importedSource);
    }

    return customCDNServices.post('/local/files', formData, {
        baseURL: getState()?.companies?.company?.customCDNEndpoint,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((response: AxiosResponse) => {
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_SUCCESSFUL,
            payload: {
                response: response?.data,
                index: payload?.index
            }
        })
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPLOAD_FILES_CDN_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const resetStateOnUploadCompleted: TAction = (payload: any) => async (dispatch, getState) => {
    window.location.reload();
    dispatch({
        type: ActionDispatchTypes.RESET_STATE_ON_UPLOAD_COMPLETED,
        payload: payload
    })
}
export const setFileToGenericPermissionModal: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.SET_FILE_TO_GENERIC_PERMISSION_MODAL,
        payload: payload
    })
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_GOOGLE_FILE_PERMISSION_MODAL,
        payload: !getState().components.googleFilePermissionModal,
    });
}

export const closeCDNPopup: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.CLOSE_CDN_POPUP,
        payload: payload
    })
}

export const getUserFilesPicker: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.GET_USER_FILES_PICKER,
        payload: payload
    })

    return cdnservices.get(`/gdrive/user/files/${payload?.key}`).then((response: AxiosResponse) => {
        dispatch({
            type: ActionDispatchTypes.GET_USER_FILES_PICKER_SUCCESSFUL,
            payload: response?.data
        })
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_USER_FILES_PICKER_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}
export const addFilesToContract: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.ADD_FILES_TO_CONTACT,
        payload: payload
    })

    api.post(`/files/contract/${getState()?.companies?.company?._id}`, { ...payload }).then((response: AxiosResponse) => {
        dispatch({
            type: ActionDispatchTypes.ADD_FILES_TO_CONTACT_SUCCESSFUL,
            payload: response?.data
        })
        return response?.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.ADD_FILES_TO_CONTACT_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}