import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Row, Col, Form, Button, Alert } from "react-bootstrap";


import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
// components
import FieldList from "@pages/contractTypes/components/CustomFieldOffcanvas/FieldList";
import DropList from "@pages/contractTypes/components/CustomFieldOffcanvas/DropList";
import ContractTypesFieldList from "@pages/contractTypes/components/CustomFieldOffcanvas/ContractTypesFieldList";

import { connect } from "react-redux";
import { deleteCustomField, setNewContractTypeData, createNewContractType } from "@redux/contract/index.actions";
import { toggleGenericAddCustomFieldModal, toggleGenericAddContractTypeModal } from "@redux/components/index.actions";

// components
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "./index.scss";

const mapStateToProps = (state: any) => ({
  companies: state.companies,
  contracts: state.contracts,
  components: state.components
});
const mapActionsToProps = {
  createNewContractType,
  setNewContractTypeData,
  deleteCustomField,
  toggleGenericAddCustomFieldModal,
  toggleGenericAddContractTypeModal
};

const CreateContractType = (props: any) => {
  const { t } = useTranslation();


  useEffect(() => {
    props.setNewContractTypeData({
      key: "reset",
      data: {}
    })
  }, [])

  const [id, setId] = useState(props?.data?._id);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const onDragEnd = (result: DropResult) => {

    const { source, destination } = result;

    if (source.droppableId === 'fieldList' && destination?.droppableId == 'contractCustomFields') {

      props.setNewContractTypeData({
        key: "addCustomField",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

    if (source.droppableId === 'contractCustomFields' && destination?.droppableId == 'fieldList') {

      props.setNewContractTypeData({
        key: "removeCustomField",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

    if (source.droppableId === 'contractCustomFields' && destination?.droppableId == 'contractCustomFields') {

      props.setNewContractTypeData({
        key: "reOrder",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

  };
  const handleSubmit = () => {
    setLoading(true);
    const newContract = props?.contracts?.newContractType

    interface requestData {
      title: string,
      company: string,
      customFields: [{
        field: string
        value: string
      }]
      showStartDate: boolean
      showEndDate: boolean
      showEffectiveDate: boolean
      showRenewalDate: boolean
      showSignedDate: boolean
    }

    if (!newContract?.title) {
      setError(t("CONTRACTS_TYPES.NO_TITLE"));
      setLoading(false);
      return;
    }


    const requestData: requestData = {
      title: newContract?.title,
      company: props?.companies?.currentCompany,
      customFields: newContract?.customFields?.map((item: any) => {
        return {
          field: item?._id,
          value: ""
        }
      }),
      showStartDate: newContract?.showStartDate,
      showEndDate: newContract?.showEndDate,
      showEffectiveDate: newContract?.showEffectiveDate,
      showRenewalDate: newContract?.showRenewalDate,
      showSignedDate: newContract?.showSignedDate,
    };

    props
      .createNewContractType(requestData)
      .then((res: any) => {
        setLoading(false);
        setError('');
        props?.toggleCreateContractTypeModal();
      })
      .catch((err: any) => {
        setLoading(false);
        setError('');
      });
  };

  return (
    <>
      <Modal
        show={props?.components?.newContractTypeModal}
        onHide={props?.toggleGenericAddContractTypeModal}
        size="xl"
        centered
      >
        <Modal.Header closeButton className="bg-secondary">
          <h4 className="modal-title text-white">{t("CONTRACTS_TYPES.NEW_CONTRACT_TYPE")}</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>

            <Col md={12} className="mb-3">
              <DragDropContext onDragEnd={onDragEnd}>
                <Row>
                  <Col md={4}>
                    <FieldList />
                  </Col>
                  <Col md={8}>
                    {(error) && <Alert variant="danger">
                      {error}
                    </Alert>}
                    <DropList />
                  </Col>
                </Row>
              </DragDropContext>
            </Col>
            <Col md={12} className="d-flex justify-content-end gap-1">
              <Button
                disabled={loading}
                className="btn btn-md btn-dark"
                onClick={handleSubmit}
              >
                {t("CONTRACTS_TYPES.CREATE")}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(CreateContractType);
