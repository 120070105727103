import React, { useEffect, useState } from "react";

import { Modal, Button, Row, Col } from "react-bootstrap";
import { Redirect, useHistory } from 'react-router-dom';
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  deleteContract,
} from "@redux/contract/index.actions";
const mapStateToProps = (state: any) => ({
  companies: state?.companies
});
const mapActionsToProps = {
  deleteContract,
};

const DeleteModal = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const request: {
    id: string
    company: string
  } = {
    id: props?.data?._id,
    company: props?.companies?.currentCompany,
  }

  const handleConfirmRemove = () => {
    props.deleteContract(request).then((res: any) => {
      //history.push('/contract/list');
      if (res !== false) {
        window.location.href = '/contract/table';
      }
    })
  }

  useEffect(() => {
    console.log('DeleteConfirm', props?.data)
  }, [])

  return (
    <Modal
      show={props?.show}
      onHide={props?.toggle}
      size="sm"
      centered
      backdropClassName="contract-overview-modal-backdrop"
    >
      <Modal.Header closeButton className="bg-danger">
        <h4 className="modal-title text-white">{t("Are you sure?")}</h4>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <p>
            You are permanently deleting the contract. Are you sure you want to
            delete?
          </p>
          <Col md={12} className="d-flex justify-content-end gap-1">
            <Button
              className="btn btn-sm"
              onClick={props?.toggle}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-sm btn-danger"
              onClick={handleConfirmRemove}
            >
              Remove
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(DeleteModal);
